import React from "react";
import { 
  EditOutlined,
  DeleteOutlined,
  CloseOutlined,
  CheckOutlined
} from '@ant-design/icons';
import { 
  Form, 
  Input, 
  Button, 
  message,
  Select, 
  InputNumber,
  Table,
  Space,
  Tooltip,
  Popconfirm
} from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { getAPI,getAPIPython } from "../../services/data";
import { 
  setIsEmptyLocalDataDireksi, 
  setDireksi, 
  fetchDireksi
} from '../../store/actions/formulirSlice';

const { Option } = Select;

const errorSubmit = (text) => {
  message.error(text);
};

const success = (text) => {
  message.success(text);
};

const Direksi = (props) => {
  const {masterData} = props
  // const { currentSteps } = props;
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const savedData = useSelector((state) => state.formulir.dataFormulir.direksi);
  const isLoadFromAPI = useSelector(state => state.formulir.loadFromAPI);
  const isEmptyLocalData = useSelector(state => state.formulir.isEmptyLocalData.direksi);
  // const [trigger, setTrigger] = useState(true);
  // const [status,setStatus] = useState(false);
  const [inputDireksi,setInputDireksi] = useState([]);
  // const [ktp,setktp] = useState([])
  const [editingKey, setEditingKey] = useState('');
  const isEditing = (record) => record.id === editingKey;

  //APIs
  // const apiGetInputted = async (id) => {
  //   // console.log("Direksi");
  //   const data = await getAPI("direksi-by-id?id=" + id);
  //   const dataArray = data.data;
  //   console.log(dataArray);

  //   let direksi = dataArray.filter((value) => value['jabatan'].includes("Direktur"));
  //   setDireksi(direksi);
  // };
  const apiGetInputted = async (id) => {
    const res = await dispatch(fetchDireksi(id)).unwrap();
    if(
      (res.length !== 0 && res[0].id_debitur !== id) ||
      (savedData.length !== 0 && savedData[0].id_debitur !== id) ||
      (savedData.length === 0 && isLoadFromAPI && !isEmptyLocalData) 
    ){
      // console.log("Direksi");
      // console.log(dataArray);
      setInputDireksi(res);
      dispatch(setDireksi(res));
      dispatch(setIsEmptyLocalDataDireksi(false));
    }else{
      if(savedData.length === 1) dispatch(setIsEmptyLocalDataDireksi(false));
      setInputDireksi(savedData);
    }
  };

  useEffect(() => {
    // console.log("Direksi FIRED");
    apiGetInputted(masterData)
  }, [savedData, isLoadFromAPI]);


  const content = [
    {
      label: "Nama",
      name: "nama",
      message: "Masukkan nama",
      required: true,
    },
    {
      label: "Jabatan",
      name: "jabatan",
      message: "Masukkan jabatan",
      required: true,
      select_data: [
        {
          id:"Direktur Utama",
          nama_debitur: "Direktur Utama",
          // status:status,
        },
        {
          id:"Direktur",
          nama_debitur: "Direktur"
        }
      ]
    },
    {
      label: "Nomor KTP",
      name: "nomor_ktp",
      message: "Masukkan nomor KTP",
      required: true,
    },
  ];

  const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    const selectJabatan = [
      {
        id:"Direktur Utama",
        nama_debitur: "Direktur Utama",
        // status:status,
      },
      {
        id:"Direktur",
        nama_debitur: "Direktur"
      }
    ]
    const inputNode = (inputType === 'number') ? <InputNumber style={{width: "50%"}} min={0} maxLength={16}/> : (inputType === 'dropdown') ? (
      <Select
      // onClick={() => setTrigger(!trigger)}
      showSearch
      placeholder="Search to Select"
      >
        {selectJabatan.map((item, i) => {
          return (
            <Option value={item.id} key={i}>
              {item.nama_debitur}
            </Option>
          );
        })}
      </Select>
    ) : <Input style={{width: "100%"}}/>;
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            style={{
              margin: 0,
            }}
            rules={[
              {
                required: true,
                message: `Please Input ${title}!`,
              },
            ]}
          >
            {inputNode}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  const edit = (record) => {
    form.setFieldsValue({
      nama: '',
      jabatan: '',
      nomor_ktp: '',
      ...record,
    });
    setEditingKey(record.id);
  };
  const cancel = () => {
    setEditingKey('');
  };
  const save = async (id) => {
    try {
      const row = await form.validateFields();
      const newData = [...inputDireksi];
      const index = newData.findIndex((item) => id === item.id);
      const excludedArray = newData.toSpliced(index, 1);
      if (index > -1) {
        const item = newData[index];

        // Check empty values
        for (let x in row) {
          if (isEmptyOrSpaces(x)) {
            return errorSubmit("Text cannot be empty");
          }
        }

        newData.splice(index, 1, {
          ...item,
          ...row,
        });

        let
          num = /\d/,
          char = /[a-zA-Z]/;

        // Validate input
        for (const vDireksi of excludedArray) {
          if (vDireksi.nomor_ktp == row["nomor_ktp"]) return errorSubmit("KTP sudah pernah diinput");
          if (row["nomor_ktp"].toString().length != 16) return errorSubmit("KTP belum 16 digit");
          if (num.test(row["nama"]) || char.test(row["nomor_ktp"])) return errorSubmit("Invalid Input Format")
        }

        if (row["jabatan"] == "Direktur Utama") {
          // Check duplicate Direktur Utama
          if (excludedArray.find((val, i) => val.jabatan == "Direktur Utama")) return errorSubmit("Direktur Utama Tidak Boleh Lebih Dari 1");
        }

        setInputDireksi(newData);
        dispatch(setDireksi(newData));
        setEditingKey('');
      } else {

        // Check empty values
        for (let x in row) {
          if (isEmptyOrSpaces(x)) {
            return errorSubmit("Text cannot be empty");
          }
        }
        newData.push(row);

        let
        num = /\d/,
        char = /[a-zA-Z]/;

        // Validate input
        for (const vDireksi of excludedArray) {
          if (vDireksi.nomor_ktp == row["nomor_ktp"]) return errorSubmit("KTP sudah pernah diinput");
          if (row["nomor_ktp"].toString().length != 16) return errorSubmit("KTP belum 16 digit");
          if (num.test(row["nama"]) || char.test(row["nomor_ktp"])) return errorSubmit("Invalid Input Format")
        }

        if (row["jabatan"] == "Direktur Utama") {
          // Check duplicate Direktur Utama
          if (excludedArray.find((val, i) => val.jabatan == "Direktur Utama")) return errorSubmit("Direktur Utama Tidak Boleh Lebih Dari 1");
        }

        setInputDireksi(newData);
        dispatch(setDireksi(newData));
        setEditingKey('');
      }
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };
  const deleteRow = async (id) => {

    const newArray = savedData.filter((v) => v.id !== id);
    if(newArray.length === 0) dispatch(setIsEmptyLocalDataDireksi(false));
    dispatch(setDireksi(newArray));

  }

  const columns = [
    {
      title: "Nama",
      dataIndex: "nama",
      key: "nama",
      editable: true,
    },
    {
      title: "Jabatan",
      dataIndex: "jabatan",
      key: "jabatan",
      editable: true,
    },
    {
      title: "Nomor KTP",
      dataIndex: "nomor_ktp",
      key: "nomor_ktp",
      editable: true,
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <Space>
            <Popconfirm title="Save changes?" onConfirm={() => save(record.id)} okButtonProps={{type: "secondary"}}>
              <Button type="secondary" icon={<CheckOutlined />} />
            </Popconfirm>
            <Popconfirm title="Cancel editing?" onConfirm={cancel} okButtonProps={{type: "secondary"}}>
              <Button type="secondary" icon={<CloseOutlined />} />
            </Popconfirm>
          </Space>
        ) : (
          <>
          <Space>
          <Tooltip title="edit">
            <Button disabled={editingKey !== ''} type="secondary" icon={<EditOutlined />} onClick={() => edit(record)}/>
          </Tooltip>
          <Popconfirm title="Confirm deletion?" onConfirm={() => deleteRow(record.id)} okButtonProps={{type: "secondary"}}>
          <Tooltip title="delete">
            <Button type="secondary" icon={<DeleteOutlined />}/>
          </Tooltip>
          </Popconfirm>
          </Space>
          </>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: (col.dataIndex === 'jabatan') ? 'dropdown' : (col.dataIndex === 'nomor_ktp') ? 'number' : 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  // const postData = async (object) => {
  //   const requestOptions = {
  //     method: "POST",
  //     headers: { "Content-Type": "application/json" },
  //     body: JSON.stringify(object),
  //   };

  //   const response = await fetch(process.env.REACT_APP_EXPRESS_SCORING_UI + "direksi", requestOptions);
  //   const result = await response.json();
  //   return result;
  // };

  const isEmptyOrSpaces = (str) => {
    return str === null || str.toString().match(/^ *$/) !== null;
  };

  // const handleSubmit = (values) => {
  //   let data;
  //   let num = /\d/;
  //   let found = false;
  //   let sameKTP = false;
  //   let char = /[a-zA-Z]/;
  //   for (let x in values) {
  //     data = values[x];
  //   }

  //   Object.keys(data).forEach(function (key) {
  //     console.log(data[key]);
  //     if (isEmptyOrSpaces(data[key])) {
  //       found = true;
  //     }
  //   });

  //   ktp.forEach((e) => {
  //     if (e == data["Nomor_KTP"]) sameKTP = true;
  //   });

  //   if (sameKTP) return errorSubmit("KTP sudah pernah diinput");

  //   if(found) return errorSubmit("Text cannot be empty");

  //   if (data["Nomor_KTP"].toString().length != 16) {
  //     return errorSubmit("KTP belum 16 digit");
  //   }

  //   if(num.test(data["Nama"]) || char.test(data["Nomor_KTP"])){
  //     return errorSubmit("Invalid Input Format")
  //   }

  //   data["Id_Debitur"] = dataParent["id"];

    
  //   if(data["Jabatan"] == "Direktur Utama" && status == true){
  //     return errorSubmit("Direktur Utama Tidak Boleh Lebih Dari 1");
  //   }
    
  //   postData(data)
  //   .then((response) => {
  //     console.log(response);
  //       if(response['statusCode'] != '000'){
  //         return errorSubmit(response['message'])
  //       }else{
  //         console.log(ktp);
  //         let ktp_data = data['Nomor_KTP']
  //         setktp([...ktp,ktp_data]);
  //         if(data["Jabatan"] == "Direktur Utama") setStatus(true);
  //         success("Success submit data");
  //         setTrigger(!trigger);
  //         console.log(response);
  //       }
  //     })
  //     .catch((error) => {
  //       errorSubmit("Failed to submit data");
  //       console.log(error);
  //     });
  // };

  const handleSubmit = (values) => {
    // console.log("DIREKSI VALUE",values)
    
    let 
      data = JSON.parse(JSON.stringify(values.values)), 
      num = /\d/,
      char = /[a-zA-Z]/;

    // console.log("DIREKSI VALUE",data)
    
    // Check empty values
    for (let x in data) {
      if (isEmptyOrSpaces(x)) {
        return errorSubmit("Text cannot be empty");
      }
    }

    // Validate input
    for (const vDireksi of inputDireksi) {
      if (vDireksi.nomor_ktp == data["nomor_ktp"]) return errorSubmit("KTP sudah pernah diinput");
      if (data["nomor_ktp"].toString().length != 16) return errorSubmit("KTP belum 16 digit");
      if (num.test(data["nama"]) || char.test(data["nomor_ktp"])) return errorSubmit("Invalid Input Format")
    }

    if (data["jabatan"] == "Direktur Utama") {
      // Check duplicate Direktur Utama
      if (inputDireksi.find((val, i) => val.jabatan == "Direktur Utama")) return errorSubmit("Direktur Utama Tidak Boleh Lebih Dari 1");
    }

    // Assign temporary id
    data["id"] = data["nomor_ktp"];
    data["id_debitur"] = masterData;

    const mergedArray = [...inputDireksi];
    mergedArray.push(data);

    // console.log("DIREKSI VALUE", mergedArray)

    dispatch(setDireksi(mergedArray));
    success("Data tersimpan");
  }

  return (
    <div style={{maxWidth: "100%"}}>
      {/* <p className="text-2xl font-bold text-center my-3">Direktur</p> */}
      <Form
        onFinish={(values) => handleSubmit({ values })}
        name="basic"
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 12,
        }}
        initialValues={{
          remember: true,
        }}
      >
        <div className="grid grid-cols-3">
          {content.map((item, i) => {
            return (
              <Form.Item
                key={i}
                label={item.label}
                name={item.name}
                rules={[
                  {
                    required: item.required,
                    message: item.message,
                  },
                ]}
              >
                {item.select_data?.length > 0 ? (
                  <Select
                    // onClick={() => setTrigger(!trigger)}
                    showSearch
                    placeholder="Search to Select"
                  >
                    {item.select_data.map((item, i) => {
                      return (
                        <Option value={item.id} key={i}>
                          {item.nama_debitur}
                        </Option>
                      );
                    })}
                  </Select>
                ) : item.name == "nomor_ktp" ? (
                  <InputNumber
                    min={0}
                    style={{ width: "100%" }}
                    maxLength={item.name == "nomor_ktp" ? 16 : ""}
                  />
                ) : (
                  <Input />
                )}
              </Form.Item>
            );
          })}
        </div>
        {/* Button Submit */}
        <Form.Item wrapperCol={{ span: 12, offset: 9 }}>
          <Button className="float-right" htmlType="submit">
            Save
          </Button>
        </Form.Item>
      </Form>
      <Form form={form} component={false}>
      <Table 
        dataSource={inputDireksi} 
        columns={mergedColumns} 
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        rowClassName="editable-row"
        bordered
        size="middle"
      />
      </Form>
    </div>
  );
};

export default Direksi;
