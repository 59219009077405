import React from "react";
import { Form, Input, InputNumber, Select } from "antd";
import { useEffect, useState } from "react";
// import { getAPI } from "../../services/data";

const { Option } = Select;

// const errorSubmit = (text) => {
//   message.error(text);
// };

// const success = (text) => {
//   message.success(text);
// };

const WewenangDireksi = (props) => {
  const { masterData, form } = props;
  // const [masterName, setMasterName] = useState([]);
  const [trigger, setTrigger] = useState(true);
  // const [disabled,setDisabled] = useState(false);

  useEffect(() => {
    form.setFieldsValue({
      Wewenang_Direksi_Atas_Pemberian_Atau_Penerimaan_Pinjaman: masterData.wewenang_direksi_atas_pemberian_atau_penerimaan_pinjaman,
      Masa_Berakhir_Direksi: masterData.masa_berakhir_direksi,
      Wewenang_Direksi_Untuk_Mewakili_Perseroan: masterData.wewenang_direksi_untuk_mewakili_perseroan,
      Pasal_Pengambilan_Keputusan_Sirkuler: masterData.pasal_pengambilan_keputusan_sirkuler,
    })
  }, [form, masterData]);

  const content = [
    {
      label: "Wewenang Direksi Atas Pemberian Atau Penerimaan Pinjaman",
      name: "Wewenang_Direksi_Atas_Pemberian_Atau_Penerimaan_Pinjaman",
      message:
        "Masukkan wewenang direksi atas pemberian atau penerimaan pinjaman",
      required: false,
    },
    {
      label: "Masa Berakhir Direksi",
      name: "Masa_Berakhir_Direksi",
      message: "Masukkan masa berakhir direksi",
      required: false,
    },
    {
      label: "Wewenang Direksi Untuk Mewakili Perseroan",
      name: "Wewenang_Direksi_Untuk_Mewakili_Perseroan",
      message: "Masukkan wewenang direksi untuk mewakili perseroan",
      required: false,
    },
    {
      label: "Pasal Pengambilan Keputusan Sirkuler",
      name: "Pasal_Pengambilan_Keputusan_Sirkuler",
      message: "Masukkan pasal pengambilan keputusan sirkuler",
      required: false,
    },
  ];

  return (
    <div>
      {/* <p className="text-2xl font-bold text-center my-3">Wewenang Direksi</p> */}
      {/* Form */}
        {/* Form Content */}
        <div className="grid grid-cols-2">
          {content.map((item, i) => {
            return (
              <Form.Item
                key={i}
                label={item.label}
                name={item.name}
                rules={[
                  {
                    required: item.required,
                    message: item.message,
                  },
                ]}
              >
                {item.select_data?.length > 0 ? (
                  <Select
                    style={{ width: "80%" }}
                    onClick={() => setTrigger(!trigger)}
                    showSearch
                    placeholder="Search to Select"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children.includes(input)
                    }
                    filterSort={(optionA, optionB) =>
                      optionA.children
                        .toLowerCase()
                        .localeCompare(optionB.children.toLowerCase())
                    }
                  >
                    {item.select_data.map((item, i) => {
                      return (
                        <Option value={item.id} key={i}>
                          {item.nama_debitur}
                        </Option>
                      );
                    })}
                  </Select>
                ) : item.name == "Masa_Berakhir_Direksi" ? (
                  <InputNumber style={{ width: "80%" }} min={0}/>
                ) : (
                  <Input style={{ width: "80%" }}/>
                )}
              </Form.Item>
            );
          })}
        </div>
        {/* Button Submit */}
    </div>
  );
};

export default WewenangDireksi;
