import React from 'react'
import { Form, Input, InputNumber, Button, message, Select, Table } from "antd";
import { useState, useEffect } from "react";
import { getAPIPython, getAPI } from "../../services/data";

const {Option} = Select;

const errorSubmit = (text) => {
  message.error(text);
};

const success = (text) => {
  message.success(text);
};

const postData = async (object) => {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(object),
  };

  const response = await fetch(process.env.REACT_APP_PYTHON_SCORING + "capabilities_new_loan",requestOptions);
  console.log(response);
  const result = await response.json();
  console.log(result);
  return result;
};

const NewLoan = (props) => {
  const {masterData,trigger} = props;
  
  const content = [
    {
      label: "Beginning Balance (Principal)",
      name: "Principal_Beg_Balance",
      message: "Masukkan principal beg balance",
      required: true,
    },
    {
      label: "Beginning Balance (Interest Efektif)",
      name: "Interest_Efektif_Beg_Balance",
      message: "Masukkan interest efektif beg balance",
      required: true,
    },
    {
      label: "Beginning Balance (Interest Flat)",
      name: "Interest_Flat_Beg_Balance",
      message: "Masukkan interest flag beginning balance",
      required: true,
    },
    {
      label: "Interest Assumption",
      name: "Interest_Assumption",
      message: "Masukkan Interest Assumption (%)",
      required: true,
    }
  ];

  const handleSubmit = (values) => {
    values['Id_Debitur'] = masterData['id'];
    console.log(values);

    postData(values)
      .then((response) => {
        if (response["statusCode"] == 200) {
          success(response["message"]);
        } else {
          errorSubmit(response["message"]);
        }
      })
      .catch((error) => {
        console.log(error);
        errorSubmit("Internal Server Error");
      });
  }


  return (
    <div>
      <p className="text-2xl font-bold text-center my-5">New Loan</p>
      <Form
        disabled={trigger}
        onFinish={(values) => handleSubmit(values)}
        labelCol={{
          span: 9,
        }}
        wrapperCol={{
          span: 10,
        }}
        initialValues={{
          remember: true,
        }}
      >
        <div className="grid grid-cols-2">
          {content.map((item, i) => {
            return (
              <Form.Item
                key={i}
                label={item.label}
                name={item.name}
                rules={[
                  {
                    required: item.required,
                    message: item.message,
                  },
                ]}
              >
                <InputNumber
                  style={{ width: "100%" }}
                  addonBefore={item.name == "Interest_Assumption" ? "" : "Rp" }
                  addonAfter={item.name == "Interest_Assumption" ? "%" : ""}
                  formatter={(value) =>
                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                />
              </Form.Item>
            );
          })}
        </div>
        <Form.Item wrapperCol={{ span: 12, offset: 9 }}>
          <Button className="float-right" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}

export default NewLoan