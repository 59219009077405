import { configureStore } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage'
import { persistReducer, persistStore } from 'redux-persist'
import { combineReducers } from '@reduxjs/toolkit'

import authReducer from './actions/authSlice'
import homeReducer from './actions/homeSlice'
import formulirReducer from './actions/formulirSlice'
import assumptionReducer from './actions/assumptionSlice'
import creditGivenReducer from './actions/creditGivenSlice'
import bouwheerReducer from './actions/bouwheerSlice'
import industryReducer from './actions/industryRiskSlice'
import debtorProfileReducer from './actions/debtorProfile'
import neracaReducer from './actions/neracaSlice'
import plReducer from './actions/plSlice'
import fsReducer from './actions/fsSlice'

const allReducers = combineReducers({
    auth: authReducer,
    home: homeReducer,
    formulir: formulirReducer,
    assumption: assumptionReducer,
    creditGiven: creditGivenReducer,
    bouwheer: bouwheerReducer,
    industryRisk: industryReducer,
    debtorProfile: debtorProfileReducer,
    neraca: neracaReducer,
    pl: plReducer,
    fs: fsReducer
})

const rootReducer = (state, action) => {
    if (action.type === 'home/resetApp') {
        storage.removeItem('persist:root')
        state = undefined;
    }
    
    return allReducers(state, action);
}

const persistConfig = {
    key: 'root',
    storage,
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
    reducer: persistedReducer,
    devTools: window.location.hostname === "localhost"
})

export const persistor = persistStore(store)