import React from 'react'
import {
  Form, 
  Input, 
  Button, 
  message,
  Select, 
  InputNumber,
  // Table, 
  Divider, 
  Space,
  Tooltip,
  Popconfirm
} from "antd";
import { 
  DeleteOutlined,
} from '@ant-design/icons';
import { useState,useEffect } from "react";
// import { getAPI, getAPIPython } from "../../services/data";
import { useDispatch, useSelector } from 'react-redux';
import CustomTable from '../../components/CustomTable';
import { DebtorProfileTableData } from "./DebtorProfileTableData";
import {
  fetchDataDireksi,
  fetchSummaryHistoris,
  setHistoryPefindo,
  pushHistoryPefindo,
  setInputtedKtp,
  setIsFirstTime,
  setEditing
} from '../../store/actions/debtorProfile'

// const { Option } = Select;

const errorSubmit = (text) => {
  message.error(text);
};

const success = (text) => {
  message.success(text);
};

// const isEmptyOrSpaces = (str) => {
//   return str === null || str.toString().match(/^ *$/) !== null;
// };

// const postData = async (object) => {
//   const requestOptions = {
//     method: "POST",
//     headers: { "Content-Type": "application/json" },
//     body: JSON.stringify(object),
//   };

//   const response = await fetch(
//     process.env.REACT_APP_EXPRESS_SCORING_UI + "historis-pefindo",
//     requestOptions
//   );
//   const result = await response.json();
//   return result;
// };


const HIstorisPefindo = (props) => {
  // const {currentSteps}= props
  const {masterData} = props
  // const dataMaster = masterData['data']

  const dispatch = useDispatch();

  // Load redux state
  const idDebitur = useSelector(state => state.home.id_debitur);
  const summaryHistoris = useSelector(state => state.debtorProfile.historisPefindo);
  const inputtedKTP = useSelector(state => state.debtorProfile.inputtedKtp);
  const isEditing = useSelector(state => state.debtorProfile.isEditing);
  const isFirstTime = useSelector(state => state.debtorProfile.isFirstTime);
  const isLoadingSubmit = useSelector(state => state.debtorProfile.isLoadingSubmit);
  const { colHistorisPefindo, dataSourceHistorisPefindo } = DebtorProfileTableData();

  const [form] = Form.useForm();
  const [direksi,setDireksi] = useState([]);
  const [direksiNames,setDireksiNames] = useState([]);
  // const [trigger, setTrigger] = useState(true);
  // const [inputtedKTP,setInputtedKTP] = useState([]);
  // const [inputtedName,setInputtedName] = useState([]);
  // const [trigger,setTrigger] = useState(false);
  // const [resultHistoris,setResultHistoris] = useState([]);
  // const [refresh, setRefresh] = useState(true);

  //APIs
  // const apiGetDireksi= async () => {
  //   let array = [];
  //   const data = await getAPI("direksi-by-id?id=" + dataMaster['id']);
  //   const direksi = data.data
  //   setDireksi(direksi);

  //   let objPerusahaan = {
  //     "id" : dataMaster['id'],
  //     "nama" : dataMaster['nama_debitur']
  //   }

  //   array.push(objPerusahaan)
  //   for (let x in direksi) {
  //     let obj = {
  //       "id" : direksi[x]["id"],
  //       "nama" : direksi[x]["nama"],
  //     }
  //     array.push(obj);
  //   }
  //   setDireksiNames(array);
  // };

  // const apiGetDireksiResult = async (id) => {
  //   const data = await getAPI("historis-pefindo?id_debitur=" + id);
  //   const resultData = data['data'];
  //   setResultHistoris(resultData);
  // }

  // const handleRefresh = () => {
  //   console.log("Refresh Historis Pefindo Dropdown Nama");
  //   setRefresh(!refresh);
  // };

  // let filteredDireksi = direksiNames.filter((value) => !inputtedName.includes(value['nama']));

  // useEffect(() => {
  //   apiGetDireksi();
  //   console.log("HERE");
  // }, [refresh]);

  // useEffect(() => {
  //   if(dataMaster) apiGetDireksiResult(dataMaster['id'])
  // },[trigger])

  const deleteRow = async (record) => {

    const existingSummary = JSON.parse(JSON.stringify(summaryHistoris));
    const indexToDelete = existingSummary.findIndex((element) => element.nama == record.nama && element.jabatan == record.jabatan);
    existingSummary.splice(indexToDelete, 1);
    dispatch(setHistoryPefindo(existingSummary));

  }

  const handleChange = (value) =>{
    //Check if value is NaN (because ID of each Direksi is always a number)
    if(typeof value === "number"){
      let result = direksi.find(({id}) => id == value);
      // console.log(result);
      form.setFieldsValue({
        nama: result?.nama,
        jabatan: result.jabatan,
      });
    //ID from Data Master is always string
    }else{
      form.setFieldsValue({
        nama: masterData.nama_debitur,
        jabatan: "Perusahaan"
      })
    }
  }

  const content = [
    {
      label: "Nama Direksi",
      name: "id_direksi",
      message: "Masukkan Nama Direksi",
      required: true,
      // select_data:filteredDireksi
      select_data: direksiNames
    },
    {
      label: "Jabatan",
      name: "jabatan",
      message: "Masukkan jabatan",
      required: true,
    },
    {
      label: "Nama",
      name: "nama",
      message: "Masukkan nama",
      required: true,
    },
    {
      label: "Score",
      name: "score",
      message: "Masukkan score",
      required: false,
    },
    {
      label: "Total",
      name: "total",
      message: "Masukkan total",
      required: false,
    },
    {
      label: "Balance",
      name: "balance",
      message: " Masukkan balance",
      required: false,
    },
    {
      label: "Past Due Value",
      name: "past_due_value",
      message: "Masukkan past due values",
      required: false,
    },
    {
      label: "Past Due Days",
      name: "past_due_days",
      message: "Masukkan past due days",
      required: false,
    },
    {
      label: "Keterangan",
      name: "keterangan",
      message: "Masukkan keterangan",
      required: false,
    },
  ];

  // const column = [
  //   {
  //     title: "Jabatan",
  //     dataIndex: "jabatan",
  //     key: "jabatan",
  //   },
  //   {
  //     title: "Nama",
  //     dataIndex: "nama",
  //     key: "nama",
  //   },
  //   {
  //     title: "Score",
  //     dataIndex: "score",
  //     key: "score",
  //   },
  //   {
  //     title: "Total",
  //     dataIndex: "total",
  //     key: "total",
  //     render: (_, record) => {
  //       return <p>{"Rp" + record["total"].toLocaleString()}</p>;
  //     },
  //   },
  //   {
  //     title: "Balance",
  //     dataIndex: "balance",
  //     key: "balance",
  //     render: (_, record) => {
  //       return <p>{"Rp" + record["balance"].toLocaleString()}</p>;
  //     },
  //   },
  //   {
  //     title: "Past Due Value",
  //     dataIndex: "past_due_value",
  //     key: "past_due_value",
  //     render: (_, record) => {
  //       return <p>{"Rp" + record["past_due_value"].toLocaleString()}</p>;
  //     },
  //   },
  //   {
  //     title: "Past Due Days",
  //     dataIndex: "past_due_days",
  //     key: "past_due_days",
  //   },
  //   {
  //     title: "Keterangan",
  //     dataIndex: "keterangan",
  //     key: "keterangan",
  //   },
  //   {
  //     title: "Action",
  //     key: "action",
  //     render: (_, record) => {
  //       return (
  //         <>
  //           {isEditing ? (
  //             <Popconfirm title="Confirm deletion?" onConfirm={() => deleteRow(record)} okButtonProps={{type: "secondary"}}>
  //               <Tooltip title="delete">
  //                 <Button type="secondary" icon={<DeleteOutlined />}/>
  //               </Tooltip>
  //             </Popconfirm>
  //           ) : (
  //             <Tooltip title="delete">
  //               <Button type="secondary" disabled={!isEditing} icon={<DeleteOutlined />}/>
  //             </Tooltip>
  //           )}
  //         </>
  //       )
  //     },
  //   },
  // ];

  const column = [
    ...colHistorisPefindo,
    {
      title: "Action",
      key: "action",
      render: (_, record) => {
        return (
          <>
            {isEditing ? (
              <Popconfirm title="Confirm deletion?" onConfirm={() => deleteRow(record)} okButtonProps={{type: "secondary"}}>
                <Tooltip title="delete">
                  <Button type="secondary" icon={<DeleteOutlined />}/>
                </Tooltip>
              </Popconfirm>
            ) : (
              <Tooltip title="delete">
                <Button type="secondary" disabled={!isEditing} icon={<DeleteOutlined />}/>
              </Tooltip>
            )}
          </>
        )
      },
    }
  ];

  const apiGetDireksi = async (id) => {

    const direksi = await dispatch(fetchDataDireksi(id)).unwrap();
    setDireksi(direksi);

    let array = [];
    if (Boolean(masterData?.id)) {
      array.push({
        label: "Perusahaan",
        options: [
          {
            label : masterData?.nama_debitur,
            value : masterData?.id,
          }
        ]
      })
    } 

    let direksiGroup = [];
    for (let x in direksi) {
      let obj = {
        label : direksi[x].nama,
        value : direksi[x].id,
      }
      direksiGroup.push(obj);
    }
    array.push({
      label: "Direksi / Komisaris",
      options: direksiGroup
    })

    setDireksiNames(array);

  }

  const apiGetSummaryHistoris = async (id) => {
    const res = await dispatch(fetchSummaryHistoris(id)).unwrap();
    if(res.length !== 0){
      if(summaryHistoris.some(item => item.id_debitur !== id)) dispatch(setEditing(true));
    } 
  }

  useEffect(() => {
    if(idDebitur){
      apiGetDireksi(idDebitur);
      if(!isEditing){
        apiGetSummaryHistoris(idDebitur);
      }
    }
  }, [masterData, idDebitur]);

  // const checkKTPDireksi = (data) => {
  //   let result = direksi.find((value) => value[''])
  // }

  const handleSubmit = (values) => {

    const payload = values.values;

    delete payload.id_direksi;

    for (const val in payload) {
      if (Object.hasOwnProperty.call(payload, val)) {
        const element = payload[val];

        if (element == undefined || element == null) {

          if (val == "keterangan") payload[val] = "-"
          else payload[val] = 0

        }

        // if(isEmptyOrSpaces(element)) return errorSubmit("Text cannot be empty !");
        
      }
    }

    payload.id_debitur = idDebitur;

    if (!isFirstTime && isEditing) {

      const existingSummary = JSON.parse(JSON.stringify(summaryHistoris));

      const findIndex = existingSummary.findIndex((element) => element.nama == payload.nama && element.jabatan == payload.jabatan);

      if (findIndex >= 0) {

        existingSummary.splice(findIndex, 1, payload);
        dispatch(setHistoryPefindo(existingSummary));

      } else {

        dispatch(pushHistoryPefindo(payload));

      }

    } else {

      const dataDireksi = direksi.find((value) => value['jabatan'] == payload['jabatan'] && value['nama'] == payload['nama']);
  
      if (payload.jabatan != "Perusahaan" && inputtedKTP.length != 0) {
  
        for (const dataInput of inputtedKTP) {
          
          if (dataInput.ktp == dataDireksi['nomor_ktp'] && dataInput['jabatan'] == dataDireksi['jabatan']) return errorSubmit("Nama dan Posisi sudah pernah diinput");
  
        }
        
      }

      dispatch(pushHistoryPefindo(payload));
      if (payload.jabatan != "Perusahaan") {
        dispatch(setInputtedKtp({
          ktp: dataDireksi.nomor_ktp,
          jabatan: dataDireksi.jabatan
        }));
      }
          
    }

    success("Data tersimpan");

  }

  // const handleSubmit = (values) => {
  //   let data;
  //   let found = false;
  //   let sameKTP = false;
  //   for (let x in values) {
  //     console.log(values);
  //     data = values[x];
  //   }
    
  //   Object.keys(data).forEach(function (key) {
  //     console.log(data[key]);
  //     if(data[key] == undefined || data[key] == null){
  //       if(key != 'Keterangan'){
  //         data[key] = 0
  //       }else{
  //         data[key] = "-"
  //       }
  //     }
      
  //     if(isEmptyOrSpaces(data[key])) {
  //       found = true;
  //     }
  //   });

  //   data['id_debitur'] = dataMaster['id']

  //   console.log(inputtedKTP);
  //   console.log(data);
    
  //   let checkData = direksi.find((value) => value['jabatan'] == data['Jabatan'] && value['nama'] == data['Nama']);
  //   console.log("HASIL NGAMBIL DATA DIREKSI" ,checkData);

  //   if(!data['Jabatan'] == 'Perusahaan'){
  //     inputtedKTP.map((e) => {
  //       if (e['ktp'] == checkData['nomor_ktp'] && e['jabatan'] == checkData['jabatan']) sameKTP = true;
  //     });
  
  //     if(sameKTP) return errorSubmit("Nama dan Posisi sudah pernah diinput")
  //   }

  //   if (!found) {
  //     postData(data)
  //     .then((response) => {
  //       if(response['statusCode'] == '000'){
  //         if(data['Jabatan'] != 'Perusahaan'){
  //           let object = {
  //             ktp : checkData['nomor_ktp'],
  //             jabatan : checkData['jabatan']
  //           }
  //           console.log(object);
  //           setInputtedName([...inputtedName,data['Nama']])
  //           setInputtedKTP([...inputtedKTP,object])
  //         }
  //           setTrigger(!trigger)
  //           success(response['message']);
  //           // setDisabled(true);
  //           // currentSteps("add");
  //           console.log(response);
  //         }else{
  //           errorSubmit(response['message']);
  //         }
  //       })
  //       .catch((error) => {
  //         errorSubmit("Failed to submit data");
  //         console.log(error);
  //       });
  //   } else {
  //     errorSubmit("Text cannot be empty !");
  //   }
  // };

  return (
    <div>
      {/* <p className="text-2xl font-bold text-center my-3">Historis Pefindo</p> */}
      <Divider>Historis Pefindo</Divider>
      {/* Form */}
      <Form
        form={form}
        onFinish={(values) => handleSubmit({ values })}
        disabled={isFirstTime ? true : isEditing ? false : true}
        name="basic"
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 10,
        }}
        initialValues={{
          remember: true,
        }}
      >
        {/* Form Content */}
        <div className="grid grid-cols-3">
          {content.map((item, i) => {
            return (
              <Form.Item
                key={i}
                label={item.label}
                name={item.name}
                rules={[
                  {
                    required: item.required,
                    message: item.message,
                  },
                ]}
              >
                {item.select_data?.length != null ? (
                  // <Select
                  //   onChange={handleChange}
                  //   // onClick={() => setTrigger(!trigger)}
                  //   showSearch
                  //   placeholder="Search to Select"
                  //   optionFilterProp="children"
                  //   filterOption={(input, option) =>
                  //     option.children.includes(input)
                  //   }
                  //   filterSort={(optionA, optionB) =>
                  //     optionA.children
                  //       .toLowerCase()
                  //       .localeCompare(optionB.children.toLowerCase())
                  //   }
                  // >
                  //   {item.select_data.map((item, i) => {
                  //     return (
                  //       <Option value={item.id} key={i}>
                  //         {item.nama}
                  //       </Option>
                  //     );
                  //   })}
                  // </Select>
                  <Select 
                    onChange={handleChange}
                    showSearch
                    placeholder="Search to Select"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.label.includes(input)
                    }
                    filterSort={(optionA, optionB) =>
                      optionA.label
                        .toLowerCase()
                        .localeCompare(optionB.label.toLowerCase())
                    }
                    options={item.select_data}
                  />
                ) : item.label == "Keterangan" ? (
                  <Input
                    placeholder={item.required != true ? "Optional" : ""}
                    disabled={
                      item.name == "nama" || item.name == "jabatan"
                        ? true
                        : isFirstTime ? true : isEditing ? false : true
                    }
                  />
                ) : (
                  <InputNumber
                    style={{ width: "100%" }}
                    min={0}
                    addonBefore={item.name == "past_due_value" || item.name =='balance' || item.name == 'total' ? "Rp" : ""}
                    formatter={(value) =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                    disabled={
                      item.name == "nama" || item.name == "jabatan"
                        ? true
                        : isFirstTime ? true : isEditing ? false : true
                    }
                    placeholder="Optional"
                  />
                )}
              </Form.Item>
            );
          })}
        </div>
        {/* Button Submit */}
        <Form.Item wrapperCol={{ span: 12, offset: 9 }}>
          <Space direction='horizontal' style={{display: 'flex', float: 'right'}}>
            <Button htmlType="submit" type="secondary" loading={isLoadingSubmit}>
              Save
            </Button>
          </Space>
          {/* <Button className="float-right mx-2" onClick={handleRefresh}>
            Refresh
          </Button> */}
        </Form.Item>
      </Form>
      {/* <Table dataSource={resultHistoris} columns={column} pagination={false}/> */}
      <CustomTable 
        columns={column} 
        dataSource={dataSourceHistorisPefindo} 
        // columns={column} 
        // dataSource={summaryHistoris} 
        bordered
        size="small"
      />
    </div>
  );
}

export default HIstorisPefindo