import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { 
    getAPI, 
    putAPI,
    getAPIPython,
    postAPIPython,
} from '../../services/data'

// Collateral To Loan
export const fetchDropdownCollateral = createAsyncThunk(
    'bouwheer/fetchDropdownCollateral',
    async (_, thunkAPI) => {
        try {
            const response = await getAPIPython(`type_of_collateral-get`);
            // console.log("SUKSES API", response)
            return response;
        } catch (error) {
            // console.log("GAGAL API", error)
            throw error
        }
    }
)
export const fetchDropdownRecovery = createAsyncThunk(
    'bouwheer/fetchDropdownRecovery',
    async (_, thunkAPI) => {
        try {
            const response = await getAPIPython(`sifat_recovery_get`);
            // console.log("SUKSES API", response)
            return response;
        } catch (error) {
            // console.log("GAGAL API", error)
            throw error
        }
    }
)
export const fetchCollateralTable = createAsyncThunk(
    'bouwheer/fetchCollateralTable',
    async (idDebitur, thunkAPI) => {
        try {
            const response = await getAPIPython(`view_collateral_to_loan?id_debitur=${idDebitur}`);
            // console.log("SUKSES API", response)
            return response.data;
        } catch (error) {
            // console.log("GAGAL API", error)
            throw error
        }
    }
)

export const fetchCollateralToLoan = createAsyncThunk(
    'bouwheer/fetchCollateralToLoan',
    async (idDebitur, thunkAPI) => {
        try {
            const response = await getAPI(`bouwheer/collateral-to-loan-value?id_debitur=${idDebitur}`);
            // console.log("SUKSES API", response)
            return response;
        } catch (error) {
            // console.log("GAGAL API", error)
            throw error
        }
    }
)
export const CreateCollateralToLoan = createAsyncThunk(
    'bouwheer/CreateCollateralToLoan',
    async (payload, thunkAPI) => {
        try {
            const response = await putAPI(`bouwheer/collateral-to-loan-value?id_debitur=${payload[0].id_debitur}`, payload);
            // console.log("SUKSES API", response)
            return response;
        } catch (error) {
            // console.log("GAGAL API", error)
            throw error
        }
    }
)

// Bouwheer Detail
export const fetchJenisPinjaman = createAsyncThunk(
    'bouwheer/fetchJenisPinjaman',
    async (idJenisPinjaman, thunkAPI) => {
        try {
            const response = await getAPI("jenis-pinjaman");
            // console.log("SUKSES API", response)

            const finder = response.data.find((val) => val.value == idJenisPinjaman);
            if(finder){
                return finder.indicator;
            }
        } catch (error) {
            // console.log("GAGAL API", error)
            throw error
        }
    }
)
export const fetchTypeOfObligor = createAsyncThunk(
    'bouwheer/fetchTypeOfObligor',
    async (_, thunkAPI) => {
        try {
            const response = await getAPIPython(`type_of_obligor-get`);
            // console.log("SUKSES API", response)
            return response.data;
        } catch (error) {
            // console.log("GAGAL API", error)
            throw error
        }
    }
)
export const fetchTypeOfInventory = createAsyncThunk(
    'bouwheer/fetchTypeOfObligor',
    async (_, thunkAPI) => {
        try {
            const response = await getAPIPython(`type_of_inventory-get`);
            // console.log("SUKSES API", response)
            return response.data;
        } catch (error) {
            // console.log("GAGAL API", error)
            throw error
        }
    }
)
export const fetchSummaryTable = createAsyncThunk(
    'bouwheer/fetchSummaryTable',
    async (idDebitur, thunkAPI) => {
        try {
            const response = await getAPI(`bouwheer/bouwheer-summary-view?id_debitur=${idDebitur}`);
            // console.log("SUKSES API", response)
            return response;
        } catch (error) {
            // console.log("GAGAL API", error)
            throw error
        }
    }
)
export const fetchSummaryParam = createAsyncThunk(
    'bouwheer/fetchSummaryParam',
    async (idDebitur, thunkAPI) => {
        try {
            const response = await getAPI(`bouwheer/bouwheer-summary-parameter?id_debitur=${idDebitur}`);
            // console.log("SUKSES API", response)
            return response;
        } catch (error) {
            // console.log("GAGAL API", error)
            throw error
        }
    }
)
export const CreateSummaryParam = createAsyncThunk(
    'bouwheer/CreateSummaryParam',
    async (payload, thunkAPI) => {
        try {
            const response = await putAPI(`bouwheer/bouwheer-summary-parameter?id_debitur=${payload.id_debitur}`, payload);
            // console.log("SUKSES API", response)
            return response;
        } catch (error) {
            // console.log("GAGAL API", error)
            throw error
        }
    }
)

const initialState = {
    collateral: [],
    dataCollateral: [],
    bouwheerDetail: [],
    isLoadingSubmit: false,
    isFirstTime: true,
    isEditing: false,
    isSubmit: false,
    skipUpdateState: false
}

export const bouwheerSlice = createSlice({
    name: 'bouwheer',
    initialState,
    reducers: {
        setCollateral: (state, action) => {
            state.collateral = action.payload
        },
        setDataCollateral: (state, action) => {
            state.dataCollateral = action.payload
        },
        setBouwheerDetail: (state, action) => {
            state.bouwheerDetail = action.payload
        },
        setLoadingSubmit: (state, action) => {
            state.isLoadingSubmit = action.payload
        },
        setEditing: (state, action) => {
            state.isEditing = action.payload
        },
        setIsFirstTime: (state, action) => {
            state.isFirstTime = action.payload
        },
        setIsSubmit: (state, action) => {
            state.isSubmit = action.payload
        }, 
        setSkipUpdateState: (state, action) => {
            state.skipUpdateState = action.payload
        },
        // setLoadFromAPI: (state, action) => {
        //     state.loadFromAPI = action.payload
        // },
        resetBouwheer: () => initialState
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchCollateralTable.fulfilled, (state, action) => {
                state.collateral = action.payload
            })
            .addCase(fetchCollateralToLoan.fulfilled, (state, action) => {
                if(!state.skipUpdateState)
                    state.dataCollateral = action.payload
            })
            .addCase(CreateCollateralToLoan.pending, (state, action) => {
                state.isLoadingSubmit = true
            })
            .addCase(CreateCollateralToLoan.fulfilled, (state, action) => {
                state.isLoadingSubmit = false
            })
            .addCase(CreateCollateralToLoan.rejected, (state, action) => {
                state.isLoadingSubmit = false
            })
            .addCase(fetchSummaryTable.fulfilled, (state, action) => {
                state.bouwheerDetail = action.payload
            })
            .addCase(CreateSummaryParam.pending, (state, action) => {
                state.isLoadingSubmit = true
            })
            .addCase(CreateSummaryParam.fulfilled, (state, action) => {
                state.isLoadingSubmit = false
            })
            .addCase(CreateSummaryParam.rejected, (state, action) => {
                state.isLoadingSubmit = false
            })
    }
})

export const { 
    setCollateral,
    setDataCollateral,
    setBouwheerDetail,
    setLoadingSubmit,
    setEditing,
    setIsFirstTime,
    setIsSubmit,
    setSkipUpdateState,
    resetBouwheer,
    // setLoadFromAPI
} = bouwheerSlice.actions

export default bouwheerSlice.reducer