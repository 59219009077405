import React from "react";
import { Form, Input, Button, message, Select, Divider, Space } from "antd";
import { useState,useEffect } from "react";
// import { getAPIPython,getAPI } from "../services/data";
import MasterData from "../components/MasterData";
import { useDispatch, useSelector } from 'react-redux';
import CustomTable from "../components/CustomTable";
import { IndustryRiskTableData } from "./Industry Risk/IndustryRiskTableData";
import {
  setSummary,
  setTotal,
  setLoadingSubmit,
  setIsFirstTime,
  setEditing,
  fetchDropdownIssue,
  fetchBidangUsaha,
  fetchIndustryRiskParam,
  fetchSummary,
  createIndustryRisk
} from '../store/actions/industryRiskSlice';

const { Option } = Select;

const errorSubmit = (response) => {
  message.error(response);
};

const success = (response) => {
  message.success(response);
};

// const postData = async (object) => {
//   const requestOptions = {
//     method: "POST",
//     headers: { "Content-Type": "application/json" },
//     body: JSON.stringify(object),
//   };

//   const response = await fetch(process.env.REACT_APP_PYTHON_SCORING + "industryrisk",requestOptions);
//   console.log(response);
//   const result = await response.json();
//   console.log(result);
//   return result;
// };

const IndustryRisk = (props) => {
  // const {currentSteps} = props;
  const {masterData} = props
  // const dataMaster = masterData['data'];
  // console.log("INI DI INDUSTRY RISK",dataMaster);
  const dispatch = useDispatch();

    // Load redux state
  const idDebitur = useSelector(state => state.home.id_debitur);
  const idDebiturIR = useSelector(state => state.industryRisk.idDebiturIR);
  const summary = useSelector(state => state.industryRisk.summary);
  const isLoadingSubmit = useSelector(state => state.industryRisk.isLoadingSubmit);
  const isEditing = useSelector(state => state.industryRisk.isEditing);
  const isFirstTime = useSelector(state => state.industryRisk.isFirstTime);
  const { colIndustryRisk, dataSourceIndustryRisk } = IndustryRiskTableData();

  const [form] = Form.useForm();
  const [issue, setIssue] = useState([]);
  // const [ir,setIr] = useState([]);
  // const [trigger, setTrigger] = useState(false);
  // const [refresh, setRefresh] = useState(true);
  // const [spin,setSpin] = useState(false)

  //APIs
  // const apiGetSummaryIndustryRisk = async (id) => {
  //   const data = await getAPIPython("summary_industry_risk?id_debitur=" + id);
  //   setIr(data.data);
  // } 

  // const apiGetIssues = async () => {
  //   const data = await getAPIPython("industryrisk-get");
  //   setIssue(data.data);
  // };

  // const apiGetBidangUsaha = async (bidangUsaha) => {
  //   const data = await getAPI("bidang-usaha");
  //   const result = data['data'];

  //   const currentBidangUsaha = result.find((value) => value['value'] == bidangUsaha);
  //   form.setFieldsValue({
  //     IndustryName : currentBidangUsaha['indicator']
  //   })
  // }

  const apiGetIssues = async () => {
    const res = await dispatch(fetchDropdownIssue()).unwrap();
    setIssue(res);
  };

  const apiGetBidangUsaha = async (bidangUsaha) => {
    const res = await dispatch(fetchBidangUsaha(bidangUsaha)).unwrap();
    // console.log(res)
    form.setFieldsValue({
      IndustryName : res.indicator
    })
  }

  const apiGetSummary = async (id) => {

    const resSummary = await dispatch(fetchSummary(idDebitur)).unwrap();
    if(resSummary.length !== 0){
      dispatch(setIsFirstTime(false));      
      form.setFieldsValue({
        issues : issue.find(item => item.indicator === resSummary[1].input)?.value,
        regulatory_impacts: issue.find(item => item.indicator === resSummary[2].input)?.value,         
        substitution_product : issue.find(item => item.indicator === resSummary[3].input)?.value,
        substitution_raw : issue.find(item => item.indicator === resSummary[4].input)?.value,
        forex_risk : issue.find(item => item.indicator === resSummary[5].input)?.value
      });
    } 

  }

  const handleSubmit = async () => {

    try {
      await form.validateFields();
      
      const payload = JSON.parse(JSON.stringify(form.getFieldsValue()));

      // Delete unused payload
      delete payload.IndustryName;
  
      // Add id_debitur to payload
      payload.id_debitur = idDebitur;
  
      // console.log(payload)
  
      try {
        const resIndustryRiskParam = await dispatch(fetchIndustryRiskParam(idDebitur)).unwrap();
        const { id, ...dataIRParam } = resIndustryRiskParam[0] || {};
        const fetchedDataIR = Object.fromEntries(Object.entries(dataIRParam).sort());
        const inputtedData = Object.fromEntries(Object.entries(payload).sort());

        if(JSON.stringify(fetchedDataIR) !== JSON.stringify(inputtedData)){
          await dispatch(createIndustryRisk(payload)).unwrap()
        }

        await dispatch(fetchSummary(idDebitur)).unwrap()
        dispatch(setEditing(false));
        dispatch(setIsFirstTime(false))
        success("Sukses submit sheet: [Industry Risk]");
  
      } catch (error) {
        errorSubmit("Gagal menyimpan data")
        dispatch(setEditing(false));
      }
    } catch (error) {
      console.error("ERROR VALIDATE FIELDS")
    }

  }
  
  useEffect(() => {
    apiGetIssues();
    if(masterData.length !== 0) apiGetBidangUsaha(masterData.bidang_usaha);
    if(idDebiturIR !== idDebitur) dispatch(setEditing(true)); 
    // apiGetSummary(idDebitur)
    // console.log("INDUSTRY RISK RENDERED")
  }, [masterData, idDebitur]);
  
  useEffect(() => {
    if(idDebitur) apiGetSummary(idDebitur);
  }, [issue, idDebitur]);

  // const handleRefresh = () => {
  //   console.log("Refresh Industry Risk");
  //   setRefresh(!refresh);
  // };

  // useEffect(() => {
  //   apiGetIssues();
  //   if(dataMaster) apiGetBidangUsaha(dataMaster['bidang_usaha'])
  // }, [refresh]);

  // useEffect(() => {
  //   if(trigger) apiGetSummaryIndustryRisk(dataMaster['id'])
  // },[trigger])

  const content = [
    {
      label: "Industry Name",
      name: "IndustryName"
    },
    {
      label: "Issues",
      // name: "Issues",
      name: "issues",
      message: "Masukkan issues",
      required: true,
      select_data: issue,
    },
    {
      label: "Regulatory Impacts",
      // name: "RegulatoryImpacts",
      name: "regulatory_impacts",
      message: "Masukkan regulatory impacts",
      required: true,
      select_data: issue,
    },
    {
      label: "Substitution Product",
      // name: "SubstitutionProduct",
      name: "substitution_product",
      message: "Masukkan substitution product",
      required: true,
      select_data: issue,
    },
    {
      label: "Substitution Raw Material",
      // name: "SubstitutionRaw",
      name: "substitution_raw",
      message: "Masukkan substitution raw",
      required: true,
      select_data: issue,
    },
    {
      label: "Forex Risk",
      // name: "ForexRisk",
      name: "forex_risk",
      message: " Masukkan forex risk",
      required: true,
      select_data: issue,
    },
  ];

  // const columns = [
  //   {
  //     title: "Indikator",
  //     dataIndex: "indikator",
  //     key: "indikator",
  //   },
  //   {
  //     title: "Score",
  //     dataIndex: "score",
  //     key: "score",
  //   },
  //   {
  //     title: "Weight",
  //     dataIndex: "weight",
  //     key: "weight",
  //     render: (_, record) => {
  //       // return <p>{record["weight"] * 100 + "%"} </p>;
  //       return <p>{(record["weight"] * 100).toFixed(0) + "%"} </p>;
  //     },
  //   },
  //   {
  //     title: "Weighted Score",
  //     dataIndex: "weighted_score",
  //     key: "weighted_score",
  //     render: (_, record) => {
  //       // return <p>{record["weight"] * 100 + "%"} </p>;
  //       return <p>{record["weighted_score"].toFixed(2)} </p>;
  //     },
  //   },
  // ];

  // const handleSubmit = async (values) => {
  //   setSpin(true)
  //   let object;
  //   let increment = 0;
  //   let process = false;

  //   for (let x in values) {
  //     object = values[x];
  //   }
  //   object['id_debitur'] = masterData['id']

  //   postData(object)
  //     .then((response) => {
  //       if (response["statusCode"] == "200") {
  //         success(response["message"]);
  //         setTrigger(!trigger);
  //         setSpin(false);
  //         // currentSteps("add");
  //         console.log(response);
  //       }else{
  //         errorSubmit(response["message"]);
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };
  
  return (
    <div>
      {masterData && <MasterData dataMaster={masterData} />}
      {/* <p className="text-2xl font-bold text-center my-5">Industry Risk</p> */}
      <Divider>Industry Risk</Divider>
      {/* Form */}
      <Form
        form={form}
        // disabled={trigger}
        disabled={isFirstTime ? false : isEditing ? false : true}
        // onFinish={(values) => handleSubmit({ values })}
        name="basic"
        labelCol={{
          span: 10,
        }}
        wrapperCol={{
          span: 12,
        }}
        initialValues={{
          remember: true,
        }}
      >
        {/* Form Content */}
        <div className="grid grid-cols-3">
          {content.map((item, i) => {
            return (
              <Form.Item
                key={i}
                label={item.label}
                name={item.name}
                rules={[
                  {
                    required: item.required,
                    message: item.message,
                  },
                ]}
              >
                {item.select_data?.length > 0 ? (
                  <Select placeholder="Select One">
                    {item.select_data.map((item, i) => {
                      return (
                        <Option value={item.value} key={i}>
                          {item.indicator}
                        </Option>
                      );
                    })}
                  </Select>
                ) : item.label != "Industry Name" ? (
                  <Select
                    // onClick={() => setTrigger(!trigger)}
                    showSearch
                    placeholder="Search to Select"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children.includes(input)
                    }
                    filterSort={(optionA, optionB) =>
                      optionA.children
                        .toLowerCase()
                        .localeCompare(optionB.children.toLowerCase())
                    }
                  >
                    {item.debitur_name?.map((item, i) => {
                      return (
                        <Option value={item.id} key={i}>
                          {item.nama_debitur}
                        </Option>
                      );
                    })}
                  </Select>
                ) : (
                  <Input disabled />
                )}
              </Form.Item>
            );
          })}
        </div>
        {/* Button Submit */}
        {/* <Form.Item wrapperCol={{ span: 12, offset: 9 }}>
          <Button className="float-right" htmlType="submit" loading={spin}>
            Submit
          </Button>
          <Button className="float-right mx-2" onClick={handleRefresh}>
            Refresh
          </Button>
        </Form.Item> */}
      </Form>
      <Space direction='vertical' style={{display: 'flex'}} size={"large"}>
      <Space direction='horizontal' style={{display: 'flex', float: 'right', width: '25%'}}>
          <Button htmlType="button" onClick={() => dispatch(setEditing(true))} 
            disabled={isFirstTime ? true : isEditing ? true : false}
          >
            Edit
          </Button>
          <Button htmlType="button" type="primary" danger onClick={() => handleSubmit()} 
            disabled={isFirstTime ? false : isEditing ? false : true} loading={isLoadingSubmit}
          >
            Submit Sheet
          </Button>
      </Space>
      {/* <Table dataSource={ir} columns={columns} pagination={false}/> */}
      <CustomTable 
        dataSource={dataSourceIndustryRisk}
        columns={colIndustryRisk}
        bordered
      />
      </Space>
    </div>
  );
};

export default IndustryRisk;
