import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { 
    getAPI, 
    postAPI,
    putAPI
} from '../../services/data'

export const fetchNeracaTahun = createAsyncThunk(
    'neraca/fetchNeracaTahun',
    async (idDebitur, thunkAPI) => {
        try {
            const response = await getAPI(`neraca/neraca-tahun?id_debitur=${idDebitur}`);
            // console.log("SUKSES API", response)
            return response;
        } catch (error) {
            // console.log("GAGAL API", error)
            throw error
        }
    }
)

export const createNeracaTahun = createAsyncThunk(
    'neraca/createNeracaTahun',
    async (payload, thunkAPI) => {
        try {            
            const response = await postAPI(`neraca/neraca-tahun?id_debitur=${payload.id_debitur}`, payload);
            // console.log("SUKSES API", response)
            return response;
        } catch (error) {
            // console.log("GAGAL API", error)
            throw error
        }
    }
)

export const updateNeracaTahun = createAsyncThunk(
    'neraca/updateNeracaTahun',
    async (payload, thunkAPI) => {
        try {
            const response = await putAPI(`neraca/neraca-tahun?id_debitur=${payload.id_debitur}`, payload);
            // console.log("SUKSES API", response)
            return response;
        } catch (error) {
            // console.log("GAGAL API", error)
            throw error
        }
    }
)

export const fetchNeracaParameter = createAsyncThunk(
    'neraca/fetchNeracaParameter',
    async (idDebitur, thunkAPI) => {
        try {
            const response = await getAPI(`neraca/neraca-parameter?id_debitur=${idDebitur}`);
            // console.log("SUKSES API ", response)
            return response;
        } catch (error) {
            // console.log("GAGAL API", error)
            throw error
        }
    }
)

// export const createNeracaParameter = createAsyncThunk(
//     'neraca/createNeracaParameter',
//     async (payload, thunkAPI) => {
//         try {
//             const response = await postAPI(`neraca/neraca-parameter?id_debitur=${payload[0].id_debitur}`, payload);
//             // console.log("SUKSES API ", response)
//             return response;
//         } catch (error) {
//             // console.log("GAGAL API", error)
//             throw error
//         }
//     }
// )

export const updateNeracaParameter = createAsyncThunk(
    'neraca/updateNeracaParameter',
    async (payload, thunkAPI) => {
        try {
            const response = await putAPI(`neraca/neraca-parameter?id_debitur=${payload[0].id_debitur}`, payload);
            // console.log("SUKSES API ", response)
            return response;
        } catch (error) {
            // console.log("GAGAL API", error)
            throw error
        }
    }
)

export const fetchNeracaDescription = createAsyncThunk(
    'neraca/fetchNeracaDescription',
    async (idDebitur, thunkAPI) => {
        try {
            const response = await getAPI(`neraca/neraca?id_debitur=${idDebitur}`);
            // console.log("SUKSES API ", response)
            return response;
        } catch (error) {
            // console.log("GAGAL API", error)
            throw error
        }
    }
)

export const fetchNeracaViewData = createAsyncThunk(
    'neraca/fetchNeracaViewData',
    async (idDebitur, thunkAPI) => {
        try {
            const response = await getAPI(`neraca/neraca-view-data?id_debitur=${idDebitur}`);
            // console.log("SUKSES API ", response)
            return response;
        } catch (error) {
            // console.log("GAGAL API", error)
            throw error
        }
    }
)

export const fetchNeracaBalanceCheck = createAsyncThunk(
    'neraca/fetchNeracaBalanceCheck',
    async (idDebitur, thunkAPI) => {
        try {
            const response = await getAPI(`neraca/neraca-balance?id_debitur=${idDebitur}`);
            // console.log("SUKSES API ", response)
            return response;
        } catch (error) {
            // console.log("GAGAL API", error)
            throw error
        }
    }
)

const initialState = {
    neracaTahun: [],
    neracaParameter: [],
    neracaSummary:[],
    neracaBalance: [],
    financialYr: '',
    selectedFinancialYr: '',
    skipUpdateState: false,
    isLoadingSubmit: false,
    isFirstTime: true,
    isEditing: false,
    isSubmit:false,
    isInputPeriodeDisabled: false,
}

export const neracaSlice = createSlice({
    name: 'neraca',
    initialState,
    reducers: {
        setNeracaTahun: (state, action) => {
            state.neracaTahun = action.payload
        },
        setNeracaParameter: (state, action) => {
            state.neracaParameter = action.payload
        },
        pushNeracaParameter: (state, action) => {
            state.neracaParameter = [...state.neracaParameter, action.payload]
        },
        setNeracaSummary: (state, action) => {
            state.neracaSummary = action.payload
        },
        setNeracaBalance: (state, action) => {
            state.neracaBalance = action.payload
        },        
        setFinancialYr: (state, action) => {
            state.financialYr = action.payload         
        },
        setSelectedFinancialYr: (state, action) => {
            state.selectedFinancialYr = action.payload         
        },
        setSkipUpdateState: (state, action) => {
            state.skipUpdateState = action.payload
        },
        setLoadingSubmit: (state, action) => {
            state.isLoadingSubmit = action.payload
        },
        setEditing: (state, action) => {
            state.isEditing = action.payload
        },
        setIsFirstTime: (state, action) => {
            state.isFirstTime = action.payload
        },
        setIsSubmit: (state, action) => {
            state.isSubmit = action.payload
        },
        setIsInputPeriodeDisabled: (state, action) => {
            state.isInputPeriodeDisabled = action.payload
        },
        resetNeraca: () => initialState
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchNeracaTahun.fulfilled, (state, action) => {
                state.neracaTahun = action.payload
            })
            .addCase(createNeracaTahun.pending, (state, action) => {
                state.isLoadingSubmit = true
            })
            .addCase(createNeracaTahun.fulfilled, (state, action) => {
                state.isLoadingSubmit = false
                state.isYear = true
            })
            .addCase(createNeracaTahun.rejected, (state, action) => {
                state.isLoadingSubmit = false
                state.isYear = false
            })
            .addCase(updateNeracaTahun.pending, (state, action) => {
                state.isLoadingSubmit = true
            })
            .addCase(updateNeracaTahun.fulfilled, (state, action) => {
                state.isLoadingSubmit = false
            })
            .addCase(updateNeracaTahun.rejected, (state, action) => {
                state.isLoadingSubmit = false
            })
            .addCase(fetchNeracaDescription.fulfilled, (state, action) => {
                state.neracaDesc = action.payload
            })            
            .addCase(fetchNeracaParameter.fulfilled, (state, action) => {
                if(!state.skipUpdateState)
                    state.neracaParameter = action.payload
            })            
            .addCase(updateNeracaParameter.pending, (state, action) => {
                state.isLoadingSubmit = true
            })
            .addCase(updateNeracaParameter.fulfilled, (state, action) => {
                state.isLoadingSubmit = false
            })
            .addCase(updateNeracaParameter.rejected, (state, action) => {
                state.isLoadingSubmit = false
            })
            .addCase(fetchNeracaViewData.fulfilled, (state, action) => {
                state.neracaSummary = action.payload
            })
            .addCase(fetchNeracaBalanceCheck.fulfilled, (state, action) => {
                state.neracaBalance = action.payload
            })
    }
})

export const { 
    setNeracaTahun,
    setNeracaParameter,
    pushNeracaParameter,
    setNeracaSummary,
    setNeracaBalance,
    setFinancialYr,
    setSkipUpdateState,
    setLoadingSubmit,
    setEditing,
    setIsFirstTime,
    setIsSubmit,
    setIsInputPeriodeDisabled,
    resetNeraca
} = neracaSlice.actions

export default neracaSlice.reducer