import { useSelector } from "react-redux";

export const BouwheerTableData = () => {
	const idDebitur = useSelector(state => state.home.id_debitur);
	const summary = useSelector(state => state.bouwheer.bouwheerDetail);
	const collateralView = useSelector(state => state.bouwheer.collateral);
	const dataCollateral = useSelector(state => state.bouwheer.dataCollateral);

	const colCollateralToLoan = [
		{
			title: "Type Of Collateral",
			dataIndex: "type_of_collateral",
			key: "type_of_collateral",
		},
		{
			title: "Book Value",
			dataIndex: "book_value",
			key: "book_value",
			align: "right",
			render: (_, record) => {
				return <p>{'Rp' + record["book_value"].toLocaleString()} </p>;
			},
		},
		{
			title: "Sifat Recovery",
			dataIndex: "sifat_recovery",
			key: "sifat_recovery",
		},
		{
			title: "Discount",
			dataIndex: "discount",
			key: "discount",
			render: (_, record) => {
				return <p>{record["discount"] * 100 + "%"} </p>;
			},
		},
		{
			title: "Value",
			dataIndex: "value",
			key: "value",
			align: "right",
			render: (_,record) => {
				return <p>{'Rp' + record['value'].toLocaleString()}</p>
			}
		},
	];

	const dataSourceCollateralToLoan = dataCollateral.some((item) => item.id_debitur === idDebitur)
		? collateralView 
		: [];

	const colBouwheerDetail = [
		{
			title: "Indikator",
			dataIndex: "indikator",
			key: "indikator",
		},
		{
			title: "Score",
			dataIndex: "score",
			key: "score",
		},
		{
			title: "Weight",
			dataIndex: "weight",
			key: "weight",
			render: (_, record) => {
				return <p>{record["weight"] * 100 + "%"} </p>;
			},
		},
		{
			title: "Weighted Score",
			dataIndex: "weighted_score",
			key: "weighted_score",
		},
	];

	const dataSourceBouwheerDetail = summary.some((item) => item.id_debitur === idDebitur)
		? summary 
		: [];

	return { colCollateralToLoan, dataSourceCollateralToLoan, colBouwheerDetail, dataSourceBouwheerDetail };
};