import React, {useEffect, useMemo} from 'react'
import {Steps, Button,Tabs} from 'antd';
import { useState } from 'react';
import {getAPI} from "../services/data"

import { useDispatch, useSelector } from 'react-redux'
import { setLoadFromAPI } from '../store/actions/formulirSlice'
import { nextPage, prevPage, setPage } from '../store/actions/homeSlice'

//CURRENT
import Formulir from './Formulir';
import DebtorProfile from './DebtorProfile';
import IndustryRisk from './IndustryRisk';
import Bouwheer from './Bouwheer';
import Assumption from './Assumption';
import CreditGiven from './CreditGiven';
import Neraca from './Neraca';
import PL from './PL';
import FS from './FS';
import Capabilities from './Capabilities';
import Financial from './Financial';
import Result from './Result';
import FinalResult from './FinalResult';

const {Step} = Steps;
const Base = (props) => {
  // const {dataFormulir} = props
  const idDebitur = useSelector(state => state.home.id_debitur);
  const currentPage = useSelector(state => state.home.currentPage);
  const dispatch = useDispatch();
  // const [current,setCurrent] = useState(0)
  const [dataDebitur,setDataDebitur] = useState([]);
  // const [container, setContainer] = useState(null);

  const next = () => {
    // setCurrent(current + 1);
    dispatch(nextPage());
  };

  const prev = () => {
    // setCurrent(current - 1);
    dispatch(prevPage());
  };

  // const getMaster = (data) => {
  //   setDataDebitur(data);
  // }

  const apiGetMasterDetail = async (id) => {
    // setTimeout(async () => {
    //   if(id !== ''){
    //     const res = await getAPI("formulir/master?id="+id);
    //     setDataDebitur(res);
    //   }
    // },1000)
    if(id !== ''){
      const res = await getAPI("formulir/master?id="+id);
      if (res?.statusCode == '000') {
        setDataDebitur(res.data);
        dispatch(setLoadFromAPI(true))
      }
    }
  }

  useEffect(() => {
    // dispatch(setPage(0))
    apiGetMasterDetail(idDebitur)
  }, [idDebitur])
  
  
  // const getSteps = (step) =>{
  //   if(step == 'add'){
  //     setCurrent(current+1);
  //   }else{
  //     setCurrent(current-1);
  //   }
  // }

  const steps = useMemo(() => [
    {
      title: "Formulir",
    },
    {
      title: "Assumption",
    },
    {
      title: "Credit Given",
    },
    {
      title: "Bouwheer",
    },
    {
      title: "Industry Risk",
    },
    {
      title: "Debtor Profile",
    },
    {
      title: "Neraca",
    },
    {
      title: "Profit & Loss",
    },
    {
      title: "FS",
    },
    {
      title: "Capabilities",
    },
    {
      title: "Financial",
    },
    {
      title: "Result",
    },
    {
      title: "Final Result",
    }
  ], [])

  // const items = useMemo(() => [
  //   {
  //     label: "Formulir",
  //     key: 0,
  //     children: <Formulir idDebitur={idDebitur} />,
  //   },
  //   {
  //     label: "Assumption",
  //     key: 1,
  //     children: <Assumption masterData={dataDebitur} />,
  //   },
  //   {
  //     label: "Credit Given",
  //     key :2,
  //     children: <CreditGiven masterData={dataDebitur}/>
  //   },
  //   {
  //     label: "Bouwheer",
  //     key: 3,
  //     children: <Bouwheer masterData={dataDebitur}/>,
  //   },
  //   {
  //     label: "Industry Risk",
  //     key: 4,
  //     children: <IndustryRisk masterData={dataDebitur} />,
  //   },
  //   {
  //     label: "Debtor Profile",
  //     key: 5,
  //     children: <DebtorProfile masterData={dataDebitur} />,
  //   },
  //   {
  //     label : "Neraca",
  //     key : 6,
  //     children : <Neraca masterData={dataDebitur} />
  //   },
  //   {
  //     label: "Profit & Loss",
  //     key : 7,
  //     children: <PL masterData={dataDebitur}/>
  //   },
  //   {
  //     label: "Financial Statement",
  //     key: 8,
  //     children: <FS masterData={dataDebitur}/>
  //   },
  //   {
  //     label: "Capabilities",
  //     key: 9,
  //     children: <Capabilities masterData={dataDebitur}/>
  //   },
  //   {
  //     label: "Financial",
  //     key: 10,
  //     children: <Financial masterData={dataDebitur}/>
  //   },
  //   {
  //     label: "Result",
  //     key: 11,
  //     children: <Result masterData={dataDebitur}/>
  //   },
  //   {
  //     label: "Final Result",
  //     key: 12,
  //     children: <FinalResult masterData={dataDebitur}/> 
  //   }
  // ], [idDebitur])

  const items = [
    {
      label: "Formulir",
      key: 0,
      children: <Formulir idDebitur={idDebitur} />,
    },
    {
      label: "Assumption",
      key: 1,
      children: <Assumption masterData={dataDebitur} />,
    },
    {
      label: "Credit Given",
      key :2,
      children: <CreditGiven masterData={dataDebitur}/>
    },
    {
      label: "Bouwheer",
      key: 3,
      children: <Bouwheer masterData={dataDebitur}/>,
    },
    {
      label: "Industry Risk",
      key: 4,
      children: <IndustryRisk masterData={dataDebitur} />,
    },
    {
      label: "Debtor Profile",
      key: 5,
      children: <DebtorProfile masterData={dataDebitur} />,
    },
    {
      label : "Neraca",
      key : 6,
      children : <Neraca masterData={dataDebitur} />
    },
    {
      label: "Profit & Loss",
      key : 7,
      children: <PL masterData={dataDebitur}/>
    },
    {
      label: "Financial Statement",
      key: 8,
      children: <FS masterData={dataDebitur}/>
    },
    {
      label: "Capabilities",
      key: 9,
      children: <Capabilities masterData={dataDebitur}/>
    },
    {
      label: "Financial",
      key: 10,
      children: <Financial masterData={dataDebitur}/>
    },
    {
      label: "Result",
      key: 11,
      children: <Result masterData={dataDebitur}/>
    },
    {
      label: "Final Result",
      key: 12,
      children: <FinalResult masterData={dataDebitur}/> 
    }
  ];

  return (
    // Horizontal
    // <div>
    //   <div className="bg-white w-3/4 py-5 px-4 my-3 mx-auto sticky top-0 z-50 rounded-lg drop-shadow-lg">
    //     <Steps current={current}>
    //       {steps.map((item, i) => {
    //         return (
    //           <Step key={i} title={item.title} description={item.description} />
    //         );
    //       })}
    //     </Steps>
    //   </div>
    //   <div className="bg-white w-3/4 py-5 px-4 my-3 mx-auto rounded-lg">
    //     <Tabs items={items} activeKey={current}/>
    // <div className="flex justify-between mt-5">
    //   {current > 0 && (
    //     <Button onClick={() => prev()}>
    //       Previous
    //     </Button>
    //   )}
    //   {current < steps.length - 1 && (
    //     <Button onClick={() => next()}>
    //       Next
    //     </Button>
    //   )}
    // </div>
    //   </div>
    // </div>

    //Vertical
    <div className="flex flex-row">
      <div className="basis-[15%] bg-white w-[200px] h-[100%] py-5 px-4 mx-3 my-3 ml-5 rounded-lg drop-shadow-lg">
        <Steps current={currentPage} direction="vertical" size='small'>
          {steps.map((item, i) => {
            return (
              <Step key={i} title={item.title} description={item.description} current={currentPage} />
            );
          })}
        </Steps>
      </div>
      <div className="basis-[85%] bg-white w-[90%] py-5 px-4 my-3 rounded-lg">
        <Tabs items={items} activeKey={currentPage} defaultActiveKey={0} destroyInactiveTabPane/>
        <div className="flex justify-between mt-5">
          {currentPage > 0 && <Button onClick={() => prev()}>Previous</Button>}          
          {currentPage < steps.length - 1 && (
            <>
            <div></div>
            <Button onClick={() => next()}>Next</Button>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default Base