import React from 'react'
// import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button} from 'antd';
// import { FileTextOutlined } from '@ant-design/icons';
import '../styles/index.css'
// import { useAuth } from './auth';
import { useDispatch } from 'react-redux';
import { resetApp } from '../store/actions/homeSlice'

const Navbar = () => {
  const location = useLocation();
  // const auth = useAuth()
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const goInsertForm = () => {
    dispatch(resetApp());
    navigate("/insert-form");
  }

  return (    
    <nav className="bg-white border-gray-200 px-2 sm:px-4 py-2.5 sticky top-0 z-50 drop-shadow-md">
      <div className="flex flex-wrap items-center justify-between">
        <div className="flex items-center">
            <img src={require("../images/iki modal Logo-Landscape.jpg")} className="h-6 mr-3 sm:h-[50px]" alt="Flowbite Logo" />
            {/* <span className="self-center text-xl font-semibold whitespace-nowrap">Scoring</span> */}
        </div>
        <button data-collapse-toggle="navbar-default" type="button" className="inline-flex items-center p-2 ml-3 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600" aria-controls="navbar-default" aria-expanded="false">
          <span className="sr-only">Open main menu</span>
          <svg className="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd"></path></svg>
        </button>
        <div className="hidden w-full md:block md:w-auto" id="navbar-default">
            {location.pathname != '/login' ? 
            <ul className="flex flex-col p-4 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium md:border-0 md:bg-white">
              <li>
                {location.pathname == '/' ? 
                  // <a href="/" className="block py-2 pl-3 pr-4 text-white bg-blue-700 rounded md:bg-transparent md:text-red-700 md:p-0 dark:text-white" aria-current="page">Home</a>
                  // :
                  // <a href="/" className="block py-2 pl-3 pr-4 bg-transparent text-gray-400 hover:text-red-400 md:p-0 rounded" aria-current="page">Home</a>
                  <Button type="link" className="block py-2 pl-3 pr-4 text-white bg-blue-700 rounded md:bg-transparent md:text-red-700 md:p-0 dark:text-white" >Home</Button>
                  :
                  <Button type="link" className="block py-2 pl-3 pr-4 bg-transparent text-gray-400 hover:text-red-400 md:p-0 rounded" aria-current="page" onClick={() => navigate("/")}>Home</Button>
                }
                
              </li>
              <li>
                {location.pathname == '/insert-form' ? 
                // <a href="/insert-form" className="block py-2 pl-3 pr-4 text-white bg-blue-700 rounded md:bg-transparent md:text-red-700 md:p-0 dark:text-white">Insert Formulir</a> 
                // :
                // <a href="/insert-form" className="block py-2 pl-3 pr-4 bg-transparent text-gray-400 hover:text-red-400 md:p-0 rounded">Insert Formulir</a>
                <Button type="link" className="block py-2 pl-3 pr-4 text-white bg-blue-700 rounded md:bg-transparent md:text-red-700 md:p-0 dark:text-white" >Insert Formulir</Button>
                :
                <Button type="link" className="block py-2 pl-3 pr-4 bg-transparent text-gray-400 hover:text-red-400 md:p-0 rounded" aria-current="page" onClick={() => goInsertForm()}>Insert Formulir</Button>
                }
              </li>
            </ul>
            : ''}
        </div>
      </div>
    </nav>
  );
}

export default Navbar