import React from "react";
import {
  Form,
  Button, 
  message, 
  Select, 
  InputNumber, 
  Skeleton,
  Empty,
  Space,
  Divider,
  Modal,
} from "antd";
// import { getAPIPython, getAPI } from "../services/data";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import MasterData from "../components/MasterData";
import { 
  dataIdJp,
  dataMain, 
  dataFinancial, 
  dataCollateral, 
  dataDebtor, 
  dataIndustry, 
  dataDscr
} from "./Assumption/AssumptionData";
import { 
  setAssumption, 
  setIsFirstTime,
  setEditing,
  setSkipUpdateState,
  fetchDefaultAssumptions,
  fetchInputedAssumptions,
  createAssumptions,
  updateAssumptions
} from '../store/actions/assumptionSlice';
import { nextPage } from '../store/actions/homeSlice';
import { 
  ExclamationCircleOutlined
} from '@ant-design/icons';
import CustomTable from "../components/CustomTable";
import { useNavigate } from 'react-router-dom';


// const { Option } = Select;

const { confirm } = Modal;

const errorSubmit = (response) => {
  message.error(response);
};

const success = (response) => {
  message.success(response);
};

// const postData = async (object) => {
//   const requestOptions = {
//     method: "POST",
//     headers: { "Content-Type": "application/json" },
//     body: JSON.stringify(object),
//   };

//   const response = await fetch(
//     process.env.REACT_APP_PYTHON_SCORING + "assumption",
//     requestOptions
//   );
//   const result = await response.json();
//   return result;
// };

const columns = [
  {
    title: "GROUP",
    dataIndex: "group_name",
    key: "group_name"
  },
  {
    title: "TOTAL WEIGHTED SCORE",
    dataIndex: "total_weighted_score",
    key: "total_weighted_score"
  },
]

const Assumption = (props) => {
  // const {currentSteps} = props;
  const {
    masterData
  } = props;
  // const dataMaster = masterData['data'];
  // console.log("MASTER DATA ASSUMPTION",dataMaster);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [form] = Form.useForm()
  const changedField = Form.useWatch('financial', form)
  
  const idDebitur = useSelector(state => state.home.id_debitur);
  const formulirData = useSelector(state => state.formulir.dataFormulir.master);
  const isEditing = useSelector(state => state.assumption.isEditing);
  const isLoadingSubmit = useSelector(state => state.assumption.isLoadingSubmit);
  const isFirstTime = useSelector(state => state.assumption.isFirstTime);

  // const [disabled,setDisabled] = useState(false);
  // const [loading,isLoading] = useState(true);
  const [empty,isEmpty] = useState(false);

  const showConfirmSubmitSheet = (values) => {

    const totalScores = [
      {
        key: 0,
        group_name: "Main Scoring Weight",
        total_weighted_score: `${values.financial + values.collateral + values.debtor_profile + values.industryrisk}%`
      },
      {
        key: 1,
        group_name: "Financial",
        total_weighted_score: `${values.dscrhistorical + values.dscrcurrent + values.dscrprojection + values.debt_to_equity + values.kualitas_financial_reporting + values.financial_trends + values.ccclastyear + values.ccccurrent + values.cccprojection + values.tenor}%`
      },
      {
        key: 2,
        group_name: "Collateral",
        total_weighted_score: `${values.type_of_obligor + values.type_of_collateral}%`
      },
      {
        key: 3,
        group_name: "Debtor Profile",
        total_weighted_score: `${values.skill_and_tenure + values.reliability + (values?.dependency_to_co ?? values.dependency) + values.historical_profile + values.repeat_borrower}%`
      },
      {
        key: 4,
        group_name: "Industry Risk",
        total_weighted_score: `${values.industry_risk + values.issues + values.regulatory_impacts + values.subs_product + values.subs_raw + values.forex_risk}%`
      },
    ]
    
    confirm({
      title: 'Pastikan semua data yang diisi sudah benar',
      icon: <ExclamationCircleOutlined />,
      // content: 'Some descriptions',
      okText: 'Submit',
      okType: 'danger',
      onOk() {
        handleSubmitSheet(values);
      },
      cancelText: 'Cancel',
      onCancel() {
        // console.log('Cancel');
      },
      centered: true,
      content: (
        <CustomTable 
          columns={columns} 
          dataSource={totalScores} 
          bordered
          size="small"
        />
      ),
      width: 500
    });

  };

  // console.log("Di assumption",masterData);

  //Get Master Form From Previous Form

  //Percentage Bar
  // Main Scoring Weight
  // const [obj1, setobj1] = useState(0);
  // const [obj2, setobj2] = useState(0);
  // const [obj3, setobj3] = useState(0);
  // const [obj4, setobj4] = useState(0);
  //Financial
  // const [objF1, setObjF1] = useState(0);
  // const [objF2, setObjF2] = useState(0);
  // const [objF3, setObjF3] = useState(0);
  // const [objF4, setObjF4] = useState(0);
  // const [objF5, setObjF5] = useState(0);
  // const [objF6, setObjF6] = useState(0);
  // const [objF7, setObjF7] = useState(0);
  // const [objF8, setObjF8] = useState(0);
  // const [objF9, setObjF9] = useState(0);
  // const [objF10, setObjF10] = useState(0);
  //Collateral
  // const [objC1, setObjC1] = useState(0);
  // const [objC2, setObjC2] = useState(0);
  //Debtor Profile
  // const [objDP1, setObjDP1] = useState(0);
  // const [objDP2, setObjDP2] = useState(0);
  // const [objDP3, setObjDP3] = useState(0);
  // const [objDP4, setObjDP4] = useState(0);
  // const [objDP5, setObjDP5] = useState(0);
  //Industry Risk
  // const [objIR1, setObjIR1] = useState(0);
  // const [objIR2, setObjIR2] = useState(0);
  // const [objIR3, setObjIR3] = useState(0);
  // const [objIR4, setObjIR4] = useState(0);
  // const [objIR5, setObjIR5] = useState(0);
  // const [objIR6, setObjIR6] = useState(0);

  //APIs
  // const apiGetInputted = async () => {
  //   isLoading(true)
  //   try {
  //    const res = await getAPIPython("assumption-get");
  //    const data = res.data;

  //    let jenisPinjamanResult = data.find(
  //      ({ id }) => id == dataMaster["jenis_pinjaman"]
  //    );

  //    for (let x in jenisPinjamanResult) {
  //      if (x != "id") {
  //        jenisPinjamanResult[x] *= 100;
  //      }
  //    }

  //    //Set value for each input form based on jenis pinjaman
  //    form.setFieldsValue({
  //      //Category : Main Scoring Weight
  //      financial: jenisPinjamanResult["financial"],
  //      collateral: jenisPinjamanResult["collateral"],
  //      debtor_profile: jenisPinjamanResult["debtor profile"],
  //      industryrisk: jenisPinjamanResult["industry risk"],

  //      //Category : Financial
  //      dscrhistorical: jenisPinjamanResult["dscr historical"],
  //      dscrcurrent: jenisPinjamanResult["dscr current"],
  //      dscrprojection: jenisPinjamanResult["dscr projection"],
  //      debt_to_equity: jenisPinjamanResult["debt to equity"],
  //      kualitas_financial_reporting:
  //        jenisPinjamanResult["kualitas dari financial reporting"],
  //      financial_trends: jenisPinjamanResult["financial_trends"],
  //      ccclastyear: jenisPinjamanResult["ccc last year"],
  //      ccccurrent: jenisPinjamanResult["ccc current year"],
  //      cccprojection: jenisPinjamanResult["ccc projection"],
  //      tenor: jenisPinjamanResult["tenor"],

  //      //Cateogry : Collateral
  //      type_of_obligor: jenisPinjamanResult["type of obligor"],
  //      type_of_collateral: jenisPinjamanResult["type of collateral"],

  //      //Category : Debtor Profile
  //      skill_and_tenure: jenisPinjamanResult["skill and tenure"],
  //      reliability: jenisPinjamanResult["reliability"],
  //      dependency: jenisPinjamanResult["dependency to co"],
  //      historical_profile: jenisPinjamanResult["historical profile"],
  //      repeat_borrower: jenisPinjamanResult["repeat borrower"],

  //      //Category : Industry Risk
  //      industry_risk: jenisPinjamanResult["industry risk 1"],
  //      issues: jenisPinjamanResult["issues, evaluation, and insurance"],
  //      regulatory_impacts: jenisPinjamanResult["regulatory impacts"],
  //      subs_product: jenisPinjamanResult["substitution of product"],
  //      subs_raw: jenisPinjamanResult["substitution of raw material"],
  //      forex_risk: jenisPinjamanResult["forex risk"],

  //      //Category : Minimum DSCR
  //      secured_dscr: jenisPinjamanResult["secured minimum dscr"],
  //      unsecured_dscr: jenisPinjamanResult["unsecured minimum dscr"],
  //      origination_fee: jenisPinjamanResult["origination fee"],
  //    }); 
  //    isEmpty(false)
  //   } catch (error) {
  //     console.log(error);
  //     isEmpty(true)
  //   }
  //   isLoading(false)
  // };

  const apiGetAssumptions = async () => {

    let assumptions = {};

    const inputtedAssumptions = await dispatch(fetchInputedAssumptions(masterData?.id ?? idDebitur)).unwrap();
    if (Object.keys(inputtedAssumptions).length != 0) {
      assumptions = JSON.parse(JSON.stringify(inputtedAssumptions));
      dispatch(setIsFirstTime(false));
    } else {
      const defaultAssumptions = await dispatch(fetchDefaultAssumptions(masterData?.jenis_pinjaman ?? formulirData?.jenis_pinjaman)).unwrap();
      assumptions = JSON.parse(JSON.stringify(defaultAssumptions));
    }

    // console.log("ASSUMPTION DEFAULT => ", assumptions);

    for (let val in assumptions) {
      if (
        val != "id" && 
        val != "jenis_pinjaman"
        // val != "secured_minimum_dscr" &&
        // val != "unsecured_minimum_dscr" &&
        // val != "origination_fee"
      ) { 

        let conversion = assumptions[val] * 100;
        assumptions[val] = Number(conversion.toFixed(2));
        
      }
    }

    form.setFieldsValue({
      //Category : Main Scoring Weight
      financial: assumptions["financial"],
      collateral: assumptions["collateral"],
      debtor_profile: assumptions["debtor_profile"],
      industryrisk: assumptions["industry_risk"],

      //Category : Financial
      dscrhistorical: assumptions["dscr_historical"],
      dscrcurrent: assumptions["dscr_current"],
      dscrprojection: assumptions["dscr_projection"],
      debt_to_equity: assumptions["debt_to_equity"],
      kualitas_financial_reporting: assumptions["kualitas_dari_financial_reporting"],
      financial_trends: assumptions["financial_trends"],
      ccclastyear: assumptions["ccc_last_year"],
      ccccurrent: assumptions["ccc_current_year"],
      cccprojection: assumptions["ccc_projection"],
      tenor: assumptions["tenor"],

      //Cateogry : Collateral
      type_of_obligor: assumptions["type_of_obligor"],
      type_of_collateral: assumptions["type_of_collateral"],

      //Category : Debtor Profile
      skill_and_tenure: assumptions["skill_and_tenure"],
      reliability: assumptions["reliability"],
      dependency: assumptions["dependency_to_co"],
      historical_profile: assumptions["historical_profile"],
      repeat_borrower: assumptions["repeat_borrower"],

      //Category : Industry Risk
      industry_risk: assumptions["industryrisk"],
      issues: assumptions["issues_evaluation_and_insurance"],
      regulatory_impacts: assumptions["regulatory_impacts"],
      subs_product: assumptions["substitution_of_product"],
      subs_raw: assumptions["substitution_of_raw_material"],
      forex_risk: assumptions["forex_risk"],

      //Category : Minimum DSCR
      secured_dscr: assumptions["secured_minimum_dscr"],
      unsecured_dscr: assumptions["unsecured_minimum_dscr"],
      origination_fee: assumptions["origination_fee"],
    });

    isEmpty(false)
    // isLoading(false)
  }

  useEffect(() => {
    if (!Boolean(masterData)) navigate(0);
    apiGetAssumptions();
    // console.log("ASSUMPTION RENDERED")
  }, [masterData, idDebitur]);

  const contentNew = [
    {
      title: "Main Scoring Weight",
      data: [
        {
          label: "financial",
          name: "financial",
          message: "Masukkan financial",
          required: true,
        },
        {
          label: "collateral",
          name: "collateral",
          message: "Masukkan collateral",
          required: true,
        },
        {
          label: "Debtor Profile",
          name: "debtor_profile",
          message: "Masukkan debtor profile",
          required: true,
        },
        {
          label: "Industry Risk",
          name: "industryrisk",
          message: "Masukkan industry risk",
          required: true,
        },
      ],
    },
    {
      title: "Financial",
      data: [
        {
          label: "DSCR Historical",
          name: "dscrhistorical",
          message: " Masukkan DSCR Historical",
          required: true,
        },
        {
          label: "DSCR Current",
          name: "dscrcurrent",
          message: " Masukkan DSCR Current",
          required: true,
        },
        {
          label: "DSCR Projection",
          name: "dscrprojection",
          message: " Masukkan DSCR Projection",
          required: true,
        },
        {
          label: "Debt to Equity",
          name: "debt_to_equity",
          message: "Masukkan debt to equity",
          required: true,
        },
        {
          label: "Kualitas Finansial Reporting",
          name: "kualitas_financial_reporting",
          message: "Masukkan kualitas finansial reporting",
          required: true,
        },
        {
          label: "Financial Trends",
          name: "financial_trends",
          message: "Masukkan financial trends",
          required: true,
        },
        {
          label: "CCC Last Year",
          name: "ccclastyear",
          message: "Masukkan CCC Last Year",
          required: true,
        },
        {
          label: "CCC Current Year",
          name: "ccccurrent",
          message: "Masukkan CCC Current Year",
          required: true,
        },
        {
          label: "CCC Projection",
          name: "cccprojection",
          message: "Masukkan CCC Projection",
          required: true,
        },
        {
          label: "Tenor",
          name: "tenor",
          message: "Masukkan tenor",
          required: true,
        },
      ],
    },
    {
      title: "Collateral",
      data: [
        {
          label: "Type of Obligor",
          name: "type_of_obligor",
          message: "Masukkan type of obligor",
          required: true,
        },
        {
          label: "Type of Collateral",
          name: "type_of_collateral",
          message: "Masukkan type of collateral",
          required: true,
        },
      ],
    },
    {
      title: "Debtor Profile",
      data: [
        {
          label: "Skill and Tenure",
          name: "skill_and_tenure",
          message: "Masukkan skill and tenure",
          required: true,
        },
        {
          label: "Reliability",
          name: "reliability",
          message: "Masukkan reliability",
          required: true,
        },
        {
          label: "Dependency",
          name: "dependency",
          message: "Masukkan dependency",
          required: true,
        },
        {
          label: "Historical Profile",
          name: "historical_profile",
          message: "Masukkan historical profile",
          required: true,
        },
        {
          label: "Repeat Borrower",
          name: "repeat_borrower",
          message: "Masukkan repeat borrower",
          required: true,
        },
      ],
    },
    {
      title: "Industry Risk",
      data: [
        {
          label: "Industry Risk",
          name: "industry_risk",
          message: "Masukkan industry risk",
          required: true,
        },
        {
          label: "Issues",
          name: "issues",
          message: "Masukkan issues",
          required: true,
        },
        {
          label: "Regulatory Impacts",
          name: "regulatory_impacts",
          message: "Masukkan regulatory impacts",
          required: true,
        },
        {
          label: "Subs Product",
          name: "subs_product",
          message: "Masukkan subs product",
          required: true,
        },
        {
          label: "Subs Raw",
          name: "subs_raw",
          message: "Masukkan subs raw",
          required: true,
        },
        {
          label: "Forex Risk",
          name: "forex_risk",
          message: "Masukkan forex risk",
          required: true,
        },
      ],
    },
    {
      title: "Minimum DSCR",
      data: [
        {
          label: "Secured DSCR",
          name: "secured_dscr",
          message: "Masukkan secured dscr",
          required: true,
        },
        {
          label: "Unsecured DSCR",
          name: "unsecured_dscr",
          message: "Masukkan unsecured dscr",
          required: true,
        },
        {
          label: "Origination Fee",
          name: "origination_fee",
          message: "Masukkan origination fee",
          required: true,
        },
      ],
    },
  ];

  // const handleSubmit = async (values) => {
  //   let object;
  //   for (let x in values) {
  //     object = values[x];
  //   }
  //   console.log(object);

  //   // object['id_debitur'] = dataMaster['id']

  //   postData(object)
  //     .then((response) => {
  //       if (response.statusCode != 400) {
  //         success(response.message);
  //         setDisabled(true)
  //       } else {
  //         errorSubmit(response.message);
  //       }
  //     })
  //     .catch((error) => {
  //       errorSubmit();
  //       console.log(error);
  //     });
  // };

  const handleSubmitSheet = async (values) => {

    const payload = JSON.parse(JSON.stringify(values));
    payload.id_debitur = masterData?.id;
    payload.jenis_pinjaman = masterData?.jenis_pinjaman;

    // console.log("VALUES TO SUBMIT", payload);

    if (isFirstTime) {

      await dispatch(createAssumptions(payload))
        .unwrap()
        .then((res) => {
          // console.log("RESPONSE CREATE ASSUMPTION => ", res)
          if (res.statusCode != '200') errorSubmit(res.message);
          else success("Sukses menginput data assumption");
          dispatch(setIsFirstTime(false));
          dispatch(nextPage());
        })
        .catch(() => {
          errorSubmit("Gagal menginput data assumption");
        });

    } else {

      for (let val in payload) {
        if (
          val != "id_debitur" && 
          val != "jenis_pinjaman"
          // val != "secured_minimum_dscr" &&
          // val != "unsecured_minimum_dscr" &&
          // val != "origination_fee"
        ) {
          payload[val] /= 100;
        }
      }

      try {
        dispatch(setSkipUpdateState(true));
        const resAssumption = await dispatch(fetchInputedAssumptions(idDebitur)).unwrap();
        const inputted = {
          ...dataIdJp(payload),
          ...dataMain(payload),
          ...dataDebtor(payload),
          ...dataFinancial(payload),
          ...dataCollateral(payload),
          ...dataIndustry(payload),
          ...dataDscr(payload)
        }
        dispatch(setSkipUpdateState(false));
        if(JSON.stringify(resAssumption) !== JSON.stringify(inputted)){
          await dispatch(updateAssumptions(payload)).unwrap();
        }

        success("Sukses menginput data assumption");
        dispatch(setIsFirstTime(false));
        dispatch(nextPage());
      } catch (error) {
        errorSubmit("Gagal menginput data assumption");
      }

    }
    

  }

  //Handle Percentage Bar Value
  // const onChange = (event, value, index) => {
  //   console.log(event, value, index);
  //   //Event = value from each input, value = category, index = key from each input
  //   switch (value) {
  //     //Main Scoring Weight
  //     case 0:
  //       //Get input value where index is key form each input
  //       switch (index) {
  //         case 0:
  //           setobj1(event);
  //           break;
  //         case 1:
  //           setobj2(event);
  //           break;
  //         case 2:
  //           setobj3(event);
  //           break;
  //         case 3:
  //           setobj4(event);
  //           break;
  //       }
  //       break;
  //     //Financial
  //     case 1:
  //       switch (index) {
  //         case 0:
  //           setObjF1(event);
  //           break;
  //         case 1:
  //           setObjF2(event);
  //           break;
  //         case 2:
  //           setObjF3(event);
  //           break;
  //         case 3:
  //           setObjF4(event);
  //           break;
  //         case 4:
  //           setObjF5(event);
  //           break;
  //         case 5:
  //           setObjF6(event);
  //           break;
  //         case 6:
  //           setObjF7(event);
  //           break;
  //         case 7:
  //           setObjF8(event);
  //           break;
  //         case 8:
  //           setObjF9(event);
  //           break;
  //         case 9:
  //           setObjF10(event);
  //           break;
  //       }
  //       break;
  //     //Collateral
  //     case 2:
  //       switch (index) {
  //         case 0:
  //           setObjC1(event);
  //           break;
  //         case 1:
  //           setObjC2(event);
  //           break;
  //       }
  //       break;
  //     //Debtor Profile
  //     case 3:
  //       switch (index) {
  //         case 0:
  //           setObjDP1(event);
  //           break;
  //         case 1:
  //           setObjDP2(event);
  //           break;
  //         case 2:
  //           setObjDP3(event);
  //           break;
  //         case 3:
  //           setObjDP4(event);
  //           break;
  //         case 4:
  //           setObjDP5(event);
  //           break;
  //       }
  //       break;
  //     //Industry Risk
  //     case 4:
  //       switch (index) {
  //         case 0:
  //           setObjIR1(event);
  //           break;
  //         case 1:
  //           setObjIR2(event);
  //           break;
  //         case 2:
  //           setObjIR3(event);
  //           break;
  //         case 3:
  //           setObjIR4(event);
  //           break;
  //         case 4:
  //           setObjIR5(event);
  //           break;
  //         case 5:
  //           setObjIR6(event);
  //           break;
  //       }
  //       break;
  //   }
  // };

  //Calculate Percentage Bar
  // const handlePercent = (index) => {
  //   let result;
  //   switch (index) {
  //     //Main Scoring Weight
  //     case 0:
  //       result = obj1 + obj2 + obj3 + obj4;
  //       break;
  //     //Financial
  //     case 1:
  //       result =
  //         objF1 +
  //         objF2 +
  //         objF3 +
  //         objF4 +
  //         objF5 +
  //         objF6 +
  //         objF7 +
  //         objF8 +
  //         objF9 +
  //         objF10;
  //       break;
  //     //Collateral
  //     case 2:
  //       result = objC1 + objC2;
  //       break;
  //     //Debtor Profile
  //     case 3:
  //       result = objDP1 + objDP2 + objDP3 + objDP4 + objDP5;
  //       break;
  //     //Industry Risk
  //     case 4:
  //       result = objIR1 + objIR2 + objIR3 + objIR4 + objIR5 + objIR6;
  //       break;
  //   }
  //   return result;
  // };

  //Check percentage bar if more than 100% (red if > 100)
  // const calculateStatus = (result) => {
  //   if (result > 100) {
  //     return "exception";
  //   } else {
  //     return "";
  //   }
  // };

  //Handle percentage bar status
  // const handleStatus = (index) => {
  //   let result;
  //   switch (index) {
  //     case 0:
  //       result = obj1 + obj2 + obj3 + obj4;
  //       return calculateStatus(result);
  //     case 1:
  //       result =
  //         objF1 +
  //         objF2 +
  //         objF3 +
  //         objF4 +
  //         objF5 +
  //         objF6 +
  //         objF7 +
  //         objF8 +
  //         objF9 +
  //         objF10;
  //       return calculateStatus(result);
  //     case 2:
  //       result = objC1 + objC2;
  //       return calculateStatus(result);
  //     case 3:
  //       result = objDP1 + objDP2 + objDP3 + objDP4 + objDP5;
  //       return calculateStatus(result);
  //     case 4:
  //       result = objIR1 + objIR2 + objIR3 + objIR4 + objIR5 + objIR6;
  //       return calculateStatus(result);
  //   }
  // };

  //Check if one of the category has value more than 100%
  // const handleDisabled = () => {
  //   let result1 = obj1 + obj2 + obj3 + obj4;
  //   let result2 =
  //     objF1 +
  //     objF2 +
  //     objF3 +
  //     objF4 +
  //     objF5 +
  //     objF6 +
  //     objF7 +
  //     objF8 +
  //     objF9 +
  //     objF10;
  //   let result3 = objC1 + objC2;
  //   let result4 = objDP1 + objDP2 + objDP3 + objDP4 + objDP5;
  //   let result5 = objIR1 + objIR2 + objIR3 + objIR4 + objIR5 + objIR6;

  //   if (
  //     result1 != 100 ||
  //     result2 != 100 ||
  //     result3 != 100 ||
  //     result4 != 100 ||
  //     result5 != 100
  //   ) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // };

  return (
    <div>
      <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
      {masterData && <MasterData dataMaster={masterData} formReady={changedField} />}
      {empty ? <Empty description={"Mohon Menginput Formulir Terlebih Dahulu"}/> :
      <Form
      form={form}
      // onFinish={(values) => handleSubmit({ values })}
      onFinish={(values) => showConfirmSubmitSheet(values)}
      name="basic"
      labelCol={{
        span: 8,
      }}
      wrapperCol={{
        span: 10,
      }}
      initialValues={{
        remember: true,
      }}
    >
      {/* Inputan Assumption */}
      {contentNew.map((item, index) => {
        return (
          <div key={index}>
            {/* <p className="text-2xl font-bold text-center my-3">
              {item.title}
            </p> */}
            <Divider>{item.title}</Divider>
            <div className="grid grid-cols-2">
              {item.data.map((item2, i) => {
                return (
                  <Form.Item
                    key={i}
                    label={item2.label}
                    name={item2.name}
                    rules={[
                      {
                        required: item2.required,
                        message: item2.message,
                      },
                    ]}
                  >
                    <InputNumber
                      min="0"
                      type="number"
                      addonAfter="%"
                      disabled={isEditing ? false :  true}
                    />
                  </Form.Item>
                );
              })}
            </div>
          </div>
        );
      })}
      <Form.Item wrapperCol={{ span: 12, offset: 9 }}>
        <div style={{float: "right"}}>
        <Space>
        {isEditing ? (
          <Button
            // disabled={isEditing ? true :  false}
            // className="float-right"
            onClick={() => dispatch(setEditing(false))}
            htmlType="button"
          >
          Cancel
          </Button>
        ) : (
          <Button
            // disabled={isEditing ? true :  false}
            // className="float-right"
            onClick={() => dispatch(setEditing(true))}
            htmlType="button"
          >
          Edit
          </Button>
        )}
        <Button
          // disabled={isLoadingSubmit}
          loading={isLoadingSubmit}
          // className="float-right"
          type='primary'
          danger
          style={{float: "right"}}
          htmlType="submit"
        >
          Submit Sheet
        </Button>
        </Space>
        </div>
      </Form.Item>
    </Form>
      // <Skeleton paragraph={{rows:15}} loading={loading} active>
      //   <Form
      //     form={form}
      //     // onFinish={(values) => handleSubmit({ values })}
      //     onFinish={(values) => showConfirmSubmitSheet(values)}
      //     name="basic"
      //     labelCol={{
      //       span: 8,
      //     }}
      //     wrapperCol={{
      //       span: 10,
      //     }}
      //     initialValues={{
      //       remember: true,
      //     }}
      //   >
      //     {/* Inputan Assumption */}
      //     {contentNew.map((item, index) => {
      //       return (
      //         <div key={index}>
      //           {/* <p className="text-2xl font-bold text-center my-3">
      //             {item.title}
      //           </p> */}
      //           <Divider>{item.title}</Divider>
      //           <div className="grid grid-cols-2">
      //             {item.data.map((item2, i) => {
      //               return (
      //                 <Form.Item
      //                   key={i}
      //                   label={item2.label}
      //                   name={item2.name}
      //                   rules={[
      //                     {
      //                       required: item2.required,
      //                       message: item2.message,
      //                     },
      //                   ]}
      //                 >
      //                   <InputNumber
      //                     min="0"
      //                     type="number"
      //                     addonAfter="%"
      //                     disabled={isEditing ? false :  true}
      //                   />
      //                 </Form.Item>
      //               );
      //             })}
      //           </div>
      //         </div>
      //       );
      //     })}
      //     <Form.Item wrapperCol={{ span: 12, offset: 9 }}>
      //       <div style={{float: "right"}}>
      //       <Space>
      //       {isEditing ? (
      //         <Button
      //           // disabled={isEditing ? true :  false}
      //           // className="float-right"
      //           onClick={() => dispatch(setEditing(false))}
      //           htmlType="button"
      //         >
      //         Cancel
      //         </Button>
      //       ) : (
      //         <Button
      //           // disabled={isEditing ? true :  false}
      //           // className="float-right"
      //           onClick={() => dispatch(setEditing(true))}
      //           htmlType="button"
      //         >
      //         Edit
      //         </Button>
      //       )}
      //       <Button
      //         // disabled={isLoadingSubmit}
      //         loading={isLoadingSubmit}
      //         // className="float-right"
      //         type='danger'
      //         style={{color: "black", float: "right"}}
      //         htmlType="submit"
      //       >
      //         Submit Sheet
      //       </Button>
      //       </Space>
      //       </div>
      //     </Form.Item>
      //   </Form>
      // </Skeleton>
      }
      </Space>
    </div>
  );
};

export default Assumption;
