import React from "react";
import { Select, Button, message, Form, Space, Modal } from "antd";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAPIPython, getAPI } from "../services/data";
import moment from "moment";
import PLComponent from "./PL/PLComponent";
// import TablePL from "./PL/TablePL";
import TablePLSummary from "./PL/TablePLSummary";
import TablePLValidation from "./PL/TablePLValidation";
import TableREValidation from "./PL/TableREValidation";
import { 
  convertDateFormatNumber, 
  convertDateFormatString, 
  dataWithoutId 
} from "../utils/util";
import { fetchNeracaTahun } from "../store/actions/neracaSlice";
import { 
  setFinancialYr,
  setEditing,
  setIsSubmit, 
  updateInputProfitLoss,
  fetchDropdownTypeIncomeExpense, 
  fetchInputProfitLoss,
  fetchProfitLossView,
  fetchProfitLossSummary,
  fetchProfitLossBalanceCheck,
  fetchProfitLossRetainedEarningCheck,
  resetPL
} from "../store/actions/plSlice";

const { Option } = Select;
const { confirm } = Modal;

const errorSubmit = (response) => {
  message.error(response);
};

const success = (response) => {
  message.success(response);
};

const postData = async (object) => {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(object),
  };

  const response = await fetch(
    process.env.REACT_APP_PYTHON_SCORING + "summary_profit_loss",
    requestOptions
  );
  console.log(response);
  const result = await response.json();
  console.log(result);
  return result;
};

const getFilter = (object, value) => {
    return object.filter((result) => result["category"].includes(value));
};

const PL = (props) => {
  const {masterData} = props;
  // const dataMaster = masterData['data']
  // console.log(dataMaster);

  const dispatch = useDispatch();

  // Load redux state
  const idDebitur = useSelector(state => state.home.id_debitur);
  const neracaTahun = useSelector(state => state.neraca.neracaTahun);
  const PLInput = useSelector(state => state.pl.PLInput);
  const financialYr = useSelector(state => state.pl.financialYr);
  const isEditing = useSelector(state => state.pl.isEditing);
  const isFirstTime = useSelector(state => state.pl.isFirstTime);
  const isLoadingSubmit = useSelector(state => state.pl.isLoadingSubmit);
  const isSubmit = useSelector(state => state.pl.isSubmit);

  const [grossProfit, setGrossProfit] = useState([]);
  const [operatingExpenses, setOperatingExpenses] = useState([]);
  const [da, setDa] = useState([]);
  const [totalOther, setTotalOther] = useState([]);
  const [netProfit, setNetProfit] = useState([]);
  const [view, setView] = useState(false);

  // const [dataResult, setDataResult] = useState([]);
  // const [disabledViewButton,setDisabledViewButton] = useState(false);
  // const [trigger, setTrigger] = useState(false);
  // const [year, setYear] = useState();
  // const [yearArray,setYearArray] = useState([]);
  // const [disabled, setDisabled] = useState(true);
  // const [refresh,setRefresh] = useState(false);
  // const [valueSettings,setValueSettings] = useState('')
  // const [disabledValueSettings,setDisabledValueSettings] = useState(false)

  const apiGetDropdownKeterangan = async () => {
    // const data = await getAPIPython("type_of_income_expense");
    // const result = data['data'];
    const result = await dispatch(fetchDropdownTypeIncomeExpense()).unwrap();

    const gp = getFilter(result, "GP");
    setGrossProfit(gp);

    const oe = getFilter(result, "OE");
    setOperatingExpenses(oe);

    const ebmin = getFilter(result,"EB");
    setDa(ebmin);

    const to = getFilter(result,"TO");
    setTotalOther(to);

    const np = getFilter(result, "NP");
    setNetProfit(np);
  }

  // const apiGetYears = async (id) => {
  //   console.log(id);
  //   const data = await getAPIPython("tahunneraca-get?id_debitur=" + id);
  //   console.log(data['data']);
  //   setYearArray(data['data'])
  // }

  // useEffect(() => {
  //   console.log("USE EFFECT");
  //   if(dataMaster){
  //     apiGetDropdown()
  //     apiGetYears(dataMaster['id'])
  //   }
  // },[])

  // useEffect(() => {
  //  if(dataMaster) apiGetYears(dataMaster["id"]);
  // },[refresh])

  const apiGetDropdownNeracaTahun = async (id) => {
    const res = await dispatch(fetchNeracaTahun(id)).unwrap();
    if(res.length === 0){
      dispatch(setEditing(false));
      dispatch(setIsSubmit(false));
      dispatch(setFinancialYr(''));
    }
  } 
  
  useEffect(() => {
    apiGetDropdownNeracaTahun(idDebitur);
    apiGetDropdownKeterangan();
    
    if(neracaTahun.some(item => item.id_debitur !== idDebitur)){
      dispatch(setEditing(false));
      dispatch(setIsSubmit(false));
      dispatch(setFinancialYr(''));
    }
    // console.log("PL RENDERED");
  },[masterData, idDebitur]);

  // const handleClick = async () => {
  //   try {
  //     if (masterData["data"] == null) return errorSubmit("No Master Data Were Found !");

  //     //Get Data Asset
  //     const dataPL = await getAPIPython("view_profit_loss?id_debitur=" + masterData['data']['id']);
  //     const resultPL = dataPL['data'];
  //     if(resultPL != null){
  //     if(resultPL.length == 0){
  //       return errorSubmit("No PL Asset were found");
  //     }
  //     }else{
  //     return errorSubmit("Failed to fetch data")
  //     }

  //     setDataResult(resultPL);
  //     setView(true)
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // const handleClickAll = async () => {
  //   console.log(masterData["data"]);
  //   let yearObject;
  // //  if(year == moment().format("YYYY")){
  // //   yearObject = 'ytd'
  // //  }else{
  // //   yearObject = year
  // //  }
  //   if (masterData["data"] == null) {
  //     console.log("Hello");
  //     return errorSubmit("No master data were found !");
  //   }
  //   let object = {
  //     Id_Debitur: masterData["data"]["id"],
  //     Year: year,
  //   };
  //   console.log(object);
  //   try {
  //     postData(object)
  //       .then((response) => {
  //         if (response["statusCode"] == 200) {
  //           console.log(response);
  //           return success(response["message"]);
  //         } else {
  //           return errorSubmit(response["message"]);
  //         }
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //         return errorSubmit("Failed to submit data");
  //       });
  //   } catch (error) {
  //     console.log("Error");
  //   }
  // };

  // const handleRefresh = () => {
  //   setRefresh(!refresh)
  // };

  // const handleSettings = (value) => {
  //   console.log("DI PL",value);
  //   let text = '';
  //   switch(value['setting']){
  //     case 'm':
  //       text = 'million'
  //       break;
  //     case 'b':
  //       text = 'bilion'
  //       break;
  //     default :
  //       text = 'normal'
  //       break;
  //   }
  //   setDisabledValueSettings(true)
  //   setValueSettings(value)
  //   success(`Value settings is set to ${text}`);
  // }

  // const settings = [
  //   {
  //     text: "Normal",
  //     value: "n",
  //   },
  //   {
  //     text: "In Million",
  //     value: "m",
  //   },
  //   {
  //     text: "In Billion",
  //     value: "b",
  //   },
  // ];

  const objectCurrentPL = {
    // year: year,
    masterData: masterData,
    // settingValue: valueSettings
  };

  const content = [
    {
      dropdown: grossProfit,
      title: "Gross Profit",
      segment: "GP"
    },
    {
      dropdown: operatingExpenses,
      title: "Operating Expenses",
      segment: "OE"
    },
    {
      dropdown: da,
      title: "Depreciation & Amortization",
      segment: "EB"
    },
    {
      dropdown: totalOther,
      title: "Total Others-nett",
      segment: "TO"
    },
    {
      dropdown: netProfit,
      title: "Tax, Income, and Interest",
      segment: "NP"
    }
  ]

  const handleChangeNeracaTahun = (value) => {
    // console.log("TAHUN DROPDOWN PL", value);
    // setYear(value);
    // setDisabled(false);
    // setTrigger(!trigger);

    const convertedYear = convertDateFormatNumber(value, "MMMM YYYY");
    dispatch(setFinancialYr(convertedYear));

    if(!isSubmit && !isEditing){
      dispatch(setEditing(true));
    }
  };

  const handleClickEdit = async () => {
    dispatch(setEditing(true));
    dispatch(setIsSubmit(false));
  }

  const handleClickAll = async () => {
    try {
      const summary = PLInput.filter((item) => neracaTahun.some((yearItem) => Object.values(yearItem).includes(item.year)))
      const payload = JSON.parse(JSON.stringify(summary));

      if(payload.length !== 0){
        const resInputtedPL = await dispatch(fetchInputProfitLoss(idDebitur)).unwrap();
        if(JSON.stringify(dataWithoutId(payload)) !== JSON.stringify(dataWithoutId(resInputtedPL))){
          await dispatch(updateInputProfitLoss(payload)).unwrap();
        }

        const resPLView = await dispatch(fetchProfitLossView(idDebitur)).unwrap();
        const resSum = await dispatch(fetchProfitLossSummary(idDebitur)).unwrap();        
        if(resPLView.length !== 0 && resSum.length !== 0){
          await dispatch(fetchProfitLossBalanceCheck(idDebitur)).unwrap();
          await dispatch(fetchProfitLossRetainedEarningCheck(idDebitur)).unwrap();
          dispatch(setEditing(false));
          dispatch(setIsSubmit(true));
          success("Sukses submit sheet: [Profit & Loss]");
          setView(true);
        }else{
          return errorSubmit("Data tidak ditemukan!");
        }
      }else{
        return errorSubmit("Data tidak ditemukan!");
      }
    } catch (error) {
      errorSubmit("Gagal menyimpan data");
    }
  }

  return (
    <div className="mx-5">
      {/* <p className="text-2xl font-bold my-4">Settings</p>
      <Form
        disabled={disabledValueSettings}
        onFinish={(values) => handleSettings(values)}
        wrapperCol={{
          span: 5,
        }}
      >
        <Form.Item key="setting" label="Value Settings:" name="setting">
          <Select
            defaultValue={"n"}
            placeholder="Normal / In Million / In Billion"
          >
            {settings.map((item, i) => {
              return (
                <Option value={item.value} key={i}>
                  {item.text}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item>
          <Button htmlType="submit">Submit</Button>
        </Form.Item>
      </Form> */}
      <p className="text-2xl font-bold my-4">Financial Year</p>
      <label>Tahun Financial : </label>
      <Select 
        onChange={handleChangeNeracaTahun} 
        value={neracaTahun.some((item) => Object.values(item).includes(financialYr)) ? convertDateFormatString(financialYr, "MMMM YYYY") : ""} 
        style={{ width: "15%" }}
      >
        {neracaTahun.flatMap((item) => (
          Object.keys(item)
            .filter(key => key.startsWith('y'))
            .map((key, i) => {
              return (
                <Option key={i} value={convertDateFormatString(item[key], "MMMM YYYY")}>
                  {convertDateFormatString(item[key], "MMMM YYYY")}
                </Option>
              );
            }) 
        ))}
      </Select>
      {/* <Button className="mx-4" onClick={handleRefresh}>
        Refresh
      </Button> */}
      {/* <DatePicker onChange={handleChange} picker="year" status={warning} /> */}
      <div className="grid grid-cols-2">
        {content.map((item, i) => {
          return (
            <PLComponent
              key={i}
              object={JSON.stringify({
                ...objectCurrentPL,
                ...{
                  dropdown: item.dropdown,
                  title: item.title,
                  segment: item.segment,
                  // disabled: disabled,
                  // trigger: trigger
                },
              })}
            />
          );
        })}
      </div>
      <div className="py-5">
        <Space direction='horizontal' style={{display: 'flex', float: 'right', width: '25%'}}>
          <Button htmlType="button" onClick={handleClickEdit} disabled={isFirstTime ? true : isSubmit ? false : true}>
            Edit
          </Button>   
          <Button htmlType="button" type="primary" danger onClick={handleClickAll} loading={isLoadingSubmit} 
            className="float-right mx-auto" disabled={isFirstTime ? true : isEditing ? false : true}
          >
            Submit Sheet
          </Button>
        </Space>
        {view &&      
          <div style={{ marginTop: "65px", marginBottom: "10px", marginLeft: "8px", marginRight: "8px" }}>       
            <TablePLSummary />
            <TablePLValidation />
            <TableREValidation />
          </div>
        }
      </div>      
      {/* <div className="mx-8 my-8"> */}
        {/* <Button onClick={handleClick} disabled={disabled}>
          View Data
        </Button> */}
        {/* {view && <TablePL result={dataResult}/>} */}
      {/* </div> */}
    </div>
  );
}

export default PL