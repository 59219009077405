import { useSelector } from "react-redux";

export const IndustryRiskTableData = () => {
  const idDebitur = useSelector(state => state.home.id_debitur);
  const idDebiturIR = useSelector(state => state.industryRisk.idDebiturIR);
	const summary = useSelector(state => state.industryRisk.summary);

	const colIndustryRisk = [
    {
      title: "Indikator",
      dataIndex: "indikator",
      key: "indikator",
    },
    {
      title: "Score",
      dataIndex: "score",
      key: "score",
    },
    {
      title: "Weight",
      dataIndex: "weight",
      key: "weight",
      render: (_, record) => {
        return <p>{(record["weight"] * 100).toFixed(0) + "%"} </p>;
      },
    },
    {
      title: "Weighted Score",
      dataIndex: "weighted_score",
      key: "weighted_score",
      render: (_, record) => {
        return <p>{record["weighted_score"].toFixed(2)} </p>;
      },
    },
  ];

	const dataSourceIndustryRisk = (idDebitur === idDebiturIR) ? summary : [];

	return { colIndustryRisk, dataSourceIndustryRisk };
};