export const dataIdJp = (payload) => ({
	"id_debitur": payload.id_debitur,
	"jenis_pinjaman": payload.jenis_pinjaman,
});

export const dataMain = (payload) => ({
	"financial": payload.financial,
	"collateral": payload.collateral,
	"debtor_profile": payload.debtor_profile,
	"industry_risk": payload.industryrisk
});

export const dataFinancial = (payload) => ({
	"dscr_historical": payload.dscrhistorical,
	"dscr_current": payload.dscrcurrent,
	"dscr_projection": payload.dscrprojection,
	"debt_to_equity": payload.debt_to_equity,
	"kualitas_dari_financial_reporting": payload.kualitas_financial_reporting,
	"financial_trends": payload.financial_trends,
	"ccc_last_year": payload.ccclastyear,
	"ccc_current_year": payload.ccccurrent,
	"ccc_projection": payload.cccprojection,
	"tenor": payload.tenor
});

export const dataCollateral = (payload) => ({
	"type_of_obligor": payload.type_of_obligor,
  "type_of_collateral": payload.type_of_collateral
});

export const dataDebtor = (payload) => ({
	"skill_and_tenure": payload.skill_and_tenure,
	"reliability": payload.reliability,
	"dependency_to_co": payload.dependency,
	"historical_profile": payload.historical_profile,
	"repeat_borrower": payload.repeat_borrower
});

export const dataIndustry = (payload) => ({
	"industryrisk": payload.industry_risk,
	"issues_evaluation_and_insurance": payload.issues,
	"regulatory_impacts": payload.regulatory_impacts,
	"substitution_of_product": payload.subs_product,
	"substitution_of_raw_material": payload.subs_raw,
	"forex_risk": payload.forex_risk
});

export const dataDscr = (payload) => ({
	"secured_minimum_dscr": payload.secured_dscr,
	"unsecured_minimum_dscr": payload.unsecured_dscr,
	"origination_fee": payload.origination_fee
});