import React, {lazy, Suspense} from 'react'
import { Route, Routes, useNavigate } from "react-router-dom";
// import { AuthProvider } from '../components/auth';
// import RequireAuth from '../components/RequireAuth';
import Base from './Base';
import Home from './Home';
// import Login from './Login';
import ErrorPage from './ErrorPage';
import {
  Button
} from 'antd';

const LazyLogin = lazy(() => import('./Login'));

const Pages = () => {
  const navigate = useNavigate();

  return (
    // <AuthProvider>
      <Routes>
        <Route 
          path="/login" 
          element={
            <Suspense fallback={<>Loading app...</>}>
              <LazyLogin />
            </Suspense>
            // <Login />
          } 
          errorElement={
            <ErrorPage 
              status={"500"}
              title={"500"} 
              subTitle={"Sorry, something went wrong."}
              extra={
                <Button onClick={() => navigate(0)}>Refresh Page</Button>
              }
            />
          }
        />
        <Route
          path="/insert-form"
          element={
            // <RequireAuth>
            //   <Base />
            // </RequireAuth>
            <Base />
          }
          errorElement={
            <ErrorPage 
              status={"500"}
              title={"500"} 
              subTitle={"Sorry, something went wrong."}
              extra={
                <Button onClick={() => navigate(0)}>Refresh Page</Button>
              }
            />
          }
        />
        <Route
          path="/"
          element={
            // <RequireAuth>
            //   <Home />
            // </RequireAuth>
            <Home />
          }
          errorElement={
            <ErrorPage 
              status={"500"}
              title={"500"} 
              subTitle={"Sorry, something went wrong."}
              extra={
                <Button onClick={() => navigate("/")}>Back Home</Button>
              }
            />
          }
        />
        <Route
          path="*"
          element={
            <ErrorPage 
              status={"404"}
              title={"404"} 
              subTitle={"Sorry, the page you visited does not exist."}
              extra={
                <Button onClick={() => navigate("/")}>Back Home</Button>
              }
            />
          }
        />
      </Routes>
    // </AuthProvider>
  );
}

export default Pages