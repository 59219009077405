import React from "react";
import { 
  EditOutlined,
  DeleteOutlined,
  CloseOutlined,
  CheckOutlined
} from '@ant-design/icons';
import { 
  Form, 
  Input, 
  Button, 
  message,
  Select,
  InputNumber,
  Table, 
  Space,
  Tooltip,
  Popconfirm,
} from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { getAPI, getAPIPython } from "../../services/data";
import { 
  setIsEmptyLocalDataPemegangSaham, 
  setPemegangSaham, 
  fetchPemegangSaham
} from '../../store/actions/formulirSlice';

const { Option } = Select;

const errorSubmit = (text) => {
  message.error(text);
};

const success = (text) => {
  message.success(text);
};

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode = inputType === 'number' ? <InputNumber style={{width: "50%"}} min={0} maxLength={dataIndex == "ktp_npwp" ? "16" : ""}/> : <Input />;
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const PemegangSaham = (props) => {
  // const { currentSteps } = props;
  const {masterData}  = props

  const [form] = Form.useForm();
  const dispatch = useDispatch();
  // const dataParent = masterData['data'];
  const savedData = useSelector((state) => state.formulir.dataFormulir.pemegangSaham);
  const isLoadFromAPI = useSelector(state => state.formulir.loadFromAPI);
  const isEmptyLocalData = useSelector(state => state.formulir.isEmptyLocalData.pemegangSaham);

  const [trigger, setTrigger] = useState(true);
  const [inputtedSaham, setInputedSaham] = useState([]);
  // const [ktp,setktp] = useState([])
  const [editingKey, setEditingKey] = useState('');
  const isEditing = (record) => record.id === editingKey;

  const edit = (record) => {
    form.setFieldsValue({
      nama_pemegang_saham: '',
      "ktp/npwp": '',
      jumlah_lembar_saham: 0,
      ...record,
    });
    setEditingKey(record.id);
  };
  const cancel = () => {
    setEditingKey('');
  };
  const save = async (id) => {
    try {
      const row = await form.validateFields();
      const newData = [...inputtedSaham];
      const index = newData.findIndex((item) => id === item.id);
      const excludedArray = newData.toSpliced(index, 1);
      if (index > -1) {
        const item = newData[index];

        // Check empty values
        for (let x in row) {
          if (isEmptyOrSpaces(x)) {
            return errorSubmit("Text cannot be empty");
          }
        }

        newData.splice(index, 1, {
          ...item,
          ...row,
        });

        // Validate input
        let char = /[a-zA-Z]/;
        for (const vSaham of excludedArray) {
          if (vSaham.ktp_npwp == row["ktp_npwp"]) return errorSubmit("KTP / NPWP sudah pernah diinput");
          if (row["ktp_npwp"].toString().length != 16) return errorSubmit("KTP / NPWP belum 16 digit");
          if (char.test(row["ktp_npwp"])) return errorSubmit("Invalid Input Format")
        }
        setInputedSaham(newData);
        dispatch(setPemegangSaham(newData));
        setEditingKey('');
      } else {

        // Check empty values
        for (let x in row) {
          if (isEmptyOrSpaces(x)) {
            return errorSubmit("Text cannot be empty");
          }
        }

        newData.push(row);

        // Validate input
        let char = /[a-zA-Z]/;
        for (const vSaham of excludedArray) {
          if (vSaham.ktp_npwp == row["ktp_npwp"]) return errorSubmit("KTP / NPWP sudah pernah diinput");
          if (row["ktp_npwp"].toString().length != 16) return errorSubmit("KTP / NPWP belum 16 digit");
          if (char.test(row["ktp_npwp"])) return errorSubmit("Invalid Input Format")
        }
        setInputedSaham(newData);
        dispatch(setPemegangSaham(newData));
        setEditingKey('');
      }
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };
  const deleteRow = async (id) => {

    const newArray = savedData.filter((v) => v.id !== id);
    if(newArray.length === 0) dispatch(setIsEmptyLocalDataPemegangSaham(true));
    dispatch(setPemegangSaham(newArray));

  }

  // APIs
  // const apiGetInputted = async (id) => {
  //   console.log(id);
  //   const data = await getAPIPython("view_pemegang_saham/?id_debitur=" + id);
  //   console.log(data);
  //   setInputedSaham(data.data);
  // };
  const apiGetInputted = async (id) => {
    const res = await dispatch(fetchPemegangSaham(id)).unwrap();
    if(
      (res.length !== 0 && res[0].id_debitur !== id) ||
      (savedData.length !== 0 && savedData[0].id_debitur !== id) ||
      (savedData.length === 0 && Boolean(isLoadFromAPI) && !isEmptyLocalData)      
    ){
      setInputedSaham(res);
      dispatch(setPemegangSaham(res));
      dispatch(setIsEmptyLocalDataPemegangSaham(false));
    }else{
      if(savedData.length === 1) dispatch(setIsEmptyLocalDataPemegangSaham(false));
      setInputedSaham(savedData);
    }
  };

  useEffect(() => {
    // console.log("Pemegang Saham");
    apiGetInputted(masterData);
  }, [savedData, isLoadFromAPI]);


  const content = [
    {
      label: "Pemegang Saham",
      name: "pemegang_saham",
      message: "Masukkan pemegang saham",
      required: true,
    },
    {
      label: "KTP NPWP",
      name: "ktp_npwp",
      message: "Masukkan nomor KTP NPWP",
      required: true,
    },
    {
      label: "Jumlah Lembar",
      name: "jumlah_lembar_saham",
      message: "Masukkan jumlah lembar",
      required: true,
    },
  ];

  const columns = [
    {
      title: "Nama Pemegang Saham",
      dataIndex: "pemegang_saham",
      key: "nama_pemegang_saham",
      editable: true,
    },
    {
      title: "KTP/NPWP",
      dataIndex: "ktp_npwp",
      key: "ktp/npwp",
      editable: true,
    },
    {
      title: "Jumlah Lembar Saham",
      dataIndex : "jumlah_lembar_saham",
      key: "jumlah_lembar_saham",
      editable: true,
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <Space>
            <Popconfirm title="Save changes?" onConfirm={() => save(record.id)} okButtonProps={{type: "secondary"}}>
              <Button type="secondary" icon={<CheckOutlined />} />
            </Popconfirm>
            <Popconfirm title="Cancel editing?" onConfirm={cancel} okButtonProps={{type: "secondary"}}>
              <Button type="secondary" icon={<CloseOutlined />} />
            </Popconfirm>
          </Space>
        ) : (
          <>
          <Space>
          <Tooltip title="edit">
            <Button disabled={editingKey !== ''} type="secondary" icon={<EditOutlined />} onClick={() => edit(record)}/>
          </Tooltip>
          <Popconfirm title="Confirm deletion?" onConfirm={() => deleteRow(record.id)} okButtonProps={{type: "secondary"}}>
          <Tooltip title="delete">
            <Button type="secondary" icon={<DeleteOutlined />}/>
          </Tooltip>
          </Popconfirm>
          </Space>
          </>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === 'pemegang_saham' ? 'text' : 'number',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  // const postData = async (object) => {
  //   const requestOptions = {
  //     method: "POST",
  //     headers: { "Content-Type": "application/json" },
  //     body: JSON.stringify(object),
  //   };

  //   const response = await fetch(process.env.REACT_APP_PYTHON_SCORING + "pemegang_saham", requestOptions);
  //   console.log(response);
  //   const result = await response.json();
  //   console.log(result);
  //   return result;
  // };

  const isEmptyOrSpaces = (str) => {
    return str === null || str.toString().match(/^ *$/) !== null;
  };

  // const handleSubmit = (values) => {
  //   let data;
  //   let found = false;
  //   let sameKTP = false;
  //   let char = /[a-zA-Z]/;
  //   for (let x in values) {
  //     data = values[x];
  //   }

  //   // console.log(data["Id_Debitur"]);
  //   ktp.forEach(e => {
  //     if(e == data['KTP_NPWP']) sameKTP = true;
  //   })

  //   if(sameKTP) return errorSubmit("KTP sudah pernah diinput");

  //   if(data['KTP_NPWP'].toString().length != 16){
  //     return errorSubmit("KTP / NPWP belum 16 digit")
  //   }
    
  //   if (char.test(data["KTP_NPWP"])) {
  //     return errorSubmit("Invalid Input Format");
  //   }
    
  //   Object.keys(data).forEach(function (key) {
  //     if (isEmptyOrSpaces(data[key])) {
  //       found = true
  //     }
  //   });

  //   if(found)return errorSubmit("Text cannot be empty");

  //   data['Id_Debitur'] = masterData;

  //   console.log(data);

  //   postData(data)
  //     .then((response) => {
  //       if(response['statusCode'] ==  200){
  //         // console.log(ktp);
  //         let ktp_data = data["KTP_NPWP"];
  //         success("Success submit data");
  //         setTrigger(!trigger)
  //         setktp([...ktp,ktp_data]);
  //       }else{
  //         errorSubmit(response['message'])
  //       }
  //       console.log(response);
  //     })
  //     .catch((error) => {
  //       errorSubmit("Failed to submit data");
  //       console.log(error);
  //     });
  // };

  const handleSubmit = (values) => {
    // console.log(values)
    let 
      data = JSON.parse(JSON.stringify(values.values)), 
      char = /[a-zA-Z]/;

    // Check empty values
    for (let x in data) {
      if (isEmptyOrSpaces(x)) {
        return errorSubmit("Text cannot be empty");
      }
    }
    
    // Validate input
    for (const vSaham of inputtedSaham) {
      if (vSaham.ktp_npwp == data["ktp_npwp"]) return errorSubmit("KTP / NPWP sudah pernah diinput");
      if (data["ktp_npwp"].toString().length != 16) return errorSubmit("KTP / NPWP belum 16 digit");
      if (char.test(data["ktp_npwp"])) return errorSubmit("Invalid Input Format")
    }

    // Assign temporary id
    data["id"] = data["ktp_npwp"];
    data["id_debitur"] = masterData;

    const mergedArray = [...inputtedSaham];
    mergedArray.push(data);

    dispatch(setPemegangSaham(mergedArray));
    success("Data tersimpan");
  }

  return (
    <div style={{maxWidth: "100%"}}>
      {/* <p className="text-2xl font-bold text-center my-3">Pemegang Saham</p> */}
      {/* Form */}
      <Form
        onFinish={(values) => handleSubmit({ values })}
        name="basic"
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 12,
        }}
        initialValues={{
          remember: true,
        }}
        labelWrap
      >
        {/* Form Content */}
        <div className="grid grid-cols-3">
          {content.map((item, i) => {
            return (
              <Form.Item
                key={i}
                label={item.label}
                name={item.name}
                rules={[
                  {
                    required: item.required,
                    message: item.message,
                  },
                ]}
              >
                {item.select_data?.length > 0 ? (
                  <Select
                    onClick={() => setTrigger(!trigger)}
                    showSearch
                    placeholder="Search to Select"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children.includes(input)
                    }
                    filterSort={(optionA, optionB) =>
                      optionA.children
                        .toLowerCase()
                        .localeCompare(optionB.children.toLowerCase())
                    }
                  >
                    {item.select_data.map((item, i) => {
                      return (
                        <Option value={item.id} key={i}>
                          {item.nama_debitur}
                        </Option>
                      );
                    })}
                  </Select>
                ) : item.name == "jumlah_lembar_saham" || item.name == "ktp_npwp" ? (
                  <InputNumber
                    min={0}
                    style={{ width: "100%" }}
                    maxLength={item.name == "ktp_npwp" ? "16" : ""}
                  />
                ) : (
                  <Input />
                )}
              </Form.Item>
            );
          })}
        </div>
        {/* Button Submit */}
        <Form.Item wrapperCol={{ span: 12, offset: 9 }}>
          <Button className="float-right" htmlType="submit">
            Save
          </Button>
        </Form.Item>
      </Form>
      <Form form={form} component={false}>
        <Table 
          dataSource={inputtedSaham} 
          columns={mergedColumns} 
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          rowClassName="editable-row"
          bordered
          size="middle"
        />
      </Form>
    </div>
  );
};

export default PemegangSaham;
