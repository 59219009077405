import React from 'react'
import { Form, Input, InputNumber, Button, message, Select, Table,Radio } from "antd";
import { useState, useEffect } from "react";
import { getAPIPython, getAPI } from "../../services/data";

const { Group } = Radio;

const errorSubmit = (text) => {
  message.error(text);
};

const success = (text) => {
  message.success(text);
};

const content = [
  {
    label: "Lender",
    name: "lender",
    message: "Masukkan jenis lender",
    required: true,
    radio: ["KBF", "BPR"],
  },
  {
    label: "Pefindo Report",
    name: "pefindoreport",
    message: "Masukkan pefindo report",
    required: true,
  },
];

const postData = async (object) => {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(object),
  };

  const response = await fetch(
    process.env.REACT_APP_PYTHON_SCORING + "result",
    requestOptions
  );
  console.log(response);
  const result = await response.json();
  console.log(result);
  return result;
};


const LenderReport = (props) => {
    const {masterData,refresh} = props;
    const [form] = Form.useForm();
    const dataMaster = masterData['data'];


    // const [result,setResult] = useState("");
    //   console.log("DI RESULT KATEGORI LENDER REPORT",masterData);

    const handleSubmit = (values) => {
      values['id_debitur'] = dataMaster['id'];
      // refresh(true);

      postData(values).then((response) => {
          console.log(response);
          if(response['statusCode'] == 200){
            success(response['message']);
            refresh(true)
          }else{
            errorSubmit(response['message']);
          }
      }).catch((error) => {
          console.log(error);
          errorSubmit("Internal Server Error !")
      })
    };

  return (
    <div>
      <p className="text-2xl font-bold text-center my-5">Lender & Report</p>
      <Form
        form={form}
        onFinish={(values) => handleSubmit(values)}
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 10,
        }}
        initialValues={{
          remember: true,
        }}
      >
        <div className="grid grid-cols-2">
          {content.map((item, i) => {
            return (
              <Form.Item
                key={i}
                label={item.label}
                name={item.name}
                rules={[
                  {
                    required: item.required,
                    message: item.message,
                  },
                ]}
              >
                {item.label == "Lender" ? (
                  <Group>
                    {item.radio.map((string) => {
                      return <Radio value={string}>{string}</Radio>;
                    })}
                  </Group>
                ) : (
                  <InputNumber style={{ width: "100%" }} />
                )}
              </Form.Item>
            );
          })}
        </div>
        <Form.Item wrapperCol={{ span: 12, offset: 9 }}>
          <Button className="float-right" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}

export default LenderReport