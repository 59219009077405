export const getAPI = async (endpoint) => {
  try {
    const res = await fetch(process.env.REACT_APP_EXPRESS_SCORING_UI + endpoint);
    if(!res.ok) {
      throw new Error("API request error");
    }
    const data = await res.json();
    return data;
  } catch (error) {
    console.error("API request error",error);
    throw error;
  }
};

export const postAPI = async (url = "", payload = {}) => {
  try {
    // const res = await fetch(process.env.REACT_APP_EXPRESS_SCORING_UI + endpoint);
    const res = await fetch(`${process.env.REACT_APP_EXPRESS_SCORING_UI}${url}`, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });
    if(!res.ok) {
      throw new Error("API request error");
    }
    return res.json();
  } catch (error) {
    console.error("API request error",error);
    throw error;
  }
};

export const putAPI = async (url = "", payload = {}) => {
  try {
    // const res = await fetch(process.env.REACT_APP_EXPRESS_SCORING_UI + endpoint);
    const res = await fetch(`${process.env.REACT_APP_EXPRESS_SCORING_UI}${url}`, {
      method: "PUT",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });
    if(!res.ok) {
      throw new Error("API request error");
    }
    return res.json();
  } catch (error) {
    console.log("API request error",error);
    throw error;
  }
};

export const getAPIPython = async (endpoint) => {
  try {
    const res = await fetch(
      process.env.REACT_APP_PYTHON_SCORING + endpoint
    );
    if(!res.ok) {
      throw new Error("API request error");
    }
    const data = await res.json();
    return data;
  } catch (error) {
    console.error("API request error",error);
    throw error;
  }
};

export const postAPIPython = async (endpoint, payload = {}) => {
  try {

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(payload),
    };

    const res = await fetch(
      process.env.REACT_APP_PYTHON_SCORING + endpoint,
      requestOptions
    );
    if(!res.ok) {
      throw new Error("API request error");
    }
    const data = await res.json();
    return data;
  } catch (error) {
    console.error("API request error",error);
    throw error;
  }
};

export const putAPIPython = async (endpoint, payload = {}) => {
  try {

    const requestOptions = {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(payload),
    };

    const res = await fetch(
      process.env.REACT_APP_PYTHON_SCORING + endpoint,
      requestOptions
    );
    if(!res.ok) {
      throw new Error("API request error");
    }
    const data = await res.json();
    return data;
  } catch (error) {
    console.error("API request error",error);
    throw error;
  }
};