import React from 'react'
import { 
  Form, 
  Input, 
  Button,
  message,
  Select,
  Divider,
  // Table 
} from "antd";
import { useState, useEffect } from "react";
// import { getAPI, getAPIPython } from "../../services/data";
import { useDispatch, useSelector } from 'react-redux';
import CustomTable from '../../components/CustomTable';
import { DebtorProfileTableData } from "./DebtorProfileTableData";
import {
  fetchDropdownIndikator,
  fetchSummaryIndikator,
  createSummaryIndikator,
  fetchDebtorProfileParameter,
  setIsFirstTime,
  setEditing,
  // setEditing
  // setBouwheerDetail
} from '../../store/actions/debtorProfile'

const { Option } = Select;

const errorSubmit = (text) => {
  message.error(text);
};

const success = (text) => {
  message.success(text);
};

// const postData = async (object) => {
//   const requestOptions = {
//     method: "POST",
//     headers: { "Content-Type": "application/json" },
//     body: JSON.stringify(object),
//   };

//   const response = await fetch(process.env.REACT_APP_PYTHON_SCORING + "debtorprof",requestOptions);
//   console.log(response);
//   const result = await response.json();
//   console.log(result);
//   return result;
// };

const historicalLoanSwitch = (indicator) => {
  switch (indicator) {
    case ">5":
      return ">=5";
    case "<5.01":
      return "<=5.01";
    case "<3.01":
      return "<=3.01";
    case "<1.01":
      return "<=1.01";
    case "<0":
      return "<=0";
    default:
      return false;
  }
};

// const columns = [
//   {
//     title: "Indikator",
//     dataIndex: "indikator",
//     key: "indikator",
//   },
//   {
//     title: "Score",
//     dataIndex: "score",
//     key: "score",
//     render: (_, record) => {
//       if(record["score"] !== null && !Number.isInteger(record["score"] )){
//         return <p>{record["score"].toFixed(1)}</p>;
//       }
//       return <p>{record["score"]}</p>;
//     },
//   },
//   {
//     title: "Weight",
//     dataIndex: "weight",
//     key: "weight",
//     render: (_, record) => {
//       // return <p>{record["weight"] * 100 + "%"} </p>;
//       return <p>{(record["weight"] * 100).toFixed(0) + "%"} </p>;
//     },
//   },
//   {
//     title: "Weighted Score",
//     dataIndex: "weighted_score",
//     key: "weighted_score",
//     render: (_, record) => {
//       return <p>{record["weighted_score"].toFixed(2)} </p>;
//     },
//   },
// ];

const DebtorProfileInformation = (props) => {
  // const {currentSteps} = props;
  const {masterData} = props;
  // const dataMaster = masterData['data'];

  const [form] = Form.useForm();
  const dispatch = useDispatch();

  // Load redux state
  const idDebitur = useSelector(state => state.home.id_debitur);
  const summary = useSelector(state => state.debtorProfile.summaryIndikator);
  const isEditing = useSelector(state => state.debtorProfile.isEditing);
  const isFirstTime = useSelector(state => state.debtorProfile.isFirstTime);
  const isLoadingSubmit = useSelector(state => state.debtorProfile.isLoadingSubmit);
  const { colDebtorProfile, dataSourceDebtorProfile } = DebtorProfileTableData();

  // useState
  const [snk, setsnk] = useState([]);
  const [reliability, setReliability] = useState([]);
  const [dependency, setDependency] = useState([]);
  const [historicalProfile, setHistoricalProfile] = useState([]);
  const [historicalLoan, setHistoricalLoan] = useState([]);
  const [historicalPayment, setHistoricalPayment] = useState([]);
  // const [summary, setSummary] = useState([]);
  // const [triggerSum,setTriggerSum] = useState(false);
  // const [trigger, setTrigger] = useState(false);
  // const [refresh, setRefresh] = useState(true);

  //APIs
  // const apiGetSnk = async () => {
  //   const data = await getAPI("snk");
  //   setsnk(data.data);
  // };

  // const apiReliability = async () => {
  //   const data = await getAPI("reliability");
  //   setReliability(data.data);
  // };

  // const apiDependency = async () => {
  //   const data = await getAPI("dependency");
  //   setDependency(data.data);
  // };

  // const apiHistoricalProfile = async () => {
  //   const data = await getAPI("historical-profile");
  //   setHistoricalProfile(data.data);
  // };

  // const apiHistoricalLoan = async () => {
  //   const data = await getAPI("historical-loan-in-iki");
  //   const historis = (indicator) => {
  //     switch (indicator) {
  //       case ">5":
  //         return ">=5";
  //       case "<5.01":
  //         return "<=5.01";
  //       case "<3.01":
  //         return "<=3.01";
  //       case "<1.01":
  //         return "<=1.01";
  //       case "<0":
  //         return "<=0";
  //     }
  //   };
  //   for(let x in data['data']){
  //     data["data"][x]["indicator"] = historis(data["data"][x]["indicator"]);
  //   }
  //   setHistoricalLoan(data.data);
  // };

  // const apiHistoricalPayment = async () => {
  //   const data = await getAPI("historical-payment");
  //   setHistoricalPayment(data.data);
  // };

  // const apiGetSummaryDebtorProfile = async (id) => {
  //   const data = await getAPIPython("summary_debtor_profile?id_debitur=" + id);
  //   setSummary(data.data);
  // }

  // const handleRefresh = () => {
  //   console.log("Refresh Debtor Profile Info");
  //   setRefresh(!refresh);
  // };

  //Put APIs into 1 function
  // const apiCall = async () => {
  //   apiGetSnk();
  //   apiReliability();
  //   apiDependency();
  //   apiHistoricalProfile();
  //   apiHistoricalLoan();
  //   apiHistoricalPayment();
  //   // apiGetDebiturName();
  // };
  

  const apiGetDropdown = async () => {

    const resApi = await dispatch(fetchDropdownIndikator()).unwrap();
    // console.log("DROPDOWN FETCHED => ", resApi);

    for(let val in resApi.historicalLoan){
      resApi.historicalLoan[val].indicator = historicalLoanSwitch(resApi.historicalLoan[val].indicator);
    }

    setsnk(resApi.skillTenure);
    setReliability(resApi.reliability);
    setDependency(resApi.dependency);
    setHistoricalProfile(resApi.historicalProfile);
    setHistoricalLoan(resApi.historicalLoan);
    setHistoricalPayment(resApi.historicalPayment);

    return resApi;

  }

  const apiGetTableSummary = async (id, data) => {
    const res = await dispatch(fetchSummaryIndikator(id)).unwrap();
    if(res.length !== 0){
      dispatch(setIsFirstTime(false));
      const resParam = await dispatch(fetchDebtorProfileParameter(id)).unwrap();
      form.setFieldsValue({
        skill_and_tenure: data.skillTenure.find(item => item.value === resParam[0].skill_and_tenure)?.value,
        reliability: data.reliability.find(item => item.value === resParam[0].reliability)?.value,
        dependency: data.dependency.find(item => item.value === resParam[0].dependency)?.value,
        historical_profile: data.historicalProfile.find(item => item.value === resParam[0].historical_profile)?.value,
        historical_loan_in_iki_modal: data.historicalLoan.find(item => item.value === resParam[0].historical_loan_in_iki_modal)?.value,
        historical_payment: data.historicalPayment.find(item => item.value === resParam[0].historical_payment)?.value
      });
    }
  }

  const fetchData = async () => {
    const dropdownData = await apiGetDropdown();
    apiGetTableSummary(idDebitur, dropdownData);
  }

  useEffect(() => {
    if(idDebitur){
      fetchData();
    }
    // console.log("DEBTOR PROFILE RENDERED");
  }, [masterData, idDebitur]);

  // useEffect(() => {
  //   apiCall();
  //   console.log("API CALL DEBTOR PROFILE INFO");
  // }, [refresh]);

  // useEffect(() => {
  //   if(trigger) apiGetSummaryDebtorProfile(dataMaster['id'])
  // },[trigger])

  const content = [
    {
      label: "Skill and Tenure",
      name: "skill_and_tenure",
      message: "Masukkan skill dan tenure",
      required: true,
      select_data: snk,
    },
    {
      label: "Reliability",
      name: "reliability",
      message: "Masukkan reliability",
      required: true,
      select_data: reliability,
    },
    {
      label: "Dependency",
      name: "dependency",
      message: "Masukkan dependency",
      required: true,
      select_data: dependency,
    },
    {
      label: "Historical",
      name: "historical_profile",
      message: "Masukkan historical profile",
      required: true,
      select_data: historicalProfile,
    },
    {
      label: "Historical Loan",
      name: "historical_loan_in_iki_modal",
      message: " Masukkan historical loan",
      required: true,
      select_data:historicalLoan,
    },
    {
      label: "Historical Payment",
      name: "historical_payment",
      message: "Masukkan historical payment",
      required: true,
      select_data: historicalPayment,
    },
  ];

  const handleSubmit = async (values) => {

    const payload = JSON.parse(JSON.stringify(values.values));
    payload.id_debitur = idDebitur;

    // console.log(payload)

    try {
      const resDPParam = await dispatch(fetchDebtorProfileParameter(idDebitur)).unwrap();
      const { id, ...dataDPParam } = resDPParam[0] || {};
      const fetchedDataDPParam = Object.fromEntries(Object.entries(dataDPParam).sort());
      const inputtedData = Object.fromEntries(Object.entries(payload).sort());

      if(JSON.stringify(fetchedDataDPParam) !== JSON.stringify(inputtedData)){
        await dispatch(createSummaryIndikator(payload)).unwrap();  
      }
      
      await dispatch(fetchSummaryIndikator(idDebitur)).unwrap();
      dispatch(setIsFirstTime(false));
      dispatch(setEditing(true));
      success("Data tersimpan");
    } catch (error) {
      errorSubmit("Gagal menyimpan data");
    }
    
  }

  // const handleSubmit = async (values) => {
  //   let object;
  //   for (let x in values) {
  //     object = values[x];
  //   }
    
  //   object['id_debitur'] = dataMaster['id']
    
  //   console.log(object);
    
  //   postData(object)
  //     .then((response) => {
  //       console.log(response);
  //       if(response['statusCode'] == 200){
  //         success(response['message']);
  //         // setTrigger(true)
  //         // currentSteps("add");
  //         console.log(response);
  //       }else{
  //         errorSubmit(response['message'])
  //       }
  //     })
  //     .catch((error) => {
  //       errorSubmit("Failed to submit data");
  //       console.log(error);
  //     });
  // };
  
  return (
    <div>
      {/* <p className="text-2xl font-bold text-center my-5">Debtor Profile Info</p> */}
      <Divider>Debtor Profile Info</Divider>
      {/* Form */}
      <Form
        // disabled={trigger}
        form={form}
        disabled={isFirstTime ? false : isEditing ? false : true}
        onFinish={(values) => handleSubmit({ values })}
        name="basic"
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 12,
        }}
        initialValues={{
          remember: true,
        }}
      >
        {/* Form Content */}
        <div className="grid grid-cols-3">
          {content.map((item, i) => {
            return (
              <Form.Item
                key={i}
                label={item.label}
                name={item.name}
                rules={[
                  {
                    required: item.required,
                    message: item.message,
                  },
                ]}
              >
                {item.select_data?.length > 0 ? (
                  <Select
                    showSearch
                    placeholder="Search to Select"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children.includes(input)
                    }
                    filterSort={(optionA, optionB) =>
                      optionA.children
                        .toLowerCase()
                        .localeCompare(optionB.children.toLowerCase())
                    }
                  >
                    {item.select_data.map((item, i) => {
                      return (
                        <Option value={item.value} key={i}>
                          {/* {item.indicator} */}
                          {item?.label ?? item?.indicator}
                        </Option>
                      );
                    })}
                  </Select>
                ) : (
                  <Input />
                )}
              </Form.Item>
            );
          })}
        </div>
        {/* Button Submit */}
        <Form.Item wrapperCol={{ span: 12, offset: 9 }}>
          <Button className="float-right" htmlType="submit">
            Save
          </Button>
          {/* <Button className="float-right mx-2" onClick={handleRefresh}>
            Refresh
          </Button> */}
        </Form.Item>
      </Form>
      {/* <Table dataSource={summary} columns={columns} pagination={false}/> */}
      <CustomTable 
        columns={colDebtorProfile} 
        dataSource={dataSourceDebtorProfile} 
        // columns={columns} 
        // dataSource={summary} 
        bordered
        size="small"
      />
    </div>
  );
}

export default DebtorProfileInformation