import React, { useEffect } from "react";
import {
    Table
  } from "antd";

function CustomTable({
    columns, 
    dataSource,
    pagination = false,
    bordered = false,
    size = "middle",
    components
}) {

    useEffect(() => {
      
    }, [dataSource])
    

    return (
        <>
            <Table 
              columns={columns} 
              dataSource={dataSource} 
              pagination={pagination}
              bordered={bordered}
              size={size}
              components={components}
              scroll={{
                x: 1000,
              }}
            />
        </>
    )
}

export default CustomTable