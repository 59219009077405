import React from "react"
import { Steps, Form, Input, Button, message, Select, Table, InputNumber, Typography, Alert, Popover } from "antd";
import { 
  ExclamationCircleOutlined,
  InfoCircleOutlined
} from "@ant-design/icons";
import {useState,useEffect} from "react";
import { useDispatch, useSelector } from "react-redux"
import moment from "moment";
import { convertDateFormatString } from "../../utils/util";

const { Title } = Typography;

const TableNeracaValidation = (props) => {
  const {setting} = props
  // console.log(setting);
  // const [empty,setEmpty] = useState(true); 
  
  // Load redux state
  const neracaTahun = useSelector(state => state.neraca.neracaTahun);
  const neracaBalance = useSelector(state => state.neraca.neracaBalance); 
  
  // const content = neracaBalance?.length !=0 ? neracaBalance?.map((item, i) => ({
  //   key: (i+1).toString(),
  //   keterangan: item?.kelompok == 'ke001' ? 'Total Assets' : 'Total Liabilities & Equity',
  //   y1: item?.y1,
  //   y2: item?.y2,
  //   y3: item?.y3
  // })).sort((a, b) => {
  //   if (a.keterangan === 'Total Assets') return -1;
  //   if (b.keterangan === 'Total Assets') return 1;
  //   return 0;
  // }) : '';

  // const years = {};
  // neracaTahun.forEach((item, index) => {
  //   years[`y${index + 1}`] = convertDateFormatString(item.indicator, "MMM YYYY");
  // }); 

  // console.log(years)
  // console.log(neracaBalance)
  // console.log(content)
  // const tAssets = content.length != 0 ? content.find((item) => item.keterangan == "Total Assets") : '';
  // const totalAssets = {
  //   y1: tAssets?.y1,
  //   y2: tAssets?.y2,
  //   y3: tAssets?.y3
  // } 
  // const tLiabilities = content.length != 0 ? content.find((item) => item.keterangan == "Total Liabilities & Equity") : '';
  // const totalLiabilities =  {
  //   y1: tLiabilities?.y1,
  //   y2: tLiabilities?.y2,
  //   y3: tLiabilities?.y3
  // } 
  // console.log(tAssets)
  // console.log(totalAssets)
  // console.log(tLiabilities)
  // console.log(totalLiabilities)

  // const content = [
  //   {
  //     key: "1",
  //     keterangan: "Total Assets",
  //     y1: neracaBalance?.find(item => item.kelompok === "ke001")?.y1 || 0,
  //     y2: neracaBalance?.find(item => item.kelompok === "ke001")?.y2 || 0,
  //     y3: neracaBalance?.find(item => item.kelompok === "ke001")?.y3 || 0,
  //   },
  //   {
  //     key: "2",
  //     keterangan: "Total Liabilities & Equity",
  //     y1: neracaBalance?.find(item => item.kelompok === "ke002")?.y1 || 0,
  //     y2: neracaBalance?.find(item => item.kelompok === "ke002")?.y2 || 0,
  //     y3: neracaBalance?.find(item => item.kelompok === "ke002")?.y3 || 0,
  //   }
  // ];

  const balanceStyle = (value) => {
    let color, status;

    if(value >= -1 && value <= 1){
      color = "green";
      status = "Balanced";
    } else {
      color = "red";
      status = "Not Balanced";
    }

    return <p style={{ color }}>{status}</p>;
  };

  const createKeteranganRow = (kelompok) => {
    const filteredData = neracaBalance.find(item => item.kelompok === kelompok);

    return {
      y1: filteredData?.y1 || 0,
      y2: filteredData?.y2 || 0,
      y3: filteredData?.y3 || 0      
    }; 
  } 

  const difference = {
    y1: createKeteranganRow("ke001").y1 - createKeteranganRow("ke002").y1, 
    y2: createKeteranganRow("ke001").y2 - createKeteranganRow("ke002").y2, 
    y3: createKeteranganRow("ke001").y3 - createKeteranganRow("ke002").y3 
  }

  const neracaValData = [
    {
      key: "1",
      keterangan: "Total Assets",
      ...createKeteranganRow("ke001")
    },
    {
      key: "2",
      keterangan: "Total Liabilities & Equity",
      ...createKeteranganRow("ke002")
    },
    {
      key: "3",
      keterangan: "Check",
      ...difference
    },
    {
      key: "4",
      keterangan: "Difference",
      ...difference
    },
  ];

  const rowClassName = (record, index) => {
    if(record.key === "3" || record.key === "4") {
      return "highlight-row";
    }else if(record.key === "1" || record.key === "2"){
        return "no-border";
    }
  };

  const columns = [
    {
      title: "Keterangan",
      dataIndex: "keterangan",
      key: "keterangan",
      fixed: "left",
      render: (_, record) => {
        if(record["keterangan"] === "Check"){
          return (
            <div style={{ display: "inline-flex", alignItems: "center", gap: "8px" }}>
              {record["keterangan"]}
              <Popover placement="top" content={
                <div style={{ display: "inline-flex", alignItems: "center", gap: "5px" }}>
                  <InfoCircleOutlined style={{ color: "#FAAD14", fontSize: "20px" }} /> Total Asset = Total Liabilities + Equity
                </div>
              }>
                <InfoCircleOutlined style={{ color: "#999999" }} />
              </Popover>
            </div>
          );
        }        
        const marginLeft = record["keterangan"] === "Total Assets" || record["keterangan"] === "Total Liabilities & Equity" ? "30px" : "0px";
        return (
          <p style={{ marginLeft }}>{record["keterangan"]}</p>
        );
      },
    },    
    ...Object.entries(neracaTahun[0])
      .filter(([key]) => key.startsWith("y"))
      .map(([key, value], index) => ({
        title: convertDateFormatString(value, "MMM YYYY"),
        dataIndex: key,
        key: key,
        render: (_, record) => {
          if(record["keterangan"] === "Check"){
            return balanceStyle(record[key]);
          }
          return (
            <p>
              {(typeof record[key] === "number") ? "Rp" + record[key].toLocaleString() : ""}
            </p>
          );
        }
      })
    )    
  ]; 

  return (
    <>
      <div className="my-8 grid grid-cols-1">
        <Title level={4} style={{ textAlign: "center", marginBottom: "15px" }}>Neraca Validation</Title>
        <Table
          pagination={false}
          dataSource={neracaValData}
          columns={columns}
          rowClassName={rowClassName}
        />
      </div>
    </>
  );
}

export default TableNeracaValidation