import React from 'react'
import { Form, Input, InputNumber, Button, message, Select, Table,Row,Col } from "antd";
import { useState, useEffect } from "react";
import { getAPIPython, getAPI } from "../../services/data";

const {Option} = Select

const errorSubmit = (text) => {
  message.error(text);
};

const success = (text) => {
  message.success(text);
};

const postData = async (object,url) => {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(object),
  };

  const response = await fetch(
    process.env.REACT_APP_PYTHON_SCORING + url,
    requestOptions
  );
  console.log(response);
  const result = await response.json();
  console.log(result);
  return result;
};

const BankLoan = (props) => {
  const {masterData,trigger} = props;
  const [form] = Form.useForm();

  const [keterangan1,setKeterangan1] = useState([]);
  const [keterangan2,setKeterangan2] = useState([]);
  const [inputtedKeterangan,setInputtedKeterangan] = useState([]);
  const [dropdown,setDropdown] = useState([]);
  const [disabledSelect,setDisabledSelect] = useState(true)
  const [disabledBank,setDisabledBank] = useState(false)
  const [disabledPeriod,setDisabledPeriod] = useState(false)
  const [bank,setBank] = useState("");
  const [period,setPeriod] = useState(0)
  const [biggestPeriod,setBiggestPeriod] = useState(0);
  const [view,setView] = useState([]);
  const [columns,setColumns] = useState([]);
  const [viewTrigger,setViewTrigger] = useState(false)
  const [boolView,setBoolView] = useState(false)
  const [isInterest, setIsInterest] = useState(false);
  const [inputtedBankLoan,setInputtedBankLoan] = useState([]);

  const columnInputted = [
    {
      title: "Bank Type",
      dataIndex: "bank_type",
    },
    {
      title: "Description",
      dataIndex: "description",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      render:(_,record) => {
        return (
          <p>{record['description'] == 'Percent Interest Payment' || record['description'] == 'Principal Prempayment' ? record['amount'] + '%' : 'Rp' + record['amount'].toLocaleString()}</p>
        )
      }
    },
    {
      title: "Period",
      dataIndex: "period"
    }
  ]

  const handleSubmit = async (values) => {
    let found = false;
    let flag = false
    values['Id_Debitur'] = masterData['id'];

    console.log(values);

    //Check if description already been inputted 
    inputtedKeterangan.map((item,i) => {
      if(item == values['Description']) found = true
    })

    //???
    if(period == 0){
      if(keterangan1.length == inputtedKeterangan.length){
        flag = true
      }
    }else{
      if(keterangan2.length == inputtedKeterangan.length){
        flag = true
      }
    }

    if(!flag){
      if(found){
        return errorSubmit("Keterangan sudah pernah diinput");
      }
    }else{
      return errorSubmit("Keterangan sudah terinput semua. Mohon menginput periode selanjutnya jika ada")
    }
    
    console.log(values['Period'],period);
    // if(values['Period'] != 0 && values['Period'] - period > 1) return errorSubmit(`Mohon menginput period ${period + 1} terlebih dahulu`)
    if(values['Period'] > biggestPeriod) setBiggestPeriod(values['Period']);

    // setBank(values["Bank_Type"]);
    // setPeriod(values["Period"]);
    // setDisabledBank(true);
    // setInputtedKeterangan([...inputtedKeterangan, values["Description"]]);
    
    postData(values,'capabilities_bank_loan').then((response) => {
      if(response['statusCode'] == 200){
        // setRefreshInput(!refreshInput)
        apiGetInputtedBankLoan()
        setBank(values['Bank_Type'])
        setPeriod(values['Period'])
        setDisabledBank(true)
        setDisabledPeriod(true)
        setInputtedKeterangan([...inputtedKeterangan,values['Description']])
        success(response['message']);
      }else{
        errorSubmit(response['message']);
      }
    }).catch((error) => {
      console.log(error);
      errorSubmit("Internal Server Error !");
    })
  }

  const handleChange = (value) => {
    if(value != null){
      setDisabledSelect(false)
      setInputtedKeterangan([])
      if(value == 0){
        setDropdown(keterangan1)
      }else{
        setDropdown(keterangan2)
      }
    }
  }

  const handleSubmitAll = () => {
    let object = {
      Id_Debitur: masterData['id'],
      Bank_Type: bank,
      Period: period
    }
    // console.log(object);

    postData(object, "capabilities_bank_loan_summary").then((response) =>{
      if(response['statusCode'] == 200){
        success(response['message']);
        setDisabledPeriod(false)
      }else{
        errorSubmit(response['message']);
      }
    }).catch((error) => {
      console.log(error);
      errorSubmit("Internal Server Error")
    })
  }

  const handleGenerateTotalExisting = () => {
    console.log("Genereate Total Existing");
    // console.log(biggestPeriod)
    let object = {
      Id_Debitur : masterData['id'],
    }

    postData(object, "capabilities_total_existing")
      .then((response) => {
        if (response["statusCode"] == 200) {
          success(response["message"]);
          setViewTrigger(!viewTrigger)
          setBoolView(true)
          trigger(false)
        } else {
          errorSubmit(response["message"]);
        }
      })
      .catch((error) => {
        console.log(error);
        errorSubmit("Internal Server Error");
      });
  }

  const handleReset = () => {
    form.resetFields();
    setDisabledBank(false);
  }

  const handleChangeDropdown = (values) => {
    if(values == 'BL02' || values == 'BL08'){
      setIsInterest(true)
    }else{
      setIsInterest(false)
    }
  }

  const apiGetKeterangan = async () => {
    const keterangan1 = [
      "BL01",
      "BL02",
      "BL03",
      "BL06",
      "BL07",
      "BL08",
      "BL09",
    ];
    const keterangan2 = ["BL02", "BL06", "BL07", "BL09"];

    const data = await getAPIPython("capabilities_bank_loan-get");
    const result = data["data"];
    // console.log(result);

    const filtered1 = result.filter((e) => keterangan1.includes(e.id));
    const filtered2 = result.filter((e) => keterangan2.includes(e.id));

    // console.log("Filtered 1", filtered1);
    // console.log("Filtered 2", filtered2);

    setKeterangan1(filtered1);
    setKeterangan2(filtered2);
  };

  const apiGetViewBankLoan = async () => {
    const data = await getAPIPython(
      "view_capabilities_bank_loan?id_debitur=" + masterData["id"]
    );
    setView(data["value"]);

    console.log(data["value"]);
    //Generate columns depends on length of the key
    const num = Object.keys(data["value"][0]).length;
    let array = [];
    for (let x = 0; x <= num - 2; x++) {
      let object = {
        title: x.toString(),
        dataIndex: x.toString(),
        key: x.toString(),
        render: (_, record) => {
          if (
            record[x.toString()] != "" &&
            record[x.toString()] != 0 &&
            record[x.toString()] != "-"
          ) {
            if (
              record["description"] == "Principal Prempayment" ||
              record["description"] == "Percent Interest Payment"
            ) {
              return <p>{record[x.toString()] * 100 + "%"}</p>;
            }
            return <p>{"Rp" + record[x.toString()].toLocaleString()}</p>;
          } else {
            return <p>{record[x.toString()]}</p>;
          }
        },
      };
      array.push(object);
    }
    array.unshift({
      title: "Keterangan",
      dataIndex: "description",
      key: "description",
      render: (_,record) =>{
        let flag = false
         if (
           record["description"] == "Principal Amount" ||
           record["description"] == "Principal Prempayment" ||
           record["description"] == "Principal Ending Balance" ||
           record["description"] == "Percent Interest Payment" ||
           record["description"] == "Interest Ending Balance"
         ){
           flag = true;
         }
         return <p className={flag ? "font-bold" : ""}>{record["description"]}</p>;
      }
    });
    setColumns(array);
  };

  const apiGetInputtedBankLoan = async () => {
  const data =await getAPI("bank-loan?id=" + masterData['id']);
  setInputtedBankLoan(data['data']);
  }

  useEffect(() => {
    apiGetKeterangan();
  }, []);

  useEffect(() => {
    console.log("API GET VIEW BANK LOAN");
    apiGetViewBankLoan();
  }, [viewTrigger]);

  const content = [
    {
      label: "Nama Bank",
      name: "Bank_Type",
      message: "Masukkan nama bank",
      required: true,
    },
    {
      label: "Periode Hutang",
      name: "Period",
      message: "Masukkan period",
      required: true,
    },
    {
      label: "Input Keterangan",
      name: "Description",
      message: "Masukkan description",
      required: true,
      select_data: dropdown
    },
    {
      label: "Amount",
      name: "Amount",
      message: "Masukkan amount",
      required: true
    }
  ];

  return (
    <div>
      <p className="text-2xl font-bold text-center my-5">Bank Loan</p>
      <Form
        form={form}
        onFinish={(values) => handleSubmit(values)}
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 10,
        }}
        initialValues={{
          remember: true,
        }}
      >
        <div className="grid grid-cols-2">
          {content.map((item, i) => {
            return (
              <Form.Item
                key={i}
                label={item.label}
                name={item.name}
                rules={[
                  {
                    required: item.required,
                    message: item.message,
                  },
                ]}
              >
                {
                  item.select_data != null ? (
                    <Select
                      onChange={(value) => handleChangeDropdown(value)}
                      disabled={disabledSelect}
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children.includes(input)
                      }
                      filterSort={(optionA, optionB) =>
                        optionA.children
                          .toLowerCase()
                          .localeCompare(optionB.children.toLowerCase())
                      }
                    >
                      {item.select_data.map((object, i) => {
                        return (
                          <Option value={object.id} key={i}>
                            {object.kategori}
                          </Option>
                        );
                      })}
                    </Select>
                  ) : item.label != "Nama Bank" ? (
                    <InputNumber
                      disabled={item.name == "Period" ? disabledPeriod : ""}
                      min={0}
                      max={isInterest ? 100 : ''}
                      onChange={
                        item.name == "Period"
                          ? (value) => handleChange(value)
                          : ""
                      }
                      style={{ width: "100%" }}
                      addonAfter={item.label == "Amount"? isInterest ? '%' : '' : ''}
                      addonBefore={item.label == "Amount"? isInterest ? '' : 'Rp' : ''}
                      formatter={(value) =>
                        `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      }
                    />
                  ) : (
                    <div className="flex">
                      <Input disabled={disabledBank} />
                      <Button className="w-[50%] mx-1" onClick={handleReset}>
                        Reset
                      </Button>
                    </div>
                  )
                  // (
                  //  item.name == 'Bank_Type' ? (
                  //   <div className='flex'>
                  //     <Input disabled={disabledBank}/>
                  //     <Button className='w-[50%] mx-1' onClick={handleReset}>Reset</Button>
                  //   </div>
                  //  ) : item.name == 'Period' ? (<Input disabled={true}/>) : (<Input disabled={true}/>)
                  // )
                }
              </Form.Item>
            );
          })}
        </div>
        <Form.Item wrapperCol={{ span:22 }}>
          <Button className="mx-3 float-right" htmlType="submit">
            Submit
          </Button>
          <Button className="mx-3 float-right" onClick={handleSubmitAll}>
            Submit Period
          </Button>
          <Button onClick={handleGenerateTotalExisting}>Generate Total Existing<sup>*</sup></Button>
        </Form.Item>
      </Form>
      {/* <Button onClick={handleRefresh}>View Data</Button> */}
      {/* <Table dataSource={view} columns={columns}/> */}
      <Table dataSource={inputtedBankLoan} columns={columnInputted} pagination={false}/>
      {
      boolView && 
      <Table 
        pagination={false}
        dataSource={view} 
        columns={columns} 
        scroll={{x: 1000}}
        rowClassName={(record, index) => {
          if (record["description"].includes("Ending Balance")) {
            return "bg-zinc-100";
          }
        }}
      />
      }
    </div>
  );
}

export default BankLoan