import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    id_debitur: '',
    currentPage: 0,
    isLoadingAPI: false
}

export const homeSlice = createSlice({
    name: 'home',
    initialState,
    reducers: {
        setIdDebitur: (state, action) => {
            state.id_debitur = action.payload
        },
        resetIdDebitur: () => initialState,
        prevPage: (state) => {
            state.currentPage = state.currentPage - 1
        },
        nextPage: (state, action) => {
            state.currentPage = state.currentPage + 1
        },
        setPage:  (state, action) => {
            state.currentPage = action.payload
        },
        setIsLoadingAPI: (state, action) => {
            state.isLoadingAPI = action.payload
        },
        resetApp: state => {

        }
    }
})

export const { setIdDebitur, resetIdDebitur, resetApp, prevPage, nextPage, setPage, setIsLoadingAPI } = homeSlice.actions

export default homeSlice.reducer