import React from "react";
import {
  Form,
  Button, 
  message, 
  // Select, 
  DatePicker, 
  InputNumber, 
  Alert, 
  // Table,
  Input,
  Divider, 
  Space,
  Popconfirm,
  Tooltip
} from "antd";
import { 
  EditOutlined,
  DeleteOutlined,
  CloseOutlined,
  CheckOutlined
} from '@ant-design/icons';
// import { getAPIPython, getAPI } from "../services/data";
import moment from "moment";
import MasterData from "../components/MasterData";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import CustomTable from "../components/CustomTable";
import { CreditGivenTableData } from "./Credit Given/CreditGivenTableData";
import { dataWithoutId } from "../utils/util";
import { 
  setDataInvoice,
  // setDataPembiayaan,
  setIsFirstTime,
  setEditing,
  setIsSubmit,
  // setLoadingSubmit,
  fetchInvoiceList,
  fetchSummary,
  // createCreditGiven,
  updateInvoice,
  updateCreditGiven,
  setSkipUpdateState,
  resetCreditGiven
} from '../store/actions/creditGivenSlice';

// const { Option } = Select;

const errorSubmit = (text) => {
  message.error({ content: text, key: "credit" });
};

const success = (text) => {
  message.success({ content: text, key: "credit" });
};

// const postData = async (object) => {
//   const requestOptions = {
//     method: "POST",
//     headers: { "Content-Type": "application/json" },
//     body: JSON.stringify(object),
//   };

//   const response = await fetch(
//     process.env.REACT_APP_PYTHON_SCORING + "input_credit_given",
//     requestOptions
//   );
//   console.log(response);
//   const result = await response.json();
//   console.log(result);
//   return result;
// };

// const postDataFinal = async (object) => {
//   const requestOptions = {
//     method: "POST",
//     headers: { "Content-Type": "application/json" },
//     body: JSON.stringify(object),
//   };

//   const response = await fetch(process.env.REACT_APP_EXPRESS_SCORING_UI + "summary-credit-given",requestOptions);
//   console.log(response);
//   const result = await response.json();
//   console.log(result);
//   return result;
// };

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  let inputNode;
  switch (dataIndex) {
    case "nomor_invoice":
      inputNode = <Input />;
      break;
    case "invoice_date":
    case "payment_date":
      inputNode = <DatePicker style={{ width: "100%" }}/>
      // inputNode = <Input />;
      break;
    default:
      inputNode = inputNode = <InputNumber
        min={
          dataIndex == "Period" || dataIndex == "amount"
            ? 1
            : ""
        }
        style={{ width: "100%" }}
        maxLength={title == "Period" ? 5 : ""}
        addonAfter={dataIndex == "Period" ? "Bulan" : ""}
        addonBefore={
          dataIndex == "amount" || dataIndex == "nilai_pajak"
            ? "Rp"
            : ""
        }
        formatter={
          dataIndex == "amount" || dataIndex == "nilai_pajak"
            ? (value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            : ""
        }
      />;
      break;
  }
  // const inputNode = inputType === 'number' ? <InputNumber style={{width: "50%"}} min={0} maxLength={dataIndex == "ktp_npwp" ? "16" : ""}/> : <Input />;
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const isEmptyOrSpaces = (str) => {
  return str === null || str.toString().match(/^ *$/) !== null;
};

const disabledDate = (current) => {
  // Can not select days before today and today
  return current > moment().endOf('day');
};

const CreditGiven = (props) => {
  // const {currentSteps} = props;
  const { masterData } = props;
  // const dataMaster = masterData["data"];
  // console.log("INI DI CREDIT GIVEN", dataMaster);

  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [formPersentase] = Form.useForm();

  const idDebitur = useSelector(state => state.home.id_debitur);
  const dataInvoice = useSelector(state => state.creditGiven.dataInvoice);
  const dataPembiayaan = useSelector(state => state.creditGiven.dataPembiayaan);
  const isEditing = useSelector(state => state.creditGiven.isEditing);
  const isLoadingSubmit = useSelector(state => state.creditGiven.isLoadingSubmit);
  const isFirstTime = useSelector(state => state.creditGiven.isFirstTime);
  const isSubmit = useSelector(state => state.creditGiven.isSubmit);
  const { colCreditGiven, dataSourceCreditGiven, colSummaryCreditGiven, dataSourceSummaryCreditGiven } = CreditGivenTableData();

  const [editingKey, setEditingKey] = useState('');
  const isEditingRow = (record) => record.id === editingKey;
  // const [trigger, setTrigger] = useState(true);
  // const [triggerSummary,setTriggerSummary] = useState(false);
  // const [summaryFlag,setSummaryFlag] = useState(false)
  // const [disabled,setDisabled] = useState(true);
  // const [disabledSubmit,setDisabledSubmit] = useState(false)
  // const [data,setData] = useState([]);
  // const [dataSummary,setDataSummary] = useState([]);
  // const [invoice,setInvoice] = useState([]);
  // const [spin,setSpinner] = useState(false);
  // const [refresh,setRefresh] = useState(false)

  //APIs
  // const apiGetCreditGiven = async (id) => {
  //   const data = await getAPIPython("view_credit_given/?id_debitur=" + id);
  //   setData(data.data);
  // };

  // const apiGetSummaryCreditGiven = async (id) => {
  //   if(summaryFlag){
  //     let array = []
  //     const data = await getAPI("summary-credit-given?id_debitur=" + id);
  //     array.push(data['data']);
  //     console.log("GET SUMMARY CREDIT GIVEN",data);
  //     setDataSummary(array)
  //   }
  // }

  const getInputted = async (id) => { 
    const inputtedInvoice = await dispatch(fetchInvoiceList(id)).unwrap();
    if(
      (!isEditing && !isSubmit && inputtedInvoice.length !== 0) || 
      (inputtedInvoice.length !== 0 && inputtedInvoice[0]?.id_debitur !== dataInvoice[0]?.id_debitur)
    ){
      dispatch(setEditing(true));
      dispatch(setIsSubmit(false));
    }

    const inputtedPembiayaan = await dispatch(fetchSummary(id)).unwrap();
    if(inputtedPembiayaan.length !== 0 && inputtedPembiayaan[0]?.id_debitur === id){
      dispatch(setIsFirstTime(false));

      formPersentase.setFieldsValue({
        persentase_pembiayaan_maksimal: inputtedPembiayaan[0]?.persentase_pembiayaan_maksimal * 100,
        pembiayaan_maksimal: inputtedPembiayaan[0]?.pembiayaan_maksimal_iki
      });
    }

    if(inputtedInvoice.length === 0){
      dispatch(setEditing(false));
      dispatch(setIsSubmit(false));
    }

    if(inputtedInvoice.length === 0 || inputtedPembiayaan.length === 0){
      dispatch(setIsFirstTime(true));
    }

    // const inputtedInvoice = await dispatch(fetchInvoiceList(id)).unwrap();
    // if (inputtedInvoice.length != 0 && dataInvoice.length == 0) {
    //   // console.log("FIRST CASE")
    //   dispatch(setIsFirstTime(false));
    //   await dispatch(fetchSummary(id)).unwrap();
    // } else if (inputtedInvoice.length == 0 && dataInvoice.length != 0) {
    //   // console.log("SECOND CASE")
    //   if (id != dataInvoice[0].id_debitur) {
    //     await dispatch(fetchInvoiceList(id)).unwrap();
    //     await dispatch(fetchSummary(id)).unwrap();
    //   } else {
    //     dispatch(setDataInvoice(dataInvoice))
    //   }
    // } else if (inputtedInvoice.length == 0 && dataInvoice.length == 0){
    //   // console.log("THIRD CASE")
    //   dispatch(setDataInvoice([]))
    // }
  }

  useEffect(() => {
    if(!isEditing){
      getInputted(idDebitur)
    }
    // getInputted(masterData.id)
    // console.log("CREDIT GIVEN RENDERED")
  }, [masterData, idDebitur]);

  // const handleRefresh = () => {
  //   setRefresh(!refresh)
  // }

  // useEffect(() => {
  //   // console.log("Credit Given");
  //   if(dataMaster) apiGetCreditGiven(dataMaster['id'])
  // }, [trigger]);

  // useEffect(() => {
  //   if(summaryFlag) apiGetSummaryCreditGiven(dataMaster['id']);
  //   console.log("Summary Credit Given");
  // },[triggerSummary]);

  const edit = (record) => {
    // console.log(record)
    form.setFieldsValue({
      ...record,
      invoice_date: moment(record.invoice_date),
      payment_date: moment(record.payment_date),
    });
    setEditingKey(record.id);
  };
  const cancel = () => {
    setEditingKey('');
  };
  const save = async (id) => {
    try {
      const row = await form.validateFields();
      const newData = [...dataInvoice];
      const index = newData.findIndex((item) => id === item.id);
      const excludedArray = newData.toSpliced(index, 1);
      if (index > -1) {
        const item = newData[index];

        // Check empty values
        for (let x in row) {
          if (isEmptyOrSpaces(x)) {
            return errorSubmit("Text cannot be empty");
          }
        }

        // Check duplicate invoice number
        for (const inv of excludedArray) {
          if (row.nomor_invoice == inv.nomor_invoice || row.nomor_invoice == inv.nomor_invoice) return errorSubmit("Nomor Invoice sudah pernah di input");
        }
        // Check tax value cannot exceed invoice amount
        if (row.nilai_pajak > row.amount) return errorSubmit("Pajak tidak boleh melebihi nilai invoice");
        // Check invoice date cannot surpass payment date
        if(moment(row.invoice_date).isAfter(moment(row.payment_date))) return errorSubmit("Invoice Date tidak boleh melebihi payment date");

        // Convert date
        row.invoice_date = moment(row.invoice_date).format("YYYY-MM-DD");
        row.payment_date = moment(row.payment_date).format("YYYY-MM-DD");
        row.nilai_invoice = row.amount;


        newData.splice(index, 1, {
          ...item,
          ...row,
        });

        // console.log("EDITED DATA => ", newData)
        dispatch(setDataInvoice(newData));
        setEditingKey('');
      } else {

        // Check empty values
        for (let x in row) {
          if (isEmptyOrSpaces(x)) {
            return errorSubmit("Text cannot be empty");
          }
        }

        // Check duplicate invoice number
        for (const inv of excludedArray) {
          if (row.nomor_invoice == inv.nomor_invoice || row.nomor_invoice == inv.nomor_invoice) return errorSubmit("Nomor Invoice sudah pernah di input");
        }
        // Check tax value cannot exceed invoice amount
        if (row.nilai_pajak > row.amount) return errorSubmit("Pajak tidak boleh melebihi nilai invoice");
        // Check invoice date cannot surpass payment date
        if(moment(row.invoice_date).isAfter(moment(row.payment_date))) return errorSubmit("Invoice Date tidak boleh melebihi payment date");

        // Convert date
        row.invoice_date = moment(row.invoice_date).format("YYYY-MM-DD");
        row.payment_date = moment(row.payment_date).format("YYYY-MM-DD");

        newData.push(row);

        // console.log("EDITED DATA => ", newData)
        dispatch(setDataInvoice(newData));
        setEditingKey('');
      }
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };
  const deleteRow = async (id) => {

    const newArray = dataInvoice.filter((v) => v.id != id);
    dispatch(setDataInvoice(newArray));

  }

  // const columnsSummary = [
  //   {
  //     title: "Persentase Pembiayaan",
  //     dataIndex: "persentase_pembiayaan_maksimal",
  //     key: "persentase_pembiayaan_maksimal",
  //     render: (_, record) => {
  //       return <>{record['persentase_pembiayaan_maksimal'] * 100}</>
  //     }
  //   },
  //   {
  //     title: "Jumlah Pembiayaan",
  //     dataIndex: "jumlah_pembiayaan_invoice",
  //     key: "jumlah_pembiayaan",
  //     // onCell: (record) => {
  //     //   record["jumlah_pembiayaan_invoice"] =
  //     //     record["jumlah_pembiayaan_invoice"].toLocaleString();
  //     // },
  //     render: (_, record) => {
  //       return <>{record['jumlah_pembiayaan_invoice']?.toLocaleString() || ""}</>
  //     }
  //   },
  //   {
  //     title: "Pembiayaan Maksimal",
  //     dataIndex: "pembiayaan_maksimal",
  //     key: "pembiayaan_maksimal",
  //     // onCell: (record) => {
  //     //   record["pembiayaan_maksimal"] =
  //     //     record["pembiayaan_maksimal"].toLocaleString();
  //     // },
  //     render: (_, record) => {
  //       return <>{record['pembiayaan_maksimal']?.toLocaleString() || ""}</>
  //     }
  //   },
  //   {
  //     title: "Pembiayaan Maksimal IKI",
  //     dataIndex: "pembiayaan_maksimal_iki",
  //     key: "pembiayaan_maksimal_iki",
  //     // onCell: (record) => {
  //     //   record["pembiayaan_maksimal_iki"] =
  //     //     record["pembiayaan_maksimal_iki"].toLocaleString();
  //     // },
  //     render: (_, record) => {
  //       return <>{record['pembiayaan_maksimal_iki'].toLocaleString()}</>
  //     }
  //   },
  //   {
  //     title: "Pertanggungan Fidusia",
  //     dataIndex: "pertanggungan_fidusia",
  //     key: "pertanggungan_fidusia",
  //   },
  // ];

  // const columns = [
  //   {
  //     title: "No. Invoice",
  //     dataIndex: "nomor_invoice",
  //     key: "no_invoice",
  //     editable: true,
  //   },
  //   {
  //     title: "Invoice Date",
  //     dataIndex: "invoice_date",
  //     key: "invoice_date",
  //     editable: true,
  //   },
  //   {
  //     title: "Payment Date",
  //     dataIndex: "payment_date",
  //     key: "payment_date",
  //     editable: true,
  //   },
  //   {
  //     title: "Amount",
  //     dataIndex: "amount",
  //     key: "amount",
  //     editable: true,
  //     // onCell: (record) => {
  //     //   console.log(record['amount'])
  //     //   return record['amount'].toLocaleString();
  //     // },
  //     render: (_, record) => {
  //       return <>{record['amount'].toLocaleString()}</>
  //     }
  //   },
  //   {
  //     title: "Tax",
  //     dataIndex: "nilai_pajak",
  //     key: "tax",
  //     editable: true,
  //   },
  //   {
  //     title: "Action",
  //     key: "action",
  //     render: (_, record) => {
  //       const editable = isEditingRow(record);
  //       return editable ? (
  //         <Space>
  //           <Popconfirm title="Save changes?" onConfirm={() => save(record.id)} okButtonProps={{type: "secondary"}}>
  //             <Button type="secondary" icon={<CheckOutlined />} />
  //           </Popconfirm>
  //           <Popconfirm title="Cancel editing?" onConfirm={cancel} okButtonProps={{type: "secondary"}}>
  //             <Button type="secondary" icon={<CloseOutlined />} />
  //           </Popconfirm>
  //         </Space>
  //       ) : (
  //         <>
  //         <Space>
  //           <Tooltip title="edit">
  //             <Button disabled={!isEditing || editingKey !== ''} type="secondary" icon={<EditOutlined />} onClick={() => edit(record)}/>
  //           </Tooltip>
  //           {isEditing ? (
  //             <Popconfirm title="Confirm deletion?" onConfirm={() => deleteRow(record.id)} okButtonProps={{type: "secondary"}}>
  //               <Tooltip title="delete">
  //                 <Button type="secondary" icon={<DeleteOutlined />}/>
  //               </Tooltip>
  //             </Popconfirm>
  //           ) : (
  //             <Tooltip title="delete">
  //               <Button disabled={!isEditing} type="secondary" icon={<DeleteOutlined />}/>
  //             </Tooltip>
  //           )}
  //         </Space>
  //         </>
  //       );
  //     },
  //   },
  // ];

  const columns = [
    ...colCreditGiven.map((item) => ({ ...item, editable: true })),
    {
      title: "Action",
      key: "action",
      render: (_, record) => {
        const editable = isEditingRow(record);
        return editable ? (
          <Space>
            <Popconfirm title="Save changes?" onConfirm={() => save(record.id)} okButtonProps={{type: "secondary"}}>
              <Button type="secondary" icon={<CheckOutlined />} />
            </Popconfirm>
            <Popconfirm title="Cancel editing?" onConfirm={cancel} okButtonProps={{type: "secondary"}}>
              <Button type="secondary" icon={<CloseOutlined />} />
            </Popconfirm>
          </Space>
        ) : (
          <>
          <Space>
            <Tooltip title="edit">
              <Button disabled={!isEditing || editingKey !== ''} type="secondary" icon={<EditOutlined />} onClick={() => edit(record)}/>
            </Tooltip>
            {isEditing ? (
              <Popconfirm title="Confirm deletion?" onConfirm={() => deleteRow(record.id)} okButtonProps={{type: "secondary"}}>
                <Tooltip title="delete">
                  <Button type="secondary" icon={<DeleteOutlined />}/>
                </Tooltip>
              </Popconfirm>
            ) : (
              <Tooltip title="delete">
                <Button disabled={!isEditing} type="secondary" icon={<DeleteOutlined />}/>
              </Tooltip>
            )}
          </Space>
          </>
        );
      },
    }
  ];

  // REFER TO ANTD DOCUMENTATIONS (EDITABLE ROWS)
  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditingRow(record),
      }),
    };
  });

  const content = [
    {
      label: "Nomor Invoice",
      name: "nomor_invoice",
      message: "Masukkan nomor invoice",
      required: true,
    },
    {
      label: "Invoice Date",
      name: "invoice_date",
      message: "Masukkan invoice date",
      required: true,
    },
    {
      label: "Payment Date",
      name: "payment_date",
      message: "Masukkan payment date",
      required: true,
    },
    {
      label: "Period",
      name: "period",
      message: "Masukkan period",
      required: true,
    },
    {
      label: "Amount",
      name: "amount",
      message: " Masukkan nilai invoice",
      required: true,
    },
    {
      label: "Tax",
      name: "nilai_pajak",
      message: " Masukkan nilai pajak",
      required: false,
    },
  ];

  // const handleSubmit = async (values) => {
  //   let object;
  //   let found = false;
  //   for (let x in values) {
  //     object = values[x];
  //   }
  //   console.log(object);

  //   object["Id_Debitur"] = dataMaster["id"];
  //   if (!object["Nilai_Pajak"]) {
  //     object["Nilai_Pajak"] = 0;
  //   }
  //   //Convert from moment object to date
  //   //Convert tanggal invoice date
  //   object.Invoice_Date = moment(object.Invoice_Date).format("YYYY-MM-DD");

  //   //Convert tanggal payment date
  //   object.Payment_Date = moment(object.Payment_Date).format("YYYY-MM-DD");

  //   invoice.forEach((e) => {
  //     if(e == object['Nomor_Invoice']) found = true
  //   })

  //   if(found) return errorSubmit("Nomor Invoice sudah pernah di input")

  //   if(object['Nilai_Pajak'] > object['Nilai_Invoice']) return errorSubmit("Tax cannot be more than amount")

  //   if(object['Invoice_Date'] > object['Payment_Date']) return errorSubmit("Invoice Date must be earlier than payment date")

  //   if(object['Invoice_Date'] > moment().format("YYYY-MM-DD")) return errorSubmit("Invoice Date should be set in the past");
    
  //   console.log(invoice);

  //   postData(object)
  //     .then((response) => {
  //       if (response.statusCode == 200) {
  //         console.log(response);
  //         let invoice_data = object['Nomor_Invoice']
  //         setInvoice([...invoice,invoice_data])
  //         success("Success Submit Data");
  //         setDisabled(false);
  //         setTrigger(!trigger)
  //       } else {
  //         errorSubmit(response.message);
  //       }
  //     })
  //     .catch((error) => {
  //       errorSubmit();
  //       console.log(error);
  //     });
  // };

  // const handleChange = (values) => {
  //   let date = +moment(values).format("YYYY");
  //   if(date <= 2020){
  //     setDisabledSubmit(true)
  //   }else{
  //     setDisabledSubmit(false)
  //   }
  //   console.log(date,typeof(date));
  // }

  const submitInvoice = async (values) => {
    const payload = JSON.parse(JSON.stringify(values.values));
    // console.log(payload);

    // Validate payload
    if (!payload?.nilai_pajak) payload.nilai_pajak = 0; // Add default pajak value
    // Check duplicate invoice number
    for (const inv of dataInvoice) {
      if (payload.nomor_invoice == inv.nomor_invoice || payload.nomor_invoice == inv.nomor_invoice) return errorSubmit("Nomor Invoice sudah pernah di input");
    }
    // Check tax value cannot exceed invoice amount
    if (payload.nilai_pajak > payload.amount) return errorSubmit("Pajak tidak boleh melebihi nilai invoice");
    // Check invoice date cannot surpass payment date
    if(moment(payload.invoice_date).isAfter(moment(payload.payment_date))) return errorSubmit("Invoice Date tidak boleh melebihi payment date");

    // Convert date
    payload.invoice_date = moment(payload.invoice_date).format("YYYY-MM-DD");
    payload.payment_date = moment(payload.payment_date).format("YYYY-MM-DD");

    // Calculate date difference
    payload.top = moment(payload.payment_date).diff(moment(payload.invoice_date), 'days');

    payload.nilai_invoice = payload.amount

    // Add id_debitur
    payload.id_debitur = masterData.id;
    payload.id = payload.nomor_invoice;

    const mergedArray = [...dataInvoice];
    mergedArray.push(payload);

    dispatch(setDataInvoice(mergedArray));
    dispatch(setEditing(true));
    dispatch(setIsSubmit(false));
    success("Data tersimpan");
  }

  const submitPersentase = async (values) => {    
    const payload = JSON.parse(JSON.stringify(values.values));
    const payloadInvoice = JSON.parse(JSON.stringify(dataInvoice));
    // console.log(payload);

    // Cek pembiayaan maksimal threshold
    const totalInvoice = payloadInvoice.reduce((accumulator, currentValue) => accumulator + currentValue.amount, 0);
    if (isFirstTime) {
      const pembiayaanMaksimal = totalInvoice * (payload.persentase_pembiayaan_maksimal / 100);
      if (pembiayaanMaksimal <= 1000000) return errorSubmit("Nilai pembiayaan maksimal harus lebih dari 1 juta, silakan tambahkan amount invoice atau perbesar persentase pembiayaan");
    } else {
      const pembiayaanMaksimal = totalInvoice * (dataPembiayaan[0]?.persentase_pembiayaan_maksimal ?? (payload.persentase_pembiayaan_maksimal / 100))
      if (pembiayaanMaksimal <= 1000000) return errorSubmit("Nilai pembiayaan maksimal harus lebih dari 1 juta, silakan tambahkan amount invoice atau perbesar persentase pembiayaan");
    }
    
    let isChangeDataPembiayaan = false;
    dispatch(setSkipUpdateState(true)); 
    const resInvoice = await dispatch(fetchInvoiceList(idDebitur)).unwrap();
    if(JSON.stringify(dataWithoutId(resInvoice)) !== JSON.stringify(dataWithoutId(payloadInvoice))){
      // Update / create invoices
      try {
        // Remove id from payload
        for (const inv of payloadInvoice) {
          delete inv.id
        }
        await dispatch(updateInvoice({id: masterData.id, invoice: payloadInvoice})).unwrap(); 
        isChangeDataPembiayaan = true;
      } catch (error) {
        return errorSubmit(error.message);
      }
    }

    if (Object.keys(payload).length !== 0) {
      const existingVal = JSON.parse(JSON.stringify(dataPembiayaan));
      existingVal.splice(0, 1, {
        id_debitur: masterData.id,
        tanggal_perhitungan: moment().format("YYYY-MM-DD HH:mm:ss"),
        persentase_pembiayaan_maksimal: (payload.persentase_pembiayaan_maksimal / 100),
        // jumlah_pembiayaan_invoice: dataPembiayaan[0].jumlah_pembiayaan_invoice,
        // pembiayaan_maksimal: dataPembiayaan[0].pembiayaan_maksimal,
        pembiayaan_maksimal_iki: payload.pembiayaan_maksimal,
        // pertanggungan_fidusia: dataPembiayaan[0].pertanggungan_fidusia
      });
      const { tanggal_perhitungan, ...formattedExistingVal } = existingVal[0] || {};
      
      const responsePPM = await dispatch(fetchSummary(idDebitur)).unwrap();
      const [resPembiayaan = {}] = responsePPM.map(({ 
        id, jumlah_pembiayaan_invoice, pembiayaan_maksimal, 
        pertanggungan_fidusia, tanggal_perhitungan, ...rest 
      }) => {
        if(rest.persentase_pembiayaan_maksimal !== undefined && rest.persentase_pembiayaan_maksimal !== null){
          rest.persentase_pembiayaan_maksimal = parseFloat(rest.persentase_pembiayaan_maksimal);
        }
        
        return rest;
      });
      dispatch(setSkipUpdateState(false));

      if(isChangeDataPembiayaan || (JSON.stringify(resPembiayaan) !== JSON.stringify(formattedExistingVal))){
        // Update / create persentase pembiayaan
        try {
          await dispatch(updateCreditGiven(existingVal[0])).unwrap();
        } catch (error) {
          return errorSubmit(error.message);
        }
      }
    }

    dispatch(fetchSummary(masterData.id));
    dispatch(setEditing(false));
    dispatch(setIsSubmit(true));
    success("Sukses menginput data credit given");
    
  }

  //  const handleSubmitFinal = async (values) => {
  //    setSpinner(true)

  //    let object;
  //   //  let message;

  //    for (let x in values) {
  //      object = values[x];
  //    }
  //    console.log(object);

  //   //  object["id"] = dataMaster["id"];
  //   object['id'] = dataMaster['id']
  //   object["Persentase_Pembiayaan_Maksimal"] = parseFloat(object["Persentase_Pembiayaan_Maksimal"]);
  //   object["pembiayaan_maksimal"] = parseFloat(object["pembiayaan_maksimal"]);

  //   console.log(typeof object["Persentase_Pembiayaan_Maksimal"],object["Persentase_Pembiayaan_Maksimal"]);
  //   console.log(typeof object["pembiayaan_maksimal"],object["pembiayaan_maksimal"]);

  //   postDataFinal(object)
  //     .then((response) => {
  //       console.log(response);
  //       if(response.statusCode == "000") {
  //         setTriggerSummary(!triggerSummary);
  //         setDisabled(true);
  //         setSpinner(false);
  //         setSummaryFlag(true)
  //         success(response.message);
  //       } else {
  //         errorSubmit(response.message);
  //         setSpinner(false)
  //       }
  //     })
  //     .catch((error) => {
  //       errorSubmit("Failed to submit data");
  //       setSpinner(false)
  //       console.log(error);
  //     });

  //   //  do {
  //   //   setTimeout(() => {
  //   //     console.log("MASUK INTERVAL",increment);
  //   //     postDataFinal(object)
  //   //       .then((response) => {
  //   //         if (response.statusCode == 200){
  //   //           setTriggerSummary(!triggerSummary)
  //   //           setDisabled(true)
  //   //           process = true
  //   //           setSpinner(false)
  //   //           return success(response.message);
  //   //         }
  //   //       })
  //   //       .catch((error) => {
  //   //         errorSubmit();
  //   //         console.log(error);
  //   //       });
  //   //     }, 5000);
  //   //     increment++
  //   //  } while (increment <= 5 && process == false);
     
  //  };
  return (
    <div>
      <Alert
        message="PASTIKAN MEMASUKKAN CREDIT GIVEN TERLEBIH DAHULU SEBELUM MEMASUKKAN PERSENTASE PEMBIAYAAN MAKSIMAL !!!"
        type="warning"
        className="my-4"
      />
      {masterData && <MasterData dataMaster={masterData} />}
      {/* <p className="text-2xl font-bold text-center my-4">Credit Given</p> */}
      <Divider>Credit Given</Divider>
      {/* Form Input Credit Given */}
      <Form
        onFinish={(values) => submitInvoice({ values })}
        disabled={!isEditing && isSubmit}
        name="basic"
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 12,
        }}
        initialValues={{
          remember: true,
        }}
      >
        {/* Form Content */}
        <div className="grid grid-cols-3">
          {content.map((item, i) => {
            return (
              <Form.Item
                key={i}
                label={item.label}
                name={item.name}
                rules={[
                  {
                    required: item.required,
                    message: item.message,
                  },
                ]}
              >
                {item.label == "Invoice Date" ||
                item.label == "Payment Date" ? (
                  <DatePicker
                    // onChange={handleChange}
                    style={{ width: "100%" }}
                    disabledDate={(item.label == "Invoice Date") ? disabledDate : ""}
                  />
                ) : item.label != "Nomor Invoice" ? (
                  <InputNumber
                    min={
                      item.name == "period" || item.name == "amount"
                        ? 1
                        : ""
                    }
                    style={{ width: "100%" }}
                    maxLength={item.label == "Period" ? 5 : ""}
                    addonAfter={item.name == "period" ? "Bulan" : ""}
                    addonBefore={
                      item.name == "amount" || item.name == "nilai_pajak"
                        ? "Rp"
                        : ""
                    }
                    formatter={
                      item.name == "amount" || item.name == "nilai_pajak"
                        ? (value) =>
                            `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        : ""
                    }
                  />
                ) : (
                  <Input autoComplete="off" />
                )}
              </Form.Item>
            );
          })}
        </div>
        {/* Button Submit Credit Given*/}
        <Form.Item wrapperCol={{ span: 12, offset: 9 }}>
          <Button
            // disabled={disabledSubmit}
            className="float-right"
            htmlType="submit"
          >
            Save
          </Button>
        </Form.Item>
      </Form>
      {/* <Table dataSource={data} columns={columns} pagination={false}/> */}
      <Form form={form} component={false}>
      <CustomTable
        dataSource={dataSourceCreditGiven} 
        columns={mergedColumns} 
        // dataSource={dataInvoice} 
        // columns={columns}  
        bordered
        size="small"
        components={{
          body: {
            cell: EditableCell,
          },
        }}
      />
      </Form>
      {/* Form Persentase Pembiayaan Maksimal*/}
      {/* <p className="text-2xl font-bold text-center my-4">
        Persentase Pembiayaan Maksimal
      </p> */}
      <Divider>Persentase Pembiayaan Maksimal</Divider>
      <Form
        // onFinish={(values) => handleSubmitFinal({ values })} 
        form={formPersentase}
        onFinish={(values) => submitPersentase({ values })}
        labelCol={{
          span: 10,
        }}
        wrapperCol={{
          span: 10,
        }}
        initialValues={{
          remember: true,
        }}
      >
        <div className="grid grid-cols-2">
          <Form.Item
            key={"persentase_pembiayaan_maksimal"}
            label={"Persentase Pembiayaan Maksimal"}
            name={"persentase_pembiayaan_maksimal"}
            rules={[
              {
                required: isFirstTime ? true : false,
                message: "Masukkan persentase pembiayaan maksimal",
              },
            ]}
          >
            <InputNumber min={1} max={100} style={{ width: "100%" }} addonAfter={"%"} disabled={isEditing ? false : true}/>
          </Form.Item>
          <Form.Item
            key={"pembiayaan_maksimal"}
            label={"Pembiayaan Maksimal IKI (Rp)"}
            name={"pembiayaan_maksimal"}
            rules={[
              {
                required: isFirstTime ? true : false,
                message: "Masukkan pembiayaan maksimal IKI",
              },
            ]}
          >
            <InputNumber min={1} style={{ width: "100%" }} addonBefore={"Rp"} 
              formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")} disabled={isEditing ? false : true}
            />
          </Form.Item>
        </div>
        {/* <div className="grid grid-cols-2">
          <Form.Item
            key={"pembiayaan_maksimal"}
            label={"Pembiayaan Maksimal IKI (Rp)"}
            name={"pembiayaan_maksimal"}
            rules={[
              {
                required: true,
                message: "Masukkan pembiayaan maksimal IKI",
              },
            ]}
          >
            <InputNumber min={1} style={{ width: "100%" }} />
          </Form.Item>
        </div> */}
        {/* Button Submit Credit Given*/}
        <Form.Item wrapperCol={{ span: 12, offset: 9 }}>
          <div style={{ float: "right" }}>
            <Space>
              <Button htmlType="button" onClick={() => {dispatch(setEditing(true)); dispatch(setIsSubmit(false));}} disabled={isSubmit ? false : true}>
                Edit
              </Button>
              <Button htmlType="submit" type="primary" danger loading={isLoadingSubmit} disabled={isEditing ? false : true}>
                Submit Sheet
              </Button>
            </Space>
          </div>
        </Form.Item>
      </Form>
      {/* <Table dataSource={dataSummary} columns={columnsSummary} pagination={false}/> */}
      <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
      <CustomTable 
        dataSource={dataSourceSummaryCreditGiven} 
        columns={colSummaryCreditGiven} 
        // dataSource={dataPembiayaan} 
        // columns={columnsSummary} 
        bordered
        size="small"
      />
      {/* <Button 
        htmlType="button" 
        onClick={() => handleSubmitSheet()}
        type='danger'
        loading={isLoadingSubmit}
        style={{float: "right", color: "black"}}
      >
        Submit Sheet
      </Button> */}
      </Space>
    </div>
  );
};

export default CreditGiven;
