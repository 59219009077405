import { useSelector } from "react-redux";

export const DebtorProfileTableData = () => {
	const idDebitur = useSelector(state => state.home.id_debitur);
	const idDebiturDP = useSelector(state => state.debtorProfile.idDebiturDP);
	const summary = useSelector(state => state.debtorProfile.summaryIndikator);
	const summaryHistoris = useSelector(state => state.debtorProfile.historisPefindo);

	const colDebtorProfile = [
		{
			title: "Indikator",
			dataIndex: "indikator",
			key: "indikator",
		},
		{
			title: "Score",
			dataIndex: "score",
			key: "score",
			render: (_, record) => {
				if(record["score"] !== null && !Number.isInteger(record["score"] )){
					return <p>{record["score"].toFixed(1)}</p>;
				}
				return <p>{record["score"]}</p>;
			},
		},
		{
			title: "Weight",
			dataIndex: "weight",
			key: "weight",
			render: (_, record) => {
				return <p>{(record["weight"] * 100).toFixed(0) + "%"} </p>;
			},
		},
		{
			title: "Weighted Score",
			dataIndex: "weighted_score",
			key: "weighted_score",
			render: (_, record) => {
				return <p>{record["weighted_score"].toFixed(2)} </p>;
			},
		}
	];

	const dataSourceDebtorProfile = (idDebitur === idDebiturDP) ? summary : [];

	const colHistorisPefindo = [
		{
      title: "Jabatan",
      dataIndex: "jabatan",
      key: "jabatan",
    },
    {
      title: "Nama",
      dataIndex: "nama",
      key: "nama",
    },
    {
      title: "Score",
      dataIndex: "score",
      key: "score",
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
      render: (_, record) => {
        return <p>{"Rp" + record["total"].toLocaleString()}</p>;
      },
    },
    {
      title: "Balance",
      dataIndex: "balance",
      key: "balance",
      render: (_, record) => {
        return <p>{"Rp" + record["balance"].toLocaleString()}</p>;
      },
    },
    {
      title: "Past Due Value",
      dataIndex: "past_due_value",
      key: "past_due_value",
      render: (_, record) => {
        return <p>{"Rp" + record["past_due_value"].toLocaleString()}</p>;
      },
    },
    {
      title: "Past Due Days",
      dataIndex: "past_due_days",
      key: "past_due_days",
    },
    {
      title: "Keterangan",
      dataIndex: "keterangan",
      key: "keterangan",
    }
	];

	const dataSourceHistorisPefindo = summaryHistoris.some((item) => item.id_debitur === idDebitur)
		? summaryHistoris 
		: [];

	return { colDebtorProfile, dataSourceDebtorProfile, colHistorisPefindo, dataSourceHistorisPefindo };
};