import React from "react";
import { Form, Input, InputNumber, Button, message, Select, Table } from "antd";
import { useState, useEffect } from "react";
import { getAPIPython, getAPI } from "../../services/data";

const { Option } = Select;

const errorSubmit = (text) => {
  message.error(text);
};

const success = (text) => {
  message.success(text);
};

const postData = async (object,url) => {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(object),
  };

  const response = await fetch(
    process.env.REACT_APP_PYTHON_SCORING + url,
    requestOptions
  );
  console.log(response);
  const result = await response.json();
  console.log(result);
  return result;
};

const DebtSizing = (props) => {
  const { masterData,trigger } = props;
  const [column,setColumn] = useState([]);
  const [boolView,setBoolView] = useState(false);
  const [dataColumn,setDataColumn] = useState([]);

  // const [keterangan,setKeterangan] = useState([]);

  const apiGetTableDebtSizing = async() => {
    const data = await getAPIPython("view_capabilities_debt_sizing?id_debitur=" + masterData['id']);
    setDataColumn(data['value']);

    const num = Object.keys(data['value'][0]).length;

    let array = [];
    for(let x = 1; x <= num - 1 ; x++){
      let object = {
        title: x.toString(),
        dataIndex: x.toString(),
        key: x.toString(),
        render: (_, record) => {
          if(record[x.toString()] != "" && record[x.toString()] != 0 && !record['Description'].includes("Interest") && record[x.toString()] != '-') {
            return <p>{"Rp" + record[x.toString()].toLocaleString()}</p>;
          } else {
            return <p>{record[x.toString()]}</p>;
          }
        },
      };
      array.push(object);
    }
    array.unshift({
      title: "Keterangan",
      dataIndex: "Description",
      key: "description",
    });
    setColumn(array);
  } 

  useEffect(() => {
    apiGetTableDebtSizing();
  },[boolView])

  const content = [
    {
      label: "Beginning Balance (Principal)",
      name: "Principal_Beg_Balance",
      message: "Masukkan principal beg balance",
      required: true,
    },
    {
      label: "Beginning Balance (Interest Efektif)",
      name: "Interest_Efektif_Beg_Balance",
      message: "Masukkan interest efektif beg balance",
      required: true,
    },
    {
      label: "Beginning Balance (Interest Flat)",
      name: "Interest_Flat_Beg_Balance",
      message: "Masukkan interest flag beginning balance",
      required: true,
    },
  ];

  const handleSubmit = async (values) => {
    values["Id_Debitur"] = masterData["id"];
    let object = {
      Id_Debitur : masterData['id']
    }

    try {
      let cpdebtsizing = await postData(values, "capabilities_debt_sizing");
      console.log('Capabilities debt sizing',cpdebtsizing);
      if(cpdebtsizing['statusCode'] == 200){
        success(cpdebtsizing['message']);
      }else{
        errorSubmit(cpdebtsizing['message']);
      }
    } catch (error) {
      console.log(error);
      errorSubmit("Internal Server Error !")
    }

    try {
      let debtsizingdscr = await postData(values, "debt_sizing_dscr");
      console.log('debt sizing dscr',debtsizingdscr);
      if (debtsizingdscr["statusCode"] == 200) {
        success(debtsizingdscr["message"]);
      } else {
        errorSubmit(debtsizingdscr["message"]);
      }
    } catch (error) {
      console.log(error);
      errorSubmit("Internal Server Error !");
    }

    setBoolView(true)
  };

  return (
    <div>
      <p className="text-2xl font-bold text-center my-5">Debt Sizing</p>
      <Form
        disabled={trigger}
        onFinish={(values) => handleSubmit(values)}
        labelCol={{
          span: 9,
        }}
        wrapperCol={{
          span: 10,
        }}
        initialValues={{
          remember: true,
        }}
      >
        <div className="grid grid-cols-2">
          {content.map((item, i) => {
            return (
              <Form.Item
                key={i}
                label={item.label}
                name={item.name}
                rules={[
                  {
                    required: item.required,
                    message: item.message,
                  },
                ]}
              >
                <InputNumber
                  style={{ width: "100%" }}
                  addonBefore={"Rp."}
                  formatter={(value) =>
                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                />
              </Form.Item>
            );
          })}
        </div>
        <Form.Item wrapperCol={{ span: 12, offset: 9 }}>
          <Button className="float-right" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
      {boolView && <Table dataSource={dataColumn} columns={column} pagination={false}/>}
    </div>
  );
};

export default DebtSizing;
