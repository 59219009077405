import React from "react"
import { PageHeader, Descriptions,message, Skeleton,Table,Tabs} from "antd";
import { useState, useEffect } from "react";
import { getAPIPython, getAPI } from "../services/data";
import { useSelector, useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { setIdDebitur, resetIdDebitur, setIsLoadingAPI } from "../store/actions/homeSlice"
import TableViewData from "../components/TableViewData";
import { formatTextAssumption } from "../utils/util";
import { CreditGivenTableData } from "./Credit Given/CreditGivenTableData";
import { BouwheerTableData } from "./Bouwheer/BouwheerTableData";
import { IndustryRiskTableData } from "./Industry Risk/IndustryRiskTableData";
import { DebtorProfileTableData } from "./Debtor Profile/DebtorProfileTableData";
import { NeracaTableData } from "./Neraca/NeracaTableData";
import { PLTableData } from "./PL/PLTableData";
import { FSTableData } from "./FS/FSTableData";
import { fetchDefaultAssumptions } from "../store/actions/assumptionSlice";
import { fetchInvoiceList, fetchSummary as fetchSummaryCG } from "../store/actions/creditGivenSlice";
import { fetchCollateralTable, fetchCollateralToLoan, fetchSummaryTable } from "../store/actions/bouwheerSlice";
import { fetchSummary as fetchSummaryIR } from "../store/actions/industryRiskSlice";
import { fetchSummaryHistoris, fetchSummaryIndikator } from "../store/actions/debtorProfile";
import { fetchNeracaTahun, fetchNeracaViewData } from "../store/actions/neracaSlice";
import {  fetchDropdownTypeIncomeExpense, fetchProfitLossSummary, fetchProfitLossView } from "../store/actions/plSlice";
import { fetchFSView } from "../store/actions/fsSlice";

const {Item} = Descriptions;

const errorSubmit = (text) => {
  message.error({ content: text, key: "view" });
};

const success = (text) => {
  message.success({ content: text, key: "view" });
};

const loadingMessage = (text) => {
  message.loading({ content: text, key: "view",duration:0 });
}

const emptyDescription = (title) => (
  <Descriptions style={{marginBottom: "3vh"}} column={1} size="small" className="mx-auto px-10 w-[100%] my-2" title={title} bordered>
   <Item label='-'>-</Item>
  </Descriptions>
);

const CustomTable = (props) => {
    const { title, data, column, scroll, row } = props;
    // console.log(scroll);
    // console.log(row);
    return (
      <div style={{marginBottom: "3vh"}}>
        <p className="mx-10 my-3 font-bold text-[15px]">{title}</p>
        <Table 
        className="mx-auto w-[93%]"  
        scroll={scroll != 0 || scroll != undefined ? {x:scroll} : '' } 
        // pagination={data != null && data.length > 10 ? true : false} 
        pagination={false}
        dataSource={data} 
        columns={column}
        rowClassName={row}
        />
      </div>
    )
}

const Home = () => {

  const idDebitur = useSelector(state => state.home.id_debitur);
  const isLoadingAPI = useSelector(state => state.home.isLoadingAPI);

  const dataAssumption = useSelector(state => state.assumption.dataAssumption);
  const { colCreditGiven, dataSourceCreditGiven, colSummaryCreditGiven, dataSourceSummaryCreditGiven } = CreditGivenTableData();
  const { colCollateralToLoan, colBouwheerDetail, dataSourceCollateralToLoan, dataSourceBouwheerDetail } = BouwheerTableData();
  const { colIndustryRisk, dataSourceIndustryRisk } = IndustryRiskTableData();  
  const { colDebtorProfile, dataSourceDebtorProfile, colHistorisPefindo, dataSourceHistorisPefindo } = DebtorProfileTableData();
  const { rowClassNeraca, colNeraca1, colNeraca2, dataSourceNeraca1, dataSourceNeraca2 } = NeracaTableData();
  const { rowClassPL, colPL, dataSourcePL } = PLTableData();
  const { rowClassFS, colFS, dataSourceFS } = FSTableData();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [detail,setDetail] = useState([]);
  const [isSelect,setIsSelect] = useState(false);
  const [loading,isLoading] = useState();

  const [akte,setAkte] = useState([]);
  const [wewenang,setWewenang] = useState([]);
  const [saham,setSaham] = useState([]);
  const [direksi,setDireksi] = useState([])
  // const [assumption,setAssumption] = useState([]);
  // const [creditGiven,setCreditGiven] = useState([]);
  // const [summaryCreditGiven,setSummaryCreditGiven] = useState([]);
  // const [collateralToLoan,setCollateralToLoan] = useState([]);
  // const [bouwheerDetail,setBouwheerDetail] = useState([]);
  // const [industryRisk,setIndustryRisk] = useState([]);
  // const [debtorProfile,setDebtorProfile] = useState([]);
  // const [historisPefindo,setHistorisPefindo] = useState([]);
  // const [neracaTahun, setNeracaTahun] = useState([]);
  // const [neraca,setNeraca] = useState([]);
  // const [neracaPerhitungan,setNeracaPerhitungan] = useState([]);
  // const [profitLoss,setProfitLoss] = useState([]);
  // const [fs,setFs] = useState([]);
  const [bankLoan,setBankLoan] = useState([]);
  const [newLoan,setNewLoan] = useState([]);
  const [debtSizing,setDebtSizing] = useState([]);
  const [financialSummary,setFinancialSummary] = useState([]);
  const [financial1,setFinancial1] = useState([]);
  const [financial3,setFinancial3] = useState([]);
  const [financial4,setFinancial4] = useState([]);
  const [financial5,setFinancial5] = useState([]);
  const [grade,setGrade] = useState("");
  const [color,setColor] = useState("");
  const [resultMain, setResultMain] = useState([]);
  const [assessment1, setAssessment1] = useState([]);
  const [assessment2, setAssessment2] = useState([]);

  const [columnsSaham,setColumnsSaham] = useState([]);
  const [columnsDireksi,setColumnsDireksi] = useState([]);
  // const [columnsCreditGiven,setColumnsCreditGiven] = useState([]);
  // const [columnsSumCreditGiven,setColumnsSumCreditGiven] = useState([]);
  // const [columnsCollateralToLoan,setColumnsCollateralToLoan] = useState([]);
  // const [columnsBouwheerDetail,setColumnsBouwheerDetail] = useState([]);
  // const [columnsIndustry,setColumnsIndustry] = useState([])
  // const [columnsDebtorProfile,setColumnsDebtorProfile] = useState([]);
  // const [columnsHistorisPefindo,setColumnsHistorisPefindo] = useState([]);
  // const [columnsNeraca1,setColumnsNeraca1] = useState([]);
  // const [columnsNeraca2,setColumnsNeraca2] = useState([]);
  // const [columnsProfitLoss,setColumnsProfitLoss] = useState([]);
  // const [columnsFS,setColumnsFS] = useState([]);
  const [columnBankLoan,setColumnBankLoan] = useState([]);
  const [columnNewLoan,setColumnNewLoan] = useState([]);
  const [columnDebtSizing,setColumnDebtSizing] = useState([]);
  const [columnFinancialSummary,setColumnFinancialSummary] = useState([])
  const [columnsFinancial1,setColumnsFinancial1] = useState([]);
  const [columnsFinancial3,setColumnsFinancial3] = useState([]);
  const [columnsFinancial4,setColumnsFinancial4] = useState([]);
  const [columnsFinancial5,setColumnsFinancial5] = useState([]);
  const [columnresultMain, setColumnResultMain] = useState([]);
  const [columnassessment1, setColumnAssessment1] = useState([]);
  const [columnassessment2, setColumnAssessment2] = useState([]);

  const apiGetAkte = async (id) => {
    const data = await getAPIPython("akta-get?id_debitur=" + id);
    const result = data['data'];
    setAkte(result);
  }

  const apiGetAssumption = async (id) => {
    // const data = await getAPIPython('assumption-get');
    const master = await getAPI("master-detail?id=" + id);
    await dispatch(fetchDefaultAssumptions(master?.data?.jenis_pinjaman)).unwrap();

    // let object = {}
    // const result = data['data'].find(e => master['data']['jenis_pinjaman'] == e['id']);
    // // console.log("ASSUMPTION",result);
    // if(result != undefined || result != null){
    //   setAssumption(result)
    // }else{
    //   setAssumption(object)
    // }
  }

  const apiGetWewenangDireksi = async (id) => {
    const data = await getAPIPython("wewenang_direksi-get?id_debitur=" + id);
    let result= {};
    if(data['data'].length != 0){
      result = data['data'][0];
    }
    // console.log(result);
    setWewenang(result)
  }

  const apiGetPemegangSaham = async (id) => {
    const data = await getAPIPython("view_pemegang_saham/?id_debitur="+id);
    const columns = [
      {
        title: "Nama Pemegang Saham",
        dataIndex: "Nama Pemegang Saham",
        key: "nama_pemegang_saham",
      },
      {
        title: "KTP/NPWP",
        dataIndex: "KTP/NPWP",
        key: "ktp/npwp",
      },
      {
        title: "Jumlah Lembar Saham",
        dataIndex : "Jumlah Lembar Saham",
        key: "jumlah_lembar_saham",
      }
    ];
    const result = data['data'];
    setColumnsSaham(columns)
    setSaham(result)
  }

  const apiGetDireksi = async (id) => {
    const column = [
      {
        title: "Nama",
        dataIndex: "nama",
        key: "nama",
      },
      {
        title: "Jabatan",
        dataIndex: "jabatan",
        key: "jabatan",
      },
      {
        title: "Nomor KTP",
        dataIndex: "nomor_ktp",
        key: "nomor_ktp",
      },
    ];
    const data = await getAPI("direksi-by-id?id=" + id);
    const result = data['data'];
    setDireksi(result);
    setColumnsDireksi(column)
  }

  const apiGetCreditGiven = async (id) => {
    await dispatch(fetchInvoiceList(id)).unwrap();

    // const columns = [
    //   {
    //     title: "No. Invoice",
    //     dataIndex: "No. Invoice",
    //     key: "no_invoice",
    //   },
    //   {
    //     title: "Invoice Date",
    //     dataIndex: "Invoice Date",
    //     key: "invoice_date",
    //   },
    //   {
    //     title: "Payment Date",
    //     dataIndex: "Payment Date",
    //     key: "payment_date",
    //   },
    //   {
    //     title: "Amount",
    //     dataIndex: "Amount",
    //     key: "amount",
    //     render: (_, record) => {
    //       return <p>{"Rp" + record["Amount"].toLocaleString()}</p>;
    //     },
    //   },
    //   {
    //     title: "Tax",
    //     dataIndex: "Tax",
    //     key: "tax",
    //     render: (_, record) => {
    //       return <p>{"Rp" + record["Tax"].toLocaleString()}</p>;
    //     },
    //   },
    // ];

    // const data = await getAPIPython("view_credit_given/?id_debitur=" + id);
    // const result = data['data'];
    // setCreditGiven(result);
    // setColumnsCreditGiven(columns)
  }

  const apiGetSummaryCreditGiven = async (id) => {
    await dispatch(fetchSummaryCG(id)).unwrap();

    // const data = await getAPIPython("summary_credit_given_get?id_debitur=" + id);
    // if(data['statusCode'] == 200){
    //   setSummaryCreditGiven(data['data']);
    // }else{
    //   setSummaryCreditGiven([])
    // }

    // const resSum = await dispatch(fetchSummary(id)).unwrap();
    // setSummaryCreditGiven(resSum);

    // const columns = [
    //   {
    //     title: "Persentase Pembiayaan",
    //     dataIndex: "persentase_pembiayaan_maksimal",
    //     key: "persentase_pembiayaan_maksimal",
    //     render: (_, record) => {
    //       return <>{record['persentase_pembiayaan_maksimal'] * 100 + "%"}</>
    //     }
    //   },
    //   {
    //     title: "Jumlah Pembiayaan",
    //     dataIndex: "jumlah_pembiayaan_invoice",
    //     key: "jumlah_pembiayaan",
    //     render: (_, record) => {
    //       return <>{"Rp" + record['jumlah_pembiayaan_invoice'].toLocaleString()}</>
    //     }
    //   },
    //   {
    //     title: "Pembiayaan Maksimal",
    //     dataIndex: "pembiayaan_maksimal",
    //     key: "pembiayaan_maksimal",
    //     render: (_, record) => {
    //       return <>{"Rp" + record['pembiayaan_maksimal'].toLocaleString()}</>
    //     }
    //   },
    //   {
    //     title: "Pembiayaan Maksimal IKI",
    //     dataIndex: "pembiayaan_maksimal_iki",
    //     key: "pembiayaan_maksimal_iki",
    //     render: (_, record) => {
    //       return <>{"Rp" + record['pembiayaan_maksimal_iki'].toLocaleString()}</>
    //     }
    //   },
    //   {
    //     title: "Pertanggungan Fidusia",
    //     dataIndex: "pertanggungan_fidusia",
    //     key: "pertanggungan_fidusia",
    //   },
    // ];
    // setColumnsSumCreditGiven(columns);
  }

  const apiGetCollateralToLoan = async (id) => {
    await dispatch(fetchCollateralTable(id)).unwrap();
    await dispatch(fetchCollateralToLoan(id)).unwrap();

    // const data = await getAPIPython("view_collateral_to_loan?id_debitur=" + id);
    // setCollateralToLoan(data['data']);

    // const columns = [
    //   {
    //     title: "Type Of Collateral",
    //     dataIndex: "type_of_collateral",
    //     key: "type_of_collateral",
    //   },
    //   {
    //     title: "Book Value",
    //     dataIndex: "book_value",
    //     key: "book_value",
    //     align: "right",
    //     render: (_, record) => {
    //       return <p>{"Rp" + record["book_value"].toLocaleString()} </p>;
    //     },
    //   },
    //   {
    //     title: "Sifat Recovery",
    //     dataIndex: "sifat_recovery",
    //     key: "sifat_recovery",
    //   },
    //   {
    //     title: "Discount",
    //     dataIndex: "discount",
    //     key: "discount",
    //     render: (_, record) => {
    //       return <p>{record["discount"] * 100 + "%"} </p>;
    //     },
    //   },
    //   {
    //     title: "Value",
    //     dataIndex: "value",
    //     key: "value",
    //     align: "right",
    //     render: (_, record) => {
    //       return <p>{'Rp' + record["value"].toLocaleString()} </p>;
    //     },
    //   },
    // ];
    // setColumnsCollateralToLoan(columns)
  }

  const apiGetBouwheerDetail = async (id) => {
    await dispatch(fetchSummaryTable(id)).unwrap();

    // const data = await getAPIPython("summary_bouwheer_get/?id_debitur=" + id);
    // console.log(data);
    // setBouwheerDetail(data['data']);

    // const columns = [
    //   {
    //     title: "Indikator",
    //     dataIndex: "indikator",
    //     key: "indikator",
    //   },
    //   {
    //     title: "Score",
    //     dataIndex: "score",
    //     key: "score",
    //   },
    //   {
    //     title: "Weight",
    //     dataIndex: "weight",
    //     key: "weight",
    //     render: (_, record) => {
    //       return <p>{record["weight"] * 100 + "%"} </p>;
    //     },
    //   },
    //   {
    //     title: "Weighted Score",
    //     dataIndex: "weighted_score",
    //     key: "weighted_score",
    //   },
    // ];
    // setColumnsBouwheerDetail(columns);
  }

  const apiGetIndustryRisk = async (id) => {
    await dispatch(fetchSummaryIR(id)).unwrap();

    // const data = await getAPIPython("summary_industry_risk?id_debitur=" + id);
    // setIndustryRisk(data['data']);

    // const columns = [
    //   {
    //     title: "Indikator",
    //     dataIndex: "indikator",
    //     key: "indikator",
    //   },
    //   {
    //     title: "Score",
    //     dataIndex: "score",
    //     key: "score",
    //   },
    //   {
    //     title: "Weight",
    //     dataIndex: "weight",
    //     key: "weight",
    //     render: (_, record) => {
    //       return <p>{(record["weight"] * 100).toFixed(0) + "%"}</p>;
    //     },
    //   },
    //   {
    //     title: "Weighted Score",
    //     dataIndex: "weighted_score",
    //     key: "weighted_score",
    //     render: (_, record) => {
    //       return <p>{record["weighted_score"].toFixed(2)}</p>;
    //     },
    //   },
    // ];
    // setColumnsIndustry(columns);
  }

  const apiGetDebtorProfile = async (id) => {
    await dispatch(fetchSummaryIndikator(id)).unwrap();

    // const data = await getAPIPython("summary_debtor_profile?id_debitur=" + id);
    // setDebtorProfile(data['data']);

    // const columns = [
    //   {
    //     title: "Indikator",
    //     dataIndex: "indikator",
    //     key: "indikator",
    //   },
    //   {
    //     title: "Score",
    //     dataIndex: "score",
    //     key: "score",
    //     render: (_, record) => {
    //       if(record["score"] !== null && !Number.isInteger(record["score"] )){
    //         return <p>{record["score"].toFixed(1)}</p>;
    //       }
    //       return <p>{record["score"]}</p>;
    //     },
    //   },
    //   {
    //     title: "Weight",
    //     dataIndex: "weight",
    //     key: "weight",
    //     render: (_, record) => {
    //       return <p>{(record["weight"] * 100).toFixed(0) + "%"}</p>;
    //     },
    //   },
    //   {
    //     title: "Weighted Score",
    //     dataIndex: "weighted_score",
    //     key: "weighted_score",
    //     render: (_, record) => {
    //       return <p>{record["weighted_score"].toFixed(2)}</p>;
    //     },
    //   },
    // ];
    // setColumnsDebtorProfile(columns);
  }

  const apiGetHistorisPefindo = async (id) => {
    await dispatch(fetchSummaryHistoris(id)).unwrap();

    // const data = await getAPI("historis-pefindo?id_debitur=" + id);
    // setHistorisPefindo(data['data'])

    // const column = [
    //   {
    //     title: "Jabatan",
    //     dataIndex: "jabatan",
    //     key: "jabatan",
    //   },
    //   {
    //     title: "Nama",
    //     dataIndex: "nama",
    //     key: "nama",
    //   },
    //   {
    //     title: "Score",
    //     dataIndex: "score",
    //     key: "score",
    //   },
    //   {
    //     title: "Total",
    //     dataIndex: "total",
    //     key: "total",
    //     render: (_, record) => {
    //       return <p>{"Rp" + record["total"].toLocaleString()}</p>;
    //     },
    //   },
    //   {
    //     title: "Balance",
    //     dataIndex: "balance",
    //     key: "balance",
    //     render: (_, record) => {
    //       return <p>{"Rp" + record["balance"].toLocaleString()}</p>;
    //     },
    //   },
    //   {
    //     title: "Past Due Value",
    //     dataIndex: "past_due_value",
    //     key: "past_due_value",
    //     render: (_, record) => {
    //       return <p>{"Rp" + record["past_due_value"].toLocaleString()}</p>;
    //     },
    //   },
    //   {
    //     title: "Past Due Days",
    //     dataIndex: "past_due_days",
    //     key: "past_due_days",
    //   },
    //   {
    //     title: "Keterangan",
    //     dataIndex: "keterangan",
    //     key: "keterangan",
    //   },
    // ];
    // setColumnsHistorisPefindo(column);
  }

  const apiGetNeraca = async (id) => {
    await dispatch(fetchNeracaTahun(id)).unwrap();
    await dispatch(fetchNeracaViewData(id)).unwrap();

    // const emptyObject = (keterangan) => {
    //   return [
    //     {
    //       keterangan: keterangan,
    //       y1: "(Empty)",
    //       y2: "(Empty)",
    //       y3: "(Empty)",
    //     },
    //   ];
    // };
    
    // const numberWithCommas = (x) => {return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}; 

    // const nonControllingInterestFunc = () => {
    //   let object = {}
    //   let result = dataAssetPreProcess.find((value) => value['keterangan'] == 'Non Controlling Interests')
    //   if(result != null){
    //     object = result
    //   }else{
    //     object = {
    //       kelompok: "Liabilities and Equity",
    //       kategori: "Stockholders Equity",
    //       keterangan: "Non Controlling Interests",
    //       y1: "-",
    //       y2: "-",
    //       y3: "-"
    //     }
    //   }
    //   return object;
    // }

    // console.log(dataAsset);
    // let dataAssetPreProcess = dataAsset['data'];
    // console.log(dataAssetPreProcess,"Data Asset PreProcess");

    //Add Comma Separator
    // for(let x in dataAssetPreProcess){
    //   if(dataAssetPreProcess[x]['y1'] != ''){
    //     dataAssetPreProcess[x]['y1'] = numberWithCommas(dataAssetPreProcess[x]['y1']);
    //   }

    //   if(dataAssetPreProcess[x]['y2'] != ''){
    //     dataAssetPreProcess[x]['y2'] = numberWithCommas(dataAssetPreProcess[x]['y2']);
    //   }

    //   if(dataAssetPreProcess[x]['y3'] != ''){
    //     dataAssetPreProcess[x]['y3'] = numberWithCommas(dataAssetPreProcess[x]['y3']);
    //   }
    // }

    // const dataTotal = await getAPIPython("neracaperhitungan-get?id_debitur=" + id);
    // const dataResult = dataTotal['data'];

    // const filter = (string) => {return dataAssetPreProcess.filter((value) => value["kategori"] == string);};
    // const findResult = (string) => {return dataResult.find((value) => value["indikator"] == string);};

    // const currentAsset = filter("Current Asset").length!= 0 ? filter("Current Asset") : emptyObject("Current Asset");
    // const nonCurrentAsset = filter("Non Current Asset").length != 0 ? filter("Non Current Asset") : emptyObject("Non Current Asset");
    // const currentLiabilities = filter("Current Liabilities").length !=0 ? filter("Current Liabilities") : emptyObject("Current Liabilities");
    // const nonCurrentLiabilities = filter("Non Current Liabilities").length != 0 ? filter("Non Current Liabilities") : emptyObject("Non Current Liabilities");
    // const stockholdersEquity = filter("Stockholders Equity").length != 0 ? filter("Stockholders Equity") : emptyObject("Stockholders Equity");
    // // const nonControllingInterest = data.find((value) => value['keterangan'] == 'Non Controlling Interests') != null ? data.find((value) => value['keterangan'] == 'Non Controlling Interests') : emptyObject("Non Controlling Interests") 
    // const nonControllingInterest= nonControllingInterestFunc();
    // // console.log(nonControllingInterest);

    // //Result 
    // const totalAsset = findResult("Total asset");
    // const totalCurrentAsset = findResult("Total current asset");
    // const totalCurrentLiabilities = findResult("Total current liabilities");
    // const totalLiabilities = findResult("Total liabilities");
    // const totalNonCurrentAsset = findResult("Total non current asset");
    // const totalNonCurrentLiabilities = findResult("Total non current liabilities");
    // const totalStockholdersEquity = findResult("Total stockholders equity");

    // let currentColumn = [
    //   {
    //     key: 1,
    //     keterangan: "Current Asset",
    //     y1: "-",
    //     y2: "-",
    //     y3: "-",
    //     children: currentAsset,
    //   },
    //   {
    //     keterangan: "TOTAL CURRENT ASSETS",
    //     y1: totalCurrentAsset["y1"],
    //     y2: totalCurrentAsset["y2"],
    //     y3: totalCurrentAsset["y3"],
    //   },
    //   {
    //     key: 2,
    //     keterangan: "Non Current Asset",
    //     y1: "-",
    //     y2: "-",
    //     y3: "-",
    //     children: nonCurrentAsset,
    //   },
    //   {
    //     keterangan: "TOTAL NON CURRENT ASSETS",
    //     y1: totalNonCurrentAsset["y1"],
    //     y2: totalNonCurrentAsset["y2"],
    //     y3: totalNonCurrentAsset["y3"],
    //   },
    //   {
    //     keterangan: "TOTAL ASSETS",
    //     y1: totalAsset["y1"],
    //     y2: totalAsset["y2"],
    //     y3: totalAsset["y3"],
    //   },
    // ];

    // let currentColumn2 = [
    //   {
    //     key: 4,
    //     keterangan: "Current Liabilities",
    //     y1: "-",
    //     y2: "-",
    //     y3: "-",
    //     children: currentLiabilities,
    //   },
    //   {
    //     keterangan: "TOTAL CURRENT LIABILITIES",
    //     y1: totalCurrentLiabilities["y1"],
    //     y2: totalCurrentLiabilities["y2"],
    //     y3: totalCurrentLiabilities["y3"],
    //   },
    //   {
    //     key: 5,
    //     keterangan: "Non Current Liabilities",
    //     y1: "-",
    //     y2: "-",
    //     y3: "-",
    //     children: nonCurrentLiabilities,
    //   },
    //   {
    //     keterangan: "TOTAL NON CURRENT LIABILITIES",
    //     y1: totalNonCurrentLiabilities["y1"],
    //     y2: totalNonCurrentLiabilities["y2"],
    //     y3: totalNonCurrentLiabilities["y3"],
    //   },
    //   {
    //     key: 3,
    //     keterangan: "Stockholders Equity",
    //     y1: "-",
    //     y2: "-",
    //     y3: "-",
    //     children: stockholdersEquity,
    //   },
    //   {
    //     keterangan: "TOTAL STOCKHOLDERS EQUITY",
    //     y1: totalStockholdersEquity["y1"],
    //     y2: totalStockholdersEquity["y2"],
    //     y3: totalStockholdersEquity["y3"],
    //   },
    //   {
    //     keterangan: "Non Controlling Interest",
    //     y1: nonControllingInterest["y1"],
    //     y2: nonControllingInterest["y2"],
    //     y3: nonControllingInterest["y3"],
    //   },
    //   {
    //     keterangan: "TOTAL LIABILITIES & EQUITY",
    //     y1: totalLiabilities["y1"],
    //     y2: totalLiabilities["y2"],
    //     y3: totalLiabilities["y3"],
    //   },
    // ];

    // const resPeriode = await dispatch(fetchNeracaTahun(id)).unwrap();
    // setNeracaTahun(resPeriode); 

    // if(resPeriode.length !== 0){
      // const columns = (title) => {
      //   return [
      //     {
      //       title: title,
      //       dataIndex: "keterangan",
      //       key: "keterangan",
      //       fixed: "left"
      //     },
      //     ...Object.entries(neracaTahun[0] ?? {})
      //       .filter(([key]) => key.startsWith('y'))
      //       .map(([key, value], index) => ({
      //         title: convertDateFormatString(value, "MMM YYYY"),
      //         dataIndex: key ?? `y${index+1}`,
      //         key: key ?? `y${index+1}`,
      //         render: (_, record) => {
      //           return (
      //             <p>
      //               {((record[key] != "" && record[key] != "-") || typeof record[key] === "number") ? "Rp" + record[key].toLocaleString() : ""}
      //             </p>
      //           );
      //         }
      //       })
      //     )      
      //   ]
      // };
      
      // setColumnsNeraca1(columns("Asset"));
      // setColumnsNeraca2(columns("Liabilities & Equity"));

      //Get Neraca View Data
      // const res = await dispatch(fetchNeracaViewData(id)).unwrap();
      // if(res.length !== 0){
        // const calculateTotalKategori = (val) => {
        //   if(Array.isArray(val)){
        //     return {
        //       y1: val.reduce((sum, item) => sum + +item?.y1, 0),
        //       y2: val.reduce((sum, item) => sum + +item?.y2, 0),
        //       y3: val.reduce((sum, item) => sum + +item?.y3, 0)
        //     }
        //   }else {
        //     return {
        //       y1: +val?.y1 || 0,
        //       y2: +val?.y2 || 0,
        //       y3: +val?.y3 || 0,
        //     }
        //   }
        // };
      
        // const calculateTotalKelompok = (array) => {
        //   return array.reduce((total, item) => {
        //     return {
        //       y1: total.y1 + item.y1,
        //       y2: total.y2 + item.y2,
        //       y3: total.y3 + item.y3,
        //     };
        //   }, {
        //     y1: 0,
        //     y2: 0,
        //     y3: 0,
        //   });
        // };
      
        // const createKeteranganRow = (key, kategori, kategoriDesc, totalDesc) => {
        //   const filteredData = neracaSummary?.filter((item) => item.kategori === kategori);
      
        //   const result = {
        //     key: key,
        //     keterangan: kategoriDesc,
        //     y1: "-",
        //     y2: "-",
        //     y3: "-",
        //   };
      
        //   if(filteredData.length > 0){
        //     result.children = filteredData.map((val) => ({
        //       id: val.keterangan,
        //       keterangan: val.keterangan_description,
        //       y1: val.y1,
        //       y2: val.y2,
        //       y3: val.y3,
        //     }))
        //     .sort((a, b) => a.id.localeCompare(b.id)) 
        //   }
      
        //   const totalKategori = {
        //     keterangan: `TOTAL ${totalDesc}`,
        //     ...calculateTotalKategori(filteredData)
        //   }    
      
        //   return [result, totalKategori];
        // }
      
        // const createTotalKelompok = (totalDesc, array) => { 
        //   return ({
        //     keterangan: `TOTAL ${totalDesc}`,
        //     ...calculateTotalKelompok(array)
        //   })
        // }
      
        // const createNonControlling = (kategori, kategoriDesc) => {
        //   const filteredData = neracaSummary?.find((item) => item.kategori === kategori);
        //   const result = {
        //     keterangan: kategoriDesc,
        //     ...calculateTotalKategori(filteredData)
        //   };
      
        //   return result;
        // };
  
        // const [currentAsset, totalCurrentAsset] = createKeteranganRow(1, "ka001", "Current Asset", "CURRENT ASSETS");
        // const [nonCurrentAsset, totalNonCurrentAsset] = createKeteranganRow(2, "ka002", "Non Current Asset", "NON CURRENT ASSETS");
        // const totalAssets = createTotalKelompok("ASSETS", [totalCurrentAsset, totalNonCurrentAsset]);
        // const currentColumn = [currentAsset, totalCurrentAsset, nonCurrentAsset, totalNonCurrentAsset, totalAssets];
      
        // const [currentLiabilities, totalCurrentLiabilities] = createKeteranganRow(3, "ka003", "Current Liabilities", "CURRENT LIABILITIES");
        // const [nonCurrentLiabilities, totalNonCurrentLiabilities] = createKeteranganRow(4, "ka004", "Non Current Liabilities", "NON CURRENT LIABILITIES");
        // const [stockholdersEquity, totalStockholdersEquity] = createKeteranganRow(5, "ka005", "Stockholders Equity", "STOCKHOLDERS EQUITY");
        // const nonControllingInterest = createNonControlling("ka006", "Non Controlling Interest");   
        // const totalLiabilities = createTotalKelompok("LIABILITIES & EQUITY", [totalCurrentLiabilities, totalNonCurrentLiabilities, totalStockholdersEquity, nonControllingInterest]);
        // const currentColumn2 = [currentLiabilities, totalCurrentLiabilities, nonCurrentLiabilities, totalNonCurrentLiabilities, 
        //   stockholdersEquity, totalStockholdersEquity, nonControllingInterest, totalLiabilities];
        
        // setNeraca(currentColumn);
        // // console.log(currentColumn2);
        // setNeracaPerhitungan(currentColumn2)
      // }
    // }    
  }

  const apiGetProfitLoss = async(id) => {
    await dispatch(fetchDropdownTypeIncomeExpense()).unwrap();
    await dispatch(fetchProfitLossView(id)).unwrap();
    await dispatch(fetchProfitLossSummary(id)).unwrap();

    // const data = await getAPIPython("view_profit_loss?id_debitur=" + id);
    // setProfitLoss(data['data']);

    // const column = [
    //   {
    //     title: "Profit and Loss",
    //     dataIndex: "fiscal year",
    //     render: (_, record) => {
    //       let flag = false;
    //       if (
    //         record["fiscal year"] == "Gross profit" ||
    //         record["fiscal year"] == "Operating expense" ||
    //         record["fiscal year"].includes("Ebit") ||
    //         record["fiscal year"].toLowerCase().includes("net")
    //       ) {
    //         flag = true;
    //       }
    //       return (
    //         <p className={flag ? "font-bold" : ""}>{record["fiscal year"]}</p>
    //       );
    //     },
    //   },
    //   {
    //     title: "Y1",
    //     dataIndex: "y1",
    //     key: "y1",

    //     render: (_, record) => (
    //       <>
    //         <div>
    //           <p>
    //             {record["y1"] != "" && record["y1"] != undefined
    //               ? "Rp" + record["y1"].toLocaleString()
    //               : ""}
    //           </p>
    //         </div>
    //       </>
    //     ),
    //   },
    //   {
    //     title: "Y2",
    //     dataIndex: "y2",
    //     key: "y2",
    //     render: (_, record) => (
    //       <>
    //         <div>
    //           <p>
    //             {record["y2"] != "" && record["y2"] != undefined
    //               ? "Rp" + record["y2"].toLocaleString()
    //               : ""}
    //           </p>
    //         </div>
    //       </>
    //     ),
    //   },
    //   {
    //     title: "y3",
    //     dataIndex: "y3",
    //     key: "y3",
    //     render: (_, record) => (
    //       <>
    //         <div>
    //           <p>
    //             {record["y3"] != "" && record["y3"] != undefined
    //               ? "Rp" + record["y3"].toLocaleString()
    //               : ""}
    //           </p>
    //         </div>
    //       </>
    //     ),
    //   },
    // ];
    // setColumnsProfitLoss(column);

    // const resNeraca = await dispatch(fetchNeracaTahun(id)).unwrap();
    // const resInputPL = await dispatch(fetchProfitLossView(id)).unwrap();
    // const resSum = await dispatch(fetchProfitLossSummary(id)).unwrap();
    // const resDrop = await dispatch(fetchDropdownTypeIncomeExpense()).unwrap();

    // if(resNeraca.length !== 0){
    //   const yearOrder = neracaTahun[0];
    //   const yearKey = Object.keys(yearOrder).filter(key => key.startsWith('y'));
    //   const total = JSON.parse(JSON.stringify(PLSummary));
  
    //   const createIncomeExpenseRow = (segment) => {
    //     const filteredData = resDrop.filter(item => item.segment === segment);
    
    //     const sortedData = filteredData.sort((a, b) => {
    //       const aPosition = a.category.includes('-') ? 1 : 0;
    //       const bPosition = b.category.includes('-') ? 1 : 0;
    
    //       if(aPosition !== bPosition){
    //         return aPosition - bPosition;
    //       }
    
    //       return a.id.localeCompare(b.id);
    //     });
    
    //     const result = sortedData.map(item => {
    //       const value = {};
    //       yearKey.forEach(ykey => {
    //         value[ykey] = PLView.find(plItem =>
    //           plItem.income_expense_id === item.id &&
    //           plItem.year === yearOrder[ykey])?.value || 0;
    //       });
        
    //       return {
    //         keterangan: item.type_income_expense,
    //         ...value
    //       };
    //     });
    
    //     return result;
    //   }
    
    //   const createTotalRow = (propName) => {
    //     const value = {};
    
    //     yearKey.forEach((key) => {
    //       value[key] = total.find(item => item.year === yearOrder[key])?.[propName] || 0;
    //     })
    
    //     const result = {
    //       keterangan: formatText(propName),
    //       ...value
    //     };
    
    //     return result;
    //   }
    
    //   const createGroupRow = (segment, propName) => {
    //     const IERow = createIncomeExpenseRow(segment);
    //     const totalRow = createTotalRow(propName);
    
    //     return [...IERow, totalRow];
    //   }
  
    //   const columns = [
    //     {
    //       title: "Profit and Loss",
    //       dataIndex: "keterangan",
    //       key: "keterangan",
    //       render: (_, record) => {       
    //         const applyMargin =  total.length > 0 && !Object.keys(total[0]).filter(key => key !== "id_debitur" && 
    //           key !== "year" && 
    //           key !== "profit_before_tax")
    //           .map(item => formatText(item)).includes(record["keterangan"]);  
    //         return (
    //           <p style={{ marginLeft: applyMargin ? "30px" : "" }}>{record["keterangan"]}</p>
    //         );       
    //       },
    //     },
    //     ...Object.entries(neracaTahun[0])
    //       .filter(([key]) => key.startsWith('y'))
    //       .map(([key, value], index) => ({
    //         title: convertDateFormatString(value, "MMM YYYY"),
    //         dataIndex: key,
    //         key: key,
    //         render: (_, record) => {
    //           return (
    //             <p>
    //               {(typeof record[key] === "number") ? "Rp" + record[key].toLocaleString() : ""}
    //             </p>
    //           );
    //         }
    //       })
    //     )
    //   ]
    //   setColumnsProfitLoss(columns);

    //   if(resInputPL.length !== 0 && resSum.length !== 0){   
    //     const summary = [...createGroupRow("GP", "gross_profit"), ...createGroupRow("OE", "operating_expense"),
    //       createTotalRow("ebitda"), ...createGroupRow("EB", "ebit"), ...createGroupRow("TO", "total_others_net"),
    //       createTotalRow("profit_before_tax"), ...createGroupRow("NP", "net_profit")
    //     ];
    //     setProfitLoss(summary);    
    //   }
    // }
  }

  const apiGetFS = async(id) => {
    await dispatch(fetchFSView(id)).unwrap();

    // const data = await getAPIPython("fs_get?id_debitur=" + id);
    // const result = data["data"];
    // console.log(result);
    // setFs(result)

    // const columns = [
    //   {
    //     title: "Kategori",
    //     dataIndex: "kategori",
    //     render: (_, record) => {
    //       let flag = false;
    //       if (
    //         record["kategori"] == "EBITDA" ||
    //         record["kategori"] == "Net Income" ||
    //         record["kategori"] == "Total Current Asset" ||
    //         record["kategori"] == "Total Fixed Asset" ||
    //         record["kategori"] == "Total Asset" ||
    //         record["kategori"] == "Total Liabilities" ||
    //         record["kategori"] == "Total Equity" ||
    //         record["kategori"] == "Total Liabilities & Equity"
    //       ) {
    //         flag = true;
    //       }
    //       return (
    //         <p className={flag ? "font-bold" : ""}>{record["kategori"]}</p>
    //       );
    //     },
    //   },
    //   {
    //     title: "Last Year Number",
    //     dataIndex: "Last Year Number",
    //     align: "right",
    //     render: (_, record) => {
    //       let color;
    //       if(record['Last Year Number'] == 'Not Balanced'){
    //         color = 'text-red-700'
    //       }else if (record['Last Year Number'] == 'Balanced'){
    //         color = 'text-green-700'
    //       }
    //       return (  
    //         <div>
    //           <p className={`${color}`}>{isNaN(record['Last Year Number']) ? record['Last Year Number'] : 'Rp' + record['Last Year Number'].toLocaleString()}</p>
    //         </div>
    //       );
    //     }
    //   },
    //   {
    //     title: "YTD",
    //     dataIndex: "YTD",
    //     align: "right",
    //     render: (_, record) => {
    //        let color;
    //        if (record["YTD"] == "Not Balanced") {
    //          color = "text-red-700";
    //        } else if (record["YTD"] == "Balanced") {
    //          color = "text-green-700";
    //        }
    //       return (
    //         <div>
    //          <p className={`${color}`}>{isNaN(record['YTD']) ? record['YTD'] : 'Rp' + record['YTD'].toLocaleString()}</p>
    //         </div>
    //       );
    //     }
    //   },
    //   {
    //     title: "Annualized",
    //     dataIndex: "Annualized",
    //     align: "right",
    //     render: (_, record) => {
    //       let color;
    //       if (record["Annualized"] == "Not Balanced") {
    //         color = "text-red-700";
    //       } else if (record["Annualized"] == "Balanced") {
    //         color = "text-green-700";
    //       }
    //       return (
    //         <div>
    //           <p className={`${color}`}>{isNaN(record['Annualized']) ? record['Annualized'] : 'Rp' + record['Annualized'].toLocaleString()}</p>
    //         </div>
    //       )
    //     } 
    //   },
    //   {
    //     title: "Projection",
    //     dataIndex: "Projection",
    //     align: "right",
    //     render: (_, record) => {
    //        let color;
    //        if (record["Projection"] == "Not Balanced") {
    //          color = "text-red-700";
    //        } else if (record["Projection"] == "Balanced") {
    //          color = "text-green-700";
    //        }
    //       return (
    //         <div>
    //           <p className={`${color}`}>{isNaN(record['Projection']) ? record['Projection'] : 'Rp' + record['Projection'].toLocaleString()}</p>
    //         </div>
    //       )
    //     } 
    //   },
    //   {
    //     title: "Diff current to previous year(%)",
    //     dataIndex: "Diff current to previous year",
    //     align: "right",
    //     render: (_, record) => (
    //       <>
    //         <div>
    //           {/* <p>{record["Diff current to previous year"] * 100 + "%"}</p> */}
    //           <p>{isNaN(record['Diff current to previous year']) ? record['Diff current to previous year'] : record['Diff current to previous year']*100 + '%'}</p>
    //         </div>
    //       </>
    //     ),
    //   },
    // ];
    // setColumnsFS(columns);
  }

  const apiGetBankLoan = async (id) => {
    const data = await getAPIPython(
      "view_capabilities_bank_loan?id_debitur=" + id
    );
    setBankLoan(data["value"]);

    // console.log(data["value"]);
    //Generate columns depends on length of the key
    const num = Object.keys(data["value"][0]).length;
    // console.log(num);
    let array = [];
    for (let x = 0; x <= num - 2; x++) {
      // console.log("X",x);
      let object = {
        title: x.toString(),
        dataIndex: x.toString(),
        key: x.toString(),
        render: (_, record) => {
            if (record[x.toString()] != "" &&record[x.toString()] != 0 &&record[x.toString()] != "-"){
              if (record["description"] == "Principal Prempayment" ||record["description"] == "Percent Interest Payment"){
                return <p>{record[x.toString()] * 100 + "%"}</p>;
              }
              return <p>{"Rp" + record[x.toString()].toLocaleString()}</p>;
            } else {
              return <p>{record[x.toString()]}</p>;
            }
        },
      };
      array.push(object);
    }
    array.unshift({
      title: "Keterangan",
      dataIndex: "description",
      key: "description",
      fixed: "left",
      render: (_,record) =>{
        let flag = false
        if (
          record["description"] == "Principal Amount" ||
          record["description"] == "Principal Prempayment" ||
          record["description"] == "Principal Ending Balance" ||
          record["description"] == "Percent Interest Payment" ||
          record["description"] == "Interest Ending Balance" ||
          record["description"].includes("Total Existing")
        ) {
          flag = true;
        }
        return <p className={flag ? "font-bold" : ""}>{record["description"]}</p>;
      }
    });
    // console.log("ARRAY COLUMNS",array);
    setColumnBankLoan(array);
  }

  const apiGetNewLoan = async (id) => {
    const data = await getAPIPython("view_capabilities_new_loan?id_debitur=" + id);
    // console.log(data['value'],data['periode']);

    const num = data['periode']

    //Generate columns depends on length of the key
    let array = [];
    for(let x = 1; x<=num;x++){
      let object = {
        title:x.toString(),
        dataIndex:x.toString(),
        align:'right',
        render:(_,record) => {
          // console.log(record[x.toString()]);
          if(record[x.toString()] != '' && record[x.toString()] != 0 && record[x.toString()] != '-'){
            if(record['Description'] == 'Repayment Proportion'){
              return <p>{record[x.toString()] + '%'}</p>
            }else{
              return <p>{'Rp' + record[x.toString()].toLocaleString()}</p>
            }
          }else{
            return <p>{record[x.toString()]}</p>
          }
        }
      }
      array.push(object)
    }
    array.unshift({
      title: "Keterangan",
      dataIndex: "Description",
      fixed: "left",
      render: (_, record) => {
        let flag = false;
        if (
          record["Description"].includes("Ending Balance") ||
          record["Description"] == "Repayment Proportion" ||
          record["Description"] == "Amortized Payment" ||
          record["Description"] == "Principal" ||
          record["Description"] == "Interest Efektif" ||
          record["Description"] == "Interest Flat" ||
          record["Description"] == " Interest Charged to Client" ||
          record["Description"] == "Principal + Interest" ||
          record["Description"] == "Historical DSCR" ||
          record["Description"] == "Current DSCR" ||
          record["Description"] == "Projection DSCR"
        ) {
          flag = true;
        }
        return (
          <p className={flag ? "font-bold" : ""}>{record["Description"]}</p>
        );
      },
    });
    setColumnNewLoan(array);
    setNewLoan(data["value"]);
  }

  const apiGetDebtSizing = async (id) => {
    const data = await getAPIPython("view_capabilities_debt_sizing?id_debitur=" + id);
    setDebtSizing(data['value']);

    const num = Object.keys(data['value'][0]).length;

    let array = [];
    for(let x = 1; x <= num - 1 ; x++){
      let object = {
        title: x.toString(),
        dataIndex: x.toString(),
        key: x.toString(),
        render: (_, record) => {
          if(record[x.toString()] != "" && record[x.toString()] != 0 && !record['Description'].includes("Interest") && record[x.toString()] != '-') {
            return <p>{"Rp" + record[x.toString()].toLocaleString()}</p>;
          } else {
            return <p>{record[x.toString()]}</p>;
          }
        },
      };
      array.push(object);
    }
    array.unshift({
      title: "Keterangan",
      dataIndex: "Description",
      fixed: "left",
      render: (_, record) => {
        let flag = false;
        if (
          record["Description"].includes("Ending Balance") ||
          record["Description"] == "Repayment Proportion" ||
          record["Description"] == "Amortized Payment" ||
          record["Description"] == "Principal" ||
          record["Description"] == "Interest Efektif" ||
          record["Description"] == "Interest Flat" ||
          record["Description"] == " Interest Charged to Client" ||
          record["Description"] == "Principal + Interest" ||
          record["Description"] == "Historical DSCR" ||
          record["Description"] == "Current DSCR" ||
          record["Description"] == "Projection DSCR"
        ) {
          flag = true;
        }
        return (
          <p className={flag ? "font-bold" : ""}>{record["Description"]}</p>
        );
      },
    });
    setColumnDebtSizing(array);
  }

  const apiGetFinancial1 = async (id) => {
    const data = await getAPIPython("financial1-get?id_debitur=" + id);
    setFinancial1(data["data"]);

    const columns = [
      {
        title: "Indicator",
        dataIndex: "indicator",
        key: "indicator",
      },
      {
        title: "Last Year",
        dataIndex: "last year",
        key: "last_year",
        render: (_, record) => {
          return (
            <p>
              {record["indicator"] == "FCF per period" ||
              record["indicator"] == "Debt repayment per period"
                ? "Rp" + parseInt(record["last year"]).toLocaleString()
                : record["last year"]}
            </p>
          );
        },
      },
      {
        title: "Current Year",
        dataIndex: "current year",
        key: "current_year",
        render: (_, record) => {
          return (
            <p>
              {record["indicator"] == "FCF per period" ||
              record["indicator"] == "Debt repayment per period"
                ? "Rp" + parseInt(record["current year"]).toLocaleString()
                : record["current year"]}
            </p>
          );
        },
      },
      {
        title: "Projection",
        dataIndex: "projection",
        key: "projection",
        render: (_, record) => {
          return (
            <p>
              {record["indicator"] == "FCF per period" ||
              record["indicator"] == "Debt repayment per period"
                ? "Rp" + parseInt(record["projection"]).toLocaleString()
                : record["projection"]}
            </p>
          );
        },
      },
    ];
    setColumnsFinancial1(columns)
  };

  const apiGetFinancial3 = async (id) => {
    const data = await getAPIPython("financial3-get?id_debitur=" + id);
    setFinancial3(data["data"]);
    
    const column = [
        {
          title: "Indicator",
          dataIndex: "indicator",
          key: "indicator",
        },
        {
          title: "Value",
          dataIndex: "value",
          key: "value",
        },
    ]

    setColumnsFinancial3(column)
  };

  const apiGetFinancial4 = async (id) => {
    const data = await getAPIPython("financial4-get?id_debitur=" + id);
    setFinancial4(data["data"]);

    const column = [
      {
        title: "Indicator",
        dataIndex: "indicator",
        key: "indicator",
      },
      {
        title: "Value",
        dataIndex: "value",
        key: "value",
      },
      {
        title: "Score",
        dataIndex: "score",
        key: "score",
      },
    ]
    setColumnsFinancial4(column)
  };

  const apiGetFinancial5 = async (id) => {
    const data = await getAPIPython("financial5-get?id_debitur=" + id);
    setFinancial5(data["data"]);

    const column = [
      {
          title: "Indicator",
          dataIndex: "indicator",
          key: "indicator",
        },
        {
          title: "Last Year",
          dataIndex: "last year",
          key: "last_year",
        },
        {
          title: "Current Year",
          dataIndex: "current year",
          key: "current_year",
        },
        {
          title: "Projection",
          dataIndex: "projection",
          key: "projection",
        },
    ]
    setColumnsFinancial5(column)
  };

  const apiGetSummary = async (id) => {
    const data = await getAPIPython("financialsummary-get?id_debitur=" + id);
    setFinancialSummary(data["data"]);

    const columns = [
      {
        title: "Indicator",
        dataIndex: "indikator",
        key: "indicator",
      },
      {
        title: "Score",
        dataIndex: "score",
        key: "score",
      },
      {
        title: "Weight",
        dataIndex: "weight",
        key: "weight",
        render: (_,record) => {
          return <p>{record['weight'] * 100 + '%'}</p>
        }
      },
      {
        title: "Weighted Score",
        dataIndex: "weighted_score",
        key: "weighted_score"
      }
    ]
    setColumnFinancialSummary(columns)
  };

  const apiGetFinalResult = async (id) => {
    const columnsMain = [
      {
        title: "Kategori",
        dataIndex: "kategori",
        key: "kategori",
      },
      {
        title: "Score",
        dataIndex: "score",
        key: "score",
      },
      {
        title: "Weighted",
        dataIndex: "weighted",
        key: "weighted",
      },
      {
        title: "Weighted Score",
        dataIndex: "weighted score",
        key: "weighted_score",
      },
      {
        title: "Risk",
        dataIndex: "risk",
        key: "risk",
        render: (_, record) => {
          let color = "";
          // record['risk'] = " Risk"
          switch (record["risk"]) {
            case "Very Low Risk":
              color = "from-green-500 to-green-600";
              break;
            case "Low Risk":
              color = "from-green-400 to-green-500";
              break;
            case "Moderate Risk":
              color = "from-amber-400 to-amber-500";
              break;
            case "High Risk":
              color = "from-red-400 to-red-500";
              break;
            case "Extremely High Risk":
              color = "from-red-500 to-red-600";
          }
          return (
            <div className={`bg-gradient-to-r ${color} px-10 py-3`}>
              <p className="">{record["risk"]}</p>
            </div>
          );
        },
      },
    ];
    setColumnResultMain(columnsMain)

    const columnsAssesmentRemarks = [
      {
        title: "Keterangan",
        dataIndex: "keterangan",
        key: "keterangan",
      },
      {
        title: "Proposed",
        dataIndex: "proposed",
        key: "proposed",
        render: (_, record) => {
          if (
            record["keterangan"] == "Expected WC Needed" ||
            record["keterangan"] == "Coverage from Bank Loan" ||
            record["keterangan"] == "Max Plafon Can Be Given" ||
            record["keterangan"] == "Max Debt Sizing"
          ) {
            return <p>{"Rp" + record["proposed"].toLocaleString()}</p>;
          } else if (
            record["keterangan"] == "Interest Efektif" ||
            record["keterangan"] == "Interest Flat" ||
            record["keterangan"] == "Origination fee"
          ) {
            return <p>{record["proposed"] + "%"}</p>;
          } else {
            return <p>{record["proposed"]}</p>;
          }
        },
      },
      {
        title: "Approved",
        dataIndex: "approved",
        key: "approved",
        render: (_, record) => {
          if (
            record["keterangan"] == "Expected WC Needed" ||
            record["keterangan"] == "Coverage from Bank Loan" ||
            record["keterangan"] == "Max Plafon Can Be Given" ||
            record["keterangan"] == "Max Debt Sizing"
          ) {
            if (record["approved"] != "") {
              return <p>{"Rp" + record["approved"].toLocaleString()}</p>;
            }
          } else if (
            record["keterangan"] == "Interest Efektif" ||
            record["keterangan"] == "Interest Flat" ||
            record["keterangan"] == "Origination fee"
          ) {
            return <p>{record["approved"] + "%"}</p>;
          } else {
            return <p>{record["approved"]}</p>;
          }
        },
      },
    ];
    setColumnAssessment1(columnsAssesmentRemarks);

    const columnsAssessmentDebitur = [
      {
        title: "Kategori",
        dataIndex: "kategori",
        key: "kategori",
      },
      {
        title: "Proposed",
        dataIndex: "proposed",
        key: "proposed",
        render: (_, record) => (
          <p>
            {record["kategori"] == "Maximum Debt" ? "Rp" + record["proposed"].toLocaleString() : record["proposed"]}
          </p>
        ),
      },
      {
        title: "Approved",
        dataIndex: "approved",
        key: "approved",
      },
    ];
    setColumnAssessment2(columnsAssessmentDebitur)

    const data = await getAPIPython("result_main-get?id_debitur=" + id);
    const assessment1 = await getAPIPython("result_credit_assesment_debitur-get?id_debitur=" + id)
    const assessment2 = await getAPIPython("result_credit_assesment_remarks-get?id_debitur=" + id)

    setResultMain(data["data"]);
    setAssessment1(assessment1["data"]);
    setAssessment2(assessment2["data"]);
  }

  const apiCall = async (value) => {
    // console.log(value);
    await apiGetAkte(value)
    await apiGetAssumption(value)
    await apiGetWewenangDireksi(value)
    await apiGetPemegangSaham(value)
    await apiGetDireksi(value)
    await apiGetCreditGiven(value)
    await apiGetSummaryCreditGiven(value)
    await apiGetCollateralToLoan(value)
    await apiGetBouwheerDetail(value)
    await apiGetIndustryRisk(value)
    await apiGetDebtorProfile(value)
    await apiGetHistorisPefindo(value)
    await apiGetNeraca(value)
    await apiGetProfitLoss(value)
    await apiGetFS(value)
    await apiGetBankLoan(value)
    await apiGetNewLoan(value)
    await apiGetDebtSizing(value)
    await apiGetFinancial1(value);
    await apiGetFinancial3(value);
    await apiGetFinancial4(value);
    await apiGetFinancial5(value);
    await apiGetSummary(value)
    await apiGetFinalResult(value)
  }

  const getId = async (value) => {
    dispatch(setIdDebitur(value))
    setAkte([])
    setWewenang([])
    setSaham([])
    setDireksi([])
    // setAssumption([])
    // setCreditGiven([])
    // setSummaryCreditGiven([])
    // setCollateralToLoan([])
    // setBouwheerDetail([])
    // setIndustryRisk([])
    // setDebtorProfile([])
    // setHistorisPefindo([])
    // setNeraca([])
    // setNeracaPerhitungan([])
    // setProfitLoss([])
    // setFs([])
    setBankLoan([])
    setNewLoan([])
    setDebtSizing([])
    setFinancialSummary([])
    setFinancial1([])
    setFinancial3([])
    setFinancial4([])
    setFinancial5([])
    setGrade([])
    setColor([])
    setResultMain([])
    setAssessment1([])
    setAssessment2([])

    loadingMessage("Loading data. Please wait...")
    // isLoading(true)
    dispatch(setIsLoadingAPI(true))
    const data = await getAPIPython("masterform-get?id_debitur="+ value).catch((error) => {
      // errorSubmit("Failed Get All Data Debitur")
      console.log("ERROR GET MASTER FORM", error)
    });
    if(data['statusCode'] == 200){
      setDetail(data['data'][0])
      setIsSelect(true)
    }else{
      errorSubmit("Failed get data debitur")
    }
    
    const dataGrade = await getAPIPython("result_main-get?id_debitur=" + value).catch((error) => {
      // errorSubmit("Failed Get All Data Debitur")
      console.log("ERROR GET RESULT MAIN", error)
    })

    if(dataGrade){
       setGrade(dataGrade['grade'])
        switch (dataGrade["grade"]) {
          case "A++":
            setColor("green-900");
            break;
          case "A+":
            setColor("green-800");
            break;
          case "A":
            setColor("green-700");
            break;
          case "A-":
            setColor("green-600");
            break;
          case "B++":
            setColor("green-500");
            break;
          case "B+":
            setColor("green-400");
            break;
          case "B":
            setColor("green-300");
            break;
          case "B-":
            setColor("green-200");
            break;
          case "C++":
            setColor("green-100");
            break;
          case "C+":
            setColor("green-50");
            break;
          case "C":
            setColor("green-50");
            break;
          case "C-":
            setColor("red-50");
            break;
          case "D++":
            setColor("red-100");
            break;
          case "D+":
            setColor("red-200");
            break;
          case "D":
            setColor("red-300");
            break;
          case "D-":
            setColor("red-400");
            break;
          case "E++":
            setColor("red-500");
            break;
          case "E+":
            setColor("red-600");
            break;
          // case "E+":
          //   setColor("red-700");
          //   break;
          // case "E+":
          //   setColor("red-800");
          //   break;
          // case "E":
          //   setColor("red-900");
          //   break;
          // case "E-":
          //   setColor("red-900");
          //   break;
          case "E":
            setColor("red-700");
            break;
          case "E-":
            setColor("red-800");
            break;
          default:
            setGrade("--");
            setColor("neutral-400");
            break;
        }
    }else{
      setGrade('--');
      setColor('neutral-400')
    }

    try{
      await apiCall(value)
      success("Sukses Get All Data Debitur")
    }catch{
      errorSubmit("Failed Get All Data Debitur")
    }
    dispatch(setIsLoadingAPI(false))
    // isLoading(false)
  }

  const handleEdit = (id_debitur) => {

    dispatch(setIdDebitur(id_debitur))
    navigate("/insert-form", {replace: true})

  }

  const MenuSummary = () => {
    // return <FinalResult masterData={dataMaster}/>
    return (
      <div>
        <Skeleton avatar active paragraph={{ rows: 30 }} loading={isLoadingAPI}>
          <div className="flex flex-row my-3">
            <p className="font-bold text-2xl mx-3">Final Scoring Report</p>
          </div>
          <div className="grid grid-cols-2">
            <div
              className={`bg-${color} w-[40%] rounded-lg drop-shadow-lg px-3 py-3 mx-auto my-auto`}
            >
              <div className="py-3">
                <p className="text-center">Final Score</p>
                <p className="font-bold text-[50px] text-center">{grade}</p>
              </div>
            </div>
            <div>
              <Table
                dataSource={resultMain}
                columns={columnresultMain}
                pagination={false}
              />
            </div>
          </div>
          <div className="grid grid-cols-2 mx-5">
            <Table
              className="mx-3 "
              dataSource={assessment1}
              columns={columnassessment1}
              pagination={false}
            />
            <Table
              dataSource={assessment2}
              columns={columnassessment2}
              pagination={false}
            />
          </div>
        </Skeleton>
      </div>
    );
  }

  const MenuOthers = () => {
    return <div>
    {/* Detail Debitur */}
    <Skeleton className="my-3 px-10" loading={isLoadingAPI} active>
      <div className="flex flex-row">
        <div className={`basis-1/5 bg-${color} ml-10 text-center`}>
          <div className="my-14">
            <p className="">Final Score</p>
            <p className="font-bold text-[50px]">{grade}</p>
          </div>
        </div>
        <div className="basis-4/5 my-auto">
          <Descriptions
            size="small"
            className="mx-auto px-10 w-[100%]"
            title="Detail Debitur"
            bordered
          >
            {Object.entries(detail).map((key, value) => {
              return <Item label={key[0]}>{key[1]}</Item>;
            })}
          </Descriptions>
        </div>
      </div>
    </Skeleton>
    {/* Detail Debitur */}

    {/* Akte */}
    <div>
      <Skeleton className="my-3 px-10" loading={isLoadingAPI} active>
        {akte.length != 0
          ? akte.map((item, i) => {
              return (
                <Descriptions
                  style={{marginBottom: "3vh"}}
                  column={2}
                  size="small"
                  className="mx-auto px-10 w-[100%] my-2"
                  title={item["jenis akte"]}
                  bordered
                >
                  {Object.entries(item).map((key, value) => {
                    // console.log(key);
                    return (
                      <Item
                        labelStyle={{ width: "10%" }}
                        contentStyle={{ width: "15%" }}
                        label={key[0]}
                      >
                        {key[1]}
                      </Item>
                    );
                  })}
                </Descriptions>
              );
            })
          : emptyDescription("Akte")}
      </Skeleton>
    </div>
    {/* Akte */}

    {/* Wewenang Direksi */}
    <div>
      <Skeleton className="my-3 px-10" loading={isLoadingAPI} active>
        {Object.keys(wewenang).length != 0 ? (
          <Descriptions
            style={{marginBottom: "3vh"}}
            column={2}
            size="small"
            className="mx-auto px-10 w-[100%]"
            title="Wewenang Direksi"
            bordered
          >
            {Object.entries(wewenang).map((key, value) => {
              if (key[0] != "id") return <Item label={key[0]}>{key[1]}</Item>;
            })}
          </Descriptions>
        ) : (
          emptyDescription("Wewenang Direksi")
        )}
      </Skeleton>
    </div>
    {/* Wewenang Direksi */}

    {/* Pemegang Saham */}
    <div>
      <Skeleton className="my-3 px-10" loading={isLoadingAPI} active>
        <CustomTable
          title="Pemegang Saham"
          data={saham}
          column={columnsSaham}
        />
      </Skeleton>
    </div>

    {/* Direksi */}
    <div>
      <Skeleton className="my-3 px-10" loading={isLoadingAPI} active>
        <CustomTable title="Direksi" data={direksi} column={columnsDireksi} />
      </Skeleton>
    </div>
    {/* Pemegang Saham */}

    {/* Assumption */}
    <div>
      <Skeleton className="my-3 px-10" loading={isLoadingAPI} active>
        {/* {Object.keys(assumption).length != 0 ? ( */}
        {Object.keys(dataAssumption).length !== 0 ? (
          <Descriptions
            style={{marginBottom: "3vh"}}
            column={4}
            size="small"
            className="mx-auto px-10 w-[100%]"
            title="Assumption"
            bordered
          >
            {Object.entries(dataAssumption).map((key, value) => {
            // {Object.entries(assumption).map((key, value) => {
              // if (key[0] != "id")
                return <Item label={formatTextAssumption(key[0])}>{key[1] * 100 + "%"}</Item>;
            })}
          </Descriptions>
        ) : (
          emptyDescription("Assumption")
        )}
      </Skeleton>
    </div>
    {/* Assumption */}

    {/* Input Credit Given */}
    <div>
      <Skeleton className="my-3 px-10" loading={isLoadingAPI} active>
        <CustomTable
          title="Credit Given"
          data={dataSourceCreditGiven}
          column={colCreditGiven}
          // data={creditGiven}
          // column={columnsCreditGiven}
        />
      </Skeleton>
    </div>
    {/* Input Credit Given */}

    {/* Summary Credit Given */}
    <div>
      <div>
        <Skeleton className="my-3 px-10" loading={isLoadingAPI} active>
          {/* {Object.keys(summaryCreditGiven).length != 0 ? (
            <Descriptions
              column={2}
              size="small"
              className="mx-auto px-10 w-[100%]"
              title="Summary Credit Given"
              bordered
            >
              {Object.entries(summaryCreditGiven).map((key, value) => {
                if (Object.keys(summaryCreditGiven).length > 1) {
                  if (key[0] != "id" && key[0] != "id_debitur") {
                    return (
                      <Item label={key[0]}>
                        {key[0] == "Jumlah pembiayaan" ||
                        key[0] == "Pembiayaan maksimal" ||
                        key[0] == "Pembiayaan maksimal iki"
                          ? "Rp" + key[1].toLocaleString()
                          : key[0] == "tanggal_perhitungan"
                          ? moment(key[1]).format("DD MMMM yy")
                          : key[0] == "Persentase pembiayaan" ||
                            key[0] == "Pertanggungan fidusia"
                          ? key[1] * 100 + "%"
                          : key[1]}
                      </Item>
                    );
                  }
                }
              })}
            </Descriptions>
          ) : (
            emptyDescription("Summary Credit Given")
          )} */}
          <CustomTable
            title="Summary Credit Given"
            data={dataSourceSummaryCreditGiven}
            column={colSummaryCreditGiven}
            // data={summaryCreditGiven}
            // column={columnsSumCreditGiven}
          />          
        </Skeleton>
      </div>
    </div>
    {/* Summary Credit Given */}

    {/* Collateral To Loan */}
    <div>
      <Skeleton className="my-3 px-10" loading={isLoadingAPI} active>
        <CustomTable
          title="Collateral To Loan"
          data={dataSourceCollateralToLoan}
          column={colCollateralToLoan}
          // data={collateralToLoan}
          // column={columnsCollateralToLoan}
        />
      </Skeleton>
    </div>
    {/* Collateral to Loan */}

    {/* Bouwheer Detail */}
    <div>
      <Skeleton className="my-3 px-10" loading={isLoadingAPI} active>
        {/* {Object.keys(bouwheerDetail).length != 0 ? (
          <Descriptions
            column={4}
            size="small"
            className="mx-auto px-10 w-[100%]"
            title="Bouwheer"
            bordered
          >
            {Object.entries(bouwheerDetail).map((key, value) => {
              if (key[0] != "id")
                return (
                  <Item label={key[0]}>
                    {key[0] == "weight" ? key[1] * 100 + "%" : key[1]}
                  </Item>
                );
            })}
          </Descriptions>
        ) : (
          emptyDescription("Bouwheer")
        )} */}
        <CustomTable
          title="Bouwheer"
          data={dataSourceBouwheerDetail}
          column={colBouwheerDetail}          
          // data={bouwheerDetail}
          // column={columnsBouwheerDetail}          
        />
      </Skeleton>
    </div>
    {/* Bouwheer Detail */}

    {/* Industry Risk */}
    <div>
      <Skeleton className="my-3 px-10" loading={isLoadingAPI} active>
        <CustomTable
          title="Industry Risk"
          data={dataSourceIndustryRisk}
          column={colIndustryRisk}
          // data={industryRisk}
          // column={columnsIndustry}
        />
      </Skeleton>
    </div>
    {/* Industry Risk */}

    {/* Debtor Profile */}
    <div>
      <Skeleton className="my-3 px-10" loading={isLoadingAPI} active>
        <CustomTable
          title="Debtor Profile"
          data={dataSourceDebtorProfile}
          column={colDebtorProfile}
          // data={debtorProfile}
          // column={columnsDebtorProfile}
        />
      </Skeleton>
    </div>
    {/* Debtor Profile */}

    {/* Historis Pefindo */}
    <div>
      <Skeleton className="my-3 px-10" loading={isLoadingAPI} active>
        <CustomTable
          title="Historis Pefindo"
          data={dataSourceHistorisPefindo}
          column={colHistorisPefindo}
          // data={historisPefindo}
          // column={columnsHistorisPefindo}
        />
      </Skeleton>
    </div>
    {/* Historis Pefindo */}

    {/* Neraca */}
    <div>
      <Skeleton className="my-3 px-10" loading={isLoadingAPI} active>
        <div>
          <div>
            <p className="mx-11 my-3 font-bold text-[15px]">Neraca</p>
          </div>
          <div className="grid grid-cols-2 mx-10">
            <Table
              className=""
              pagination={{
                hideOnSinglePage: true,
              }}
              dataSource={dataSourceNeraca1}
              columns={colNeraca1}
              rowClassName={rowClassNeraca}
              // dataSource={neraca}
              // columns={columnsNeraca1}
            />
            <Table
              pagination={{
                hideOnSinglePage: true,
              }}
              dataSource={dataSourceNeraca2}
              columns={colNeraca2}
              rowClassName={rowClassNeraca}
              // dataSource={neracaPerhitungan}
              // columns={columnsNeraca2}
            />
          </div>
        </div>
      </Skeleton>
    </div>
    {/* Neraca */}

    {/* PL */}
    <div>
      <Skeleton className="my-3 px-10" loading={isLoadingAPI} active>
        <CustomTable
          title="Profit & Loss"
          data={dataSourcePL}
          column={colPL}
          row={rowClassPL}
          // data={profitLoss}
          // column={columnsProfitLoss}        
        />
      </Skeleton>
    </div>
    {/* PL */}

    {/* FS */}
    <div>
      <Skeleton className="my-3 px-10" loading={isLoadingAPI} active>
        <CustomTable
          title="Financial Statement"
          data={dataSourceFS}
          column={colFS}
          row={rowClassFS}
          // data={fs}
          // column={columnsFS}
          // row={(record, index) => {
          //   if (
          //     record["kategori"] == "EBITDA" ||
          //     record["kategori"] == "Net Income" ||
          //     record["kategori"] == "Total Current Asset" ||
          //     record["kategori"] == "Total Fixed Asset" ||
          //     record["kategori"] == "Total Asset" ||
          //     record["kategori"] == "Total Liabilities" ||
          //     record["kategori"] == "Total Equity" ||
          //     record["kategori"] == "Total Liabilities & Equity"
          //   ) {
          //     return "bg-zinc-100";
          //   }
          // }}
        />
      </Skeleton>
    </div>
    {/* FS */}

    {/* Bank Loan */}
    <div>
      <Skeleton className="my-3 px-10" loading={isLoadingAPI} active>
        <CustomTable
          title="Capabilities - Bank Loan"
          data={bankLoan}
          column={columnBankLoan}
          scroll={1300}
          row={(record, index) => {
            if (
              record["description"].includes("Ending Balance") ||
              record["description"] == "Total Existing Payment"
            ) {
              return "bg-zinc-100";
            }
          }}
        />
      </Skeleton>
    </div>
    {/* Bank Loan */}

    {/* New Loan */}
    <div>
      <Skeleton className="my-3 px-10" loading={isLoadingAPI} active>
        <CustomTable
          title="Capabilities - New Loan"
          data={newLoan}
          column={columnNewLoan}
          scroll={1300}
          row={(record, index) => {
            if (record["Description"].includes("Ending Balance")) {
              return "bg-zinc-100";
            }
          }}
        />
      </Skeleton>
    </div>
    {/* New Loan */}

    {/* Debt Sizing */}
    <div>
      <Skeleton className="my-3 px-10" loading={isLoadingAPI} active>
        <CustomTable
          title="Capabilities - Debt Sizing"
          data={debtSizing}
          column={columnDebtSizing}
          scroll={1300}
          row={(record, index) => {
            if (record["Description"].includes("Ending Balance")) {
              return "bg-zinc-100";
            }
          }}
        />
      </Skeleton>
    </div>
    {/* Debt Sizing */}

    {/* Financial -  DSCR*/}
    <div>
      <Skeleton className="my-3 px-10" loading={isLoadingAPI} active>
        <CustomTable
          title="Financial - DSCR"
          data={financial1}
          column={columnsFinancial1}
          scroll={1300}
        />
      </Skeleton>
    </div>
    {/* Financial - DSCR*/}

    {/* Financial - Kualitas Dari Financial Reporting */}
    <div>
      <Skeleton className="my-3 px-10" loading={isLoadingAPI} active>
        <CustomTable
          title="Financial - Kualitas Dari Financial Reporting"
          data={financial3}
          column={columnsFinancial3}
          scroll={1300}
        />
      </Skeleton>
    </div>
    {/* Financial - Kualitas Dari Financial Reporting */}

    {/* Finnncial - Financial Trends */}
    <div>
      <Skeleton className="my-3 px-10" loading={isLoadingAPI} active>
        <CustomTable
          title="Financial - Financial Trends"
          data={financial4}
          column={columnsFinancial4}
          scroll={1300}
        />
      </Skeleton>
    </div>
    {/* Financial - Financial Trends */}

    {/* Financial - Cash Conversion Style */}
    <div>
      <Skeleton className="my-3 px-10" loading={isLoadingAPI} active>
        <CustomTable
          title="Financial - Cash Conversion Style"
          data={financial5}
          column={columnsFinancial5}
          scroll={1300}
        />
      </Skeleton>
    </div>
    {/* Financial - Cash Conversion Style */}

    {/* Financial - Summary */}
    <div>
      <Skeleton className="my-3 px-10" loading={isLoadingAPI} active>
        <CustomTable
          title="Financial - Summary"
          data={financialSummary}
          column={columnFinancialSummary}
          scroll={1300}
        />
      </Skeleton>
    </div>
    {/* Financial - Summary */}
  </div>;
  }
  
  const items = [
    { label: "Summary", key: "summary" ,children : <MenuSummary/> },
    { label: "Others", key: "others",children:<MenuOthers/>},
  ];

  return (
    <>
      <div style={{marginBottom: "2vh"}}>
        <PageHeader title={"Home"} subTitle={"List Debitur"} />
        <div className="bg-white w-[90%] rounded-lg mx-auto">
          <TableViewData idDebitur={getId} lastId={idDebitur} editData={handleEdit}/>
          {isSelect && (
            <Tabs className='mx-5' items={items}/>
          )}
        </div>
      </div>
    </>
  );
}

export default Home