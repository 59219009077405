import React from 'react'
import { Steps, Form, Input, Button, message, Select, Table, InputNumber, Alert, Typography, Popover } from "antd";
import { 
  ControlOutlined,
  ExclamationCircleOutlined,
  InfoCircleOutlined
} from '@ant-design/icons';
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux"
import { convertDateFormatString } from "../../utils/util";
import { fetchDropdownTypeIncomeExpense } from "../../store/actions/plSlice";

const { Title } = Typography;

const TableREValidation = (props) => {
  const {result} = props;

  const dispatch = useDispatch();

  // Load redux state
  const idDebitur = useSelector(state => state.home.id_debitur);
  const neracaTahun = useSelector(state => state.neraca.neracaTahun);
  const PLRetainedEarning = useSelector(state => state.pl.PLRetainedEarning);

  const balanceStyle = (value) => {
    let color, status;
    if(typeof value === "number"){
      if(value === 0){
        color = 'green';
        status = 'Balanced';
      }else{
        color = 'red';
        status = 'Not Balanced';
      }
      return <p style={{ color }}>{status}</p>;
    }else{
      return value;
    }
  };

  const createKeteranganRow = (keterangan) => {
    const filteredData = PLRetainedEarning.find(item => item.id_description === keterangan);

    return {
      y1: filteredData?.y1 || 0,
      y2: filteredData?.y2 || 0,
      y3: filteredData?.y3 || 0      
    }; 
  } 

  const difference = {
    y1: "-", 
    y2: createKeteranganRow("kt505").y2 - (createKeteranganRow("kt505")?.y1 + createKeteranganRow("np")?.y1), 
    y3: createKeteranganRow("kt505").y3 - (createKeteranganRow("kt505")?.y2 + createKeteranganRow("np")?.y2)
  }

  const tableData = [
    {
      key: "1",
      keterangan: "Retained Earning",
      ...createKeteranganRow("kt505")
    },
    {
      key: "2",
      keterangan: "Net Profit",
      ...createKeteranganRow("np")
    },
    {
      key: "3",
      keterangan: "Check",
      ...difference
    },
    {
      key: "4",
      keterangan: "Difference",
      ...difference
    },
  ];

  const columns = [
    {
      title: "Keterangan",
      dataIndex: "keterangan",
      key: "keterangan",
      render: (_, record) => {     
        if(record["keterangan"] === "Check"){
          return (
          <div style={{ display: "inline-flex", alignItems: "center", gap: "8px" }}>
            {record["keterangan"]}
            <Popover placement="top" content={
              <div style={{ display: "inline-flex", alignItems: "center", gap: "5px", maxWidth: "250px", textAlign: "center" }}>
                <InfoCircleOutlined style={{ color: "#FAAD14", fontSize: "20px" }} /> Retained Earning Tahun Berjalan = Retained Earning + Net Profit Tahun Sebelumnya
              </div>
            }>
              <InfoCircleOutlined style={{ color: "#999999" }} />
            </Popover>
          </div>
          );
        }
        return record["keterangan"];       
      },
    },
    ...Object.entries(neracaTahun[0])
      .filter(([key]) => key.startsWith('y'))
      .map(([key, value], index) => ({
        title: convertDateFormatString(value, "MMM YYYY"),
        dataIndex: key,
        key: key,
        render: (_, record) => {
          if(record["keterangan"] === "Check"){
            return balanceStyle(record[key]);
          }
          return (
            <p>
              {(typeof record[key] === "number") ? "Rp" + record[key].toLocaleString() : record[key]}
            </p>
          );
        }
      })
    )
  ]

  return (
    <div style={{ marginTop: "35px", marginBottom: "35px" }}>
      <Title level={4} style={{ textAlign: "center", marginBottom: "15px" }}>Retained Earning Validation</Title>
      <Table
        pagination={false}
        dataSource={tableData}
        columns={columns}
        rowClassName={(record, index) => {
          if(record["keterangan"] == "Check" || record["keterangan"] == "Difference"){
            return "highlight-row";
          }else{
            return "no-border";
          }
        }}
      />
    </div>
  );
}

export default TableREValidation