import React from 'react'
import { useState, useEffect } from "react";
// import {
//   Button
// } from "antd";
import { getAPI, getAPIPython } from '../services/data';

//Add Number Separator
const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
} 

const MasterData = (props) => {
  const {
    dataMaster,
    changedField
  } = props
  // console.log(dataMaster);

  const [jenisPinjaman, setJenisPinjaman] = useState([]);
  const [paymentScheme, setPaymentScheme] = useState([]);
  const [pembayaran, setPembayaran] = useState([]);
  const [nilaiPinjaman, setNilaiPinjaman] = useState([]);
  // const [refresh, setRefresh] = useState(true);

  // const handleRefresh = () => {
  //   // console.log("Refresh Collateral to Loan");
  //   setRefresh(!refresh);
  // };

  //Get Pinjaman
  const apiGetJenisPinjaman = async () => {
    const data = await getAPI("jenis-pinjaman");
    const res = data['data'];
    for(let x in res){
      if(dataMaster['jenis_pinjaman'] == res[x]['value']) setJenisPinjaman(res[x]['indicator'])
    }
  };

  //Get Payment Scheme
  const apiGetPaymentScheme = async () => {
    const data = await getAPI("payment-scheme");
    const res = data["data"];
    for (let x in res) {
      if (dataMaster["payment_scheme"] == res[x]["value"]) setPaymentScheme(res[x]["indicator"]);
    }
  };

  //Get Pembayaran
  const apiGetPembayaran = async () => {
    const data = await getAPI("pembayaran");
    const res = data["data"];
    for (let x in res) {
      if (dataMaster["pembayaran"] == res[x]["value"]) setPembayaran(res[x]["indicator"]);
    }
  };


  const apiGetPinjaman = async (id) => {
    try{
      const data = await getAPI("summary-credit-given?id_debitur=" + id);
        let res = data?.data?.pembiayaan_maksimal_iki;
        if(res) setNilaiPinjaman(numberWithCommas(res));
    }catch(error){
      console.error(error);
    }
  }

  //Capsulate APIs into one function
  const apiCall = async () => {
    apiGetJenisPinjaman();
    apiGetPaymentScheme();
    apiGetPembayaran();
    apiGetJenisPinjaman();
    apiGetPinjaman(dataMaster['id'])
  };

  useEffect(() => {
    // console.log('API CALL MASTERDATA');
    if(dataMaster) apiCall();
  }, [dataMaster, changedField]);

  return (
    <div className="bg-gradient-to-r from-blue-500 to-cyan-500 rounded-lg drop-shadow-lg w-[50%] px-3 py-3 mx-auto">
      {/* <Button className='float-right bg-white rounded-lg' onClick={handleRefresh}>Refresh</Button> */}
      <div className="px-3">
        <p className="py-1 text-white font-semibold text-center text-xl">
          Master Data
        </p>
        <p className="py-1 text-white">
          Nama Debitur : {dataMaster['nama_debitur'] ??  "-"}
        </p>
        <p className="py-1 text-white">
          Nilai Pinjaman : {nilaiPinjaman != 0 ? "Rp" + nilaiPinjaman : "-"}
        </p>
        <p className="py-1 text-white">
          Jenis Pinjaman : {jenisPinjaman ?? "-"}
        </p>
        <p className="py-1 text-white">
          Payment Scheme : {paymentScheme ?? "-"}

        </p>
        <p className="py-1 text-white">
          Lama Pinjaman : {dataMaster ? dataMaster["lama_pinjaman"] + " Bulan" : "-"}
        </p>
        <p className="py-1 text-white">
          Grace Period : {dataMaster ? dataMaster["grace_period"] + " Bulan" : "-"}
        </p>
        <p className="py-1 text-white">
          Pembayaran : {pembayaran ?? "-"}
        </p>
      </div>
    </div>
  );
}

export default MasterData