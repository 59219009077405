import React from 'react'
import { Form, Input, Button, message, Select, InputNumber, Table } from "antd";
import { useState, useEffect } from "react";
import { getAPI, getAPIPython } from "../services/data";

const { Option } = Select;

const errorSubmit = (text) => {
  message.error(text);
};

const success = (text) => {
  message.success(text);
};

const Financial = (props) => {
  const {masterData} = props
  const dataMaster = masterData['data'];
  console.log(dataMaster);
  const [tenorOption,setOptionTenor] = useState([]);
  const [disabled,setDisabled] = useState(false)
  const [refresh,setRefresh] = useState(false);

  //State
  const [summary,setSummary] = useState([]);
  const [financial1,setFinancial1] = useState([]);
  const [financial3,setFinancial3] = useState([]);
  const [financial4,setFinancial4] = useState([]);
  const [financial5,setFinancial5] = useState([]); 

  const apiGetTenor = async () => {
    const data = await getAPIPython("financialtenor-get");
    setOptionTenor(data['data']);
  }

  const apiGetFinancial1 = async (id) => {
    const data = await getAPIPython("financial1-get?id_debitur=" + id);
    setFinancial1(data['data']);
  }

  const apiGetFinancial3 = async (id) => {
    const data = await getAPIPython("financial3-get?id_debitur=" + id);
    setFinancial3(data['data']);
    console.log("Financial 1",data['data']);
  }

  const apiGetFinancial4 = async (id) => {
    const data = await getAPIPython("financial4-get?id_debitur=" + id);
    setFinancial4(data['data'])
    console.log("Financial 2", data["data"]);
  }

  const apiGetFinancial5 = async (id) => {
    const data = await getAPIPython("financial5-get?id_debitur=" + id);
    setFinancial5(data['data']);
    console.log("Financial 3", data["data"]);
  }

  const apiGetSummary = async (id) => {
    const data = await getAPIPython("financialsummary-get?id_debitur=" + id);
    setSummary(data['data']);
    console.log("Financial Summary",data['data']);
  }

  const apiCall = async (id) => {
    apiGetFinancial1(id)
    apiGetFinancial3(id)
    apiGetFinancial4(id)
    apiGetFinancial5(id)
    apiGetSummary(id);
  }

  useEffect(() => {
    console.log("Financial API Call");
    apiGetTenor();
    if(dataMaster) apiCall(dataMaster['id']);
    // if(dataMaster) apiCall("TestingTID1");
    // apiCall("0YI7uE");
  },[refresh])

  // useEffect(() => {
  //   apiGetTenor();
  // }, [refresh]);

  const table = [
    {
      title: "Debt Service Coverage Ratio",
      columns: [
        {
          title: "Indicator",
          dataIndex: "indicator",
          key: "indicator",
        },
        {
          title: "Last Year",
          dataIndex: "last year",
          key: "last_year",
        },
        {
          title: "Current Year",
          dataIndex: "current year",
          key: "current_year",
        },
        {
          title: "Projection",
          dataIndex: "projection",
          key: "projection",
        },
      ],
      data: financial1,
    },
    {
      title: "Kualitas Dari Financial Reporting",
      columns: [
        {
          title: "Indicator",
          dataIndex: "indicator",
          key: "indicator",
        },
        {
          title: "Value",
          dataIndex: "value",
          key: "value",
        },
      ],
      data: financial3,
    },
    {
      title: "Financial Trends",
      columns: [
        {
          title: "Indicator",
          dataIndex: "indicator",
          key: "indicator",
        },
        {
          title: "Value",
          dataIndex: "value",
          key: "value",
        },
        {
          title: "Score",
          dataIndex: "score",
          key: "score",
        },
      ],
      data: financial4,
    },
    {
      title: "Cash Conversion Style",
      columns: [
        {
          title: "Indicator",
          dataIndex: "indicator",
          key: "indicator",
        },
        {
          title: "Last Year",
          dataIndex: "last year",
          key: "last_year",
        },
        {
          title: "Current Year",
          dataIndex: "current year",
          key: "current_year",
        },
        {
          title: "Projection",
          dataIndex: "projection",
          key: "projection",
        },
      ],
      data: financial5,
    },
    {
      title: "Financial Summary",
      columns: [
        {
          title: "Indicator",
          dataIndex: "indikator",
          key: "indicator",
        },
        {
          title: "Score",
          dataIndex: "score",
          key: "score",
        },
        {
          title: "Weight",
          dataIndex: "weight",
          key: "weight",
        },
        {
          title: "Weighted Score",
          dataIndex: "weighted_score",
          key: "weighted_score"
        }
      ],
      data:summary
    },
  ];

  const handleRefresh = () => {
    setRefresh(!refresh)
  }

  const postData = async (object) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(object),
    };

    const response = await fetch(process.env.REACT_APP_PYTHON_SCORING + "financial",requestOptions);
    console.log(response);
    const result = await response.json();
    console.log(result);
    return result;
  };

  const handleSubmit = (values) => {
    let object;
    for(let x in values){
      object = values[x];
    }
    object['id_debitur'] = dataMaster['id'];

    console.log(object);
    postData(object).then((response) => {
      if(response['statusCode'] == 200){
        console.log(response);
        setDisabled(true)
        success(response['message']);
        setRefresh(!refresh);
      }else{
        errorSubmit(response['message'])
      }
    }).catch((error) => {
      errorSubmit("Server Error")
      console.log(error);
    })
  }

  return (
    <div>
      <p className="text-2xl font-bold mx-5 my-5">Tenor</p>
      <Form
        // disabled={disabled}
        onFinish={(values) => handleSubmit({ values })}
        wrapperCol={{
          span: 3,
        }}
        initialValues={{
          remember: true,
        }}
      >
        <div>
          <Form.Item
            label="Tenor"
            name="tenor"
            rules={[
              {
                required: true,
                message: "Masukkan tenor",
              },
            ]}
          >
            <Select>
              {tenorOption.map((item, i) => {
                return <Option value={item.tenor}>{item.tenor}</Option>;
              })}
            </Select>
          </Form.Item>
          <Form.Item wrapperCol={{ span: 12, offset: 9 }}>
            <Button className="float-right" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </div>
      </Form>
      {table.map((item,i) => {
        return (
          <div>
            <p className="text-2xl font-bold text-center my-5">{item.title}</p>
            <Table dataSource={item.data} columns={item.columns} pagination={false}/>
          </div>
        )
      })}
      <Button onClick={handleRefresh}>Refresh</Button>
    </div>
  );
}

export default Financial