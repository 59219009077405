import { useSelector } from "react-redux";

export const FSTableData = () => {
  const idDebitur = useSelector(state => state.home.id_debitur);
  const FSView = useSelector(state => state.fs.FSView);

  const balanceStyle = (value) => {
    let color;
    if(value === "Balanced"){
      color = "green";
    }else{
      color = "red";
    }
  
    return <p style={{ color }}>{value}</p>;
  }
  
  const convertToPercentage = (num) => {
    const percent = Math.round(num * 10000) / 100;
    return percent + "%";
  }
  
  const totalRow = [
    "EBITDA", "Net Income", "Total Current Asset",
    "Total Non Current Asset", "Total Asset",
    "Total Liabilities", "Total Equity",
    "Total Liabilities & Equity",
  ];
  
  const rowClassFS = (record, index) => {
    if(record["children"]) return "font-medium";
    if(totalRow.includes(record["kategori"])) return "highlight-row child-row-no-indent";
  };
  
  const createGroupRow = (key, kategori, start, end) => {
    const result = { key, kategori };
    const filteredData = FSView?.filter((item) => {
      const id = parseInt(item.kategori_id.replace("fs", ""));
      return end ? id >= start && id <= end : id === start;
    });

    if(kategori === "Check"){
      const check = filteredData[0];
      result.lastYearNumber = check?.last_year ?? "";
      result.ytd = check?.ytd ?? "";
      result.annualized = check?.annualized ?? "";
      result.projection = check?.projection ?? "";
    }else{
      result.children = filteredData?.map((item, index) => ({
        key: `${key}-${index + 1}`,
        kategori: item.kategori,
        lastYearNumber: item.last_year,
        ytd: item.ytd,
        annualized: item.annualized,
        projection: item.projection,
        difference: item.diff
      }));
    }

    return result;
  };
    
  const dataSourceFS = FSView.some((item) => item.id_debitur === idDebitur)
    ? [
        createGroupRow("1", "Income Statement", 1, 9),
        createGroupRow("2", "Cash Flow", 10),
        createGroupRow("3", "Balance Sheet", 11, 44),
        createGroupRow("4", "Check", 45)
      ]
    : [];
  
  const colFS = [
    {
      title: "Kategori",
      dataIndex: "kategori",
      key: "kategori",
    },
    {
      title: "Last Year Number",
      dataIndex: "lastYearNumber",
      key: "lastYearNumber",
      render: (_, record) => {
        if(record["lastYearNumber"] !== undefined){
          if(record["kategori"] === "Check"){
            return balanceStyle(record["lastYearNumber"]);
          }else{
            return (
              <p>{"Rp" + Math.round(parseFloat(record["lastYearNumber"])).toLocaleString()}</p>
            )
          } 
        }
      }
    },
    {
      title: "YTD",
      dataIndex: "ytd",
      key: "ytd",
      render: (_, record) => {
        if(record["ytd"] !== undefined){
          if(record["kategori"] === "Check"){
            return balanceStyle(record["ytd"]);
          }else{
            return (
              <p>{"Rp" + Math.round(parseFloat(record["ytd"])).toLocaleString()}</p>
            )
          } 
        }
      }
    },
    {
      title: "Annualized",
      dataIndex: "annualized",
      key: "annualized",
      render: (_, record) => {
        if(record["annualized"] !== undefined){
          if(record["kategori"] === "Check"){
            return balanceStyle(record["annualized"]);
          }else{
            return (
              <p>{"Rp" + Math.round(parseFloat(record["annualized"])).toLocaleString()}</p>
            )
          } 
        }
      }
    },
    {
      title: "Projection",
      dataIndex: "projection",
      key: "projection",
      render: (_, record) => {
        if(record["projection"] !== undefined){
          if(record["kategori"] === "Check"){
            return balanceStyle(record["projection"]);
          }else{
            return (
              <p>{"Rp" + Math.round(parseFloat(record["projection"])).toLocaleString()}</p>
            )
          } 
        }
      }
    },
    {
      title: "Diff. current to previous year(%)",
      dataIndex: "difference",
      key: "difference",
      render: (_, record) => {
        if(record["difference"] !== undefined){
          return (
            <p>{record["kategori"] !== "Check" ? convertToPercentage(record["difference"]) : ""}</p>
          )
        }
      }
    },
  ];

  return { rowClassFS, dataSourceFS, colFS };
};