import React from 'react'
import { Form, Input, InputNumber, Button, message, Select, Table,Radio } from "antd";
import { useState, useEffect } from "react";
import { getAPIPython, getAPI } from "../../services/data";

const errorSubmit = (text) => {
  message.error(text);
};

const success = (text) => {
  message.success(text);
};

const content = [
  {
    label: "Score",
    name: "score",
    required: false,
  },
  {
    label: "Adjusted Weight Score",
    name: "adjusted",
    required: true,
    message: "Masukkan adjusted weight score",
  },
  {
    label: "Risk (Premium) / Discount",
    name: "riskprem",
    required: true,
    message: "Masukkan risk / premium discount",
  },
];

const postData = async (object,url) => {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(object),
  };

  const response = await fetch(
    process.env.REACT_APP_PYTHON_SCORING + url,
    requestOptions
  );
  console.log(response);
  const result = await response.json();
  console.log(result);
  return result;
};

const Scoring = (props) => {
    const {masterData,refreshData} = props;
    const dataMaster = masterData['data']
    const [form] = Form.useForm();
    const [refresh,setRefresh] = useState(false);
    const [disabled,setDisabled] = useState(false)

    // console.log("DI RESULT KATEGORI SCORING",masterData);

    const apiGetResultScore = async () => {
      const data = await getAPIPython("score_result-get?id_debitur=" + dataMaster['id']);
      // const data = await getAPIPython("score_result-get?id_debitur=0YI7uE");
      console.log(data);
      form.setFieldsValue({
        score: data['data'][0]['score']
      })
    }

    useEffect(() => {
      // console.log("USE EFFECT GET RESULT SCORE");
      apiGetResultScore()
    },[refreshData,refresh])

    const handleSubmit = async (values) => {
      delete values["score"];
      values["id_debitur"] = dataMaster["id"];
      console.log(values);

      console.log("1.RESULT V2");
      try{
        let responseResult = await postData(values,"result2");
        console.log("RESULT V2",responseResult);
        if(responseResult['statusCode'] == 200){
          success(responseResult['message'])
        }else{
          return errorSubmit(responseResult['message']);
        }
      }catch(e){
        console.log(e);
        return errorSubmit("Internal Server Error")
      }
      //V2
      let object = { id_debitur: dataMaster["id"] };
      let financialFlag = false;
      let resultFlag = false;
      let newloanFlag = false;
      let debtsizingFlag = false;

      console.log("2.NEW LOAN V2");
      try {
        let responseNewLoanV2 = await postData(object,"capabilities_new_loan_v2");
        console.log("NEW LOAN V2",responseNewLoanV2);
        if(responseNewLoanV2['statusCode'] == 200){
          success(responseNewLoanV2['message']);
        }else{
          return errorSubmit(responseNewLoanV2['message']);
        }
      } catch (error) {
        console.log(error);
        return errorSubmit('Internal Server Error !')
      }

      console.log("3. Debt Sizing V2");
      try {
        let resultDebtSizing = await postData(object,'capabilities_debt_sizing_v2');
        console.log("DEBT SIZING V2");
        if(resultDebtSizing['statusCode'] == 200){
          success(resultDebtSizing['message']);
        }else{
          return errorSubmit(resultDebtSizing['message']);
        }
      } catch (error) {
        console.log(error);
        return errorSubmit("Failed to submit data");
      }

      console.log("4. Financial V2");
      try {
        let resultFinancial = await postData(object, "financial_v2");
        console.log("Financial V2",resultFinancial);
        if(resultFinancial['statusCode'] == 200){
          success(resultFinancial['message']);
        }else{
          return errorSubmit(resultFinancial['message']);
        }
      } catch (error) {
        console.log(error);
        return errorSubmit("Internal Server Error");
      }

      console.log("5. Result V2");
      try {
        let resultv2 = await postData(object, "result_v2");
        console.log("RESULT V2",resultv2);
        if (resultv2["statusCode"] == 200) {
          success(resultv2["message"]);
        } else {
          return errorSubmit(resultv2["message"]);
        }
      } catch (error) {
        console.log(error);
        return errorSubmit("Internal Server Error");
      }
      setDisabled(true)
    };

    const handleRefresh = () => {
      setRefresh(!refresh)
    }

  return (
    <div>
      <p className="text-2xl font-bold text-center my-5">Scoring</p>
      <Form
        disabled={disabled}
        form={form}
        onFinish={(values) => handleSubmit(values)}
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 10,
        }}
        initialValues={{
          remember: true,
        }}
      >
        <div className="grid grid-cols-2">
          {content.map((item, i) => {
            return (
              <Form.Item
                key={i}
                label={item.label}
                name={item.name}
                rules={[
                  {
                    required: item.required,
                    message: item.message,
                  },
                ]}
              >
                <InputNumber
                  addonAfter={item.name == 'riskprem' ? '%' : ''}
                  style={{width: "100%" }}
                  disabled={item.label == "Score" ? "true" : ""}
                  // min={item.name == 'adjusted' ? 0 : ''}
                  min={0}
                  max={item.name == "adjusted" ? 5 : ''}
                />
              </Form.Item>
            );
          })}
        </div>
        <Form.Item wrapperCol={{ span: 12, offset: 9 }}>
          {/* <Button className="float-right mx-1" onClick={handleRefresh}>
            Refresh
          </Button> */}
          <Button className="float-right" htmlType="submit">
            Generate Score
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}

export default Scoring