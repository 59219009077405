import React from 'react'
import { Steps, Form, Input, Button, message, Select, Table, InputNumber, Alert, Typography } from "antd";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux"
import { PLTableData } from "./PLTableData";
import { fetchDropdownTypeIncomeExpense } from "../../store/actions/plSlice";

const { Title } = Typography;

const TablePLSummary = (props) => {
  const {result} = props;

  const dispatch = useDispatch();

  // Load redux state
  const idDebitur = useSelector(state => state.home.id_debitur);
  const { rowClassPL, colPL, dataSourcePL } = PLTableData();

  const apiGetDropdownKeterangan = async () =>  {
    return await dispatch(fetchDropdownTypeIncomeExpense()).unwrap();
  }

  useEffect(() => {
    apiGetDropdownKeterangan();
  },[idDebitur]);

  // const columns = [
  //   {
  //     title: "Profit and Loss",
  //     dataIndex: "keterangan",
  //     key: "keterangan",
  //     render: (_, record) => {       
  //       const applyMargin =  total.length > 0 && !Object.keys(total[0]).filter(key => key !== "id_debitur" && 
  //         key !== "year" && 
  //         key !== "profit_before_tax")
  //         .map(item => formatText(item)).includes(record["keterangan"]);  
  //       return (
  //         <p style={{ marginLeft: applyMargin ? "30px" : "" }}>{record["keterangan"]}</p>
  //       );       
  //     },
  //   },
  //   ...Object.entries(neracaTahun[0])
  //     .filter(([key]) => key.startsWith('y'))
  //     .map(([key, value], index) => ({
  //       title: convertDateFormatString(value, "MMM YYYY"),
  //       dataIndex: key,
  //       key: key,
  //       render: (_, record) => {
  //         return (
  //           <p>
  //             {(typeof record[key] === "number") ? "Rp" + record[key].toLocaleString() : ""}
  //           </p>
  //         );
  //       }
  //     })
  //   )
  // ]

  // const createIncomeExpenseRow = (segment) => {
  //   const filteredData = PLDropdown.filter(item => item.segment === segment);

  //   const sortedData = filteredData.sort((a, b) => {
  //     const aPosition = a.category.includes('-') ? 1 : 0;
  //     const bPosition = b.category.includes('-') ? 1 : 0;

  //     if(aPosition !== bPosition){
  //       return aPosition - bPosition;
  //     }

  //     return a.id.localeCompare(b.id);
  //   });

  //   const result = sortedData.map(item => {
  //     const value = {};
  //     yearKey.forEach(ykey => {
  //       value[ykey] = PLView.find(plItem =>
  //         plItem.income_expense_id === item.id &&
  //         plItem.year === yearOrder[ykey])?.value || 0;
  //     });
    
  //     return {
  //       keterangan: item.type_income_expense,
  //       ...value
  //     };
  //   });

  //   return result;
  // }

  // const createTotalRow = (propName) => {
  //   const value = {};

  //   yearKey.forEach((key) => {
  //     value[key] = total.find(item => item.year === yearOrder[key])?.[propName] || 0;
  //   })

  //   const result = {
  //     keterangan: formatText(propName),
  //     ...value
  //   };

  //   return result;
  // }

  // const createGroupRow = (segment, propName) => {
  //   const IERow = createIncomeExpenseRow(segment);
  //   const totalRow = createTotalRow(propName);

  //   return [...IERow, totalRow];
  // }

  // const summary = [...createGroupRow("GP", "gross_profit"), ...createGroupRow("OE", "operating_expense"),
  //   createTotalRow("ebitda"), ...createGroupRow("EB", "ebit"), ...createGroupRow("TO", "total_others_net"),
  //   createTotalRow("profit_before_tax"), ...createGroupRow("NP", "net_profit")
  // ];

  return (
    <div>
      <Title level={4} style={{ textAlign: "center", marginBottom: "15px" }}>Summary</Title>
      <Table
        pagination={false}
        dataSource={dataSourcePL}
        columns={colPL}
        rowClassName={rowClassPL}
      />
    </div>
  );
}

export default TablePLSummary