import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    username: '',
    password: '',
    token: ''
}

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        login: (state, action) => {
            state = {
                username: action.payload.username,
                password: action.payload.password,
                token: action.payload.token
            }
        },
        logout: () => initialState
    }
})

export const { login, logout } = authSlice.actions

export default authSlice.reducer