import React from "react";
import {message,Radio } from "antd";
import { useState, useEffect} from "react";
import { getAPIPython, getAPI } from "../services/data";

import LenderReport from "./Result/LenderReport";
import Scoring from "./Result/Scoring";

const {Group} = Radio

const content = [
  {
    label: "Lender",
    name: "Lender",
    message: "Masukkan jenis lender",
    required: true,
    radio: ["KBF", "BPR"],
  },
  {
    label: "Pefindo Report",
    name: "Pefindo_Report",
    message: "Masukkan pefindo report",
    required: true,
  },
];

const Result = (props) => {
  const {masterData} = props;
  const [refresh,setRefresh] = useState(false)

  const getRefresh = (value) => {
    setRefresh(value)
  } 

  return (
    <div>
      <LenderReport masterData={masterData} refresh={getRefresh}/>
      <Scoring masterData={masterData} refreshData={refresh}/>
    </div>
  );
};

export default Result;
