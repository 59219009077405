import React from "react";
import { Form, Input, InputNumber ,Button, message,Select,Modal } from "antd";
import { useState,useEffect } from "react";
import { getAPI } from "../../services/data";
import { useSelector } from 'react-redux'

const {Option} = Select;

const errorSubmit = (text) => {
  message.error({content:text,key:"master"});
};

const success = (text) => {
  message.success({ content: text, key:"master" });
};

const MasterForm = (props) => {
  // const { currentSteps } = props;
  const { masterData, form } = props;
  const isLoadFromAPI = useSelector(state => state.formulir.loadFromAPI);
  //useState
  const [bidangUsaha, setBidangUsaha] = useState([]);
  const [badanUsaha, setBadanUsaha] = useState([]);
  const [jenisPinjaman, setJenisPinjaman] = useState([]);
  const [paymentScheme, setPaymentScheme] = useState([]);
  const [pembayaran, setPembayaran] = useState([]);

  //APIs
  const apiGetBidangUsaha = async () => {
    const data = await getAPI("bidang-usaha");
    setBidangUsaha(data.data);
  };

  const apiGetBadanUsaha = async () => {
    const data = await getAPI("badan-usaha");
    setBadanUsaha(data.data);
  };

  const apiGetJenisPinjaman = async () => {
    const data = await getAPI("jenis-pinjaman");
    setJenisPinjaman(data.data);
  };

  const apiGetPaymentScheme = async () => {
    const data = await getAPI("payment-scheme");
    setPaymentScheme(data.data);
  };

  const apiGetPembayaran = async () => {
    const data = await getAPI("pembayaran");
    setPembayaran(data.data);
  };
  
  //Put APIs into 1 function
  const apiCall = async () => {
    apiGetBidangUsaha();
    apiGetBadanUsaha();
    apiGetJenisPinjaman();
    apiGetPaymentScheme();
    apiGetPembayaran();
  };

  useEffect(() => {
    apiCall();
  }, []);

  useEffect(() => {
    form.setFieldsValue({
      id: masterData.id,
      nama_debitur: masterData.nama_debitur,
      badan_usaha: masterData.badan_usaha,
      bidang_usaha: masterData.bidang_usaha,
      jenis_pinjaman: masterData.jenis_pinjaman,
      payment_scheme: masterData.payment_scheme,
      lama_pinjaman: masterData.lama_pinjaman,
      grace_period: masterData.grace_period,
      pembayaran: masterData.pembayaran,
    })
  }, [form, masterData]);

  //Content of each input
  const content = [
    {
      label: "TID",
      name: "id",
      message:"Masukkan transaction ID",
      required: true,
      disabled: isLoadFromAPI
    },
    {
      label: "Nama Debitur",
      name: "nama_debitur",
      message: "Masukkan nama debitur",
      required: true,
    },
    {
      label: "Badan Usaha",
      name: "badan_usaha",
      message: "Masukkan badan usaha",
      required: true,
      select_data: badanUsaha,
    },
    {
      label: "Bidang Usaha",
      name: "bidang_usaha",
      message: "Masukkan bidang usaha",
      required: true,
      select_data: bidangUsaha,
    },
    {
      label: "Jenis Pinjaman",
      name: "jenis_pinjaman",
      message: "Masukkan jenis pinjaman",
      required: true,
      select_data: jenisPinjaman,
    },
    {
      label: "Payment Scheme",
      name: "payment_scheme",
      message: "Masukkan payment scheme",
      required: true,
      select_data: paymentScheme,
    },
    {
      label: "Lama Pinjaman",
      name: "lama_pinjaman",
      message: " Masukkan lama pinjaman",
      required: true,
      numeric: true,
    },
    {
      label: "Grace Period",
      name: "grace_period",
      message: "Masukkan grace period",
      required: true,
      numeric: true,
    },
    {
      label: "Pembayaran",
      name: "pembayaran",
      message: "Masukkan pembayaran",
      required: true,
      select_data: pembayaran,
    },
  ];

  return (
    <div>
      {/* <p className="text-2xl font-bold text-center my-3">Master Form</p> */}
      {/* Form */}
        {/* Form Content */}
        <div className="grid grid-cols-3">
          {/* Loop from content object */}
          {content.map((item, i) => {
            return (
              <Form.Item
                key={i}
                label={item.label}
                name={item.name}
                rules={[
                  {
                    required: item.required,
                    message: item.message,
                  },
                ]}
              >
                {item.select_data?.length > 0 ? (
                  <Select
                    showSearch
                    placeholder="Search to Select"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children.includes(input)
                    }
                    filterSort={(optionA, optionB) =>
                      optionA.children
                        .toLowerCase()
                        .localeCompare(optionB.children.toLowerCase())
                    }
                  >
                    {item.select_data.map((item, i) => {
                      return (
                        <Option value={item.value} key={i}>
                          {item.indicator}
                        </Option>
                      );
                    })}
                  </Select>
                ) : item.numeric != null && item.numeric != false ? (
                  <InputNumber
                    min={item.name == "lama_pinjaman" ? 1 : 0}
                    style={{ width: "100%" }}
                  />
                ) : (
                  <Input 
                    maxLength={item.name == 'id' ? 15 : ""}
                    disabled={item.disabled}
                  />
                )}
              </Form.Item>
            );
          })}
        </div>
    </div>
  );
};;

export default MasterForm;
