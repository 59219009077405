import React from "react";
import Navbar from "./components/Navbar";
import Pages from "./pages/Pages";
import './styles/index.css';
import { BrowserRouter as Router } from "react-router-dom";
import { Layout } from "antd";
import { Content } from "antd/lib/layout/layout";

const App = () => {
  return (
  <Router>
    <Layout>
      <Navbar/>
      <Content style={{minHeight: "100vh"}}>
        <Pages/>
      </Content>
    </Layout>
  </Router>
  )
}

export default App