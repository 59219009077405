import React from "react";
import { Steps, Form, Input, Button, message, Select, Table, InputNumber, Typography, Alert } from "antd";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAPIPython, getAPI } from "../../services/data";
import { NeracaTableData } from "./NeracaTableData";

const { Title } = Typography;

// const emptyObject = (keterangan) => {
//   return [
//     {
//       keterangan: keterangan,
//       y1: 0,
//       y2: 0,
//       y3: 0,
//     }
//   ] 
// }

const TableNeracaSummary = (props) => {
  const {setting} = props
  // console.log(setting);
  // const [empty,setEmpty] = useState(false);  

  // Load redux state
  const { rowClassNeraca, colNeraca1, colNeraca2, dataSourceNeraca1, dataSourceNeraca2 } = NeracaTableData();

  // const boldText = (text) => <p style={{ fontWeight: 'bold' }}>{text}</p>;

  // const filter = (string) => {
  //   const filteredData = neracaSummary.filter((value) => value["kategori_description"] == string);
  //   return filteredData.map(item => ({
  //     keterangan: item.keterangan_description,
  //     y1: item.y1,
  //     y2: item.y2,
  //     y3: item.y3
  //   }));
  // }

  // const findResult = (string) => {
  //   return result.find((value) => value['indikator'] == string)
  // }

  // const nonControllingInterestFunc = () => {
  //   let object = {}
  //   let result = neracaSummary.find((value) => value['keterangan_description'] == 'Non Controlling Interests')
  //   if(result != null){
  //     object = result
  //   }else{
  //     object = {
  //       kelompok: "Liabilities and Equity",
  //       kategori: "Stockholders Equity",
  //       keterangan: "Non Controlling Interests",
  //       y1: 0,
  //       y2: 0,
  //       y3: 0
  //     }
  //   }
  //   return object;
  // }
  
  // const currentAsset = filter("Current Asset").length != 0 ? filter("Current Asset") : emptyObject("Current Asset");
  // const nonCurrentAsset = filter("Non Current Asset").length != 0 ? filter("Non Current Asset") : emptyObject("Non Current Asset");
  // const currentLiabilities = filter("Current Liabilities").length !=0 ? filter("Current Liabilities") : emptyObject("Current Liabilities");
  // const nonCurrentLiabilities = filter("Non Current Liabilities").length != 0 ? filter("Non Current Liabilities") : emptyObject("Non Current Liabilities");
  // const stockholdersEquity = filter("Stockholders Equity");
  // // const nonControllingInterest = data.find((value) => value['keterangan'] == 'Non Controlling Interests') != null ? data.find((value) => value['keterangan'] == 'Non Controlling Interests') : emptyObject("Non Controlling Interests") 
  // const nonControllingInterest = nonControllingInterestFunc();

  //Result 
  // const totalCurrentAsset = total(currentAsset);
  // const totalNonCurrentAsset = total(nonCurrentAsset);  
  // const totalAsset = calculateTotal([totalCurrentAsset, totalNonCurrentAsset]); 

  // const totalCurrentLiabilities = total(currentLiabilities);
  // const totalNonCurrentLiabilities = total(nonCurrentLiabilities);
  // const totalStockholdersEquity = total(stockholdersEquity);
  // const totalNonControllingInterest = total(nonControllingInterest);  
  // const totalLiabilities = calculateTotal([totalCurrentLiabilities, totalNonCurrentLiabilities, totalStockholdersEquity, totalNonControllingInterest]);

  // const filter = (string) => {
  //   const filteredData = neracaSummary.filter((value) => value["kategori_description"] == string);
  //   return filteredData.map(item => ({
  //     keterangan: item.keterangan_description,
  //     y1: item.y1,
  //     y2: item.y2,
  //     y3: item.y3
  //   }));
  // }

  // let currentColumn;
  // let currentColumn2;
  // if(totalAsset || totalCurrentAsset || totalNonCurrentAsset){
  // //    const assetData = neracaSummary.filter((value) => value["kelompok"] == "ke001");
  // // assetData.map(item => ({
  // //     keterangan: item.keterangan_description,
  // //     y1: item.y1,
  // //     y2: item.y2,
  // //     y3: item.y3
  // //   }));
  //   currentColumn = [
  //     {
  //       key: 1,
  //       keterangan: "Current Asset",
  //       y1: "-",
  //       y2: "-",
  //       y3: "-",
  //       children: currentAsset,
  //     },
  //     {
  //       keterangan: "TOTAL CURRENT ASSETS",
  //       y1: totalCurrentAsset["y1"],
  //       y2: totalCurrentAsset["y2"],
  //       y3: totalCurrentAsset["y3"],
  //     },
  //     {
  //       key: 2,
  //       keterangan: "Non Current Asset",
  //       y1: "-",
  //       y2: "-",
  //       y3: "-",
  //       children: nonCurrentAsset,
  //     },
  //     {
  //       keterangan: "TOTAL NON CURRENT ASSETS",
  //       y1: totalNonCurrentAsset["y1"],
  //       y2: totalNonCurrentAsset["y2"],
  //       y3: totalNonCurrentAsset["y3"],
  //     },
  //     {
  //       keterangan: boldText("TOTAL ASSETS"),
  //       y1: totalAsset["y1"],
  //       y2: totalAsset["y2"],
  //       y3: totalAsset["y3"],
  //     },
  //   ];
  // }else{
  //   currentColumn = [
  //     {
  //       key: 1,
  //       keterangan: "Current Asset",
  //       y1: "",
  //       y2: "",
  //       y3: "",
  //       children: currentAsset,
  //     },
  //     {
  //       keterangan: "TOTAL CURRENT ASSETS",
  //       y1: "-",
  //       y2: "-",
  //       y3: "-",
  //     },
  //     {
  //       key: 2,
  //       keterangan: "Non Current Asset",
  //       y1: "",
  //       y2: "",
  //       y3: "",
  //       children: nonCurrentAsset,
  //     },
  //     {
  //       keterangan: "TOTAL NON CURRENT ASSETS",
  //       y1: "-",
  //       y2: "-",
  //       y3: "-",
  //     },
  //     {
  //       keterangan: boldText("TOTAL ASSETS"),
  //       y1: "-",
  //       y2: "-",
  //       y3: "-",
  //     },
  //   ];
  // }

  // if(totalCurrentLiabilities || totalNonCurrentLiabilities || totalLiabilities || totalStockholdersEquity){
  //   currentColumn2 = [
  //     {
  //       key: 4,
  //       keterangan: "Current Liabilities",
  //       y1: "-",
  //       y2: "-",
  //       y3: "-",
  //       children: currentLiabilities,
  //     },
  //     {
  //       keterangan: "TOTAL CURRENT LIABILITIES",
  //       y1: totalCurrentLiabilities["y1"],
  //       y2: totalCurrentLiabilities["y2"],
  //       y3: totalCurrentLiabilities["y3"],
  //     },
  //     {
  //       key: 5,
  //       keterangan: "Non Current Liabilities",
  //       y1: "-",
  //       y2: "-",
  //       y3: "-",
  //       children: nonCurrentLiabilities,
  //     },
  //     {
  //       keterangan: "TOTAL NON CURRENT LIABILITIES",
  //       y1: totalNonCurrentLiabilities["y1"],
  //       y2: totalNonCurrentLiabilities["y2"],
  //       y3: totalNonCurrentLiabilities["y3"],
  //     },
  //     {
  //       key: 3,
  //       keterangan: "Stockholders Equity",
  //       y1: "-",
  //       y2: "-",
  //       y3: "-",
  //       // children: stockholdersEquity,
  //       ...(stockholdersEquity.length > 0 && { children: stockholdersEquity })
  //     },
  //     {
  //       keterangan: "TOTAL STOCKHOLDERS EQUITY",
  //       y1: totalStockholdersEquity["y1"],
  //       y2: totalStockholdersEquity["y2"],
  //       y3: totalStockholdersEquity["y3"],
  //     },
  //     {
  //       keterangan: "Non Controlling Interest",
  //       y1: nonControllingInterest["y1"],
  //       y2: nonControllingInterest["y2"],
  //       y3: nonControllingInterest["y3"],
  //     },
  //     {
  //       keterangan: boldText("TOTAL LIABILITIES & EQUITY"),
  //       y1: totalLiabilities["y1"],
  //       y2: totalLiabilities["y2"],
  //       y3: totalLiabilities["y3"],
  //     },
  //   ];
  // }else{
  //   currentColumn2 = [
  //     {
  //       key: 4,
  //       keterangan: "Current Liabilities",
  //       y1: "",
  //       y2: "",
  //       y3: "",
  //       children: currentLiabilities,
  //     },
  //     {
  //       keterangan: "TOTAL CURRENT LIABILITIES",
  //       y1: "-",
  //       y2: "-",
  //       y3: "-",
  //     },
  //     {
  //       key: 5,
  //       keterangan: "Non Current Liabilities",
  //       y1: "",
  //       y2: "",
  //       y3: "",
  //       children: nonCurrentLiabilities,
  //     },
  //     {
  //       keterangan: "TOTAL NON CURRENT LIABILITIES",
  //       y1: "-",
  //       y2: "-",
  //       y3: "-",
  //     },
  //     {
  //       key: 3,
  //       keterangan: "Stockholders Equity",
  //       y1: "",
  //       y2: "",
  //       y3: "",
  //       children: stockholdersEquity,
  //     },
  //     {
  //       keterangan: "TOTAL STOCKHOLDERS EQUITY",
  //       y1: "-",
  //       y2: "-",
  //       y3: "-",
  //     },
  //     {
  //       keterangan: "Non Controlling Interest",
  //       y1: nonControllingInterest["y1"],
  //       y2: nonControllingInterest["y2"],
  //       y3: nonControllingInterest["y3"],
  //     },
  //     {
  //       keterangan: boldText("TOTAL LIABILITIES & EQUITY"),
  //       y1: "-",
  //       y2: "-",
  //       y3: "-",
  //     },
  //   ];
  // }

  // const columns = (title) => {
  //   return [
  //     {
  //       title: title,
  //       dataIndex: "keterangan",
  //       key: "keterangan",
  //       fixed: "left"
  //     },
  //     ...Object.entries(neracaTahun[0])
  //       .filter(([key]) => key.startsWith('y'))
  //       .map(([key, value], index) => ({
  //         title: convertDateFormatString(value, "MMM YYYY"),
  //         dataIndex: key,
  //         key: key,
  //         render: (_, record) => {
  //           return (
  //             <p>
  //               {((record[key] != "" && record[key] != "-") || typeof record[key] === "number") ? "Rp" + record[key].toLocaleString() : ""}
  //             </p>
  //           );
  //         }
  //       })
  //     )           
  //   ]
  // };
  // const col1 = columns("Asset");
  // const col2 = columns("Liabilities & Equity");

  // const calculateTotalKategori = (val) => {
  //   if(Array.isArray(val)){
  //     return {
  //       y1: val.reduce((sum, item) => sum + +item?.y1, 0),
  //       y2: val.reduce((sum, item) => sum + +item?.y2, 0),
  //       y3: val.reduce((sum, item) => sum + +item?.y3, 0)
  //     }
  //   }else {
  //     return {
  //       y1: +val?.y1 || 0,
  //       y2: +val?.y2 || 0,
  //       y3: +val?.y3 || 0,
  //     }
  //   }
  // };

  // const calculateTotalKelompok = (array) => {
  //   return array.reduce((total, item) => {
  //     return {
  //       y1: total.y1 + item.y1,
  //       y2: total.y2 + item.y2,
  //       y3: total.y3 + item.y3,
  //     };
  //   }, {
  //     y1: 0,
  //     y2: 0,
  //     y3: 0,
  //   });
  // };

  // const createKeteranganRow = (key, kategori, kategoriDesc, totalDesc) => {
  //   const filteredData = neracaSummary?.filter((item) => item.kategori === kategori);

  //   const result = {
  //     key: key,
  //     keterangan: kategoriDesc,
  //     y1: "-",
  //     y2: "-",
  //     y3: "-",
  //   };

  //   if(filteredData.length > 0){
  //     result.children = filteredData.map((val) => ({
  //       id: val.keterangan,
  //       keterangan: val.keterangan_description,
  //       y1: val.y1,
  //       y2: val.y2,
  //       y3: val.y3,
  //     }))
  //     .sort((a, b) => a.id.localeCompare(b.id)) 
  //   }

  //   const totalKategori = {
  //     keterangan: `TOTAL ${totalDesc}`,
  //     ...calculateTotalKategori(filteredData)
  //   }    

  //   return [result, totalKategori];
  // }

  // const createTotalKelompok = (totalDesc, array) => { 
  //   return ({
  //     keterangan: `TOTAL ${totalDesc}`,
  //     ...calculateTotalKelompok(array)
  //   })
  // }

  // const createNonControlling = (kategori, kategoriDesc) => {
  //   const filteredData = neracaSummary?.find((item) => item.kategori === kategori);
  //   const result = {
  //     keterangan: kategoriDesc,
  //     ...calculateTotalKategori(filteredData)
  //   };

  //   return result;
  // };
  // const createKeteranganRow = useSelector(testKRow);
  // const createNonControlling = useSelector(testNonControlling);
  // const [currentAsset, totalCurrentAsset] = createKeteranganRow(1, "ka001", "Current Asset", "CURRENT ASSETS");
  // const [nonCurrentAsset, totalNonCurrentAsset] = createKeteranganRow(2, "ka002", "Non Current Asset", "NON CURRENT ASSETS");
  // const totalAssets = createTotalKelompok("ASSETS", [totalCurrentAsset, totalNonCurrentAsset]);
  // const currentColumn = [currentAsset, totalCurrentAsset, nonCurrentAsset, totalNonCurrentAsset, totalAssets];

  // const [currentLiabilities, totalCurrentLiabilities] = createKeteranganRow(3, "ka003", "Current Liabilities", "CURRENT LIABILITIES");
  // const [nonCurrentLiabilities, totalNonCurrentLiabilities] = createKeteranganRow(4, "ka004", "Non Current Liabilities", "NON CURRENT LIABILITIES");
  // const [stockholdersEquity, totalStockholdersEquity] = createKeteranganRow(5, "ka005", "Stockholders Equity", "STOCKHOLDERS EQUITY");
  // const nonControllingInterest = createNonControlling("ka006", "Non Controlling Interest");   
  // const totalLiabilities = createTotalKelompok("LIABILITIES & EQUITY", [totalCurrentLiabilities, totalNonCurrentLiabilities, totalStockholdersEquity, nonControllingInterest]);
  // const currentColumn2 = [currentLiabilities, totalCurrentLiabilities, nonCurrentLiabilities, totalNonCurrentLiabilities, 
  //   stockholdersEquity, totalStockholdersEquity, nonControllingInterest, totalLiabilities];

  return (
    <>
      <div>
        <Title level={4} style={{ textAlign: "center", marginBottom: "15px" }}>Summary</Title>
        <div className="my-5 grid grid-cols-2">
          <Table
            pagination={false}
            dataSource={dataSourceNeraca1}
            columns={colNeraca1}
            rowClassName={rowClassNeraca}
            // dataSource={currentColumn}
            // columns={col1}
          />
          <Table
            pagination={false}
            dataSource={dataSourceNeraca2}
            columns={colNeraca2}
            rowClassName={rowClassNeraca}
            // dataSource={currentColumn2}
            // columns={col2}
          />
        </div>
      </div>
    </>
  );
}

export default TableNeracaSummary