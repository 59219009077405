import { useSelector } from "react-redux";
import { convertDateFormatString } from "../../utils/util";

export const NeracaTableData = () => {
	const idDebitur = useSelector(state => state.home.id_debitur);
	const neracaTahun = useSelector(state => state.neraca.neracaTahun); 
  const neracaSummary = useSelector(state => state.neraca.neracaSummary);

	const rowClassNeraca = (record, index) => {
		if(record.keterangan === "TOTAL ASSETS" || record.keterangan === "TOTAL LIABILITIES & EQUITY")
			return "font-medium";
	};

	const columns = (title) => [
		{
			title: title,
			dataIndex: "keterangan",
			key: "keterangan",
			fixed: "left"
		},
		...Array.from({ length: 3 }, (v, i) => {
			const key = `y${i + 1}`;
			const title = (neracaTahun.length !== 0 && neracaTahun.some((item) => item.id_debitur === idDebitur)) 
				? convertDateFormatString(neracaTahun[0][key], "MMM YYYY") 
				: key;
		
			return {
				title,
				dataIndex: key,
				key,
				render: (_, record) => {
					return (
						<p>
							{((record[key] !== "" && record[key] !== "-") || typeof record[key] === "number") ? "Rp" + record[key].toLocaleString() : ""}
						</p>
					);
				}
			}
		})        
	];

	const calculateTotalKategori = (val) => {
    if(Array.isArray(val)){
      return {
        y1: val.reduce((sum, item) => sum + +item?.y1, 0),
        y2: val.reduce((sum, item) => sum + +item?.y2, 0),
        y3: val.reduce((sum, item) => sum + +item?.y3, 0)
      }
    }else {
      return {
        y1: +val?.y1 || 0,
        y2: +val?.y2 || 0,
        y3: +val?.y3 || 0,
      }
    }
  };

  const calculateTotalKelompok = (array) => {
    return array.reduce((total, item) => {
      return {
        y1: total.y1 + item.y1,
        y2: total.y2 + item.y2,
        y3: total.y3 + item.y3,
      };
    }, {
      y1: 0,
      y2: 0,
      y3: 0,
    });
  };

	const createKeteranganRow = (key, kategori, keterangan, totalDesc) => {
    const filteredData = neracaSummary?.filter((item) => item.kategori === kategori);

    const result = {
      key,
      keterangan,
      y1: "-",
      y2: "-",
      y3: "-",
    };

    if(filteredData.length > 0){
      result.children = filteredData.map((val) => ({
        id: val.keterangan,
        keterangan: val.keterangan_description,
        y1: val.y1,
        y2: val.y2,
        y3: val.y3,
      }))
      .sort((a, b) => a.id.localeCompare(b.id)) 
    }

    const totalKategori = {
      keterangan: `TOTAL ${totalDesc}`,
      ...calculateTotalKategori(filteredData)
    };   

    return [result, totalKategori];
  };

  const createTotalKelompok = (totalDesc, array) => { 
    return ({
      keterangan: `TOTAL ${totalDesc}`,
      ...calculateTotalKelompok(array)
    })
  };

  const createNonControlling = (kategori, keterangan) => {
    const filteredData = neracaSummary?.find((item) => item.kategori === kategori);
    const result = {
      keterangan,
      ...calculateTotalKategori(filteredData)
    };

    return result;
  };

	const colNeraca1 = columns("Asset");
  const colNeraca2 = columns("Liabilities & Equity");

  const [currentAsset, totalCurrentAsset] = createKeteranganRow(1, "ka001", "Current Asset", "CURRENT ASSETS");
  const [nonCurrentAsset, totalNonCurrentAsset] = createKeteranganRow(2, "ka002", "Non Current Asset", "NON CURRENT ASSETS");
  const totalAssets = createTotalKelompok("ASSETS", [totalCurrentAsset, totalNonCurrentAsset]);
  const dataSourceNeraca1 = neracaSummary.some((item) => item.id_debitur === idDebitur)
		? [
				currentAsset, totalCurrentAsset, nonCurrentAsset, 
				totalNonCurrentAsset, totalAssets
			] 
		: [];

  const [currentLiabilities, totalCurrentLiabilities] = createKeteranganRow(3, "ka003", "Current Liabilities", "CURRENT LIABILITIES");
  const [nonCurrentLiabilities, totalNonCurrentLiabilities] = createKeteranganRow(4, "ka004", "Non Current Liabilities", "NON CURRENT LIABILITIES");
  const [stockholdersEquity, totalStockholdersEquity] = createKeteranganRow(5, "ka005", "Stockholders Equity", "STOCKHOLDERS EQUITY");
  const nonControllingInterest = createNonControlling("ka006", "Non Controlling Interest");   
  const totalLiabilities = createTotalKelompok("LIABILITIES & EQUITY", [totalCurrentLiabilities, totalNonCurrentLiabilities, totalStockholdersEquity, nonControllingInterest]);
  const dataSourceNeraca2 = neracaSummary.some((item) => item.id_debitur === idDebitur) 
		? [
				currentLiabilities, totalCurrentLiabilities, nonCurrentLiabilities, totalNonCurrentLiabilities, 
				stockholdersEquity, totalStockholdersEquity, nonControllingInterest, totalLiabilities
			] 
		: [];

	return { rowClassNeraca, colNeraca1, colNeraca2, dataSourceNeraca1, dataSourceNeraca2 };
};