import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
	getAPI,
	getAPIPython,
	postAPIPython,
	postAPI,
	putAPI,
} from '../../services/data';

export const fetchInputFS = createAsyncThunk(
	'fs/fetchInputFS',
	async (idDebitur, thunkAPI) => {
		try {
			const response = await getAPI(`fs/fs-main?id_debitur=${idDebitur}`);
			// console.log("SUKSES API ", response)
			return response;
		} catch (error) {
			// console.log("GAGAL API", error)
			throw error;
		}
	}
);

export const updateInputFS = createAsyncThunk(
	'fs/updateInputFS',
	async (payload, thunkAPI) => {
		try {
			const response = await putAPI(`fs/fs-main?id_debitur=${payload.id_debitur}`, payload);
			// console.log("SUKSES API ", response)
			return response;
		} catch (error) {
			// console.log("GAGAL API", error)
			throw error;
		}
	}
);

export const fetchFS = createAsyncThunk(
	'fs/fetchFS',
	async (idDebitur, thunkAPI) => {
		try {
			const response = await getAPI(`fs/fs?id_debitur=${idDebitur}`);
			// console.log("SUKSES API ", response)
			return response;
		} catch (error) {
			// console.log("GAGAL API", error)
			throw error;
		}
	}
);

export const updateFS = createAsyncThunk(
	'fs/updateFS',
	async (idDebitur, thunkAPI) => {
		try {
			const response = await putAPI(`fs/fs?id_debitur=${idDebitur}`);
			// console.log("SUKSES API ", response)
			return response;
		} catch (error) {
			// console.log("GAGAL API", error)
			throw error;
		}
	}
);

export const fetchFSView = createAsyncThunk(
	'fs/fetchFSView',
	async (idDebitur, thunkAPI) => {
		try {
			const response = await getAPI(`fs/fs-view-data?id_debitur=${idDebitur}`);
			// console.log("SUKSES API ", response)
			return response;
		} catch (error) {
			// console.log("GAGAL API", error)
			throw error;
		}
	}
);

export const fetchFSFR = createAsyncThunk(
	'fs/fetchFSFR',
	async (idDebitur, thunkAPI) => {
		try {
			const response = await getAPI(`fs/fs-fr?id_debitur=${idDebitur}`);
			// console.log("SUKSES API ", response)
			return response;
		} catch (error) {
			// console.log("GAGAL API", error)
			throw error;
		}
	}
);

export const fetchFSCCC = createAsyncThunk(
	'fs/fetchFSCCC',
	async (idDebitur, thunkAPI) => {
		try {
			const response = await getAPI(`fs/fs-ccc?id_debitur=${idDebitur}`);
			// console.log("SUKSES API ", response)
			return response;
		} catch (error) {
			// console.log("GAGAL API", error)
			throw error;
		}
	}
);

export const fetchFSPerhitungan = createAsyncThunk(
	'fs/fetchFSPerhitungan',
	async (idDebitur, thunkAPI) => {
		try {
			const response = await getAPI(`fs/fs-perhitungan?id_debitur=${idDebitur}`);
			// console.log("SUKSES API ", response)
			return response;
		} catch (error) {
			// console.log("GAGAL API", error)
			throw error;
		}
	}
);

const initialState = {
	FSInput: [],
	FSSummary: [],
	FSView: [],
	FSFinancialRatio: [],
	FSCashConversionCycle: [], 
	FSPerhitungan: [],
	isLoadingSubmit: false,
	isFirstTime: true,
	isEditing: false,
	isNoInputNeracaPL: true
};

export const fsSlice = createSlice({
	name: 'fs',
	initialState,
	reducers: {
		setFSInput: (state, action) => {
			state.FSInput = action.payload;
		},
		setFSSummary: (state, action) => {
			state.FSSummary = action.payload;
		},
		setFSView: (state, action) => {
			state.FSView = action.payload;
		},
		setFSFinancialRatio: (state, action) => {
			state.FSFinancialRatio = action.payload;
		},
		setFSCashConversionCycle: (state, action) => {
			state.FSCashConversionCycle = action.payload;
		},
		setFSPerhitungan: (state, action) => {
			state.FSPerhitungan = action.payload;
		},
		setIsFirstTime: (state, action) => {
			state.isFirstTime = action.payload;
		},
		setEditing: (state, action) => {
			state.isEditing = action.payload;
		},
		setIsNoInputNeracaPL: (state, action) => {
			state.isNoInputNeracaPL = action.payload;
		},
		resetFS: () => initialState,
	},
	extraReducers: (builder) => {
		builder
		.addCase(fetchInputFS.fulfilled, (state, action) => {
			state.FSInput = action.payload;
		})
		.addCase(updateInputFS.pending, (state, action) => {
			state.isLoadingSubmit = true;
		})
		.addCase(updateInputFS.fulfilled, (state, action) => {
			state.isLoadingSubmit = false;
		})
		.addCase(updateInputFS.rejected, (state, action) => {
			state.isLoadingSubmit = false;
		})
		.addCase(fetchFS.fulfilled, (state, action) => {
			state.FSSummary = action.payload;
		})
		.addCase(fetchFSView.fulfilled, (state, action) => {
			state.FSView = action.payload;
		})		
		.addCase(fetchFSFR.fulfilled, (state, action) => {
			state.FSFinancialRatio = action.payload;
		})	
		.addCase(fetchFSCCC.fulfilled, (state, action) => {
			state.FSCashConversionCycle = action.payload;
		})
		.addCase(fetchFSPerhitungan.fulfilled, (state, action) => {
			state.FSPerhitungan = action.payload;
		})	
	},
});

export const {
	setFSInput,
	setFSSummary,
	setFSView,
	setFSCashConversionCycle,
	setFSFinancialRatio,
	setFSPerhitungan,
	setLoadingSubmit,
	setIsNoInputNeracaPL,
	setIsFirstTime,
	setEditing,
	resetFS
} = fsSlice.actions;

export default fsSlice.reducer;
