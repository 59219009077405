import React from "react";
import { Form, Input, InputNumber, Button, message, Select,Table,  Divider, } from "antd";
import { useState, useEffect } from "react";
// import { getAPIPython,getAPI } from "../../services/data";
import { useDispatch, useSelector } from 'react-redux';
import CustomTable from '../../components/CustomTable';
import { BouwheerTableData } from "./BouwheerTableData";
import {
  fetchDropdownCollateral,
  fetchDropdownRecovery,
  fetchCollateralTable,
  fetchCollateralToLoan,
  CreateCollateralToLoan,
  setIsFirstTime,
  setEditing,
  setSkipUpdateState,
  resetBouwheer
} from '../../store/actions/bouwheerSlice';

const { Option } = Select;

const errorSubmit = (text) => {
  message.error(text);
};

const success = (text) => {
  message.success(text);
};

const CollateralToLoan = (props) => {
  // const { currentSteps } = props;
  const {masterData} = props;
  // const dataParent = masterData['data'];

  const [form] = Form.useForm();
  const dispatch = useDispatch();

  // Load redux state
  const idDebitur = useSelector(state => state.home.id_debitur);
  const collateralView = useSelector(state => state.bouwheer.collateral);
  const dataCollateral = useSelector(state => state.bouwheer.dataCollateral);
  const isEditing = useSelector(state => state.bouwheer.isEditing);
  // const isLoadingSubmit = useSelector(state => state.bouwheer.isLoadingSubmit);
  const isFirstTime = useSelector(state => state.bouwheer.isFirstTime);
  const { colCollateralToLoan, dataSourceCollateralToLoan } = BouwheerTableData();

  //useState
  const [recovery, setRecovery] = useState([]);
  const [collateral, setCollateral] = useState([]);
  // const [collateralToLoan, setCollateralToLoan] = useState([])
  // const [get,setGet] = useState(false)
  // const [trigger, setTrigger] = useState(true);
  // const [refresh,setRefresh] = useState(true)
  // const [resultCollateral,setResultCollateral] = useState([]);

  //APIs
  // const apiGetRecovery = async () => {
  //   const data = await getAPIPython("sifat_recovery_get");
  //   const newObject = data.data;
  //   let array = [];

  //   for (let x in newObject) {
  //     let object = {
  //       value: newObject[x].id,
  //       indicator: newObject[x].sifat_recovery,
  //     };
  //     array.push(object);
  //   }
  //   // console.log(array);
  //   setRecovery(array);
  // };

  // const apiGetCollateral = async () => {
  //   const data = await getAPIPython("type_of_collateral-get");
  //   const newObject = data.data;
  //   let array = [];

  //   for (let x in newObject) {
  //     let object = {
  //       value: newObject[x].id,
  //       indicator: newObject[x].type_of_collateral,
  //     };
  //     array.push(object);
  //   }
  //   console.log(array);
  //   setCollateral(array);
  // };

  //  const apiGetCollateralToLoan = async (id) => {
  //   // const data = await getAPI("collateral-to-loan?id=" + id);
  //   const res = await getAPIPython("view_collateral_to_loan/?id_debitur=" + id)
  //   const data = res['data']

  //   console.log(data);
  //   setCollateralToLoan(data);
  //  }

  const apiGetDropdown = async () => {

    const resCollateral = await dispatch(fetchDropdownCollateral()).unwrap();
    const dropdownCollateral = resCollateral.data.map((val) => {
      return {
        value: val.id,
        indicator: val.type_of_collateral,
      }
    })
    // console.log("DROPDOWN COLLATERAL => ", dropdownCollateral)
    setCollateral(dropdownCollateral);

    const resRecovery = await dispatch(fetchDropdownRecovery()).unwrap();
    const dropdownRecovery = resRecovery.data.map((val) => {
      return {
        value: val.id,
        indicator: val.sifat_recovery,
      }
    })
    // console.log("DROPDOWN RECOVERY => ", dropdownRecovery)
    setRecovery(dropdownRecovery);

  }

  const apiGetTableView = async (id) => {

    const resCollateralView = await dispatch(fetchCollateralTable(id)).unwrap();
    const resDataCollateral = await dispatch(fetchCollateralToLoan(id)).unwrap();

    if(resDataCollateral.length !== 0){
      dispatch(setIsFirstTime(false));      
      form.setFieldsValue({
        Type_of_Collateral: resDataCollateral[0]?.type_of_collateral,
        Book_Value: resDataCollateral[0]?.book_value,
        Sifat_Recovery: resCollateralView[0]?.sifat_recovery 
      });    

      if(resDataCollateral[0]?.id_debitur !== dataCollateral[0]?.id_debitur){
        dispatch(setEditing(true));
      }
    }
  }

  // const handleRefresh = () => {
  //   console.log("Refresh Collateral to Loan");
  //   setRefresh(!refresh);
  // }

  useEffect(() => {
    apiGetDropdown();
    apiGetTableView(idDebitur);
    // console.log("BOUWHEER RENDERED")
  }, [masterData, idDebitur]);

  // useEffect(() => {
  //   apiGetRecovery();
  //   apiGetCollateral();
  //   // console.log("HERE");
  // }, [refresh]);

  // useEffect(() => {
  //   console.log("Render Tabel");
  //   if(get)apiGetCollateralToLoan(masterData.id);
  // },[trigger])

  const content = [
    {
      label: "Tipe Collateral",
      name: "Type_of_Collateral",
      message: "Masukkan tipe collateral",
      required: true,
      select_data: collateral,
    },
    {
      label: "Book Value",
      name: "Book_Value",
      message: "Masukkan book value",
      required: true,
      numeric: true,
    },
    {
      label: "Sifat Recovery",
      name: "Sifat_Recovery",
      message: "Masukkan sifat recovery",
      required: true,
      select_data: recovery,
    },
  ];

  // const columns = [
  //   {
  //     title: "Type Of Collateral",
  //     dataIndex: "type_of_collateral",
  //     key: "type_of_collateral",
  //   },
  //   {
  //     title: "Book Value",
  //     dataIndex: "book_value",
  //     key: "book_value",
  //     align: "right",
  //     render: (_, record) => {
  //       return <p>{'Rp' + record["book_value"].toLocaleString()} </p>;
  //     },
  //   },
  //   {
  //     title: "Sifat Recovery",
  //     dataIndex: "sifat_recovery",
  //     key: "sifat_recovery",
  //   },
  //   {
  //     title: "Discount",
  //     dataIndex: "discount",
  //     key: "discount",
  //     render: (_, record) => {
  //       return <p>{record["discount"] * 100 + "%"} </p>;
  //     },
  //   },
  //   {
  //     title: "Value",
  //     dataIndex: "value",
  //     key: "value",
  //     align: "right",
  //     render: (_,record) => {
  //       return <p>{'Rp' + record['value'].toLocaleString()}</p>
  //     }
  //   },
  // ];

  const handleSubmit = async (values) => {

    const payload = JSON.parse(JSON.stringify(values.values));

    try {
      const resRecovery = await dispatch(fetchDropdownRecovery()).unwrap();
      dispatch(setSkipUpdateState(true));
      const resCollateral = await dispatch(fetchCollateralToLoan(idDebitur)).unwrap();
      const { id, id_debitur, value, ...fetchedDataCollateral } = resCollateral[0] || {};
      dispatch(setSkipUpdateState(false));
      
      const inputtedData = {
        type_of_collateral: payload.Type_of_Collateral,
        book_value: payload.Book_Value,
        sifat_recovery: resRecovery.data.find((item) => item.sifat_recovery === payload.Sifat_Recovery)?.id
      };
      
      if(JSON.stringify(fetchedDataCollateral) !== JSON.stringify(inputtedData)){
        await dispatch(CreateCollateralToLoan([{
          id_debitur: masterData.id,
          type_of_collateral: payload.Type_of_Collateral,
          book_value: payload.Book_Value,
          sifat_recovery: payload.Sifat_Recovery
        }])).unwrap();
      }

      await dispatch(fetchCollateralTable(masterData.id)).unwrap();
      dispatch(setIsFirstTime(false));
      dispatch(setEditing(true));
      success("Data tersimpan");

    } catch (error) {
      
      errorSubmit("Gagal menyimpan data");

    }

  }

  // const postData = async (object) => {
  //   const requestOptions = {
  //     method: "POST",
  //     headers: { "Content-Type": "application/json" },
  //     body: JSON.stringify(object),
  //   };

  //   const response = await fetch(
  //     process.env.REACT_APP_PYTHON_SCORING + "collateral_to_loan",
  //     requestOptions
  //   );

  //   // console.log(response);
  //   const result = await response.json();
  //   console.log(result);
  //   return result;
  // };

  // const handleSubmit = (values) => {
  //   console.log(resultCollateral);
  //   let found = false
  //   let data;
  //   for (let x in values) {
  //     data = values[x];
  //   }

  //   data['Id_Debitur'] = masterData.id;

  //   resultCollateral.forEach((e) => {
  //     if(e == data['Type_of_Collateral']) found = true
  //   })

  //   if(found) return errorSubmit("Selected type of collateral cannot be more than one !")

  //   console.log(data);
  //   postData(data)
  //     .then((response) => {
  //       success("Success submit data");
  //       setTrigger(!trigger);
  //       setGet(true)
  //       setResultCollateral([...resultCollateral, data["Type_of_Collateral"]]);
  //       // currentSteps("add");
  //       console.log(response);
  //     })
  //     .catch((error) => {
  //       errorSubmit("Failed to submit data");
  //       console.log(error);
  //     });
  // };

  return (
    <div>
      {/* <p className="text-2xl font-bold text-center my-3">Collateral to loan</p> */}
      <Divider>Collateral To Loan</Divider>
      {/* Form */}
      <Form
        form={form}
        disabled={isFirstTime ? false : isEditing ? false : true}
        onFinish={(values) => handleSubmit({ values })}
        name="basic"
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 12,
        }}
        initialValues={{
          remember: true,
        }}
      >
        {/* Form Content */}
        <div className="grid grid-cols-3">
          {content.map((item, i) => {
            return (
              <Form.Item
                key={i}
                label={item.label}
                name={item.name}
                rules={[
                  {
                    required: item.required,
                    message: item.message,
                  },
                ]}
              >
                {item.select_data?.length > 0 ? (
                  <Select
                    showSearch
                    placeholder="Search to Select"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children.includes(input)
                    }
                    filterSort={(optionA, optionB) =>
                      optionA.children
                        .toLowerCase()
                        .localeCompare(optionB.children.toLowerCase())
                    }
                  >
                    {item.select_data.map((text, i) => {
                      if (item.name == "Sifat_Recovery") {
                        return (
                          <Option value={text.indicator} key={i}>
                            {text.indicator}
                          </Option>
                        );
                      } else {
                        return (
                          <Option value={text.value} key={i}>
                            {text.indicator}
                          </Option>
                        );
                      }
                    })}
                  </Select>
                ) : item.numeric != null && item.numeric != false ? (
                  <InputNumber
                    min={0}
                    style={{ width: "100%" }}
                    addonBefore={item.name == "Book_Value" ? "Rp" : ""}
                    formatter={
                      item.name == "Book_Value"
                        ? (value) =>
                            `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        : ""
                    }
                  />
                ) : (
                  <Input />
                )}
              </Form.Item>
            );
          })}
        </div>
        {/* Button Submit */}
        <Form.Item wrapperCol={{ span: 12, offset: 9 }}>
          <Button className="float-right" htmlType="submit" type="secondary">
            Save
          </Button>
          {/* <Button className="float-right mx-2" onClick={handleRefresh}>
            Refresh
          </Button> */}
        </Form.Item>
      </Form>
      {/* <Table dataSource={collateralToLoan} columns={columns} pagination={false}/> */}
      <CustomTable 
        dataSource={dataSourceCollateralToLoan}
        columns={colCollateralToLoan}
        // dataSource={collateralView}
        // columns={columns}
        bordered
      />
    </div>
  );
};

export default CollateralToLoan;
