import React from "react";
import { Form, Button, message, Select, DatePicker, InputNumber, Alert, Table, Input, Radio, Space } from "antd";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAPIPython, getAPI } from "../services/data";
import { fetchNeracaParameter, fetchNeracaTahun } from "../store/actions/neracaSlice";
import { fetchInputProfitLoss } from "../store/actions/plSlice";
import TableFS from "./FS/TableFS";
import {
  fetchFS,
  fetchFSPerhitungan,
  fetchFSView,
  fetchInputFS,
  setEditing,
  setIsFirstTime,
  setIsNoInputNeracaPL,
  updateFS,
  updateInputFS
} from "../store/actions/fsSlice";

// const { Option } = Select;
const { Group } = Radio;

const dropdownLaporanKeuangan = [
  { value: "lk001", indicator: "Audited" },
  { value: "lk002", indicator: "Tidak Audited" },
];

const dropdownAkurasiLaporanKeuangan = [
  { value: "ak001", indicator: "Akurat" },
  { value: "ak002", indicator: "Tidak Akurat" },
];

const dropdownPeriodeTerakhir = [
  { value: "pp001", indicator: "Updated" },
  { value: "pp002", indicator: "Tidak Updated" },
];

const dropdownSPT = [
  { value: "sp001", indicator: "Mempunyai SPT" },
  { value: "sp002", indicator: "Tidak Mempunyai SPT" },
];

const errorSubmit = (text) => {
  message.error(text);
};

const success = (text) => {
  message.success(text);
};

const postData = async (object) => {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(object),
  };

  const response = await fetch(
    process.env.REACT_APP_PYTHON_SCORING + "financialstatement",
    requestOptions
  );
  const result = await response.json();
  return result;
};

const FS = (props) => {
  const { masterData } = props;
  // const dataMaster = masterData['data'];
  // console.log("INI DI FS", dataMaster);
  const [form] = Form.useForm();

  // const [years,setYears] = useState([]);
  // const [result,setResult] = useState([]);
  const [view, setView] = useState(false);

  const dispatch = useDispatch();

  // Load redux state
  const idDebitur = useSelector(state => state.home.id_debitur);
  const neracaTahun = useSelector(state => state.neraca.neracaTahun);

  const isEditing = useSelector(state => state.fs.isEditing);
  const isFirstTime = useSelector(state => state.fs.isFirstTime);
  const isLoadingSubmit = useSelector(state => state.fs.isLoadingSubmit);
  const isNoInputNeracaPL = useSelector(state => state.fs.isNoInputNeracaPL);

  // const apiGetTahun = async (id) => {
  //   const data = await getAPIPython("fs_get_tahun?id_debitur=" + id);
  //   const result = data['data'];

  //   form.setFieldsValue({
  //     tahunkedua: result[0]['tahunkedua'],
  //     tahunytd: result[0]['tahunketiga']
  //   });
  // }

  // useEffect(() => {
  //   if(dataMaster) apiGetTahun(dataMaster['id']);
  // },[])

  // const viewData = async (id) => {
  //   const data = await getAPIPython("fs_get?id_debitur=" + id)
  //   console.log(data);
  //   console.log("ID",id);
  //   if(data['statusCode'] == 200 && data['data'].length != 0){
  //     setResult(data['data']);
  //     setView(true)
  //   }else{
  //     errorSubmit("No Data Found")
  //   }
  // }

  // const handleSubmit = (values) => {
  //   let object;
  //   let found = false;
  //   for(let x in values){
  //       object = values[x]
  //   }

  //   console.log(object);

  //   object["id_debitur"] = dataMaster['id']
  //   postData(object).then((response) => {
  //     console.log(response);
  //       if(response.statusCode == 200){
  //           success(response['message']);
  //       }else{
  //           errorSubmit(response['message'])
  //       }
  //   }).catch((error) => {
  //       console.log(error);
  //       errorSubmit('Failed submit data');
  //   })
  // }

  // const columns = [
  //   {
  //     title: "Kategori",
  //     dataIndex: "kategori",
  //     render: (_, record) => {
  //       let flag = false;
  //       if (
  //         record["kategori"] == "EBITDA" ||
  //         record["kategori"] == "Net Income" ||
  //         record["kategori"] == "Total Current Asset" ||
  //         record["kategori"] == "Total Fixed Asset" ||
  //         record["kategori"] == "Total Asset" ||
  //         record["kategori"] == "Total Liabilities" ||
  //         record["kategori"] == "Total Equity" ||
  //         record["kategori"] == "Total Liabilities & Equity"
  //       ) {
  //         flag = true;
  //       }
  //       return <p className={flag ? "font-bold" : ""}>{record["kategori"]}</p>;
  //     },
  //   },
  //   {
  //     title: "Last Year Number",
  //     dataIndex: "Last Year Number",
  //     align: "right",
  //     render: (_, record) => {
  //       let color;
  //       if (record["Last Year Number"] == "Not Balanced") {
  //         color = "text-red-700";
  //       } else if (record["Last Year Number"] == "Balanced") {
  //         color = "text-green-700";
  //       }
  //       return (
  //         <div>
  //           <p className={`${color}`}>
  //             {isNaN(record["Last Year Number"])
  //               ? record["Last Year Number"]
  //               : "Rp" + record["Last Year Number"].toLocaleString()}
  //           </p>
  //         </div>
  //       );
  //     },
  //   },
  //   {
  //     title: "YTD",
  //     dataIndex: "YTD",
  //     align: "right",
  //     render: (_, record) => {
  //       let color;
  //       if (record["YTD"] == "Not Balanced") {
  //         color = "text-red-700";
  //       } else if (record["YTD"] == "Balanced") {
  //         color = "text-green-700";
  //       }
  //       return (
  //         <div>
  //           <p className={`${color}`}>
  //             {isNaN(record["YTD"])
  //               ? record["YTD"]
  //               : "Rp" + record["YTD"].toLocaleString()}
  //           </p>
  //         </div>
  //       );
  //     },
  //   },
  //   {
  //     title: "Annualized",
  //     dataIndex: "Annualized",
  //     align: "right",
  //     render: (_, record) => {
  //       let color;
  //       if (record["Annualized"] == "Not Balanced") {
  //         color = "text-red-700";
  //       } else if (record["Annualized"] == "Balanced") {
  //         color = "text-green-700";
  //       }
  //       return (
  //         <div>
  //           <p className={`${color}`}>
  //             {isNaN(record["Annualized"])
  //               ? record["Annualized"]
  //               : "Rp" + record["Annualized"].toLocaleString()}
  //           </p>
  //         </div>
  //       );
  //     },
  //   },
  //   {
  //     title: "Projection",
  //     dataIndex: "Projection",
  //     align: "right",
  //     render: (_, record) => {
  //       let color;
  //       if (record["Projection"] == "Not Balanced") {
  //         color = "text-red-700";
  //       } else if (record["Projection"] == "Balanced") {
  //         color = "text-green-700";
  //       }
  //       return (
  //         <div>
  //           <p className={`${color}`}>
  //             {isNaN(record["Projection"])
  //               ? record["Projection"]
  //               : "Rp" + record["Projection"].toLocaleString()}
  //           </p>
  //         </div>
  //       );
  //     },
  //   },
  //   {
  //     title: "Diff current to previous year(%)",
  //     dataIndex: "Diff current to previous year",
  //     align: "right",
  //     render: (_, record) => (
  //       <>
  //         <div>
  //           {/* <p>{record["Diff current to previous year"] * 100 + "%"}</p> */}
  //           <p>
  //             {isNaN(record["Diff current to previous year"])
  //               ? record["Diff current to previous year"]
  //               : record["Diff current to previous year"] * 100 + "%"}
  //           </p>
  //         </div>
  //       </>
  //     ),
  //   },
  // ];

  const content = [
    {
      title: "Financial Reporting Quality",
      type: "radio",
      data: [
        {
          label: "Laporan Keuangan",
          name: "laporankeuangan",
          message: "Masukkan status laporan keuangan",
          required: true,
          radio: dropdownLaporanKeuangan,
        },
        {
          label: "Akurasi Laporan Keuangan",
          name: "akurasi",
          message: "Masukkan status akurasi laporan keuangan",
          required: true,
          radio: dropdownAkurasiLaporanKeuangan,
        },
        {
          label: "Periode Terakhir",
          name: "periodeterakhir",
          message: "Masukkan periode terakhir",
          required: true,
          radio: dropdownPeriodeTerakhir,
        },
        {
          label: "SPT",
          name: "spt",
          message: "Masukkan spt",
          required: true,
          radio: dropdownSPT,
        },
      ],
    },
    {
      title: "Others",
      data: [
        {
          label: "Tahun Laporan Keuangan Tahun Sebelumnya",
          name: "tahunkedua",
          message: "Masukkan tahun laporan keuangan tahun sebelumnya",
          type: "date",
          required: true,
        },
        {
          label: "Tahun Laporan Keuangan Tahun YTD",
          name: "tahunytd",
          message: "Masukkan tahun laporan keuangan tahun YTD",
          type: "date",
          required: true,
        },
        {
          label: "Periode Laporan Keuangan Tahun Sebelumnya",
          name: "periodekedua",
          message: "Masukkan periode laporan keuangan tahun sebelumnya",
          type: "date",
          required: true,
        },
        {
          label: "Periode Laporan Keuangan Tahun YTD",
          name: "periodeytd",
          message: "Masukkan periode laporan keuangan tahun YTD",
          type: "date",
          required: true,
        },
        {
          label: "Est. Increase Future Annualized",
          name: "estannualized",
          message: "Masukkan estimate increase future annualized",
          type: "percent",
          required: true,
        },
        {
          label: "Est. Increase Future Projection",
          name: "estprojection",
          message: "Masukkan estimate increase future projection",
          type: "percent",
          required: true,
        },
        {
          label: "Office Equipment Tahun Sebelumnya",
          name: "officekedua",
          message: "Masukkan office equipment tahun sebelumnya",
          type: "currency",
          required: true,
        },
        {
          label: "Office Equipment YTD",
          name: "officeketiga",
          message: "Masukkan office equipment YTD",
          type: "currency",
          required: true,
        },
        {
          label: "IKI Loan Tahun Sebelumnya",
          name: "ikiloan2",
          message: "Masukkan IKI Loan tahun sebelumnya",
          type: "currency",
          required: true,
        },
        {
          label: "IKI Loan YTD",
          name: "ikiloan3",
          message: "Masukkan IKI Loan YTD",
          type: "currency",
          required: true,
        },
        {
          label: "IKI Loan Annualized",
          name: "ikiloan_an",
          message: "Masukkan loan annualized",
          type: "currency",
          required: true,
        },
        {
          label: "IKI Loan Projection",
          name: "ikiloan_ytd",
          message: "Masukkan IKI loan projection",
          type: "currency",
          required: true,
        }
      ],
    },
  ];

  const apiGetNeracaTahunInputFS = async (id) => {
    const resNeracaTahun = await dispatch(fetchNeracaTahun(id)).unwrap();
    if(resNeracaTahun.length !== 0){
      const resNeracaInput = await dispatch(fetchNeracaParameter(id)).unwrap();
      const resPLInput = await dispatch(fetchInputProfitLoss(id)).unwrap();
      
      if(
        (resNeracaInput.length !== 0 && resNeracaInput.some((item) => item.year !== resNeracaTahun[0]?.y2 || item.year !== resNeracaTahun[0]?.y3)) &&
        (resPLInput.length !== 0 && resPLInput.some((item) => item.year !== resNeracaTahun[0]?.y2 || item.year !== resNeracaTahun[0]?.y3))
      ){
        if((isFirstTime && !isEditing) || neracaTahun.some((item) => item.id_debitur !== id)){
          dispatch(setEditing(true));
        }
        dispatch(setIsNoInputNeracaPL(false));
  
        const {y2, y3} = resNeracaTahun[0];
        const [t2, p2] = y2.split("-");
        const [t3, p3] = y3.split("-");
  
        form.setFieldsValue({
          tahunkedua: +t2,
          tahunytd: +t3,
          periodekedua: +p2,
          periodeytd: +p3,
        });

        const resFSInput = await dispatch(fetchInputFS(id)).unwrap();
        if(resFSInput.statusCode === "000"){
          dispatch(setIsFirstTime(true));
          form.setFieldsValue({
            estannualized: 100,
            estprojection: 100,
            officekedua: 0,
            officeketiga: 0,
            ikiloan2: 0,
            ikiloan3: 0,
            ikiloan_an: 0,
            ikiloan_ytd: 0,
          });
        }else{
          dispatch(setIsFirstTime(false));
          form.setFieldsValue({
            laporankeuangan: resFSInput.laporan_keuangan,
            akurasi: resFSInput.akurasi,
            periodeterakhir: resFSInput.periode_terakhir,
            spt: resFSInput.spt,
            estannualized: resFSInput.est_annualized * 100,
            estprojection: resFSInput.est_proj * 100,
            officekedua: resFSInput.office_equipment_y2,
            officeketiga: resFSInput.office_equipment_y3,
            ikiloan2: resFSInput.iki_loan_y2,
            ikiloan3: resFSInput.iki_loan_y3,
            ikiloan_an: resFSInput.iki_loan_annualized,
            ikiloan_ytd: resFSInput.iki_loan_projection,
          });
        }
      }else{
        dispatch(setIsFirstTime(true));
        dispatch(setIsNoInputNeracaPL(true));
        dispatch(setEditing(false));
      }
    }else{
      dispatch(setIsNoInputNeracaPL(true));
      dispatch(setEditing(false));
    }
  };

  useEffect(() => {
    if(idDebitur){
      apiGetNeracaTahunInputFS(idDebitur);
    }
    // console.log("FS RENDERED");
  }, [masterData, idDebitur]);

  const handleSubmit = async (values) => {
    try {      
      const resNeracaInput = await dispatch(fetchNeracaParameter(idDebitur)).unwrap();
      const resPLInput = await dispatch(fetchInputProfitLoss(idDebitur)).unwrap();
      
      if(resNeracaInput.length !== 0 && resPLInput.length !== 0){        
        const input = JSON.parse(JSON.stringify(values.values));
        const payload = {
          id_debitur: idDebitur,
          laporan_keuangan: input.laporankeuangan,
          akurasi: input.akurasi,
          periode_terakhir: input.periodeterakhir,
          spt: input.spt,
          tahun2: input.tahunkedua,
          tahun3: input.tahunytd,
          periode2: input.periodekedua,
          periode3: input.periodeytd,
          periode_annualized: 12,
          periode_proj: 12,
          est_annualized: input.estannualized,
          est_proj: input.estprojection,
          office_equipment_y2: input.officekedua,
          office_equipment_y3: input.officeketiga,
          iki_loan_y2: input.ikiloan2,
          iki_loan_y3: input.ikiloan3,
          iki_loan_annualized: input.ikiloan_an,
          iki_loan_projection: input.ikiloan_ytd,
        };

        const resFSInput = await dispatch(fetchInputFS(idDebitur)).unwrap();
        const { id, ...existingInput } = resFSInput;
        if(!resFSInput.statusCode){
          existingInput.est_annualized *= 100;
          existingInput.est_proj *= 100;
        }

        const othersFSData = [
          "tahun2", "tahun3", "periode2", "periode3",
          "est_annualized", "est_proj", "iki_loan_annualized", "iki_loan_projection"
        ];
        let isChangingDataFSTable = false;

        if(JSON.stringify(existingInput) !== JSON.stringify(payload)){
          isChangingDataFSTable = othersFSData.some((key) => {
            return existingInput[key] !== payload[key];
          });
          await dispatch(updateInputFS(payload)).unwrap();
        }  

        const resFS = await dispatch(fetchFS(idDebitur)).unwrap();
        const resFSPerhitungan = await dispatch(fetchFSPerhitungan(idDebitur)).unwrap();
        const [fsperiodekedua, fsperiodeketiga] = resFSPerhitungan;

        isChangingDataFSTable = (resFS.length === 0) || resFS.some((item) => {
          const kategori = item.kategori;
          return fsperiodekedua[kategori] !== item.last_year_number || fsperiodeketiga[kategori] !== item.year_ytd;
        });

        if(isChangingDataFSTable){
          // update FS, maka otomatis update FS FR dan FS CCC
          await dispatch(updateFS(idDebitur)).unwrap(); 
        }

        const resFSView = await dispatch(fetchFSView(idDebitur)).unwrap();
        if(resFSView.length !== 0){
          dispatch(setIsFirstTime(false));
          dispatch(setEditing(false));
          success("Sukses submit sheet: [Financial Statement]");
          setView(true);
        }else{
          return errorSubmit("Gagal menyimpan data");
        }
      }else{
        return errorSubmit("Gagal menyimpan data");
      }     
    } catch (error) {
      errorSubmit("Gagal menyimpan data");
    }
  };

  const isFieldDisabled = isNoInputNeracaPL ? true : isFirstTime ? false : isEditing ? false : true;

  return (
    <div>
      <Form
        form={form}
        onFinish={(values) => handleSubmit({ values })}
        // disabled={isFieldDisabled}        
        labelCol={{
          span: 12,
        }}
        wrapperCol={{
          span: 9,
        }}
      >
        {content.map((item, i) => {
          return (
            <div key={i}>
              <p className="text-2xl font-bold text-center my-3">
                {item.title}
              </p>
              <div className="grid grid-cols-2">
                {item.data.map((object, index) => {
                  return (
                    <Form.Item
                      key={index}
                      label={object.label}
                      name={object.name}
                      rules={[
                        {
                          required: object.required,
                          message: object.message,
                        },
                      ]}
                    >
                      {item.type === "radio" ? (
                        <Group disabled={isFieldDisabled}>
                          {object.radio.map((option, i) => {
                            return (
                              <Radio value={option.value}>
                                {option.indicator}
                              </Radio>
                            );
                          })}
                        </Group>
                      ) : (
                        <InputNumber
                          style={{ width: "90%" }}
                          disabled={object.type === "date" || isFieldDisabled}
                          addonAfter={object.type === "percent" ? "%" : ""}
                          addonBefore={object.type === "currency" ? "Rp" : ""}
                          formatter={
                            object.type === "currency"
                              ? (value) =>
                                  `${value}`.replace(
                                    /\B(?=(\d{3})+(?!\d))/g,
                                    ","
                                  )
                              : ""
                          }
                        />
                      )}
                    </Form.Item>
                  );
                })}
              </div>
            </div>
          );
        })}
        <div className="py-5">
          <Space
            direction="horizontal"
            style={{ display: "flex", float: "right", width: "25%" }}
          >
            <Button
              htmlType="button" onClick={() => dispatch(setEditing(true))}
              disabled={isNoInputNeracaPL ? true : isFirstTime ? true : isEditing}
            >
              Edit
            </Button>
            <Button
              htmlType="submit" type="primary" danger loading={isLoadingSubmit}
              className="float-right mx-auto" disabled={isEditing ? false : true}
            >
              Submit Sheet
            </Button>
          </Space>
        </div>
      </Form>
      {/* <Form.Item wrapperCol={{ span: 12, offset: 6 }}>
          <Button
            className="float-right"
            htmlType="submit"
            // onClick={() => setTrigger(!trigger)}
            // loading={spinner}
          >
            Submit
          </Button>
        </Form.Item>  */}
      {/* <Button className="my-3" onClick={() => viewData(dataMaster["id"])}>
        View Data
      </Button> */}
      {view && (
        <TableFS />
        // <div>
        //   <Table
        //     pagination={false}
        //     dataSource={result}
        //     columns={columns}
        //     rowClassName={(record, index) => {
        //       if (
        //         record["kategori"] == "EBITDA" ||
        //         record["kategori"] == "Net Income" ||
        //         record["kategori"] == "Total Current Asset" ||
        //         record["kategori"] == "Total Fixed Asset" ||
        //         record["kategori"] == "Total Asset" ||
        //         record["kategori"] == "Total Liabilities" ||
        //         record["kategori"] == "Total Equity" ||
        //         record["kategori"] == "Total Liabilities & Equity"
        //       ) {
        //         return "bg-zinc-100";
        //       }
        //     }}
        //   />
        // </div>
      )}
    </div>
  );
};

export default FS;