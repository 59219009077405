import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { 
    getAPI, 
    putAPI,
    getAPIPython,
    postAPIPython,
} from '../../services/data'

export const fetchDropdownIssue = createAsyncThunk(
    'industryRisk/fetchDropdownIssue',
    async (_, thunkAPI) => {
        try {
            const response = await getAPIPython(`industryrisk-get`);
            // console.log("SUKSES API", response)
            return response.data;
        } catch (error) {
            // console.log("GAGAL API", error)
            throw error
        }
    }
)

export const fetchBidangUsaha = createAsyncThunk(
    'industryRisk/fetchBidangUsaha',
    async (bidangUsaha, thunkAPI) => {
        try {
            const response = await getAPI(`bidang-usaha`);
            // console.log("SUKSES API", response)
            const currentBidangUsaha = response.data.find((value) => value['value'] == bidangUsaha);

            return currentBidangUsaha;
        } catch (error) {
            // console.log("GAGAL API", error)
            throw error
        }
    }
)

// export const fetchSummary = createAsyncThunk(
//     'industryRisk/fetchSummary',
//     async (idDebitur, thunkAPI) => {
//         try {
//             const response = await getAPIPython(`summary_industry_risk?id_debitur=${idDebitur}`);
//             // console.log("SUKSES API", response)
//             return response.data;
//         } catch (error) {
//             // console.log("GAGAL API", error)
//             throw error
//         }
//     }
// )

export const fetchSummary = createAsyncThunk(
    'industryRisk/fetchSummary',
    async (idDebitur, thunkAPI) => {
        try {
            const response = await getAPI(`industry-risk/industry-risk-summary?id_debitur=${idDebitur}`);
            // console.log("SUKSES API", response)
            return response;
        } catch (error) {
            // console.log("GAGAL API", error)
            throw error
        }
    }
)

export const fetchIndustryRiskParam = createAsyncThunk(
    'industryRisk/fetchIndustryRiskParam',
    async (idDebitur, thunkAPI) => {
        try {
            const response = await getAPI(`industry-risk/industry-risk-parameter?id_debitur=${idDebitur}`);
            // console.log("SUKSES API", response)
            return response;
        } catch (error) {
            // console.log("GAGAL API", error)
            throw error
        }
    }
)

export const createIndustryRisk = createAsyncThunk(
    'industryRisk/createIndustryRisk',
    async (payload, thunkAPI) => {
        try {
            const response = await putAPI(`industry-risk/industry-risk-parameter?id_debitur=${payload.id_debitur}`, payload);
            // console.log("SUKSES API", response)
            return response;
        } catch (error) {
            // console.log("GAGAL API", error)
            throw error
        }
    }
)

const initialState = {
    summary: [],
    total: {},
    idDebiturIR: null,
    isLoadingSubmit: false,
    isFirstTime: true,
    isEditing: false
}

export const industryRiskSlice = createSlice({
    name: 'industryRisk',
    initialState,
    reducers: {
        setSummary: (state, action) => {
            state.summary = action.payload
        },
        setTotal: (state, action) => {
            state.total = action.payload
        },
        setLoadingSubmit: (state, action) => {
            state.isLoadingSubmit = action.payload
        },
        setEditing: (state, action) => {
            state.isEditing = action.payload
        },
        setIsFirstTime: (state, action) => {
            state.isFirstTime = action.payload
        },
        resetIndustryRisk: () => initialState
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchSummary.fulfilled, (state, action) => {
                state.idDebiturIR = action.meta.arg;
                state.summary = action.payload;
            })
            .addCase(createIndustryRisk.pending, (state, action) => {
                state.isLoadingSubmit = true
            })
            .addCase(createIndustryRisk.fulfilled, (state, action) => {
                state.isLoadingSubmit = false
            })
            .addCase(createIndustryRisk.rejected, (state, action) => {
                state.isLoadingSubmit = false
            })
    }
})

export const { 
    setSummary,
    setTotal,
    setLoadingSubmit,
    setEditing,
    setIsFirstTime,
    resetIndustryRisk,
    // setLoadFromAPI
} = industryRiskSlice.actions

export default industryRiskSlice.reducer