import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { 
    getAPI, 
    putAPI,
    getAPIPython,
    postAPIPython,
} from '../../services/data'
import { 
    dataIdJp, 
    dataMain, 
    dataFinancial, 
    dataCollateral, 
    dataDebtor, 
    dataIndustry, 
    dataDscr
} from "../../pages/Assumption/AssumptionData";

export const fetchInputedAssumptions = createAsyncThunk(
    'assumption/fetchInputedAssumptions',
    async (idDebitur, thunkAPI) => {
        const resMain = await getAPI(`assumption/weighted-mainscoring?id_debitur=${idDebitur}`);
        const resFinancial = await getAPI(`assumption/weighted-financial?id_debitur=${idDebitur}`);
        const resDebtor = await getAPI(`assumption/weighted-debtor-profile?id_debitur=${idDebitur}`);
        const resCollateral = await getAPI(`assumption/weighted-collateral?id_debitur=${idDebitur}`);
        const resIndustry = await getAPI(`assumption/weighted-industry-risk?id_debitur=${idDebitur}`);
        const resDscr = await getAPI(`assumption/assumption?id_debitur=${idDebitur}`);
        
        const response = {
            ...resMain[0],
            ...resDebtor[0],
            ...resFinancial[0],
            ...resCollateral[0],
            ...resIndustry[0],
            ...resDscr[0]
        };
        delete response.id;
        return response;
    }
)

export const fetchDefaultAssumptions = createAsyncThunk(
    'assumption/fetchDefaultAssumptions',
    async (dataMaster, thunkAPI) => {
        const res = await getAPIPython("assumption-get");
        const data = res.data;

        let defaultAssumptions = data.find(
            ({ id }) => id == dataMaster
        );

        const response = {
            "financial": defaultAssumptions.financial,
            "collateral": defaultAssumptions.collateral,
            "debtor_profile": defaultAssumptions["debtor profile"],
            "industry_risk": defaultAssumptions["industry risk"],
            "dscr_historical": defaultAssumptions["dscr historical"],
            "dscr_current": defaultAssumptions["dscr current"],
            "dscr_projection": defaultAssumptions["dscr projection"],
            "debt_to_equity": defaultAssumptions["debt to equity"],
            "kualitas_dari_financial_reporting": defaultAssumptions["kualitas dari financial reporting"],
            "financial_trends": defaultAssumptions["financial_trends"],
            "ccc_last_year": defaultAssumptions["ccc last year"],
            "ccc_current_year": defaultAssumptions["ccc current year"],
            "ccc_projection": defaultAssumptions["ccc projection"],
            "tenor": defaultAssumptions["tenor"],
            "type_of_obligor": defaultAssumptions["type of obligor"],
            "type_of_collateral": defaultAssumptions["type of collateral"],
            "skill_and_tenure": defaultAssumptions["skill and tenure"],
            "reliability": defaultAssumptions["reliability"],
            "dependency_to_co": defaultAssumptions["dependency to co"],
            "historical_profile": defaultAssumptions["historical profile"],
            "repeat_borrower": defaultAssumptions["repeat borrower"],
            "industryrisk": defaultAssumptions["industry risk 1"],
            "issues_evaluation_and_insurance": defaultAssumptions["issues, evaluation, and insurance"],
            "regulatory_impacts": defaultAssumptions["regulatory impacts"],
            "substitution_of_product": defaultAssumptions["substitution of product"],
            "substitution_of_raw_material": defaultAssumptions["substitution of raw material"],
            "forex_risk": defaultAssumptions["forex risk"],
            "secured_minimum_dscr": defaultAssumptions["secured minimum dscr"],
            "unsecured_minimum_dscr": defaultAssumptions["unsecured minimum dscr"],
            "origination_fee": defaultAssumptions["origination fee"]
        }
        
        return response;
    }
)

export const createAssumptions = createAsyncThunk(
    'assumption/createAssumptions',
    async (payload, thunkAPI) => {

        try {
            const response = await postAPIPython("assumption", payload);
            // console.log("SUKSES API", response)
            return response;
        } catch (error) {
            // console.log("GAGAL API", error)
            throw error
        }
    }
)

export const updateAssumptions = createAsyncThunk(
    'assumption/updateAssumptions',
    async (payload, thunkAPI) => {

        // let 
        //     isError = false,
        //     errorSegment;
        const resMain = await putAPI(`assumption/weighted-mainscoring?id_debitur=${payload.id_debitur}`, {
            ...dataIdJp(payload), 
            ...dataMain(payload)
        }).catch((error) => {
            console.error("GAGAL UPDATE MAIN")
            throw error;
        });
        const resFinancial = await putAPI(`assumption/weighted-financial?id_debitur=${payload.id_debitur}`, {
            ...dataIdJp(payload),
            ...dataFinancial(payload)
        }).catch((error) => {
            console.error("GAGAL UPDATE FINANCIAL")
            throw error;
        });
        const resCollateral = await putAPI(`assumption/weighted-collateral?id_debitur=${payload.id_debitur}`, {
            ...dataIdJp(payload),
            ...dataCollateral(payload)
        }).catch((error) => {
            console.error("GAGAL UPDATE COLLATERAL")
            throw error;
        });
        const resDebtor = await putAPI(`assumption/weighted-debtor-profile?id_debitur=${payload.id_debitur}`, {
            ...dataIdJp(payload),
            ...dataDebtor(payload)
        }).catch((error) => {
            console.error("GAGAL UPDATE DEBTOR")
            throw error;
        });
        const resIndustry = await putAPI(`assumption/weighted-industry-risk?id_debitur=${payload.id_debitur}`, {
            ...dataIdJp(payload),
            ...dataIndustry(payload)
        }).catch((error) => {
            console.error("GAGAL UPDATE INDUSTRY")
            throw error;
        });
        const resDscr = await putAPI(`assumption/assumption?id_debitur=${payload.id_debitur}`, {
            ...dataIdJp(payload),
            ...dataDscr(payload)
        }).catch((error) => {
            console.error("GAGAL UPDATE DSCR")
            throw error;
        });

        console.log("UPDATE MAIN => ", resMain);
        console.log("UPDATE FINANCIAL => ", resFinancial);
        console.log("UPDATE COLLATERAL => ", resCollateral);
        console.log("UPDATE DEBTOR => ", resDebtor);
        console.log("UPDATE INDUSTRY => ", resIndustry);
        console.log("UPDATE DSCR => ", resDscr);

        return true;
    }
)

const initialState = {
    dataAssumption: {},
    isLoadingSubmit: false,
    isFirstTime: true,
    isEditing: false,
    skipUpdateState: false
}

export const assumptionSlice = createSlice({
    name: 'assumption',
    initialState,
    reducers: {
        setAssumption: (state, action) => {
            state.dataAssumption = action.payload
        },
        setLoadingSubmit: (state, action) => {
            state.isLoadingSubmit = action.payload
        },
        setEditing: (state, action) => {
            state.isEditing = action.payload
        },
        setIsFirstTime: (state, action) => {
            state.isFirstTime = action.payload
        },
        setSkipUpdateState: (state, action) => {
            state.skipUpdateState = action.payload
        },
        // setLoadFromAPI: (state, action) => {
        //     state.loadFromAPI = action.payload
        // },
        resetDataAssumption: () => initialState
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchDefaultAssumptions.fulfilled, (state, action) => {
                state.dataAssumption = action.payload
            })
            .addCase(fetchInputedAssumptions.fulfilled, (state, action) => {
                if(!state.skipUpdateState)
                    state.dataAssumption = action.payload
            })
            .addCase(createAssumptions.pending, (state, action) => {
                state.isEditing = false;
                state.isLoadingSubmit = true;
            })
            .addCase(createAssumptions.fulfilled, (state, action) => {
                state.isLoadingSubmit = false;
            })
            .addCase(createAssumptions.rejected, (state, action) => {
                state.isLoadingSubmit = false;
            })
            .addCase(updateAssumptions.pending, (state, action) => {
                state.isEditing = false;
                state.isLoadingSubmit = true;
            })
            .addCase(updateAssumptions.fulfilled, (state, action) => {
                state.isLoadingSubmit = false;
            })
            .addCase(updateAssumptions.rejected, (state, action) => {
                state.isLoadingSubmit = false;
            })
            // .addCase(fetchAktaById.fulfilled, (state, action) => {
            //     state.dataFormulir.master = {
            //         ...state.dataFormulir.master,
            //         ...action.payload
            //     }
            // })
            // .addCase(fetchWewenangById.fulfilled, (state, action) => {
            //     state.dataFormulir.master = {
            //         ...state.dataFormulir.master,
            //         ...action.payload
            //     }
            // })
    }
})

export const { 
    setAssumption,
    setLoadingSubmit,
    setEditing,
    setIsFirstTime,
    setSkipUpdateState,
    resetDataAssumption,
    // setLoadFromAPI
} = assumptionSlice.actions

export default assumptionSlice.reducer