import React from 'react'
import { Form,Button,message, Divider, Modal} from "antd";
import { useState,useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getAPI } from "../services/data";
import moment from 'moment';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import MasterForm from './Formulir/MasterForm'
import PemegangSaham from './Formulir/PemegangSaham'
import Direksi from './Formulir/Direksi'
import Komisaris from './Formulir/Komisaris'
import WewenangDireksi from './Formulir/WewenangDireksi'
import AktePendirian from './Formulir/AktePendirian'
import AktePerubahanDireksiTerbaru from './Formulir/AktePerubahanDireksiTerbaru'
import AktePerubahanSahamTerbaru from './Formulir/AktePerubahanSahamTerbaru'

import { dataWithoutId } from "../utils/util";
import { 
  setIdDebitur, 
  nextPage
} from '../store/actions/homeSlice';
import { 
  setMaster, 
  setLoadingSubmit, 
  fetchMasterById,
  fetchAktaById,
  fetchWewenangById,
  fetchPemegangSaham,
  fetchDireksi,
  fetchKomisaris,
  setSkipUpdateState,
  setLoadFromAPI
} from '../store/actions/formulirSlice';

const errorSubmit = (text) => {
  message.error({ content: text, key: "masterError" });
};

const success = (text) => {
  message.success({ content: text, key: "masterSuccess" });
};

const { confirm } = Modal;

const Formulir = (props) => {
  const {idDebitur} = props;
  const dispatch = useDispatch();
  const [form] = Form.useForm()
  const isLoadingSubmit = useSelector(state => state.formulir.isLoadingSubmit);
  const savedData = useSelector(state => state.formulir.dataFormulir.master);
  const formulirData = useSelector(state => state.formulir.dataFormulir);
  const isLoadFromAPI = useSelector(state => state.formulir.loadFromAPI);

  // const [data,setData] = useState([]);
  // const [result,setResult] = useState([]);
  // const [disabled,setDisabled] = useState(false);
  const [trigger,setTrigger] = useState(false);
  // const [spinner,setSpinner] = useState(false);
  // const [id,setId] = useState(idDebitur);
  const [masterForm, setMasterForm] = useState({})
  const [aktePendirian, setAktePendirian] = useState({})
  const [aktePerubahanDireksi, setAktePerubahanDireksi] = useState({})
  const [aktePerubahanSaham, setAktePerubahanSaham] = useState({})
  const [wewenangDireksi, setWewenangDireksi] = useState({})

  //API Get Data Debitur
  // const apiGetName = async () => {
  //   setTimeout(async () => {
  //     if(id != ''){
  //       const res = await getAPI("master-detail?id="+id);
  //       // console.log(res);
  //       setResult(res);
  //       dataMaster(res);
  //     }
  //   },1000)
  // }

  // API aggregator
  const fetchAll = async (id_debitur) => {
    await apiGetMasterDetail(id_debitur);
    await apiGetAkta(id_debitur);
    await apiGetWewenangDireksi(id_debitur);
  }

  // API Get Master Form
  const apiGetMasterDetail = async (id) => {
    // console.log(savedData)
    if (Object.keys(savedData).length !== 0 && Boolean(savedData?.id) && savedData?.id == id) {
      // console.log("ATAS")
      setMasterForm(savedData);
    } else {
      // console.log("FETCH API?", isLoadFromAPI)
      if (isLoadFromAPI) {
        // console.log("BAWAH")
        const res = await dispatch(fetchMasterById(id)).unwrap();
        setMasterForm(res);
        // console.log("BAWAH BANGET")
      }
    }
  }

  // API Get Akta
  const apiGetAkta = async (id) => {
    // console.log("SECOND")
    // console.log(savedData)
    if (Object.keys(savedData).length !== 0 && Boolean(savedData?.akte_pendirian) && savedData?.id == id) {
      // console.log("ATAS")
      setAktePendirian(savedData?.akte_pendirian)
      setAktePerubahanDireksi(savedData?.akte_perubahan_direksi)
      setAktePerubahanSaham(savedData?.akte_perubahan_saham)
    } else {
      // console.log("BAWAH")
      if (isLoadFromAPI) {
        // const res = await getAPI(`formulir/akte?id_debitur=${id}`);
        const res = await dispatch(fetchAktaById(id)).unwrap();
        setAktePendirian(res.akte_pendirian)
        setAktePerubahanDireksi(res.akte_perubahan_direksi)
        setAktePerubahanSaham(res.akte_perubahan_saham)
        // console.log("MERGED AKTA", mergedData)
      }
    }
  }

  // API Get Wewenang Direksi
  const apiGetWewenangDireksi = async (id) => {
    if (Object.keys(savedData).length !== 0 && (Boolean(savedData.wewenang_direksi_atas_pemberian_atau_penerimaan_pinjaman) || Boolean(savedData.masa_berakhir_direksi) || Boolean(savedData.wewenang_direksi_untuk_mewakili_perseroan) || Boolean(savedData.pasal_pengambilan_keputusan_sirkuler)) && savedData?.id == id) 
    {
      setWewenangDireksi({
        wewenang_direksi_atas_pemberian_atau_penerimaan_pinjaman: savedData.wewenang_direksi_atas_pemberian_atau_penerimaan_pinjaman,
        masa_berakhir_direksi: savedData.masa_berakhir_direksi,
        wewenang_direksi_untuk_mewakili_perseroan: savedData.wewenang_direksi_untuk_mewakili_perseroan,
        pasal_pengambilan_keputusan_sirkuler: savedData.pasal_pengambilan_keputusan_sirkuler
      });
    } else {
      if (isLoadFromAPI) {
        // const res = await getAPI(`formulir/wewenang-direksi?id_debitur=${id}`);
        const res = await dispatch(fetchWewenangById(id)).unwrap();
        setWewenangDireksi(res);
        // console.log("MERGED WEWENANG DIREKSI", mergedData)
      }
    }
  }

  useEffect(() => {
    fetchAll(idDebitur)
    // console.log("FORMULIR RENDERED")
  }, [idDebitur,  isLoadFromAPI])
  

  //Check Empty String
  const isEmptyOrSpaces = (str) => {
    return str.toString().match(/^ *$/) !== null;
  };

  const showConfirmSubmitSheet = () => {
    confirm({
      title: 'Pastikan semua data yang diisi sudah benar',
      icon: <ExclamationCircleOutlined />,
      // content: 'Some descriptions',
      okText: 'Submit',
      okType: 'danger',
      onOk() {
        handleSubmitSheet();
      },
      cancelText: 'Cancel',
      onCancel() {
        // console.log('Cancel');
      },
      centered: true
    });
  };

  // Create master
  const postDataMaster = async (object) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(object),
    };

    const response = await fetch(process.env.REACT_APP_EXPRESS_SCORING_UI + "master",requestOptions);
    return await response.json();
  };
  // Update master
  const putDataMaster = async (object) => {
    const requestOptions = {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(object),
    };

    const response = await fetch(`${process.env.REACT_APP_EXPRESS_SCORING_UI}formulir/master?id=${object.id}`,requestOptions);
    return await response.json();
  };
  // Update akte
  const putDataAkte = async (id, object) => {
    const requestOptions = {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(object),
    };

    const response = await fetch(`${process.env.REACT_APP_EXPRESS_SCORING_UI}formulir/akte?id_debitur=${id}`,requestOptions);
    return await response.json();
  };
  // Update wewenang direksi
  const putDataWewenangDireksi = async (id, object) => {
    const requestOptions = {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(object),
    };

    const response = await fetch(`${process.env.REACT_APP_EXPRESS_SCORING_UI}formulir/wewenang-direksi?id_debitur=${id}`,requestOptions);
    return await response.json();
  };

  // Create pemegang saham
  const postDataSaham = async (object) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(object),
    };

    const response = await fetch(process.env.REACT_APP_PYTHON_SCORING + "pemegang_saham", requestOptions);
    return await response.json();
  };
  // Update pemegang saham
  const putDataPemegangSaham = async (id, object) => {
    // console.log(object);
    const requestOptions = {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(object),
    };

    const response = await fetch(`${process.env.REACT_APP_EXPRESS_SCORING_UI}formulir/pemegang-saham?id_debitur=${id}`,requestOptions);
    return await response.json();
  };

  // Create direksi
  const postDataDireksi = async (object) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(object),
    };

    const response = await fetch(process.env.REACT_APP_EXPRESS_SCORING_UI + "direksi", requestOptions);
    return await response.json();
  };
  // Update direksi
  const putDataDireksi = async (id, object) => {
    // console.log(object);
    const requestOptions = {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(object),
    };

    const response = await fetch(`${process.env.REACT_APP_EXPRESS_SCORING_UI}formulir/direksi?id_debitur=${id}`,requestOptions);
    return await response.json();
  };

  // Create komisaris
  const postDataKomisaris = async (object) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(object),
    };

    const response = await fetch(process.env.REACT_APP_EXPRESS_SCORING_UI + "direksi", requestOptions);
    return await response.json();
  };
  // Update direksi
  const putDataKomisaris = async (id, object) => {
    // console.log(object);
    const requestOptions = {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(object),
    };

    const response = await fetch(`${process.env.REACT_APP_EXPRESS_SCORING_UI}formulir/komisaris?id_debitur=${id}`,requestOptions);
    return await response.json();
  };

  const handleSaveMaster = async (values) => {

    // console.log("RAW VALUES", values)

    let data = JSON.parse(JSON.stringify(values.values)); // DEEP COPY FOR SAFER OBJECT CLONING

    if(data["Wewenang_Direksi_Atas_Pemberian_Atau_Penerimaan_Pinjaman"] == '' || data["Wewenang_Direksi_Atas_Pemberian_Atau_Penerimaan_Pinjaman"] == null){
      data["wewenang_direksi_atas_pemberian_atau_penerimaan_pinjaman"] = "-";
    } else {
      data["wewenang_direksi_atas_pemberian_atau_penerimaan_pinjaman"] = data["Wewenang_Direksi_Atas_Pemberian_Atau_Penerimaan_Pinjaman"]
      delete data["Wewenang_Direksi_Atas_Pemberian_Atau_Penerimaan_Pinjaman"]
    }

    if(data["Masa_Berakhir_Direksi"] == "" || data["Masa_Berakhir_Direksi"] == null) {
      data["masa_berakhir_direksi"] = 0;
    } else {
      data["masa_berakhir_direksi"] = data["Masa_Berakhir_Direksi"]
      delete data["Masa_Berakhir_Direksi"]
    }

    if(data["Wewenang_Direksi_Untuk_Mewakili_Perseroan"] == '' || data["Wewenang_Direksi_Untuk_Mewakili_Perseroan"] == null){
      data["wewenang_direksi_untuk_mewakili_perseroan"]= "-";
    } else {
      data["wewenang_direksi_untuk_mewakili_perseroan"] = data["Wewenang_Direksi_Untuk_Mewakili_Perseroan"]
      delete data["Wewenang_Direksi_Untuk_Mewakili_Perseroan"]
    }

    if(data["Pasal_Pengambilan_Keputusan_Sirkuler"] == '' ||data["Pasal_Pengambilan_Keputusan_Sirkuler"] == null){
      data["pasal_pengambilan_keputusan_sirkuler"] = "-";
    } else {
      data["pasal_pengambilan_keputusan_sirkuler"] = data["Pasal_Pengambilan_Keputusan_Sirkuler"]
      delete data["Pasal_Pengambilan_Keputusan_Sirkuler"]
    }

    //Convert tanggal akte pembuatan pendirian
    data.Tanggal_Pembuatan_Pendirian = moment(data.Tanggal_Pembuatan_Pendirian).format("YYYY-MM-DD");

    //Convert tanggal akte pembuatan perubahan direksi
    data.Tanggal_Pembuatan_Perubahan_Direksi = moment(data.Tanggal_Pembuatan_Perubahan_Direksi).format("YYYY-MM-DD");

    //Convert tanggal akte pembuatan perubahan saham terbaru
    data.Tanggal_Pembuatan_Perubahan_Saham_Terbaru = moment(data.Tanggal_Pembuatan_Perubahan_Saham_Terbaru).format("YYYY-MM-DD");

    if(data['Tanggal_Pembuatan_Pendirian'] > moment().format("YYYY-MM-DD") || data['Tanggal_Pembuatan_Perubahan_Direksi'] > moment().format("YYYY-MM-DD") || data['Tanggal_Pembuatan_Perubahan_Saham_Terbaru'] > moment().format("YYYY-MM-DD")){
      return errorSubmit("Date should be set in the past !");
      // console.error("Date should be set in the past !");
    }

    for (const key in data) {
      if (isEmptyOrSpaces(key)) return errorSubmit("Text cannot be empty");
    }


    const akte_pendirian = {
      nomor_akta: data?.Nomor_Akta_Pendirian,
      tanggal_pembuatan: data?.Tanggal_Pembuatan_Pendirian,
      sk_menkunham: data?.SK_Menkunham_Pendirian,
      id_debitur: data?.id,
      jenis_akta: "Akte Pendirian"
    }
    setAktePendirian(akte_pendirian)
    delete data['Nomor_Akta_Pendirian'];
    delete data['Tanggal_Pembuatan_Pendirian'];
    delete data['SK_Menkunham_Pendirian'];

    const akte_perubahan_direksi = {
      nomor_akta: data?.Nomor_Akta_Perubahan_Direksi,
      tanggal_pembuatan: data?.Tanggal_Pembuatan_Perubahan_Direksi,
      sk_menkunham: data?.SK_Menkunham_Perubahan_Direksi,
      id_debitur: data?.id,
      jenis_akta: "Akte Perubahan Direksi Terbaru"
    }
    setAktePerubahanDireksi(akte_perubahan_direksi)
    delete data['Nomor_Akta_Perubahan_Direksi'];
    delete data['Tanggal_Pembuatan_Perubahan_Direksi'];
    delete data['SK_Menkunham_Perubahan_Direksi'];

    const akte_perubahan_saham = {
      nomor_akta: data?.Nomor_Akta_Perubahan_Saham_Terbaru,
      tanggal_pembuatan: data?.Tanggal_Pembuatan_Perubahan_Saham_Terbaru,
      sk_menkunham: data?.SK_Menkunham_Perubahan_Saham_Terbaru,
      id_debitur: data?.id,
      jenis_akta: "Akte Perubahan Saham Terbaru"
    }
    setAktePerubahanSaham(akte_perubahan_saham)
    delete data['Nomor_Akta_Perubahan_Saham_Terbaru'];
    delete data['Tanggal_Pembuatan_Perubahan_Saham_Terbaru'];
    delete data['SK_Menkunham_Perubahan_Saham_Terbaru'];
    
    const mergedData = {
      ...data,
      akte_pendirian: akte_pendirian,
      akte_perubahan_direksi: akte_perubahan_direksi,
      akte_perubahan_saham: akte_perubahan_saham
    }

    // console.log("CONVERTED VALUES =>",data)
    dispatch(setMaster(mergedData))
    success("Data tersimpan");
    // message.success('This is a success message');
  }

  const handleSubmitSheet = async () => {

    // Check sheet must be filled
    if (
      (Object.keys(formulirData?.master).length == 0) ||
      formulirData?.pemegangSaham.length == 0 ||
      formulirData?.direksi == 0 ||
      formulirData?.komisaris == 0
    ) {
      errorSubmit("Mohon isi formulir terlebih dahulu");
      return;
    }
    
    // console.log(formulirData);
    dispatch(setLoadingSubmit(true));

    if (isLoadFromAPI) { // Edit existing sheet
      // Update data master
      dispatch(setSkipUpdateState(true));
      const resDataMaster = await dispatch(fetchMasterById(idDebitur)).unwrap();
      const dataMaster = {
        id: formulirData?.master?.id,
        nama_debitur: formulirData?.master?.nama_debitur,
        badan_usaha: formulirData?.master?.badan_usaha,
        bidang_usaha: formulirData?.master?.bidang_usaha,
        jenis_pinjaman: formulirData?.master?.jenis_pinjaman,
        payment_scheme: formulirData?.master?.payment_scheme,
        lama_pinjaman: formulirData?.master?.lama_pinjaman,
        grace_period: formulirData?.master?.grace_period,
        pembayaran: formulirData?.master?.pembayaran
      }
      if(JSON.stringify(resDataMaster) !== JSON.stringify(dataMaster)){
        const resMaster = putDataMaster(dataMaster)
          .then((response) => {
            if(response['statusCode'] == '001'){
              errorSubmit(response['message']);
              return 0;
            } else {
              return 1;
            }
          })
          .catch((error) => {
            errorSubmit("Failed to submit data master");
            return 0;
          });
        if (!Boolean(resMaster)) {
          dispatch(setLoadingSubmit(false))
          return;
        }
      }

      // Update data akte
      const resAkte = await dispatch(fetchAktaById(idDebitur)).unwrap();
      const formattedDataAkte = Object.values(resAkte).map(({ id, ...rest }) => rest);

      const dataAktePendirian = JSON.parse(JSON.stringify(formulirData?.master?.akte_pendirian));
      delete dataAktePendirian.id;

      const dataAktePerubahanDireksi = JSON.parse(JSON.stringify(formulirData?.master?.akte_perubahan_direksi));
      delete dataAktePerubahanDireksi.id;
      
      const dataAktePerubahanSaham = JSON.parse(JSON.stringify(formulirData?.master?.akte_perubahan_saham));
      delete dataAktePerubahanSaham.id;

      const allAkte = [dataAktePendirian, dataAktePerubahanDireksi, dataAktePerubahanSaham];
      if(JSON.stringify(formattedDataAkte) !== JSON.stringify(allAkte)){
        await putDataAkte(formulirData?.master?.id, allAkte);
      }

      // Update data wewenang direksi
      const resWD = await dispatch(fetchWewenangById(idDebitur)).unwrap();
      const { id, ...resWewenangDireksi } = resWD[0] || {};
      dispatch(setSkipUpdateState(false)); 
      const datawewenangDireksi = {
        id_debitur: formulirData?.master?.id,
        wewenang_direksi_atas_pemberian_atau_penerimaan_pinjaman: formulirData?.master?.wewenang_direksi_atas_pemberian_atau_penerimaan_pinjaman,
        masa_berakhir_direksi: formulirData?.master?.masa_berakhir_direksi,
        wewenang_direksi_untuk_mewakili_perseroan: formulirData?.master?.wewenang_direksi_untuk_mewakili_perseroan,
        pasal_pengambilan_keputusan_sirkuler: formulirData?.master?.pasal_pengambilan_keputusan_sirkuler
      }
      if(JSON.stringify(resWewenangDireksi) !== JSON.stringify(datawewenangDireksi)){
        await putDataWewenangDireksi(formulirData?.master?.id, datawewenangDireksi)
      }

      // Update pemegang saham
      const resPemegangSaham = await dispatch(fetchPemegangSaham(idDebitur)).unwrap();
      const dataPemegangSaham = formulirData.pemegangSaham.map((saham) => {
        return {
          id_debitur: formulirData?.master?.id,
          pemegang_saham: saham.pemegang_saham,
          ktp_npwp: saham.ktp_npwp,
          jumlah_lembar_saham: saham.jumlah_lembar_saham
        }
      })
      if(JSON.stringify(dataWithoutId(resPemegangSaham)) !== JSON.stringify(dataPemegangSaham)){
        await putDataPemegangSaham(formulirData?.master?.id, dataPemegangSaham)
      }

      // Update direksi
      const resDireksi = await dispatch(fetchDireksi(idDebitur)).unwrap();
      const dataDireksi = formulirData.direksi.map((direksi) => {
        return {
          id_debitur: formulirData?.master?.id,
          nama: direksi.nama,
          jabatan: direksi.jabatan,
          nomor_ktp: direksi.nomor_ktp
        }
      })
      if(JSON.stringify(dataWithoutId(resDireksi)) !== JSON.stringify(dataDireksi)){
        await putDataDireksi(formulirData?.master?.id, dataDireksi)
      }

      // Update komisaris
      const resKomisaris = await dispatch(fetchKomisaris(idDebitur)).unwrap();
      const dataKomisaris = formulirData.komisaris.map((komisaris) => {
        return {
          id_debitur: formulirData?.master?.id,
          nama: komisaris.nama,
          jabatan: komisaris.jabatan,
          nomor_ktp: komisaris.nomor_ktp
        }
      })
      if(JSON.stringify(dataWithoutId(resKomisaris)) !== JSON.stringify(dataKomisaris)){
        await putDataKomisaris(formulirData?.master?.id, dataKomisaris)
      }

      success("Sukses edit sheet: [FORMULIR]");
    } else { // Insert new sheet

      // Submit data master
      const dataMaster = {
        Id_Debitur: formulirData?.master?.id,
        Nama_Debitur: formulirData?.master?.nama_debitur,
        Badan_Usaha: formulirData?.master?.badan_usaha,
        Bidang_Usaha: formulirData?.master?.bidang_usaha,
        Jenis_Pinjaman : formulirData?.master?.jenis_pinjaman,
        Payment_Scheme : formulirData?.master?.payment_scheme,
        Lama_Pinjaman : formulirData?.master?.lama_pinjaman,
        Grace_Period : formulirData?.master?.grace_period,
        Pembayaran : formulirData?.master?.pembayaran,
        Nomor_Akta_Pendirian: formulirData?.master?.akte_pendirian?.nomor_akta,
        Tanggal_Pembuatan_Pendirian: formulirData?.master?.akte_pendirian?.tanggal_pembuatan,
        SK_Menkunham_Pendirian: formulirData?.master?.akte_pendirian?.sk_menkunham,
        Nomor_Akta_Perubahan_Direksi: formulirData?.master?.akte_perubahan_direksi?.nomor_akta,
        Tanggal_Pembuatan_Perubahan_Direksi: formulirData?.master?.akte_perubahan_direksi?.tanggal_pembuatan,
        SK_Menkunham_Perubahan_Direksi: formulirData?.master?.akte_perubahan_direksi?.sk_menkunham,
        Nomor_Akta_Perubahan_Saham_Terbaru: formulirData?.master?.akte_perubahan_saham?.nomor_akta,
        Tanggal_Pembuatan_Perubahan_Saham_Terbaru: formulirData?.master?.akte_perubahan_saham?.tanggal_pembuatan,
        SK_Menkunham_Perubahan_Saham_Terbaru: formulirData?.master?.akte_perubahan_saham?.sk_menkunham,
        Wewenang_Direksi_Atas_Pemberian_Atau_Penerimaan_Pinjaman: formulirData?.master?.wewenang_direksi_atas_pemberian_atau_penerimaan_pinjaman,
        Wewenang_Direksi_Untuk_Mewakili_Perseroan: formulirData?.master?.wewenang_direksi_untuk_mewakili_perseroan,
        Masa_Berakhir_Direksi: formulirData?.master?.masa_berakhir_direksi,
        Pasal_Pengambilan_Keputusan_Sirkuler: formulirData?.master?.pasal_pengambilan_keputusan_sirkuler,
      }
      const resMaster = postDataMaster(dataMaster)
        .then((response) => {
          if(response['statusCode'] == '001'){
            errorSubmit(response['message']);
            return 0;
          } else {
            return 1;
          }
        })
        .catch((error) => {
          errorSubmit("Failed to submit data master");
          return 0;
        });
      if (!Boolean(resMaster)) {
        dispatch(setLoadingSubmit(false))
        return;
      }

      // Submit data pemegang saham
      for (const vSaham of formulirData?.pemegangSaham) {
        const objPayload = {
          Id_Debitur: formulirData?.master?.id,
          Pemegang_Saham: vSaham.pemegang_saham,
          KTP_NPWP: vSaham.ktp_npwp,
          Jumlah_Lembar: vSaham.jumlah_lembar_saham
        }

        postDataSaham(objPayload)
          .then((response) => {
            if(response['statusCode'] == '001'){
              console.log(`Submit pemegang saham gagal. Message: ${response['message']}`)
            } else {
              console.log(`Submit pemegang saham berhasil. Message: ${response['message']}`)
              return 1;
            }
            // console.error("PEMEGANG SAHAM RESPONSE", response)
          })
          .catch((error) => {
            console.error(error)
            errorSubmit("Failed to submit data pemegang saham");
          });
      }

      // Submit data direksi
      for (const vDireksi of formulirData?.direksi) {
        const objPayload = {
          Id_Debitur: formulirData?.master?.id,
          Nama: vDireksi.nama,
          Jabatan: vDireksi.jabatan,
          Nomor_KTP: vDireksi.nomor_ktp
        }
        postDataDireksi(objPayload)
          .then((response) => {
            if(response['statusCode'] == '001'){
              console.log(`Submit direksi. Message: ${response['message']}`)
            } else {
              console.log(`Submit direksi. Message: ${response['message']}`)
              return 1;
            }
          })
          .catch((error) => {
            console.error(error)
            errorSubmit("Failed to submit data direksi");
          });
      }

      // Submit data komisaris
      for (const vKomisaris of formulirData?.komisaris) {
        const objPayload = {
          Id_Debitur: formulirData?.master?.id,
          Nama: vKomisaris.nama,
          Jabatan: vKomisaris.jabatan,
          Nomor_KTP: vKomisaris.nomor_ktp
        }
        postDataKomisaris(objPayload)
          .then((response) => {
            if(response['statusCode'] == '001'){
              console.log(`Submit komisaris. Message: ${response['message']}`)
            } else {
              console.log(`Submit komisaris. Message: ${response['message']}`)
              return 1;
            }
          })
          .catch((error) => {
            console.error(error)
            errorSubmit("Failed to submit data komisaris");
          });
      }

      dispatch(setLoadFromAPI(true));
      success("Sukses submit sheet: [FORMULIR]");
      dispatch(setIdDebitur(formulirData?.master?.id))
    }

    dispatch(setLoadingSubmit(false))
    dispatch(nextPage())

  }

  return (
    <>
      <Form
        form={form}
        // disabled={disabled}
        onFinish={(values) => handleSaveMaster({ values })}
        name="basic"
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 13,
        }}
        initialValues={{
          remember: true,
        }}
        labelWrap
      >
        {/* Form Item */}
        <Divider>MASTER FORM</Divider>
        <MasterForm masterData={masterForm} form={form}/>
        <Divider>AKTA PENDIRIAN</Divider>
        <AktePendirian masterData={aktePendirian} form={form}/>
        <Divider>AKTA PERUBAHAN DIREKSI TERBARU</Divider>
        <AktePerubahanDireksiTerbaru masterData={aktePerubahanDireksi} form={form}/>
        <Divider>AKTA PERUBAHAN SAHAM TERBARU</Divider>
        <AktePerubahanSahamTerbaru masterData={aktePerubahanSaham} form={form}/>
        <Divider>WEWENANG DIREKSI</Divider>
        <WewenangDireksi masterData={wewenangDireksi} form={form}/>
        <Form.Item wrapperCol={{ span: 12, offset: 9 }}>
          <Button
            className="float-right"
            htmlType="submit"
            onClick={() => setTrigger(!trigger)}
            // loading={spinner}
          >
            Save
          </Button>
        </Form.Item>
      </Form>

      {/* Multiple Input */}
      <Divider>PEMEGANG SAHAM</Divider>
      <PemegangSaham masterData={idDebitur}/>
      <Divider>DIREKTUR</Divider>
      <Direksi masterData={idDebitur}/>
      <Divider>KOMISARIS</Divider>
      <Komisaris masterData={idDebitur}/>

      <div style={{margin: "auto", width: "100%", padding: "10px", float: "right"}}>
        <Button
          htmlType="button"
          type='primary'
          danger
          style={{float: "right"}}
          loading={isLoadingSubmit}
          // onClick={() => handleSubmitSheet()}
          onClick={() => showConfirmSubmitSheet()}
        >
          Submit Sheet
        </Button>
      </div>
    </>
  );
}

export default Formulir