import React from 'react'
import { Space } from "antd";
import BouwheerDetail from './Bouwheer/BouwheerDetail'
import CollateralToLoan from './Bouwheer/CollateralToLoan';
import MasterData from '../components/MasterData';

const Bouwheer = (props) => {
  const {masterData} = props;
  // const dataMaster = masterData['data'];
  return (
    <div>
      <Space direction='vertical' style={{display: 'flex'}}>
        {masterData && <MasterData dataMaster={masterData} />}
        <CollateralToLoan masterData={masterData} />
        <BouwheerDetail masterData={masterData} />
      </Space>
    </div>
  );
}

export default Bouwheer