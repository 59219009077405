import { Table } from "antd";
import { FSTableData } from "./FSTableData";

const TableFS = () => {
	const { rowClassFS, colFS, dataSourceFS } = FSTableData();

	return (
		<div style={{ marginTop: "65px", marginBottom: "10px", marginLeft: "8px", marginRight: "8px" }}>
			<Table
				pagination={false}
				dataSource={dataSourceFS}
				columns={colFS}
				rowClassName={rowClassFS}
			/>
		</div>
	)
};

export default TableFS;