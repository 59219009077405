import React from "react"
import { Steps, Form, Input, Button, message, Select, Table, InputNumber, Popconfirm, Tooltip } from "antd";
import { useState,useEffect } from "react";
import { useDispatch, useSelector } from "react-redux"
import { getAPIPython, getAPI } from "../../services/data";
import moment from "moment";
import { 
  DeleteOutlined,
} from "@ant-design/icons";
import { convertDateFormatNumber, convertDateFormatString } from "../../utils/util"
import { 
  updateNeracaParameter,
  setNeracaParameter, 
  createNeracaTahun, 
  fetchNeracaParameter, 
  setIsFirstTime, 
  setEditing,
  setIsSubmit,
  pushNeracaParameter,
  resetNeraca
} from "../../store/actions/neracaSlice";

const { Option } = Select;

const errorSubmit = (response) => {
  message.error(response);
};

const success = (response) => {
  message.success(response);
};

const postData = async (object) => {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(object),
  };
  
  const response = await fetch(process.env.REACT_APP_PYTHON_SCORING + "neraca",requestOptions);
  // console.log(response);
  const result = await response.json();
  // console.log(result);
  return result;
};
  
const NeracaComponent = (props) => {
  const {object} = props
  const parent = JSON.parse(object);
  const {
    masterData,
    // year,
    dropdown,
    title,
    idka,
    disabled,
    // trigger,
    // settingValue
  } = parent
  // console.log("Setting Value di Component",settingValue);
  const dispatch = useDispatch();
  // const [inputtedKeterangan,setInputtedKeterangan]= useState([])
  const [inputtedInfo,setInputedInfo] = useState([]);
  const [submitTrigger,setSubmitTrigger] = useState(false);
  const [spin,setSpinner] = useState(false)
  const [idKelompok, setIdKelompok] = useState();
  const [idKategori, setIdKategori] = useState();
  // const [settings,setSettings] = useState(0);

  // Load redux state
  const neracaTahun = useSelector(state => state.neraca.neracaTahun);
  const neracaParameter = useSelector(state => state.neraca.neracaParameter);
  const idDebitur = useSelector(state => state.home.id_debitur);
  const isEditing = useSelector(state => state.neraca.isEditing);
  const isFirstTime = useSelector(state => state.neraca.isFirstTime);
  const isLoadingSubmit = useSelector(state => state.neraca.isLoadingSubmit);
  const isSubmit = useSelector(state => state.neraca.isSubmit);
  const year = useSelector(state => state.neraca.financialYr); 
  
  // console.log(dropdown);
  // console.log(idka)
  // console.log(tableData)
  // console.log(masterData)
  // console.log(year)

  // const apiGetKeterangan = async (id,year,kategori) => {
  //   const data = await getAPIPython(`neraca2-get?id_debitur=${id}&year=${year}&kategori=${kategori}`);
  //   // console.log(data);
  //   const result = data['data'];
  //   setInputedInfo(result)
  // }  

  // useEffect(() => {
  //   if(masterData && year && dropdown) apiGetKeterangan(masterData['data']['id'],year,dropdown[0]['kategori'])
  // },[])
  
  // useEffect(() => {
  //   if(masterData && year && dropdown) apiGetKeterangan(masterData['data']['id'],year,dropdown[0]['kategori'])
  // },[submitTrigger,year])

  // useEffect(() => {
  //   console.log("USE EFFECT VALUE SETTINGS");
  //   switch(settingValue['setting']){
  //     case 'm':
  //       setSettings(1000000);
  //       break;
  //     case 'b':
  //       setSettings(1000000000);
  //       break;
  //     default:
  //       setSettings(1);
  //       break;
  //   }
  // },[settingValue])

  // const handleSubmit = async (values) => {
  //   setSpinner(true)
  //   let data = values
  //   let found = false;
  
  //   //Add Additional Information Form Parent 
  //   data['year'] = year;

  //   // console.log(data['value'],settings);
  //   // data['value']*=settings

  //   // console.log(typeof(year));
  //   //Select kelompok,kategori from index 0 
  //   data['kelompok'] = dropdown[0]['kelompok'];
  //   data['kategori'] = dropdown[0]['kategori'];
  //   data['id_debitur'] = masterData['data']['id']
  //   console.log(typeof(data['value']),data['value'])

  //   //Validation
  //   // inputtedKeterangan.map((item) => {
  //   //   if(item == data['keterangan']) found = true
  //   // })

  //   // if(found){
  //   //   setSpinner(false)
  //   //   return errorSubmit("Keterangan tidak bisa diinput lagi !");
  //   // }
    
  //   postData(data).then((response) => {
  //     console.log(response)
  //     if(response['statusCode'] == '200'){
  //       // setInputtedKeterangan([...inputtedKeterangan,data['keterangan']]);
  //       // setInputedInfo([...inputtedInfo,data])
  //       setSpinner(false)
  //       setSubmitTrigger(!submitTrigger);
  //       success(response['message'])
  //     }else{
  //       setSpinner(false)
  //       errorSubmit(response['message']);
  //       console.log(response);
  //     }
  //   }).catch((error) => {
  //     setSpinner(false)
  //     errorSubmit("Server Error !")
  //     console.log(error);
  //   })
  // }

  const apiGetNeracaParameter = async (id) => {
    const res = await dispatch(fetchNeracaParameter(id)).unwrap();
    if(res.length === 0){
      dispatch(setEditing(false));
      dispatch(setIsSubmit(false));
    }
  }

  useEffect(() => {
    if(idDebitur){
      if(!isEditing || neracaTahun.some(item => item.id_debitur !== idDebitur)){
        apiGetNeracaParameter(idDebitur);    
      }
    }
  },[idDebitur, year]);

  const columns = [
    {
      title: "Year",
      dataIndex:"year"
    },
    {
      title: "Keterangan",
      dataIndex: "keterangan"
    },
    {
      title: "Amount",
      dataIndex : "value",
      render: (_, record) => {  
        return <p>{"Rp" + record["value"].toLocaleString()}</p>;
      }
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (_, record) => {
        return (
          <>
            {isEditing ? (
              <Popconfirm title="Confirm deletion?" onConfirm={() => deleteRow(record)} okButtonProps={{type: "secondary"}}>
                <Tooltip title="delete">
                  <Button type="secondary" icon={<DeleteOutlined />}/>
                </Tooltip>
              </Popconfirm>
            ) : (
              <Tooltip title="delete">
                <Button type="secondary" disabled={!isEditing} icon={<DeleteOutlined />}/>
              </Tooltip>
            )}           
          </>
        )
      },
    }
  ];

  const deleteRow = async (record) => {
    const existingNeracaParam = JSON.parse(JSON.stringify(neracaParameter));       
    const indexToDelete = existingNeracaParam.findIndex((element) =>
      dropdown.find((obj) => obj.id_keterangan === element.id_keterangan)?.keterangan === record.keterangan  &&
      element.year === convertDateFormatNumber(record.year, "MMM YYYY")
    );

    if(indexToDelete !== -1){
      existingNeracaParam.splice(indexToDelete, 1);
      dispatch(setNeracaParameter(existingNeracaParam));
    }
  }

  const handleChangeKeterangan = (value) => {
    const selectedKeterangan = dropdown.find((item) => item.id_keterangan === value);
    // console.log(selectedKeterangan.id_keterangan)
    setIdKelompok(selectedKeterangan.id_kelompok);
    setIdKategori(selectedKeterangan.id_kategori);
  }

  let tableData = [];
  if(neracaTahun.some((item) => Object.values(item).includes(year))){ 
    const filteredNeracaParameter = neracaParameter.filter(item => item?.id_debitur === idDebitur && item?.id_kategori === idka && item?.year === year);
    tableData = filteredNeracaParameter.map(item => ({
      // id: item.id, 
      year: convertDateFormatString(item.year, "MMM YYYY"),
      keterangan: dropdown.find((obj) => obj.id_keterangan === item.id_keterangan)?.keterangan,
      value: item.value,
    }));
  }
  
  const handleSubmit = async (values) => {
    const payload = JSON.parse(JSON.stringify(values.values));
    const payloadArr = {
      id_debitur: idDebitur,
      id_kelompok: idKelompok,
      id_kategori: idKategori,
      id_keterangan: payload.keterangan,
      year: year,
      value: payload.value
    }
    
    // console.log(payload)
    // console.log(payloadArr)
    
    try { 
      const existingNeracaParam = JSON.parse(JSON.stringify(neracaParameter));
      const findIndex = existingNeracaParam.findIndex((item) => item.id_debitur == payloadArr.id_debitur && item.year == payloadArr.year && item.id_keterangan == payloadArr.id_keterangan);
      if(year !== ""){
        if(findIndex >= 0){
          existingNeracaParam.splice(findIndex, 1, payloadArr);
          dispatch(setNeracaParameter(existingNeracaParam));
          // await dispatch(updateNeracaParameter(payloadArr)).unwrap();
        }else{
          dispatch(pushNeracaParameter(payloadArr));
          // await dispatch(createNeracaParameter(payloadArr)).unwrap();
          // await dispatch(fetchNeracaParameter(idDebitur)).unwrap();
        }
        success("Data tersimpan");     
      }else{
        return errorSubmit("Silakan pilih tahun");
      }
    } catch (error) {
      errorSubmit("Gagal menyimpan data");
    }
    // console.log(neracaParameter)
  }

  return (
    <div className="mx-5">
      <p className="text-2xl font-bold my-4">{title}</p>
        <Form
          disabled={isFirstTime ? true : isEditing ? false : true}
          onFinish={(values) => handleSubmit({ values })}
          labelCol={{
            span: 6,
          }}
          labelAlign="left"
          wrapperCol={{
            span: 10,
          }}
          initialValues={{
            remember: true,
          }}
        >
        <div>
          <Form.Item
            key="keterangan"
            label="Input Keterangan"
            name="keterangan"
            rules={[
              {
                required: true,
                message: "Masukkan keterangan",
              },
            ]}
          >
            <Select onChange={handleChangeKeterangan}>
              {dropdown.map((item, i) => {
                return (
                  <Option key={i} value={item.id_keterangan}>{item.keterangan}</Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item
            key="amount"
            label="Input Amount"
            name="value"
            rules={[
              {
                required: true,
                message: "Masukkan amount",
              },
            ]}
          >
            <InputNumber
              addonBefore="Rp"
              formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              style={{ width: "100%" }}
            />             
          </Form.Item>
        </div>
        <div className="float-right">
          <Form.Item>
            <Button htmlType="submit" loading={isLoadingSubmit}>
              Save
            </Button>
          </Form.Item>
        </div>
        </Form>
        <Table dataSource={tableData} columns={columns} pagination={false} />
    </div>
  );
}

export default NeracaComponent