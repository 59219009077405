import React from 'react';
import { Space, Button, message, } from "antd";
import DebtorProfileInformation from './Debtor Profile/DebtorProfileInformation';
import HIstorisPefindo from './Debtor Profile/HIstorisPefindo';
import MasterData from '../components/MasterData';
import { useDispatch, useSelector } from 'react-redux';
import { dataWithoutId } from '../utils/util';
import {
  createSummaryHistoris,
  fetchSummaryHistoris,
  resetInputtedKtp,
  setEditing,
  setSkipUpdateState
} from '../store/actions/debtorProfile';

const errorSubmit = (text) => {
  message.error(text);
};

const success = (text) => {
  message.success(text);
};

const DebtorProfile = (props) => {
  const {masterData} = props;
  // const dataMaster = masterData["data"];

  const dispatch = useDispatch();

    // Load redux state
    const idDebitur = useSelector(state => state.home.id_debitur);
    const summary = useSelector(state => state.debtorProfile.historisPefindo);
    const isEditing = useSelector(state => state.debtorProfile.isEditing);
    const isFirstTime = useSelector(state => state.debtorProfile.isFirstTime);
    const isLoadingSubmit = useSelector(state => state.debtorProfile.isLoadingSubmit);

    const handleSubmitSheet = async () => {

      const payload = JSON.parse(JSON.stringify(summary));

      for (const val of payload) {
        
        delete val.id

      }

      try {
        dispatch(setSkipUpdateState(true));
        const resHP = await dispatch(fetchSummaryHistoris(idDebitur)).unwrap();
        dispatch(setSkipUpdateState(false));
        
        if(JSON.stringify(dataWithoutId(resHP)) !== JSON.stringify(payload)){
          dispatch(createSummaryHistoris({
            id_debitur: masterData.id,
            data: payload
          }));
        }

        dispatch(resetInputtedKtp());
        dispatch(setEditing(false));
        success("Sukses submit sheet: [Debtor Profile]");
      } catch (error) {
        dispatch(setEditing(false));
        errorSubmit("Gagal submit sheet");
      }

    }

  return (
    <div>
      <Space direction='vertical' style={{display: 'flex'}}>
        {masterData && <MasterData dataMaster={masterData} />}
        <DebtorProfileInformation masterData={masterData} />
        <HIstorisPefindo masterData={masterData} />
        {/* <Button htmlType="button" onClick={() => dispatch(setEditing(true))} disabled={isFirstTime ? true : isEditing ? true : false}>
          Edit
        </Button> */}
        <Space direction='horizontal' style={{display: 'flex', float: 'right', width: '25%'}}>
          <Button htmlType="button" onClick={() => dispatch(setEditing(true))} 
            disabled={isFirstTime ? true : isEditing}
          >
            Edit
          </Button>
          <Button htmlType="button" type="primary" danger onClick={() => handleSubmitSheet()} 
            loading={isLoadingSubmit} disabled={isFirstTime ? true : isEditing ? false : true}     
          >
            Submit Sheet
          </Button>
        </Space>
      </Space>
    </div>
  );
}

export default DebtorProfile