import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { 
    getAPI,
    getAPIPython,
    postAPIPython,
    postAPI,
    putAPI
} from '../../services/data'

export const fetchDropdownTypeIncomeExpense = createAsyncThunk(
    'pl/fetchDropdownTypeIncomeExpense',
    async (_, thunkAPI) => {
        try {
            const response = await getAPIPython("type_of_income_expense");
            // console.log("SUKSES API", response)
            return response.data;
        } catch (error) {
            // console.log("GAGAL API", error)
            throw error
        }
    }
)

// export const createProfitLoss = createAsyncThunk(
//     'pl/createProfitLoss',
//     async (payload, thunkAPI) => {
//         try {
//             const response = await postAPI(`pl/input-profit-loss?id_debitur=${payload[0].id_debitur}`, payload);
//             // console.log("SUKSES API ", response)
//             return response;
//         } catch (error) {
//             // console.log("GAGAL API", error)
//             throw error
//         }
//     }
// )

export const fetchInputProfitLoss = createAsyncThunk(
    'pl/fetchInputProfitLoss',
    async (idDebitur, thunkAPI) => {
        try {
            const response = await getAPI(`pl/input-profit-loss?id_debitur=${idDebitur}`);
            // console.log("SUKSES API ", response)
            return response;
        } catch (error) {
            // console.log("GAGAL API", error)
            throw error
        }
    }
)

export const updateInputProfitLoss = createAsyncThunk(
    'pl/updateInputProfitLoss',
    async (payload, thunkAPI) => {
        try {
            const response = await putAPI(`pl/input-profit-loss?id_debitur=${payload[0].id_debitur}`, payload);
            // console.log("SUKSES API ", response)
            return response;
        } catch (error) {
            // console.log("GAGAL API", error)
            throw error
        }
    }
)

export const fetchProfitLossView = createAsyncThunk(
    'pl/fetchProfitLossView',
    async (idDebitur, thunkAPI) => {
        try {
            const response = await getAPI(`pl/profit-loss?id_debitur=${idDebitur}`);
            // console.log("SUKSES API ", response)
            return response;
        } catch (error) {
            // console.log("GAGAL API", error)
            throw error
        }
    }
)

export const fetchProfitLossSummary = createAsyncThunk(
    'pl/fetchProfitLossSummary',
    async (idDebitur, thunkAPI) => {
        try {
            const response = await getAPI(`pl/profit-loss-summary?id_debitur=${idDebitur}`);
            // console.log("SUKSES API ", response)
            return response;
        } catch (error) {
            // console.log("GAGAL API", error)
            throw error
        }
    }
)

export const fetchProfitLossBalanceCheck = createAsyncThunk(
    'pl/fetchProfitLossBalanceCheck',
    async (idDebitur, thunkAPI) => {
        try {
            const response = await getAPI(`pl/profit-loss-balance-check?id_debitur=${idDebitur}`);
            // console.log("SUKSES API", response)
            return response;
        } catch (error) {
            // console.log("GAGAL API", error)
            throw error
        }
    }
)

export const fetchProfitLossRetainedEarningCheck = createAsyncThunk(
    'pl/fetchProfitLossRetainedEarningCheck',
    async (idDebitur, thunkAPI) => {
        try {
            const response = await getAPI(`pl/profit-loss-retained-earning-check?id_debitur=${idDebitur}`);
            // console.log("SUKSES API", response)
            return response;
        } catch (error) {
            // console.log("GAGAL API", error)
            throw error
        }
    }
)

const initialState = {
    PLInput: [],
    PLDropdown: [],
    PLView: [],
    PLSummary: [],
    PLBalance: [],
    PLRetainedEarning: [],
    financialYr: "",
    isLoadingSubmit: false,
    isFirstTime: true,
    isEditing: false,
    isSubmit: false,
}

export const plSlice = createSlice({
    name: 'pl',
    initialState,
    reducers: {
        setPLInput: (state, action) => {
            state.PLInput = action.payload
        },
        pushPLInput: (state, action) => {
            state.PLInput = [...state.PLInput, action.payload]
        },
        setPLDropdown: (state, action) => {
            state.PLDropdown = action.payload
        },
        setPLView: (state, action) => {
            state.PLView = action.payload
        },
        setPLSummary: (state, action) => {
            state.PLSummary = action.payload
        },
        setPLBalance: (state, action) => {
            state.PLBalance = action.payload
        },
        setPLRetainedEarning: (state, action) => {
            state.PLRetainedEarning = action.payload
        },
        setFinancialYr: (state, action) => {
            state.financialYr = action.payload         
        },
        setIsFirstTime: (state, action) => {
            state.isFirstTime = action.payload
        },
        setEditing: (state, action) => {
            state.isEditing = action.payload
        },
        setIsSubmit: (state, action) => {
            state.isSubmit = action.payload
        },
        resetPL: () => initialState
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchDropdownTypeIncomeExpense.fulfilled, (state, action) => {
                state.PLDropdown = action.payload
            })
            .addCase(fetchInputProfitLoss.fulfilled, (state, action) => {
                state.PLInput = action.payload
            })
            .addCase(updateInputProfitLoss.pending, (state, action) => {
                state.isLoadingSubmit = true
            })
            .addCase(updateInputProfitLoss.fulfilled, (state, action) => {
                state.isLoadingSubmit = false
            })
            .addCase(updateInputProfitLoss.rejected, (state, action) => {
                state.isLoadingSubmit = false
            })
            .addCase(fetchProfitLossView.fulfilled, (state, action) => {
                state.PLView = action.payload
            })
            .addCase(fetchProfitLossSummary.fulfilled, (state, action) => {
                state.PLSummary = action.payload
            })
            .addCase(fetchProfitLossBalanceCheck.fulfilled, (state, action) => {
                state.PLBalance = action.payload
            })
            .addCase(fetchProfitLossRetainedEarningCheck.fulfilled, (state, action) => {
                state.PLRetainedEarning = action.payload
            })
    }
})

export const { 
    setPLInput,
    pushPLInput,
    setPLDropdown,
    setPLSummary,
    setPLView,
    setPLBalance,
    setPLRetainedEarning,
    setFinancialYr,
    setLoadingSubmit,
    setIsFirstTime,
    setEditing,
    setIsSubmit,
    resetPL
} = plSlice.actions

export default plSlice.reducer