import React from 'react'
import { useState } from "react";
import { Select } from 'antd';
import BankLoan from './Capabilities/BankLoan';
import NewLoan from './Capabilities/NewLoan';
import NewLoanDSCR from './Capabilities/NewLoanDSCR';
import DebtSizing from './Capabilities/DebtSizing';

const {Option} = Select;

const Capabilities = (props) => {
  const {masterData} = props;
  const dataMaster = masterData['data']
  const [generate,setGenerate] = useState(true)
  const [valueSettings,setValueSettings] = useState(1);


  const getTrigger = (data) => {
    setGenerate(data)
    console.log("DI CAPABILITIES", generate);
  }

  return (
    <div>
      <BankLoan masterData={dataMaster} trigger={getTrigger}/>
      <NewLoan masterData={dataMaster} trigger={generate}/>
      <NewLoanDSCR masterData={dataMaster} trigger={generate}/>
      <DebtSizing masterData={dataMaster} trigger={generate}/>
    </div>
  )
}

export default Capabilities