import React from "react";
import { Form, Input,Radio,DatePicker ,Select} from "antd";
import moment from "moment"
import { useEffect, useState } from "react";
// import { getAPI } from "../../services/data";


const {Group} = Radio;
const { Option } = Select;

// const errorSubmit = (text) => {
//   message.error(text);
// };

// const success = (text) => {
//   message.success(text);
// };

const AktePerubahanDireksiTerbaru = (props) => {
  const { masterData, form } = props;

  // const [masterName, setMasterName] = useState([]);
  const [trigger, setTrigger] = useState(true); // TIDAK JELAS, AFRAID TO REMOVE
  // const [disabled,setDisabled] = useState(false)

  //APIs
  // const apiGetDebiturName = async () => {
  //   const data = await getAPI("master-name");
  //   setMasterName(data.data);
  // };

  // useEffect(() => {
  //   apiGetDebiturName();
  //   console.log("HERE");
  // }, [trigger]);

  useEffect(() => {
    form.setFieldsValue({
      Nomor_Akta_Perubahan_Direksi: masterData.nomor_akta,
      Tanggal_Pembuatan_Perubahan_Direksi: moment(masterData.tanggal_pembuatan),
      SK_Menkunham_Perubahan_Direksi: masterData.sk_menkunham,
    })

  }, [form, masterData]);

  const content = [
    {
      label: "Nomor Akta",
      name: "Nomor_Akta_Perubahan_Direksi",
      message: "Masukkan nomor akta",
      required: true,
    },
    {
      label: "Tanggal Pembuatan",
      name: "Tanggal_Pembuatan_Perubahan_Direksi",
      message: "Masukkan tanggal pembuatan",
      required: true,
      type: "DatePicker",
    },
    {
      label: "SK Menkunham",
      name: "SK_Menkunham_Perubahan_Direksi",
      message: "Masukkan SK Menkumham",
      required: true,
      type: "Radio",
      radio_data: ["Sudah Menyerahkan", "Belum Menyerahkan"],
    },
  ];

  return (
    <div>
      {/* <p className="text-2xl font-bold text-center my-3">
        Akte Perubahan Direksi Terbaru
      </p> */}
      {/* Form */}
        {/* Form Content */}
        <div className="grid grid-cols-3">
          {content.map((item, i) => {
            return (
              <Form.Item
                key={i}
                label={item.label}
                name={item.name}
                rules={[
                  {
                    required: item.required,
                    message: item.message,
                  },
                ]}
              >
                {item.type?.includes("Radio") ? (
                  <Group>
                    {item.radio_data.map((obj, i) => {
                      return <Radio value={obj}>{obj}</Radio>;
                    })}
                  </Group>
                ) : item.type?.includes("DatePicker") ? (
                  <DatePicker 
                    style={{ width: "100%" }}
                  />
                ) : item.select_data?.length > 0 ? (
                  <Select
                    onClick={() => setTrigger(!trigger)}
                    showSearch
                    placeholder="Search to Select"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children.includes(input)
                    }
                    filterSort={(optionA, optionB) =>
                      optionA.children
                        .toLowerCase()
                        .localeCompare(optionB.children.toLowerCase())
                    }
                  >
                    {item.select_data.map((item, i) => {
                      return (
                        <Option value={item.id} key={i}>
                          {item.nama_debitur}
                        </Option>
                      );
                    })}
                  </Select>
                ) : (
                  <Input />
                )}
              </Form.Item>
            );
          })}
        </div>
        {/* Button Submit */}

    </div>
  );
};

export default AktePerubahanDireksiTerbaru;
