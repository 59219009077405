//Mohon baca dokumentasi Ant Design V4 (bukan V5 dst.),karena banyak logik yang langsung ngambil dari dokumntasi

import React, { useEffect, useRef, useState } from "react";
import { SearchOutlined } from "@ant-design/icons";
import { Skeleton, Table, Button, Input, Space } from "antd";
import { getAPI,getAPIPython } from '../services/data'
import Highlighter from "react-highlight-words";

const TableViewData = (props) => {
  const { idDebitur, lastId, editData } = props;
  const [result, setResult] = useState([]);
  const [view, setView] = useState(true);
  // const [filteredInfo, setFilteredInfo] = useState({});
  // const [sortedInfo, setSortedInfo] = useState({});
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");

  const [bidangUsaha, setBidangUsaha] = useState([]);
  const [badanUsaha, setBadanUsaha] = useState([]);
  const [jenisPinjaman, setJenisPinjaman] = useState([]);
  const [paymentScheme, setPaymentScheme] = useState([]);
  const [pembayaran, setPembayaran] = useState([]);

  const searchInput = useRef(null);
  //Refer to Ant Design V4 Documentation
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    // console.log("SELECTED KEYS => ", selectedKeys);
    // console.log("SEARCH COLUMN => ", dataIndex);
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
    // console.log("MASUK SEARCH");
  };

  //Refer to Ant Design V4 Documentation
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  //Refer to Ant Design V4 Documentation
  // const handleChange = (pagination, filters, sorter) => {
  //   console.log("TABLE PARAMETERS", pagination, filters, sorter);
  //   setFilteredInfo(filters);
  //   setSortedInfo(sorter);
  // };

  //API Get All Master List
  const apiGetAllMaster = async () => {
    const data = await getAPIPython("debiturlist-get");
    setResult(data["data"]);
    setView(false);
  };

  //API Get Bidang Usaha
  const apiGetBidangUsaha = async () => {
    const data = await getAPI("bidang-usaha");
    const result = data["data"];
    let array = [];

    for (let x in result) {
      let object = {
        text: result[x]["indicator"],
        value: result[x]["indicator"],
      };
      array.push(object);
    }

    setBidangUsaha(array);
  };

  //API Get Badan Usaha
  const apiGetBadanUsaha = async () => {
    const data = await getAPI("badan-usaha");
    const result = data["data"];
    let array = [];

    for (let x in result) {
      let object = {
        text: result[x]["indicator"],
        value: result[x]["indicator"],
      };
      array.push(object);
    }
    setBadanUsaha(array);
  };

  //API GET Jenis Pinjaman
  const apiGetJenisPinjaman = async () => {
    const data = await getAPI("jenis-pinjaman");
    const result = data["data"];
    let array = [];

    for (let x in result) {
      let object = {
        text: result[x]["indicator"],
        value: result[x]["indicator"],
      };
      array.push(object);
    }
    setJenisPinjaman(array);
  };

  //API GET Payment Scheme
  const apiGetPaymentScheme = async () => {
    const data = await getAPI("payment-scheme");
    const result = data["data"];
    let array = [];

    for (let x in result) {
      let object = {
        text: result[x]["indicator"],
        value: result[x]["indicator"],
      };
      array.push(object);
    }
    setPaymentScheme(array);
  };

  //API GET Pembayaran
  const apiGetPembayaran = async () => {
    const data = await getAPI("pembayaran");
    const result = data["data"];
    let array = [];

    for (let x in result) {
      let object = {
        text: result[x]["indicator"],
        value: result[x]["indicator"],
      };
      array.push(object);
    }
    setPembayaran(array);
  };

  //Put APIs into 1 function
  const apiCall = async () => {
    apiGetBidangUsaha();
    apiGetBadanUsaha();
    apiGetJenisPinjaman();
    apiGetPaymentScheme();
    apiGetPembayaran();
  };

  useEffect(() => {
    // console.log("REFRESH TABLE");
    apiCall();
    apiGetAllMaster();
  }, []);

  useEffect(() => {
    if (lastId != '') {
      viewData(lastId);
    }
  }, [lastId])

  //Refer to Ant Design V4 Documentation
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            className="bg-blue-400"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) => {
      if (record[dataIndex] != null) {
        return record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase());
      }
    },
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: "ID Pinjaman",
      dataIndex: "ID Pinjaman",
      //Add Search (Refer to Ant Design V4 Documentation)
      ...getColumnSearchProps("ID Pinjaman"),
      //Add Sort (Refer to Ant Design V4 Documentation)
      sorter: (a, b) => a["ID Pinjaman"].localeCompare(b["Pinjaman"]),
    },
    {
      title: "Nama",
      dataIndex: "nama",
      //Add Search (Refer to Ant Design V4 Documentation)
      ...getColumnSearchProps("nama"),
      //Add Sort (Refer to Ant Design V4 Documentation)
      sorter: (a, b) => a.nama.localeCompare(b.nama),
    },
    {
      title: "Badan Usaha",
      dataIndex: "badan usaha",
      filters: badanUsaha,
      //Add Filter (Refer to Ant Design V4 Documentation)
      onFilter: (value, record) => {
        if (record["badan usaha"] != null) {
          console.log(value, record["badan usaha"]);
          return record["badan usaha"].includes(value);
        }
      },
    },
    {
      title: "Bidang Usaha",
      dataIndex: "bidang usaha",
      filters: bidangUsaha,
      //Add Filter (Refer to Ant Design V4 Documentation)
      onFilter: (value, record) => {
        if (record["bidang usaha"] != null) {
          return record["bidang usaha"].includes(value);
        }
      },
    },
    {
      title: "Jenis Pinjaman",
      dataIndex: "jenis pinjaman",
      filters: jenisPinjaman,
      //Add Filter (Refer to Ant Design V4 Documentation)
      onFilter: (value, record) => {
        if (record["jenis pinjaman"] != null) {
          return record["jenis pinjaman"].includes(value);
        }
      },
    },
    {
      title: "Payment Scheme",
      dataIndex: "payment scheme",
      filters: paymentScheme,
      //Add Filter (Refer to Ant Design V4 Documentation)
      onFilter: (value, record) => {
        if (record["payment scheme"] != null) {
          return record["payment scheme"].includes(value);
        }
      },
    },
    {
      title: "Lama Pinjaman",
      dataIndex: "lama pinjaman",
      key: "lama_pinjaman",
    },
    {
      title: "Grace Period",
      dataIndex: "grace period",
      key: "grace_period",
    },
    {
      title: "Pembayaran",
      dataIndex: "pembayaran",
      filters: pembayaran,
      //Add Filter (Refer to Ant Design V4 Documentation)
      onFilter: (value, record) => {
        if (record["pembayaran"] != null) {
          return record["pembayaran"].includes(value);
        }
      },
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <>
          <Space>
          <Button onClick={() => viewData(record["ID Pinjaman"])}>
            View Data
          </Button>
          <Button type="button" onClick={() => handleEdit(record["ID Pinjaman"])}>
            Edit
          </Button>
          </Space>
        </>
      ),
    },
  ];

  const viewData = (id) => {
    idDebitur(id);
  };

  // Handle edit button action
  const handleEdit = (id_debitur) => {
    editData(id_debitur)
  }

  return (
    <div>
      <Skeleton loading={view} active>
        <Table
          // onChange={handleChange}
          title={() => "List Debitur"}
          bordered
          className="mx-auto py-5"
          style={{ width: "90%" }}
          dataSource={result}
          columns={columns}
          scroll={{
            x: 1000,
          }}
        />
      </Skeleton>
    </div>
  );
}

export default TableViewData