import React from "react";
import { 
  Form,
  Button, 
  message, 
  Select, 
  Input,
  // Table, 
  Divider,
  Modal,
  Space,
  Tooltip,
  Popover
} from "antd";
import { 
  ExclamationCircleOutlined,
  InfoCircleOutlined
} from '@ant-design/icons';
import { useState, useEffect } from "react";
// import { getAPIPython, getAPI } from "../../services/data";
import { useDispatch, useSelector } from 'react-redux';
import CustomTable from '../../components/CustomTable';
import { BouwheerTableData } from "./BouwheerTableData";
import {
  fetchJenisPinjaman,
  fetchTypeOfObligor,
  fetchTypeOfInventory,
  fetchSummaryTable,
  fetchSummaryParam,
  CreateSummaryParam,
  setEditing,
  resetBouwheer
  // setBouwheerDetail
} from '../../store/actions/bouwheerSlice';

const { Option } = Select;

const errorSubmit = (text) => {
  message.error(text);
};

const success = (text) => {
  message.success(text);
};

// const columns = [
//   {
//     title: "Indikator",
//     dataIndex: "indikator",
//     key: "indikator",
//   },
//   {
//     title: "Score",
//     dataIndex: "score",
//     key: "score",
//   },
//   {
//     title: "Weight",
//     dataIndex: "weight",
//     key: "weight",
//     render: (_, record) => {
//       return <p>{record["weight"] * 100 + "%"} </p>;
//     },
//   },
//   {
//     title: "Weighted Score",
//     dataIndex: "weighted_score",
//     key: "weighted_score",
//   },
// ];

const { confirm } = Modal;


const BouwheerDetail = (props) => {
//   const { currentSteps } = props;
  const [form] = Form.useForm();
  const {masterData} = props
  // const dataParent = masterData['data']

  const dispatch = useDispatch();

  // Load redux state
  const idDebitur = useSelector(state => state.home.id_debitur);
  const isEditing = useSelector(state => state.bouwheer.isEditing);
  const isFirstTime = useSelector(state => state.bouwheer.isFirstTime);
  const summary = useSelector(state => state.bouwheer.bouwheerDetail);
  const isLoadingSubmit = useSelector(state => state.bouwheer.isLoadingSubmit);
  const { colBouwheerDetail, dataSourceBouwheerDetail } = BouwheerTableData();

  //useState
  const [obligor,setObligor] = useState([])
  const [labelJenisPinjaman, setLabelJenisPinjaman] = useState("");
  // const [summary,setSummary] = useState([]);
  // const [trigger,setTrigger] = useState([]);
  // const [get,setGet] = useState(false);
  // const [refresh, setRefresh] = useState(true);
  // const [spin,setSpin] = useState(false);

  //APIs
  //Type of Obligor
  // const apiGetObligor = async () => {
  //   console.log("Get Obligor");
  //   const data = await getAPIPython("type_of_obligor-get");
  //   const newObject = data.data;
  //   //Create New Array Type of Obligor with New Key (Value & Indicator)
  //   let array = [];

  //   for (let x in newObject) {
  //     let object = {
  //       value: newObject[x].id,
  //       indicator: newObject[x].type_of_obligor,
  //     };
  //     array.push(object);
  //   }

  //   const data2 = await getAPIPython("type_of_inventory-get");
  //   const newObject2 = data2.data;
  //   //Create New Array Type of Inventory with New Key (Value & Indicator)
  //   let array2 = [];

  //   for (let x in newObject2) {
  //     let object = {
  //       value: newObject2[x].id,
  //       indicator: newObject2[x].type_of_inventory,
  //     };
  //     array2.push(object);
  //   }

  //   //If Jenis Pinjaman is Inventory Financing
  //   if (dataParent["jenis_pinjaman"] == "JP003") {
  //     setObligor(array2) //Set Dropdown to Inventory(s)
  //   } else {
  //     setObligor(array); //Set Dropdown to Obligor(s)
  //   }
  // }

  //Summary 
  // const apiGetSummary = async (id) => {
  //   let array = []
  //   const data = await getAPIPython("summary_bouwheer_get/?id_debitur=" + id);
  //   array.push(data['data']);
  //   setSummary(array)
  // }

  const apiGetObligor = async () => {

    // Check jenis_pinjaman
    if (masterData?.jenis_pinjaman == "JP003") {
      const res = await dispatch(fetchTypeOfInventory()).unwrap()
      const dropdownObligor = res.map((val) => {
        return {
          value: val.id,
          indicator: val.type_of_inventory,
        }
      })
      setObligor(dropdownObligor)
    } else {
      const res = await dispatch(fetchTypeOfObligor()).unwrap()
      const dropdownObligor = res.map((val) => {
        return {
          value: val.id,
          indicator: val.type_of_obligor,
        }
      })
      setObligor(dropdownObligor)
    }

  }

  const apiGetSummary = async (id) => {
    const res = await dispatch(fetchSummaryTable(id)).unwrap();
    if(res.length !== 0){
      const type = obligor.find((item) => item.value === res[0]?.obligor_inventory_score)
      form.setFieldsValue({
        Obligor_Inventory_Score: type?.value
      });      
    }
  }

  //Set Jenis Pinjaman to Input Form
  const setJenisPinjaman = async () => {
    //If Jenis Pinjaman is Inventory Financing
    if(masterData.jenis_pinjaman == 'JP003'){ 
      // console.log("HERE3");
      //Set value form to Inventory
      form.setFieldsValue({
        Type_Of_Collateral : "Type of Collateral"
      });
    }else{
      // console.log("HERE2");
      //Set value form to Obligor
      form.setFieldsValue({
        Type_Of_Collateral: "Type of Obligor",
      });
    }

    const res = await dispatch(fetchJenisPinjaman(masterData.jenis_pinjaman)).unwrap();
    setLabelJenisPinjaman(res);
  }

  // const handleRefresh = () => {
  //   console.log("Refresh Bouwheer Detail");
  //   setRefresh(!refresh);
  // };

  useEffect(() => {
    apiGetObligor();
    setJenisPinjaman();
    // apiGetSummary(idDebitur);
  }, [masterData, idDebitur]);

  useEffect(() => {
    apiGetSummary(idDebitur);
  }, [obligor, idDebitur]);

  // useEffect(() => {
  //   console.log("API CALL BOUWHEER DETAIL")
  //   apiGetObligor();
  //   // apiGetInventory();
  //   setJenisPinjaman();
  // }, [refresh]);

  // useEffect(() => {
  //   if(get) apiGetSummary(dataParent['id'])
  // },[trigger])

  const showConfirmSubmitSheet = () => {
    confirm({
      title: 'Pastikan semua data yang diisi sudah benar',
      icon: <ExclamationCircleOutlined />,
      // content: 'Some descriptions',
      okText: 'Submit',
      okType: 'danger',
      onOk() {
        handleSubmit();
      },
      cancelText: 'Cancel',
      onCancel() {
        // console.log('Cancel');
      },
      centered: true
    });
  };

  const handleSubmit = async () => {
    const payload = JSON.parse(JSON.stringify(form.getFieldsValue()));
    // console.log("PAYLOAD TO SUBMIT => ",payload)

    try {
      const inputtedData = {
        score: payload.Obligor_Inventory_Score
      };
      const resSumParam = await dispatch(fetchSummaryParam(idDebitur)).unwrap();
      const { id, id_debitur, ...fetchedDataSumParam } = resSumParam[0] || {};

      if(JSON.stringify(fetchedDataSumParam) !== JSON.stringify(inputtedData)){
        await dispatch(CreateSummaryParam({
          id_debitur: masterData?.id,
          score: payload.Obligor_Inventory_Score
        })).unwrap();  
      }

      await dispatch(fetchSummaryTable(masterData?.id)).unwrap();
      dispatch(setEditing(false));
      success("Sukses submit sheet: [Bouwheer]");

    } catch (error) {

      errorSubmit("Gagal menyimpan data")
      dispatch(setEditing(false));
    
    }
  
  }

  // const postData = async (object) => {
  //   const requestOptions = {
  //     method: "POST",
  //     headers: { "Content-Type": "application/json" },
  //     body: JSON.stringify(object),
  //   };

  //   const response = await fetch(
  //     process.env.REACT_APP_PYTHON_SCORING + "summary_bouwheer",
  //     requestOptions
  //   );

  //   // console.log(response);
  //   const result = await response.json();
  //   console.log(result);
  //   return result;
  // };

  // const handleChange = (value) => {
  //   console.log(value);
  //   if(value == 'Obligor'){
  //       setChange(obligor)
  //   }else{
  //       setChange(inventory)
  //   }
  // }

  // const handleSubmit = (values) => {
  //   console.log(values);
  //   setSpin(true)
  //   let data = {
  //     Id_Debitur: masterData["id"],
  //     Obligor_Inventory_Score: values['values']['Obligor_Inventory_Score'],
  //   };

  //   console.log("Payload Bouwheer Detail",data);

  //   postData(data)
  //     .then((response) => {
  //       if (response["statusCode"] == "200") {
  //         success(response['message']);
  //         // process = true;
  //         setTrigger(!trigger);
  //         setGet(true);
  //         setSpin(false);
  //         // currentSteps("add");
  //         console.log(response);
  //       }else{
  //         errorSubmit(response['message'])
  //         setSpin(false)
  //       }
  //     })
  //     .catch((error) => {
  //       setSpin(false)
  //       errorSubmit("Internal Server Error")
  //       console.log(error);
  //     }); 
  // };

  return (
    <div>
      {/* <p className="text-2xl font-bold text-center my-3">Bouwheer Detail</p> */}
      <Divider>Bouwheer Detail</Divider>
      {/* Form */}
      <Form
        form={form}
        disabled={isFirstTime ? true : isEditing ? false : true}
        onFinish={(values) => handleSubmit({ values })}
        name="basic"
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 10,
        }}
        initialValues={{
          remember: true,
        }}
      >
        {/* Form Content */}
        <div className="grid grid-cols-2">
          <Form.Item
            key={1}
            // label={"Indikator"}
            // label={
            //   <div style={{ display: "inline-flex", alignItems: "center", gap: "8px"}}>
            //   <Tooltip title="SAMPLE">
            //     <InfoCircleOutlined />
            //   </Tooltip>
            //   Indikator
            //   </div>
            // }
            label={
              <div style={{ display: "inline-flex", alignItems: "center", gap: "8px"}}>
              <Popover placement="topLeft" content={
                <>
                  Jenis Pinjaman {labelJenisPinjaman}
                </>
              }>
                <InfoCircleOutlined />
              </Popover>
              Indikator
              </div>
            }
            name={"Type_Of_Collateral"}
            // tooltip={{
            //   title: 'Tooltip with customize icon',
            //   icon: <InfoCircleOutlined />,
            // }}
          >
            {/* <Select disabled>
              <Option value="Obligor">Obligor</Option>
              <Option value="Inventory">Inventory</Option>
            </Select> */}
            <Input disabled />
          </Form.Item>
          <Form.Item
            key={2}
            label={"Type Of Obligor"}
            name={"Obligor_Inventory_Score"}
          >
            <Select placeholder="Select Obligor">
              {/* Looping dropdown based on jenis pinjaman */}
              {obligor.map((item, i) => {
                return (
                  <Option key={i} value={item.value}>
                    {item.indicator}
                  </Option>
                );
              })}
              {/* <Option value="Obligor">Obligor</Option>
              <Option value="Inventory">Inventory</Option> */}
            </Select>
          </Form.Item>
        </div>
        {/* Button Submit */}
        {/* <Form.Item wrapperCol={{ span: 12, offset: 9 }}>
          <Space direction='horizontal' style={{display: 'flex', float: 'right'}}>
          <Button className="float-right" htmlType="submit" >
            Save
          </Button>
          <Button className="float-right mx-2" onClick={handleRefresh}>
            Refresh
          </Button>
          </Space>
        </Form.Item> */}
      </Form>
      <Space direction='vertical' style={{display: 'flex'}} size={"large"}>
      <Space direction='horizontal' style={{display: 'flex', float: 'right', width: '25%'}}>
        <Button htmlType="button" onClick={() => dispatch(setEditing(true))} disabled={isFirstTime ? true : isEditing}>
          Edit
        </Button>
        <Button htmlType="submit" type="primary" danger onClick={() => showConfirmSubmitSheet()} 
          loading={isLoadingSubmit} disabled={isFirstTime ? true : isEditing ? false : true}
        >
          Submit Sheet
        </Button>
        {/* <Button className="float-right" htmlType="submit" >
          Save
        </Button> */}
        {/* <Button className="float-right mx-2" onClick={handleRefresh}>
          Refresh
        </Button> */}
      </Space>
      {/* <Table columns={columns} dataSource={summary} pagination={false}/> */}
      <CustomTable 
        dataSource={dataSourceBouwheerDetail} 
        columns={colBouwheerDetail} 
        // dataSource={summary} 
        // columns={columns} 
        bordered
        size="small"
      />
      </Space>
    </div>
  );
};

export default BouwheerDetail;
