import React from 'react'
import { Skeleton,Button, message,Table,Empty} from "antd";
import { useState, useEffect } from "react";
import { getAPIPython, getAPI } from "../services/data";

const isNumeric = (value) => {
  return /^-?\d+$/.test(value);
}

const errorSubmit = (text) => {
  message.error(text);
};

const success = (text) => {
  message.success(text);
};

const columnsMain = [
  {
    title: "Kategori",
    dataIndex: "kategori",
    key: "kategori",
  },
  {
    title: "Score",
    dataIndex: "score",
    key: "score",
  },
  {
    title: "Weighted",
    dataIndex: "weighted",
    key:"weighted",
    render: (_,record) => (
      <p>{record['weighted'] * 100 + "%"}</p>
    )
  },
  {
    title: "Weighted Score",
    dataIndex: "weighted score",
    key: "weighted_score"
  },
  {
    title: "Risk",
    dataIndex: "risk",
    key: "risk",
    render: (_,record) => {
      let color = '';
      // record['risk'] = " Risk"
      switch(record['risk']){
        case 'Very Low Risk':
          color = 'from-green-500 to-green-600';
          break;
        case 'Low Risk':
          color = 'from-green-400 to-green-500';
          break;
        case 'Moderate Risk':
          color = 'from-amber-400 to-amber-500';
          break;
        case 'High Risk':
          color = 'from-red-400 to-red-500';
          break;
        case 'Extremely High Risk':
          color = 'from-red-500 to-red-600';
          break;
      } 
      return (
        <div className={`bg-gradient-to-r ${color} px-10 py-3`}>
          <p className=''>{record['risk']}</p>
        </div>
      )
    }
  }
]

const columnsAssesmentRemarks = [
  {
    title: "Keterangan",
    dataIndex: "keterangan",
    key: "keterangan",
  },
  {
    title: "Proposed",
    dataIndex: "proposed",
    key: "proposed",
    render: (_,record) => {
      //Render kolom rupiah
      if(record['keterangan'] == 'Expected WC Needed' || record['keterangan'] == 'Coverage from Bank Loan' || record['keterangan'] == 'Max Plafon Can Be Given' || record['keterangan'] == 'Max Debt Sizing'){
        return (
          <p>{'Rp' + record['proposed'].toLocaleString()}</p>
        )
      //Render kolom persen
      }else if (record['keterangan'] == 'Interest Efektif' || record['keterangan'] == 'Interest Flat' || record["keterangan"] == "Origination fee"){
        return (
          <p>{record['proposed'] + '%'}</p>
        )
      //Render kolom biasa
      }else{
        return (
          <p>{record['proposed']}</p>
        )
      }
    }
  },
  {
    title: "Approved",
    dataIndex: "approved",
    key: "approved",
     render: (_,record) => {
      //Render kolom rupiah
      if(record['keterangan'] == 'Expected WC Needed' || record['keterangan'] == 'Coverage from Bank Loan' || record['keterangan'] == 'Max Plafon Can Be Given' || record['keterangan'] == 'Max Debt Sizing'){
        if(record['approved'] != ''){
          return (
            <p>{'Rp' + record['approved'].toLocaleString()}</p>
          )
        }
      //Render kolom persen
      }else if (
        record["keterangan"] == "Interest Efektif" ||
        record["keterangan"] == "Interest Flat" ||
        record["keterangan"] == "Origination fee"
      ) {
        return <p>{record["approved"] + "%"}</p>;
      //Render kolom 
      } else {
        return <p>{record["approved"]}</p>;
      }
    }
  }
]

const columnsAssessmentDebitur = [
  {
    title: "Kategori",
    dataIndex: "kategori",
    key: "kategori",
  },
  {
    title: "Proposed",
    dataIndex: "proposed",
    key: "proposed",
    render: (_,record) => (
      //Render kolom maximum debt
      <p>{record['kategori'] == 'Maximum Debt' ? 'Rp' + record['proposed'].toLocaleString() : record['proposed']}</p>
    )
  },
  {
    title: "Approved",
    dataIndex: "approved",
    key: "approved",
    render: (_, record) => {
      //Render kolom
      let color = "";
      switch (record["approved"]) {
        case "Dapat diberikan pinjaman":
          color = "green-600";
          break;
        case "Tidak diberikan pinjaman":
          color = "red-500";
          break;
      }
      return (
        <div className={`bg-${color} px-10 py-3`}>
          <p className="">{record["approved"]}</p>
        </div>
      );
    },
  },
];

const FinalResult = (props) => {
  const {masterData} = props;
  const dataMaster = masterData['data'];

  const [grade,setGrade] = useState("");
  const [color,setColor] = useState("");
  const [resultMain,setResultMain] = useState([]);
  const [assessment1,setAssessment1] = useState([]);
  const [assessment2,setAssessment2] = useState([]);
  const [refresh,setRefresh] = useState(false)
  const [loading,isLoading] = useState(true)
  const [empty,setIsEmpty] = useState(false)

  const apiGetResultMain = async () => {
    isLoading(true);
    try {
      //Dummy Data
      // const data = await getAPIPython("result_main-get?id_debitur=100005983-C6");
      // const assessment1 = await getAPIPython("result_credit_assesment_debitur-get?id_debitur=100005983-C6")
      // const assessment2 = await getAPIPython("result_credit_assesment_remarks-get?id_debitur=100005983-C6")

      //Real Data
      const data = await getAPIPython("result_main-get?id_debitur=" + dataMaster["id"]);
      const assessment1 = await getAPIPython("result_credit_assesment_debitur-get?id_debitur=" + dataMaster["id"]);
      const assessment2 = await getAPIPython("result_credit_assesment_remarks-get?id_debitur=" + dataMaster["id"]);
      // console.log(dataMaster['id']);
      // const data = await getAPIPython("result_main-get?id_debitur=" + dataMaster['id'])
      // console.log(data);

      const gradeData = data["grade"];
      setResultMain(data["data"]);
      setAssessment1(assessment1["data"]);
      setAssessment2(assessment2["data"]);
      isLoading(false);

      // setGrade(grade);
      setGrade(gradeData);
      switch (gradeData) {
        case "A++":
          setColor("green-900");
          break;
        case "A+":
          setColor("green-800");
          break;
        case "A":
          setColor("green-700");
          break;
        case "A-":
          setColor("green-600");
          break;
        case "B++":
          setColor("green-500");
          break;
        case "B+":
          setColor("green-400");
          break;
        case "B":
          setColor("emerald-400");
          break;
        case "B-":
          setColor("emerald-300");
          break;
        case "C++":
          setColor("emerald-200");
          break;
        case "C+":
          setColor("teal-100");
          break;
        case "C":
          setColor("teal-100");
          break;
        case "C-":
          setColor("red-50");
          break;
        case "D++":
          setColor("red-100");
          break;
        case "D+":
          setColor("red-200");
          break;
        case "D":
          setColor("red-300");
          break;
        case "D-":
          setColor("red-400");
          break;
        case "E++":
          setColor("red-500");
          break;
        case "E+":
          setColor("red-600");
          break;
        case "E+":
          setColor("red-700");
          break;
        case "E+":
          setColor("red-800");
          break;
        case "E":
          setColor("red-900");
          break;
        case "E-":
          setColor("red-900");
          break;
      }
      setIsEmpty(false)
    } catch (error) {
      setIsEmpty(true)
    }
  }

  const handleRefresh = () => {
    setRefresh(!refresh);
  }

  useEffect(() => {
    apiGetResultMain();
  },[refresh])


  return (
    <div>
      <Button onClick={handleRefresh}>Refresh</Button>
      {empty ? <Empty/> : 
        <Skeleton avatar active paragraph={{rows:30}} loading={loading}>
          <div className="flex flex-row my-3">
            <p className="font-bold text-2xl mx-3">Final Scoring Report</p>
          </div>
          <div className="grid grid-cols-2">
            <div
              className={`bg-${color} w-[40%] rounded-lg drop-shadow-lg px-3 py-3 mx-auto my-auto`}
            >
              <div className="py-3">
                <p className="text-center">Final Score</p>
                <p className="font-bold text-[50px] text-center">{grade}</p>
              </div>
            </div>
            <div>
              <Table dataSource={resultMain} columns={columnsMain} pagination={false}/>
            </div>
          </div>
          <div className="grid grid-cols-2 mx-5">
            <Table
              className="mx-3 "
              dataSource={assessment1}
              columns={columnsAssesmentRemarks}
            />
            <Table dataSource={assessment2} columns={columnsAssessmentDebitur} pagination={false}/>
          </div>
        </Skeleton>
      }
    </div>
  );
}

export default FinalResult