import React from 'react'
import { DatePicker, Button, message, Form, Select, Radio, Space, Modal, Typography,  Alert } from "antd";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux"
import { getAPIPython, getAPI } from "../services/data";
import moment from "moment";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import NeracaComponent from "./Neraca/NeracaComponent";
import TableNeracaSummary from "./Neraca/TableNeracaSummary";
import TableNeracaValidation from "./Neraca/TableNeracaValidation";
import {  convertDateFormatNumber, convertDateFormatString, dataWithoutId } from "../utils/util";
import { 
  setIsFirstTime, 
  setEditing,
  setIsSubmit, 
  setIsInputPeriodeDisabled,
  setFinancialYr,
  fetchNeracaTahun, 
  createNeracaTahun, 
  updateNeracaTahun,
  fetchNeracaParameter,
  updateNeracaParameter, 
  fetchNeracaViewData, 
  fetchNeracaBalanceCheck,
  resetNeraca,
  setSkipUpdateState
} from "../store/actions/neracaSlice";

const { Group } = Radio;
const { Option } = Select;
const { confirm } = Modal;
const { Title } = Typography;

const errorSubmit = (response) => {
  message.error(response);
};

const success = (response) => {
  message.success(response);
};

const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}; 

//Function untuk filter API GET dropdown ke masing-masing kategori
const getFilter = (object,value) => {
  return object.filter((result) => result['id_kategori'] == value)
}

const postData = async (url,object) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(object),
    };

    const response = await fetch(
      process.env.REACT_APP_PYTHON_SCORING + url,
      requestOptions
    );
    console.log(response);
    const result = await response.json();
    console.log(result);
    return result;
}

const Neraca = (props) => {
  const {masterData} = props;
  // const dataMaster = masterData['data'];
  // console.log(masterData);

  const [form] = Form.useForm();
  const dispatch = useDispatch();

  // Load redux state
  const idDebitur = useSelector(state => state.home.id_debitur);
  const neracaTahun = useSelector(state => state.neraca.neracaTahun);
  const neracaParameter = useSelector(state => state.neraca.neracaParameter);  
  const financialYr = useSelector(state => state.neraca.financialYr); 
  const isEditing = useSelector(state => state.neraca.isEditing);
  const isFirstTime = useSelector(state => state.neraca.isFirstTime);
  const isLoadingSubmit = useSelector(state => state.neraca.isLoadingSubmit);
  const isSubmit = useSelector(state => state.neraca.isSubmit);
  const isInputPeriodeDisabled = useSelector(state => state.neraca.isInputPeriodeDisabled);
  
  const [currentAsset,setCurrentAsset] = useState([]);
  const [nonCurrentAsset,setNonCurrentAsset] = useState([])
  const [currentLiabilities,setCurrentLiabilities] = useState([]);
  const [nonCurrentLiabilities,setNonCurrentLiabilities] = useState([]);
  const [Stockholders,setStockholders] = useState([]);
  const [nonControllingInterest, setNonControllingInterest] = useState([]);
  const [alertYear, setAlertYear] = useState(false);
  const [alertMessage, setAlertMessage] = useState();
  const [view, setView] = useState(false);
  
  // const [data,setData] = useState([]);
  // // const [dataResult,setDataResult] = useState([]);
  // const [trigger,setTrigger] = useState(false);
  // const [year,setYear] = useState()
  // const [yearArray,setYearArray] = useState([])
  // // const [submittedYear,setSubmittedYear] = useState()
  // const [disabled,setDisabled] = useState(true)
  // const [disabledYear,setDisabledYear] = useState(true);
  const disabledMonthObj = {
    m1: true,
    m2: true,
    m3: true
  };
  const [disabledMonth,setDisabledMonth] = useState(disabledMonthObj);
  const [ytd,setYtd] = useState({});
  const selectedYearObj = {
    y1: null,
    y2: null,
    y3: null,
  };
  const [selectedYear, setSelectedYear] = useState(selectedYearObj);
  const selectedMonthObj = {
    m1: null,
    m2: null, 
    m3: null
  }
  const [selectedMonth, setSelectedMonth] = useState(selectedMonthObj);

  //API Get Dropdown
  const apiGetDropdownNeracaKeterangan = async () => {
    const data = await getAPIPython("neraca_dropdown");
    const result = data["data"];

    //Filter yang kategori Current Asset
    const asset = getFilter(result, "ka001");
    setCurrentAsset(asset);

    //Filter yang kategori Non Current Asset
    const nonAsset = getFilter(result, "ka002");
    setNonCurrentAsset(nonAsset);

    //Filter yang kategori Current Liablities
    const currentLiabilities = getFilter(result, "ka003");
    setCurrentLiabilities(currentLiabilities);

    //Filter yang kategori Non Current Liabilities
    const nonCurrentLiabilities = getFilter(result, "ka004");
    setNonCurrentLiabilities(nonCurrentLiabilities);

    //Filter yang kategori Stockholders Equity
    const stockholders = getFilter(result, "ka005");
    setStockholders(stockholders);

    //Filter yang kategori Non Controlling Interest
    const nonControllingInterest = getFilter(result,"ka006");
    setNonControllingInterest(nonControllingInterest);
  };

  const apiGetDropdownNeracaTahun = async (id) => {
    const res = await dispatch(fetchNeracaTahun(id)).unwrap();
    if(res.length !== 0){
      const data = res[0];
      const periodeField = {};
      const disabledMonth = {};
      
      for(let i = 1; i <= 3; i++){
        const yKey = `y${i}`;
        const mKey = `m${i}`;
        const date = moment(data[yKey], "YYYY-MM");
        periodeField[`ytd${i}`] = date.format("MM") === "12" ? "Tidak" : "Ya";
        periodeField[yKey] = date;
        periodeField[mKey] = date.format("MM") === "12" ? null : date;
        disabledMonth[mKey] = date.format("MM") === "12" ? true : false;
      }

      setDisabledMonth(disabledMonth);
      form.setFieldsValue(periodeField);

      if(neracaTahun.some(item => item.id_debitur !== id)){
        dispatch(setEditing(true));
        dispatch(setIsSubmit(false));  
        dispatch(setFinancialYr('')); 
      }

      dispatch(setIsFirstTime(false));
      dispatch(setIsInputPeriodeDisabled(true));
    }else{
      dispatch(setIsFirstTime(true));
      dispatch(setEditing(false));
      dispatch(setIsSubmit(false));
      dispatch(setIsInputPeriodeDisabled(false));
      dispatch(setFinancialYr('')); 
    }
  } 

  useEffect(() => {
    apiGetDropdownNeracaTahun(idDebitur);  
    apiGetDropdownNeracaKeterangan();
    // console.log("NERACA RENDERED");
  }, [masterData, idDebitur]);

  useEffect(() => {
    if(alertYear){
      const timeId = setTimeout(() => {
        setAlertYear(false);
      }, 3000);
      
      return () => {
        clearTimeout(timeId);
      }
    }
  }, [alertYear]);

  const validateYearMonthOrder = (data) => {
    // const currentYear = moment().year();
    // const currentMonth = moment().month() + 1;

    const dateArray = [];
    for(let i = 0; i < 3; i++){
      dateArray.push(moment(`${data[`y${i + 1}`]}-${data[`m${i + 1}`]}`, 'YYYY-M').format('YYYY-MM'));
    }
    
    let isValid = true;     
    let prevYear = null;
    let prevMonth = null; 

    if(
      neracaTahun.length !== 0 &&
      dateArray[0] === neracaTahun[0]["y1"] && 
      dateArray[1] === neracaTahun[0]["y2"] &&
      dateArray[2] === neracaTahun[0]["y3"]
    ){
      isValid = false;
      setAlertMessage("Data periode sudah ada");
    }else{
      for(let i = 0; i < dateArray.length; i++){
        const date = moment(dateArray[i]);      
        const year = date.year();
        const month = date.month() + 1;
  
        // if(year > currentYear){
        //   isValid = false;
        //   setAlertMessage("Tahun tidak bisa melebihi tahun sekarang");
        //   break;
        // }
  
        // if(currentYear - year > 5){
        //   isValid = false;
        //   setAlertMessage("Tahun maksimal 5 tahun sebelum");
        //   break;
        // }
  
        // if(year === currentYear && month >= currentMonth){
        //   isValid = false;
        //   setAlertMessage("Tahun sekarang tidak bisa memiliki bulan melebihi bulan sekarang");
        //   break;
        // }
  
        if(prevYear !== null && ((year !== prevYear && year - prevYear !== 1) || (year === prevYear && month <= prevMonth))){
          isValid = false;
          setAlertMessage("Silakan pilih periode secara berurutan");
          break;
        }
  
        prevYear = year;
        prevMonth = month;
      }
    }

    return isValid;
  }    

  //Set Financial Year
  const handleChangeNeracaTahun = (value) => {
    // console.log("TAHUN DROPDOWN", value);
    // setYear(value)
    // setNeracaTahun(value) 
    const convertedYear = convertDateFormatNumber(value, "MMMM YYYY");
    dispatch(setFinancialYr(convertedYear));

    if(!isEditing && !isSubmit){
      dispatch(setEditing(true));
    }

    // setTrigger(!trigger);
  }
  
  //Set Y1,Y2,Y3/YTD
  // const handleChangeYear = (values) => {
  //   let object = values
  //   for(let x in object){
  //     object[x] = moment(object[x]).format("YYYY")
  //   }
  //   object['id_debitur'] = dataMaster['id']
  //   console.log(object);
  //   postData("tahunneraca",object).then((response) => {
  //     // console.log(response);
  //     if(response['statusCode'] == '200'){
  //       success(response['message'])
  //       setYearArray(response['data'])
  //       setDisabledYear(false)
  //     }else{
  //       errorSubmit(response['message']);
  //     }
  //   }).catch((error) => {
  //     errorSubmit("Server Error !");
  //     console.log(error);
  //   })
  // }

  const handleChangeYtd = (name, value) => {
    setYtd({ ...ytd, [name]: value });
    const monthName = name.replace("ytd", "m");

    if(value === "Tidak"){
      setSelectedMonth({ ...selectedMonth, [monthName]: null });
      setDisabledMonth({ ...disabledMonth, [monthName]: true });
      form.setFieldsValue({ [monthName]: null });
    } else {
      setDisabledMonth({ ...disabledMonth, [monthName]: false });
    }
  }  

  const handleChangeYearPicker = (name, value) => {
    const year = value ? value.year() : null;
    const monthName = name.replace("y", "m");
    setSelectedYear({ ...selectedYear, [name]: year });

    if(year === moment().year() && moment(selectedMonth[monthName], 'MMMM').format('M') >= moment().month()){
      setSelectedMonth({ ...selectedMonth, [monthName]: null });
      form.setFieldsValue({ [monthName]: null });
    }
  };
  
  const handleChangeMonth = (name, value) => {
    const month = value ? value.format('MMMM') : null;
    setSelectedMonth({ ...selectedMonth, [name]: month });
  };

  const disabledMonthPicker = (year) => {
    return current => {
      return year === moment().year() && current.month() >= moment().month();
    }
  }

  const disabledYearPicker = (current) => {
    return current.year() > moment().year() || current.year() < moment().year() - 5;
  }

  //Button for view data
  // const handleClick = async () => {
  //   try{
  //     if (masterData["data"] == null)
  //       return errorSubmit("No Master Data Were Found !");

  //     //Get Data Asset
  //     const dataAsset = await getAPIPython(
  //       "neraca-get?id_debitur=" + masterData["data"]["id"]
  //     );
  //     // console.log(dataAsset);
  //     let dataAssetPreProcess = dataAsset['data'];
  //     console.log(dataAssetPreProcess,"Data Asset PreProcess");
  //     //Add Comma Separator
  //     for(let x in dataAssetPreProcess){
  //       if(dataAssetPreProcess[x]['y1'] != ''){
  //         dataAssetPreProcess[x]['y1'] = numberWithCommas(dataAssetPreProcess[x]['y1']);
  //       }

  //       if(dataAssetPreProcess[x]['y2'] != ''){
  //         dataAssetPreProcess[x]['y2'] = numberWithCommas(dataAssetPreProcess[x]['y2']);
  //       }

  //       if(dataAssetPreProcess[x]['ytd'] != ''){
  //         dataAssetPreProcess[x]['ytd'] = numberWithCommas(dataAssetPreProcess[x]['ytd']);
  //       }
  //     }

  //     console.log("Data Asset Pre Process", dataAssetPreProcess);
  //     const resultAsset = dataAssetPreProcess;
  //     console.log(resultAsset);
  //     if (resultAsset != null) {
  //       if (resultAsset.length == 0)
  //         return errorSubmit("No asset were found !");
  //     } else {
  //       return errorSubmit("Failed to fetch data");
  //     }

  //     const dataTotal = await getAPIPython(
  //       "neracaperhitungan-get?id_debitur=" + masterData["data"]["id"]
  //     );
  //     // console.log(dataTotal);
  //     const resultTotal = dataTotal["data"];
  //     console.log(resultTotal);
  //     if (resultTotal != null) {
  //       if (resultTotal.length != 0) {
  //         setDataResult(resultTotal);
  //       }
  //     }
  //     setView(true);
  //     // setDisabledViewButton(true)
  //     setData(resultAsset);
  //   }catch(error){
  //     console.log(error)
  //   }
  // };

  //Button for submit all (neracaperhitungan)
  // const handleClickAll = async () => {
  //   console.log(masterData["data"]);
  //   if(masterData['data'] == null){
  //     console.log("Hello");
  //     return errorSubmit("No master data were found !")
  //   }
  //   let object = {
  //     id_debitur: masterData['data']["id"],
  //     year: year,
  //   };
  //   console.log(object);
  //   try {
  //     postData("neracaperhitungan",object)
  //       .then((response) => {
  //         if(response['statusCode'] == 200){
  //           console.log(response);
  //           return success(response['message'])
  //         }else{
  //           return errorSubmit(response['message'])
  //         }
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //         return errorSubmit("Failed to submit data");
  //       })
  //   } catch (error) {
  //     console.log("Error");
  //   }
  // };

  const handleClickAll = async () => {
    try {
      const inputNeracaParameter = neracaParameter.filter(item => neracaTahun.some((yearItem) => Object.values(yearItem).includes(item.year)));
      const payload = JSON.parse(JSON.stringify(inputNeracaParameter));
      
      if(payload.length !== 0){
        dispatch(setSkipUpdateState(true));
        const resInputtedNeraca = await dispatch(fetchNeracaParameter(idDebitur)).unwrap();
        dispatch(setSkipUpdateState(true));
        
        if(JSON.stringify(dataWithoutId(payload)) !== JSON.stringify(dataWithoutId(resInputtedNeraca))){
          await dispatch(updateNeracaParameter(payload)).unwrap();
        }

        const resNeracaView = await dispatch(fetchNeracaViewData(idDebitur)).unwrap();
        if(resNeracaView.length !== 0){
          await dispatch(fetchNeracaBalanceCheck(idDebitur)).unwrap();
          dispatch(setEditing(false));
          dispatch(setIsSubmit(true));
          success("Sukses submit sheet: [Neraca]");
          setView(true);
        }else{
          return errorSubmit("Data tidak ditemukan!");
        }
      }else{
        return errorSubmit("Data tidak ditemukan!");
      }
    } catch (error) {
      errorSubmit("Gagal menyimpan data")
    }
  }

  // const handleSettings = (value) => {
  //   console.log('Value Settings', value);
  //   let text = '';
  //   switch(value['setting']){
  //     case 'm':
  //       text = 'million'
  //       break;
  //     case 'b':
  //       text = 'bilion'
  //       break;
  //     default :
  //       text = 'normal'
  //       break;
  //   }
  //   setDisabledValueSettings(true)
  //   setValueSettings(value)
  //   success(`Value settings is set to ${text}`)
  // }

  //Object untuk di passing ke child component
  const objectCurrentAsset = {
    // year: financialYr,
    masterData: masterData,
    // settingValue : valueSettings,
  }

  // Content untuk render seberapa banyak komponen
  const content = [
    {
      dropdown: currentAsset,
      title: "Current Asset",
      idka: "ka001"
    },
    {
      dropdown: currentLiabilities,
      title: "Current Liabilities",
      idka: "ka003"
    },
    {
      dropdown:nonCurrentAsset,
      title: "Non Current Asset",
      idka: "ka002"
    },
    {
      dropdown: nonCurrentLiabilities,
      title: "Non Current Liabilities",
      idka: "ka004"
    },
    {
      dropdown:Stockholders,
      title: "Stockholders Equity",
      idka: "ka005"
    },
    {
      dropdown: nonControllingInterest,
      title: "Non Controlling Interest",
      idka: "ka006"
    }
  ]

  //Render Input Year 1 / Year 2 / Year 3
  const yearContent = [
    {
      label: "YTD",
      name: "ytd1",
      message: "Masukkan YTD",
      radio_data: ["Ya", "Tidak"],
      type: "Radio",
      required: true
    },
    {
      label: "YTD",
      name: "ytd2",
      message: "Masukkan YTD",
      radio_data: ["Ya", "Tidak"],
      type: "Radio",
      required: true
    },
    {
      label: "YTD", 
      name: "ytd3",
      message: "Masukkan YTD",
      radio_data: ["Ya", "Tidak"],
      type: "Radio",
      required: true
    },
    {
      label: "Year 1",
      name: "y1",
      message: "Masukkan Year 1",
      type: "PickerYear",
      required: true
    },
    {
      label: "Year 2",
      name: "y2",
      message: "Masukkan Year 2",
      type: "PickerYear",
      required: true
    },
    {
      label: "Year 3",
      name: "y3",
      message: "Masukkan Year 3",
      type: "PickerYear",
      required: true
    }, 
    {
      label: "Month",
      name: "m1",
      message: "Masukkan Month",
      type: "PickerMonth",
      required: false
    },
    {
      label: "Month",
      name: "m2",
      message: "Masukkan Month",
      type: "PickerMonth",
      required: false
    },
    {
      label: "Month",
      name: "m3",
      message: "Masukkan Month",
      type: "PickerMonth",
      required: false
    },
  ]

  // const settings = [
  //   {
  //     text: "Normal",
  //     value: 'n'
  //   },
  //   {
  //     text: "In Million",
  //     value: "m"
  //   },
  //   {
  //     text: "In Billion",
  //     value: "b"
  //   }
  // ]

  const showConfirmNeracaTahun = (payload) => {
    confirm({
      title: "Apakah Anda ingin mengubah periode yang telah Anda input?",
      icon: <ExclamationCircleOutlined />,
      okText: "Ya",
      okType: "danger",
      cancelText: "Tidak",
      onOk: async () => {
        await handleConfirmModalOk(payload);       
      },
      onCancel: () => {
        dispatch(setIsInputPeriodeDisabled(false));
      },
      centered: true
    });
  };

  const handleConfirmModalOk = async (payload) => {
    try {
      await dispatch(updateNeracaTahun(payload)).unwrap();
      await apiGetDropdownNeracaTahun(idDebitur);      
      success('Data tersimpan');
    } catch (error) {
      errorSubmit('Gagal menyimpan data');
    }
  };
  
  const handleSubmitNeracaTahun = async (values) => {
    const payload = JSON.parse(JSON.stringify(values.values));

    delete payload.ytd1;
    delete payload.ytd2;
    delete payload.ytd3;
    
    payload.y1 = moment(payload.y1).format('YYYY');
    payload.y2 = moment(payload.y2).format('YYYY');
    payload.y3 = moment(payload.y3).format('YYYY');
    payload.m1 = (payload.m1 != null) ? moment(payload.m1).format('M') : '12';
    payload.m2 = (payload.m2 != null) ? moment(payload.m2).format('M') : '12';
    payload.m3 = (payload.m3 != null) ? moment(payload.m3).format('M') : '12';
    payload.id_debitur = idDebitur;

    // console.log(payload) 

    try {
      if(masterData.length !== 0){
        const validateYear = validateYearMonthOrder(payload);
        if(validateYear){
          const res = await dispatch(fetchNeracaTahun(idDebitur)).unwrap();
          if(res.length !== 0){
            showConfirmNeracaTahun(payload);
          }else{
            await dispatch(createNeracaTahun(payload)).unwrap();
            await apiGetDropdownNeracaTahun(idDebitur);
            success("Data tersimpan");      
          }
        }else{
          setAlertYear(true);
        }
      }else{
        errorSubmit("Gagal menyimpan data");
      }
    } catch (error) {
      errorSubmit("Gagal menyimpan data");
    }
  } 

  const handleClickEdit = () => {
    dispatch(setIsInputPeriodeDisabled(false));
    dispatch(setEditing(true));
    dispatch(setIsSubmit(false));
  }

  return (
    <div className="mx-5">
      <div>
        {alertYear && (
          <Alert
            message="Warning"
            description={alertMessage}
            type="warning"
            showIcon
            closable
            onClose={() => setAlertYear(false)} 
          />
        )}
      </div>
      <p className="text-2xl font-bold my-4">Periode</p>
      <Form
        // disabled={!disabledValueSettings}
        // onFinish={(values) => handleChangeYear(values)
        form={form}
        disabled={isInputPeriodeDisabled}
        onFinish={(values) => handleSubmitNeracaTahun({ values })}
      >
        <div className="grid grid-cols-3">
          {/* Looping sebanyak content (Year Content) */}
          {yearContent.map((item, i) => {
            return (
              <Form.Item
                key={i}
                // key={item.name}
                label={item.label}
                name={item.name}
                rules={[
                  {
                    required: item.required,
                    message: item.message,                    
                  },
                  {
                    validator: (_, value) => {
                      if(item.type === 'PickerMonth' && ytd[item.name.replace("m", "ytd")] === 'Ya' && !selectedMonth[item.name]){
                        return Promise.reject(item.message);
                      }
                      return Promise.resolve();
                    },
                  },                  
                ]}
                style={item.label === "Month" ? {marginLeft: "30px"} : null}
              >
                {item.type?.includes("Radio") ? (
                  <Group onChange={(e) => handleChangeYtd(item.name, e.target.value)}>
                    {item.radio_data.map((obj, i) => {
                      return <Radio value={obj}>{obj}</Radio>
                    })}
                  </Group>
                ) : item.type?.includes("PickerYear") ? (
                  <DatePicker
                    picker="year" placeholder="Select Year"
                    onChange={(value) => handleChangeYearPicker(item.name, value)} 
                    disabledDate={disabledYearPicker}
                  />
                ) : ( 
                  <DatePicker 
                    picker="month" placeholder="Select Month" format="MMMM"
                    popupClassName="month-navigation" onChange={(value) => handleChangeMonth(item.name, value)}
                    disabled={disabledMonth[item.name] || isInputPeriodeDisabled} 
                    disabledDate={disabledMonthPicker(selectedYear[item.name.replace("m", "y")])}
                  /> 
                )}
              </Form.Item>              
            );
          })}
        </div>
        <Form.Item wrapperCol={{ span: 12, offset: 9 }}>
          <Button className="float-right" htmlType="submit"> 
            Save
          </Button>
        </Form.Item>
      </Form>
      <p className="text-2xl font-bold my-4">Financial Year</p>
      {/* Render tahun financial */}
      <label>Tahun Financial : </label>
      <Select
        onChange={handleChangeNeracaTahun}
        // disabled={disabledYear}
        value={neracaTahun.some((item) => Object.values(item).includes(financialYr)) ? convertDateFormatString(financialYr, "MMMM YYYY") : ""}
        disabled={isFirstTime}
        style={{ width: "15%" }}
      >
        {/* Looping jumlah tahun */}
        {neracaTahun.flatMap((item) => (
          Object.keys(item)
            .filter(key => key.startsWith('y'))
            .map((key, i) => {
              return (
                <Option key={i} value={convertDateFormatString(item[key], "MMMM YYYY")}>
                  {convertDateFormatString(item[key], "MMMM YYYY")}
                </Option>
              );
            }) 
        ))}
      </Select>
      {/* <DatePicker onChange={handleChange} picker="year" status={warning} /> */}
      <div className="grid grid-cols-2">
        {/* Looping Neraca Component Based On Content */}
        {content.map((item, i) => {
          return (
            <NeracaComponent
              key={i}
              object={JSON.stringify({
                ...objectCurrentAsset,
                ...{
                  dropdown: item.dropdown,
                  title: item.title,
                  idka: item.idka,
                  // trigger: trigger
                },
              })}
            />
          );
        })}
      </div>
      <div className="py-5">     
        <Space direction='horizontal' style={{display: 'flex', float: 'right', width: '25%'}}>
          <Button htmlType="button" onClick={handleClickEdit} disabled={isFirstTime ? true : isSubmit ? false : true}>
            Edit
          </Button>          
          <Button htmlType="button" type="primary" danger onClick={handleClickAll} loading={isLoadingSubmit} 
            className="float-right mx-auto" disabled={isFirstTime ? true : isEditing ? false : true}
          >
            Submit Sheet
          </Button>
        </Space>
        {view && 
          <div style={{ marginTop: "65px", marginBottom: "10px", marginLeft: "8px", marginRight: "8px" }}>       
            <TableNeracaSummary />            
            <TableNeracaValidation />
          </div>}
      </div>     
      {/* <div className="mx-8 my-8">
        <Button onClick={handleClick} disabled={disabled}>
          View Data
        </Button>
      </div> */}
    </div>
  );
}

export default Neraca