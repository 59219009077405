import { useSelector } from "react-redux";

export const CreditGivenTableData = () => {
	const idDebitur = useSelector(state => state.home.id_debitur);
  const dataInvoice = useSelector(state => state.creditGiven.dataInvoice);
  const dataPembiayaan = useSelector(state => state.creditGiven.dataPembiayaan);

	const colCreditGiven = [
    {
      title: "No. Invoice",
      dataIndex: "nomor_invoice",
      key: "no_invoice",
    },
    {
      title: "Invoice Date",
      dataIndex: "invoice_date",
      key: "invoice_date",
    },
    {
      title: "Payment Date",
      dataIndex: "payment_date",
      key: "payment_date",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (_, record) => {
        return <>{record['amount'].toLocaleString()}</>
      }
    },
    {
      title: "Tax",
      dataIndex: "nilai_pajak",
      key: "tax",
    },
  ];

	const dataSourceCreditGiven = dataInvoice.some((item) => item.id_debitur === idDebitur)
		? dataInvoice 
		: [];
	
	const colSummaryCreditGiven = [
		{
			title: "Persentase Pembiayaan",
			dataIndex: "persentase_pembiayaan_maksimal",
			key: "persentase_pembiayaan_maksimal",
			render: (_, record) => {
				return <>{record['persentase_pembiayaan_maksimal'] * 100}</>
			}
		},
		{
			title: "Jumlah Pembiayaan",
			dataIndex: "jumlah_pembiayaan_invoice",
			key: "jumlah_pembiayaan",
			render: (_, record) => {
				return <>{record['jumlah_pembiayaan_invoice']?.toLocaleString() || ""}</>
			}
		},
		{
			title: "Pembiayaan Maksimal",
			dataIndex: "pembiayaan_maksimal",
			key: "pembiayaan_maksimal",
			render: (_, record) => {
				return <>{record['pembiayaan_maksimal']?.toLocaleString() || ""}</>
			}
		},
		{
			title: "Pembiayaan Maksimal IKI",
			dataIndex: "pembiayaan_maksimal_iki",
			key: "pembiayaan_maksimal_iki",
			render: (_, record) => {
				return <>{record['pembiayaan_maksimal_iki'].toLocaleString()}</>
			}
		},
		{
			title: "Pertanggungan Fidusia",
			dataIndex: "pertanggungan_fidusia",
			key: "pertanggungan_fidusia",
		},
	];
	
	const dataSourceSummaryCreditGiven = dataPembiayaan.some((item) => item.id_debitur === idDebitur)
		? dataPembiayaan
		: [];

	return { colCreditGiven, dataSourceCreditGiven, colSummaryCreditGiven, dataSourceSummaryCreditGiven };
}