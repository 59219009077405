import { useSelector } from "react-redux";
import { convertDateFormatString, formatText } from "../../utils/util";

export const PLTableData = () => {
	const idDebitur = useSelector(state => state.home.id_debitur);
  const neracaTahun = useSelector(state => state.neraca.neracaTahun);
	const PLSummary = useSelector(state => state.pl.PLSummary);
	const PLDropdown = useSelector(state => state.pl.PLDropdown);	
	const PLView = useSelector(state => state.pl.PLView);
	let dataSourcePL = [];

	const rowClassPL = (record, index) => {
		if(
			PLSummary.length > 0 && 
			Object.keys(PLSummary[0]).filter(key => key !== "id_debitur" && 
			key !== "year" && 
			key !== "profit_before_tax")
			.map(item => formatText(item)).includes(record["keterangan"])  
		){
			return "highlight-row";
		}
	};

	const colPL = [
		{
			title: "Profit and Loss",
			dataIndex: "keterangan",
			key: "keterangan",
			render: (_, record) => {       
				const applyMargin =  PLSummary.length > 0 && !Object.keys(PLSummary[0]).filter(key => key !== "id_debitur" && 
					key !== "year" && 
					key !== "profit_before_tax")
					.map(item => formatText(item)).includes(record["keterangan"]);  
				return (
					<p style={{ marginLeft: applyMargin ? "30px" : "" }}>{record["keterangan"]}</p>
				);       
			},
		},
		...Array.from({ length: 3 }, (v, i) => {
			const key = `y${i + 1}`;
			const title = (neracaTahun.length !== 0 && neracaTahun[0]["id_debitur"] === idDebitur) 
				? convertDateFormatString(neracaTahun[0][key], "MMM YYYY") 
				: key;
		
			return {
				title,
				dataIndex: key,
				key,
				render: (_, record) => {
					return (
						<p>
							{(typeof record[key] === "number") ? "Rp" + record[key].toLocaleString() : ""}
						</p>
					);
				}
			}
		}) 
	];

	if(neracaTahun.length !== 0){
		const yearOrder = neracaTahun[0];
		const yearKey = Object.keys(yearOrder).filter(key => key.startsWith('y'));

		const createIncomeExpenseRow = (segment) => {
			const filteredData = PLDropdown.filter(item => item.segment === segment);
			const sortedData = filteredData.sort((a, b) => {
				const aPosition = a.category.includes('-') ? 1 : 0;
				const bPosition = b.category.includes('-') ? 1 : 0;
		
				if(aPosition !== bPosition){
					return aPosition - bPosition;
				}
		
				return a.id.localeCompare(b.id);
			});
		
			const result = sortedData.map(item => {
				const value = {};
				yearKey.forEach(ykey => {
					value[ykey] = PLView.find(plItem =>
						plItem.income_expense_id === item.id &&
						plItem.year === yearOrder[ykey])?.value || 0;
				});
			
				return {
					keterangan: item.type_income_expense,
					...value
				};
			});
		
			return result;
		};
		
		const createTotalRow = (propName) => {
			const value = {};
		
			yearKey.forEach((key) => {
				value[key] = PLSummary.find(item => item.year === yearOrder[key])?.[propName] || 0;
			})
		
			const result = {
				keterangan: formatText(propName),
				...value
			};
		
			return result;
		};
	
		const createGroupRow = (segment, propName) => {
			const IERow = createIncomeExpenseRow(segment);
			const totalRow = createTotalRow(propName);
		
			return [...IERow, totalRow];
		};
		
		if(PLView.some((item) => item.id_debitur === idDebitur)){
			dataSourcePL = [
				...createGroupRow("GP", "gross_profit"), ...createGroupRow("OE", "operating_expense"),
				createTotalRow("ebitda"), ...createGroupRow("EB", "ebit"), ...createGroupRow("TO", "total_others_net"),
				createTotalRow("profit_before_tax"), ...createGroupRow("NP", "net_profit")
			];
		}
	}
	
	return { rowClassPL, colPL, dataSourcePL };
};
