import React from "react";
import { Form, Input, InputNumber, Button, message, Select, Table,Row,Col } from "antd";
import { useState, useEffect } from "react";
import { getAPIPython, getAPI } from "../../services/data";

const { Option } = Select;

const errorSubmit = (text) => {
  message.error(text);
};

const success = (text) => {
  message.success(text);
};

const postData = async (object,url) => {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(object),
  };

  const response = await fetch(
    process.env.REACT_APP_PYTHON_SCORING + url,
    requestOptions
  );
  console.log(response);
  const result = await response.json();
  console.log(result);
  return result;
};

const NewLoanDSCR = (props) => {
  const { masterData,trigger } = props;

  const [period,setPeriod] = useState([]);
  const [inputtedPeriod,setInputtedPeriod]= useState([]);
  const [refresh,setRefresh] = useState(false)
  const [column,setColumns] = useState([]);
  const [dataTable,setDataTable] = useState([]);
  const [boolView,setBoolView] = useState(false);
  const [inputtedData,setInputtedData] = useState([])

  const apiGetPeriod = async () => {
    const data = await getAPIPython("capabilities_period-get?id_debitur=" + masterData['id']);
    // const data = await getAPIPython("capabilities_period-get?id_debitur=Austin1");
    // console.log(data['data'][0]["last period"]);
    setPeriod(data['last period']);
  };

  const apiGetDataNewLoan = async () => {
    const data = await getAPIPython("view_capabilities_new_loan?id_debitur=" + masterData['id']);
    // const data = await getAPIPython("view_capabilities_new_loan?id_debitur=testingresult01");
    console.log(data['value'],data['periode']);

    const num = data['periode']

    //Generate columns depends on length of the key
    let array = [];
    for(let x = 1; x<=num;x++){
      let object = {
        title:x.toString(),
        dataIndex:x.toString(),
        align:'right',
        render:(_,record) => {
          console.log(record[x.toString()]);
          if(record[x.toString()] != '' && record[x.toString()] != 0){
            // console.log("MASUK IF",record[x.toString()],record[x.toString()].toLocaleString());
            return record[x.toString()] = record[x.toString()].toLocaleString();
          }else{
            return record[x.toString()];
          }
        }
      }
      array.push(object)
    }
    array.unshift({
      title: "Keterangan",
      dataIndex: "Description",
      fixed: "left",
      render: (_, record) => {
        let flag = false;
        if (
          record["Description"].includes("Ending Balance") ||
          record["Description"] == "Repayment Proportion" ||
          record["Description"] == "Amortized Payment" ||
          record["Description"] == "Principal" ||
          record["Description"] == "Interest Efektif" ||
          record["Description"] == "Interest Flat" ||
          record["Description"] == " Interest Charged to Client" ||
          record["Description"] == "Principal + Interest" ||
          record["Description"] == "Historical DSCR" ||
          record["Description"] == "Current DSCR" ||
          record["Description"] == "Projection DSCR"
        ) {
          flag = true;
        }
        return (
          <p className={flag ? "font-bold" : ""}>{record["Description"]}</p>
        );
      },
    });
    setColumns(array);
    setDataTable(data['value']);
  }

  useEffect(() => {
    apiGetPeriod();
  }, [refresh]);

  useEffect(() => {
    apiGetDataNewLoan();
  },[boolView])

  const content = [
    {
      label: "Period",
      name: "Periode",
      message: "Masukkan period",
      required: true,
      select_data: period,
    },
    {
      label: "Addition - Previous Loan (Historical)",
      name: "Historical_DSCR",
      message: "Masukkan addition - previous loan (historical)",
      required: true,
    },
    {
      label: "Addition - Previous Loan (Current)",
      name: "Current_DSCR",
      message: "Masukkan addition - previous loan (current)",
      required: true
    },
    {
      label: "Addition - Previous Loan (Projection)",
      name: "Projection_DSCR",
      message: "Masukkan addition - previous loan (projection)",
      required: true
    },
  ];

  const columnsInputted = [
    {
      title:"Periode",
      dataIndex: 'Periode'
    },
    {
      title:"Historical DSCR",
      dataIndex: 'Historical_DSCR',
      render: (_,record) => {
        return (
          <p>{'Rp' + record['Historical_DSCR'].toLocaleString()}</p>
        )
      }
    },
    {
      title:"Current DSCR",
      dataIndex: 'Current_DSCR',
      render: (_,record) => {
        return (
          <p>{'Rp' + record['Current_DSCR'].toLocaleString()}</p>
        )
      }
    },
     {
      title:"Projection DSCR",
      dataIndex: 'Projection_DSCR',
       render: (_,record) => {
        return (
          <p>{'Rp' + record['Projection_DSCR'].toLocaleString()}</p>
        )
      }
      
    },
  ]

  const handleSubmit = (values) => {
    let found = false;
    values["Id_Debitur"] = masterData["id"];

    inputtedPeriod.map((item, i) => {
      if (item == values["Periode"]) found = true;
    });
    console.log(inputtedPeriod);

    if(found) return errorSubmit("Period sudah pernah diinput !");
    // setInputtedPeriod([...inputtedPeriod, values["Period"]]);

    postData(values,'capabilities_dscr')
      .then((response) => {
        if (response["statusCode"] == 200) {
          setInputtedData([...inputtedData,values])
          success(response["message"]);
          setInputtedPeriod([...inputtedPeriod, values["Periode"]]);
        } else {
          errorSubmit(response["message"]);
        }
      })
      .catch((error) => {
        console.log(error);
        errorSubmit("Internal Server Error");
      });
  };

  const handleSubmitAll = () => {
    console.log("submit all");
    let object = {
        Id_Debitur : masterData['id']
    }

    postData(object, "summary_dscr").then((response) => {
      if(response['statusCode'] == 200){
        success(response['message']);
        setBoolView(true)
      }else{
          errorSubmit(response['message']);
      }
    }).catch((error) => {
        console.log(error);
        errorSubmit("Internal Server Error")
    })
  }

  const handleRefresh = () =>{
    setInputtedPeriod([]);
    setRefresh(!refresh)
  }

  return (
    <div>
      <p className="text-2xl font-bold text-center my-5">
        New Loan - Debt Service Coverage Ratio
      </p>
      <Form
        disabled={trigger}
        onFinish={(values) => handleSubmit(values)}
        labelCol={{
          span: 9,
        }}
        wrapperCol={{
          span: 10,
        }}
        initialValues={{
          remember: true,
        }}
      >
        <div className="grid grid-cols-2">
          {content.map((item, i) => {
            return (
              <Form.Item
                key={i}
                label={item.label}
                name={item.name}
                rules={[
                  {
                    required: item.required,
                    message: item.message,
                  },
                ]}
              >
                {item.name == "Periode" ? (
                  <Select showSearch>
                    {item.select_data.map((item, i) => {
                      return (
                        <Option value={item} key={i}>
                          {item}
                        </Option>
                      );
                    })}
                  </Select>
                ) : (
                  <InputNumber
                    style={{ width: "100%" }}
                    addonBefore={"Rp."}
                    formatter={(value) =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                  />
                )}
              </Form.Item>
            );
          })}
        </div>
        <Form.Item wrapperCol={{ span: 22 }}>
          <Button className="float-right" htmlType="submit">
            Submit
          </Button>
          <Button
            className="float-right mx-3"
            onClick={() => handleSubmitAll()}
          >
            Submit All
          </Button>
          <Button onClick={handleRefresh}>Refresh Period</Button>
        </Form.Item>
      </Form>
      <Table dataSource={inputtedData} columns={columnsInputted} pagination={false}/>
      {boolView && (
        <Table
          pagination={false}
          dataSource={dataTable}
          columns={column}
          scroll={{ x: 2000 }}
          rowClassName={(record, index) => {
            if (record["Description"].includes("Ending Balance")) {
              return "bg-zinc-100";
            }
          }}
        />
      )}
    </div>
  );
};

export default NewLoanDSCR;
