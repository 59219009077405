import React from 'react'
import {
    Result
} from 'antd'

const ErrorPage = ({
  status,
  title,
  subTitle,
  extra
}) => {
  return (
    <div style={{margin: "auto"}}>
      <Result
        status={status}
        title={title}
        subTitle={subTitle}
        extra={extra}
      />
    </div>
  )
}

export default ErrorPage