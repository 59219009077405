import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { 
    getAPI, 
    putAPI,
    getAPIPython,
    postAPIPython,
} from '../../services/data'

// Debtor Profile Info
export const fetchDropdownIndikator = createAsyncThunk(
    'debtorProfile/fetchDropdownIndikator',
    async (_, thunkAPI) => {
        try {
            const resSkillTenure = await getAPI(`snk`);
            const resReliability = await getAPI(`reliability`);
            const resDependency = await getAPI(`dependency`);
            const resHistoricalProfile = await getAPI(`historical-profile`);
            const resHistoricalLoan = await getAPI(`historical-loan-in-iki`);
            const resHistoricalPayment = await getAPI(`historical-payment`);
            // console.log("SUKSES API", response)
            return {
                skillTenure: resSkillTenure.data,
                reliability: resReliability.data,
                dependency: resDependency.data,
                historicalProfile: resHistoricalProfile.data,
                historicalLoan: resHistoricalLoan.data,
                historicalPayment: resHistoricalPayment.data
            };
        } catch (error) {
            // console.log("GAGAL API", error)
            throw error
        }
    }
)

export const fetchDebtorProfileParameter = createAsyncThunk(
    'debtorProfile/fetchDebtorProfileParameter',
    async (idDebitur, thunkAPI) => {
        try {
            const response = await getAPI(`debtor-profile/debtor-profile-parameter?id_debitur=${idDebitur}`);
            // console.log("SUKSES API", response)
            return response
        } catch (error) {
            // console.log("GAGAL API", error)
            throw error
        }
    }
)

export const fetchSummaryIndikator = createAsyncThunk(
    'debtorProfile/fetchSummaryIndikator',
    async (idDebitur, thunkAPI) => {
        try {
            const response = await getAPI(`debtor-profile/debtor-profile-summary?id_debitur=${idDebitur}`);
            // console.log("SUKSES API", response)
            return response
        } catch (error) {
            // console.log("GAGAL API", error)
            throw error
        }
    }
)

export const createSummaryIndikator = createAsyncThunk(
    'debtorProfile/createSummaryIndikator',
    async (payload, thunkAPI) => {
        try {
            const response = await putAPI(`debtor-profile/debtor-profile-parameter?id_debitur=${payload.id_debitur}`, payload);
            // console.log("SUKSES API", response)
            return response
        } catch (error) {
            // console.log("GAGAL API", error)
            throw error
        }
    }
)

// Historis Pefindo
export const fetchDataDireksi = createAsyncThunk(
    'debtorProfile/fetchDataDireksi',
    async (idDebitur, thunkAPI) => {
        try {
            const response = await getAPI(`direksi-by-id?id=${idDebitur}`);
            // console.log("SUKSES API", response)
            return response.data
        } catch (error) {
            // console.log("GAGAL API", error)
            throw error
        }
    }
)
export const fetchSummaryHistoris = createAsyncThunk(
    'debtorProfile/fetchSummaryHistoris',
    async (idDebitur, thunkAPI) => {
        try {
            const response = await getAPI(`debtor-profile/historis-pefindo-perusahaan?id_debitur=${idDebitur}`);
            // console.log("SUKSES API", response)
            return response
        } catch (error) {
            // console.log("GAGAL API", error)
            throw error
        }
    }
)
export const createSummaryHistoris = createAsyncThunk(
    'debtorProfile/createSummaryHistoris',
    async (payload, thunkAPI) => {
        try {
            const response = await putAPI(`debtor-profile/historis-pefindo-perusahaan?id_debitur=${payload.id_debitur}`, payload.data);
            // console.log("SUKSES API", response)
            return response
        } catch (error) {
            // console.log("GAGAL API", error)
            throw error
        }
    }
)


const initialState = {
    summaryIndikator: [],
    historisPefindo: [],
    inputtedKtp: [],
    idDebiturDP: null,
    isLoadingSubmit: false,
    isFirstTime: true,
    isEditing: false,
    skipUpdateState: false
}

export const debtorProfileSlice = createSlice({
    name: "debtorProfile",
    initialState,
    reducers: {
        setSummaryIndikator: (state, action) => {
            state.summary = action.payload
        },
        pushHistoryPefindo: (state, action) => {
            state.historisPefindo = [...state.historisPefindo, action.payload]
        },
        setHistoryPefindo: (state, action) => {
            state.historisPefindo = action.payload
        },
        setInputtedKtp: (state, action) => {
            state.inputtedKtp = [...state.inputtedKtp, action.payload]
        },
        resetInputtedKtp: (state, action) => {
            state.inputtedKtp = []
        },
        setLoadingSubmit: (state, action) => {
            state.isLoadingSubmit = action.payload
        },
        setEditing: (state, action) => {
            state.isEditing = action.payload
        },
        setIsFirstTime: (state, action) => {
            state.isFirstTime = action.payload
        },
        setSkipUpdateState: (state, action) => {
            state.skipUpdateState = action.payload
        },
        resetDebtorProfile: () => initialState
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchSummaryIndikator.fulfilled, (state, action) => {
                state.idDebiturDP = action.meta.arg;
                state.summaryIndikator = action.payload
            })
            .addCase(fetchSummaryHistoris.fulfilled, (state, action) => {
                if(!state.skipUpdateState)
                    state.historisPefindo = action.payload
            })
            .addCase(createSummaryIndikator.pending, (state, action) => {
                state.isLoadingSubmit = true
            })
            .addCase(createSummaryIndikator.fulfilled, (state, action) => {
                state.isLoadingSubmit = false
            })
            .addCase(createSummaryIndikator.rejected, (state, action) => {
                state.isLoadingSubmit = false
            })
            .addCase(createSummaryHistoris.pending, (state, action) => {
                state.isLoadingSubmit = true
            })
            .addCase(createSummaryHistoris.fulfilled, (state, action) => {
                state.isLoadingSubmit = false
                state.isEditing = false
                state.isFirstTime = false
            })
            .addCase(createSummaryHistoris.rejected, (state, action) => {
                state.isLoadingSubmit = false
                state.isEditing = false
            })
    }
})

export const { 
    setSummaryIndikator,
    setHistoryPefindo,
    pushHistoryPefindo,
    setInputtedKtp,
    setLoadingSubmit,
    setEditing,
    setIsFirstTime,
    setSkipUpdateState,
    resetDebtorProfile,
    resetInputtedKtp
    // setLoadFromAPI
} = debtorProfileSlice.actions

export default debtorProfileSlice.reducer