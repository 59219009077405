import moment from "moment";

export const convertDateFormatNumber = (value, formatString) => {
  const ym = moment(value, formatString, true);
  if(ym.isValid()){
    return ym.format("YYYY-MM");
  }else{
    const yr = moment(value, "YYYY", true);  
    if(yr.isValid()){
      return yr.format("YYYY-12");
    }else{
      return undefined;
    }
  }
}

export const convertDateFormatString = (value, formatString) => {
  const yr = moment(value, "YYYY-12", true);  
  if(yr.isValid()){
    return yr.format("YYYY");
  }else{
    const ym = moment(value, "YYYY-MM", true);
    if(ym.isValid()){
      return ym.format(formatString);
    }else{
      return undefined;
    }
  }
}

export const formatText = (text) => {
  if(text.includes("_")){
    const word = text.split("_");
    const firstWord = word[0].charAt(0).toUpperCase() + word[0].slice(1).toLowerCase();
    const restOfWord = word.slice(1).join(" ").toLowerCase();
    return `${firstWord} ${restOfWord}`;
  }else{
    return text.toUpperCase();
  }
}

export const formatTextAssumption = (text) => {
  const co = "co";
  const evWords = ["issues", "evaluation"];
  const lowerWords = ["dari", "and", "of", "to"];
  const upperWords = ["ccc", "dscr"];
  const removeWords = ["minimum"];
  const words = text.split("_");
  
  const capitalizedWords = words.map((word) => {
    if(upperWords.includes(word))
      return word.toUpperCase();
    else if(lowerWords.includes(word))
      return word.toLowerCase();
    else if(removeWords.includes(word))
      return "";
    else if(word.includes("industryrisk"))
      return "Industry Risk 1";
    else if(word === co)
      return word.replace(co, "the Co-founder");

    const result = word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    if(evWords.includes(word))
      return result + ",";

    return result;
  });

  return capitalizedWords.join(" ");
}

export const dataWithoutId = (arr) => arr.map(({ id, ...data }) => data)
