import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { 
    getAPI, 
    // postAPI, 
    // putAPI 
} from '../../services/data'

export const fetchMasterById = createAsyncThunk(
    'formulir/fetchMasterById',
    async (idDebitur, thunkAPI) => {
        const response = await getAPI(`formulir/master?id=${idDebitur}`);
        return response.data
    }
)

export const fetchAktaById = createAsyncThunk(
    'formulir/fetchAktaById',
    async (idDebitur, thunkAPI) => {
        const response = await getAPI(`formulir/akte?id_debitur=${idDebitur}`);
        let mergedAkta = {};
        for (const akta of response.data) {
            if (akta.jenis_akta == "Akte Pendirian") {
                mergedAkta ={
                    ...mergedAkta,
                    akte_pendirian: akta
                }
                // console.log("FIRST", akta)
            } else if (akta.jenis_akta == "Akte Perubahan Direksi Terbaru") {
                mergedAkta ={
                    ...mergedAkta,
                    akte_perubahan_direksi: akta
                }
                // console.log("SECOND", akta)   
            } else if (akta.jenis_akta == "Akte Perubahan Saham Terbaru") {
                mergedAkta ={
                    ...mergedAkta,
                    akte_perubahan_saham: akta
                }
                // console.log("THIRD", akta) 
            }
        }
        return mergedAkta
    }
)

export const fetchWewenangById = createAsyncThunk(
    'formulir/fetchWewenangById',
    async (idDebitur, thunkAPI) => {
        const response = await getAPI(`formulir/wewenang-direksi?id_debitur=${idDebitur}`);

        if(thunkAPI.getState().formulir.skipUpdateState) return response.data;
        
        const mergedData = {
            wewenang_direksi_atas_pemberian_atau_penerimaan_pinjaman: response.data[0].wewenang_direksi_atas_pemberian_atau_penerimaan_pinjaman,
            masa_berakhir_direksi: response.data[0].masa_berakhir_direksi,
            wewenang_direksi_untuk_mewakili_perseroan: response.data[0].wewenang_direksi_untuk_mewakili_perseroan,
            pasal_pengambilan_keputusan_sirkuler: response.data[0].pasal_pengambilan_keputusan_sirkuler
        }
        return mergedData;
    }
)

export const fetchPemegangSaham = createAsyncThunk(
    'formulir/fetchPemegangSaham',
    async (idDebitur, thunkAPI) => {
        const response = await getAPI(`formulir/pemegang-saham?id_debitur=${idDebitur}`);
        return response.data;
    }
)

export const fetchDireksi = createAsyncThunk(
    'formulir/fetchDireksi',
    async (idDebitur, thunkAPI) => {
        const response = await getAPI(`formulir/direksi?id_debitur=${idDebitur}`);
        return response.data;
    }
)

export const fetchKomisaris = createAsyncThunk(
    'formulir/fetchKomisaris',
    async (idDebitur, thunkAPI) => {
        const response = await getAPI(`formulir/komisaris?id_debitur=${idDebitur}`);
        return response.data;
    }
)

// export const createFormulir = createAsyncThunk(
//     'formulir/createFormulir',
//     async (payload, thunkAPI) => {

//         try {
//             const response = await postAPI(`master`, payload);
//             // console.log("SUKSES API", response)
//             return response;
//         } catch (error) {
//             // console.log("GAGAL API", error)
//             throw error
//         }
//     }
// )

const initialState = {
    dataFormulir: {
        master: {},
        pemegangSaham: [],
        direksi: [],
        komisaris: []
    },
    isEmptyLocalData: {
        pemegangSaham: false,
        direksi: false,
        komisaris: false
    },
    skipUpdateState: false,
    isLoadingSubmit: false,
    loadFromAPI: false
}

export const formulirSlice = createSlice({
    name: 'formulir',
    initialState,
    reducers: {
        setMaster: (state, action) => {
            state.dataFormulir.master = action.payload
        },
        setPemegangSaham: (state, action) => {
            state.dataFormulir.pemegangSaham = action.payload
        },
        setDireksi: (state, action) => {
            state.dataFormulir.direksi = action.payload
        },
        setKomisaris: (state, action) => {
            state.dataFormulir.komisaris = action.payload
        },
        setLoadingSubmit: (state, action) => {
            state.isLoadingSubmit = action.payload
        },
        setIsEmptyLocalDataPemegangSaham: (state, action) => {
            state.isEmptyLocalData.pemegangSaham = action.payload
        },
        setIsEmptyLocalDataDireksi: (state, action) => {
            state.isEmptyLocalData.direksi = action.payload
        },
        setIsEmptyLocalDataKomisaris: (state, action) => {
            state.isEmptyLocalData.komisaris = action.payload
        },
        setSkipUpdateState: (state, action) => {
            state.skipUpdateState = action.payload
        },
        setLoadFromAPI: (state, action) => {
            state.loadFromAPI = action.payload
        },
        resetDataFormulir: () => initialState
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchMasterById.fulfilled, (state, action) => {
                if(!state.skipUpdateState)
                    state.dataFormulir.master = action.payload
            })
            .addCase(fetchAktaById.fulfilled, (state, action) => {
                if(!state.skipUpdateState)
                    state.dataFormulir.master = {
                        ...state.dataFormulir.master,
                        ...action.payload
                    }
            })
            .addCase(fetchWewenangById.fulfilled, (state, action) => {
                if(!state.skipUpdateState)
                    state.dataFormulir.master = {
                        ...state.dataFormulir.master,
                        ...action.payload
                    }
            })
    }
})

export const { 
    setMaster,
    setPemegangSaham,
    setDireksi,
    setKomisaris,
    setLoadingSubmit,
    setIsEmptyLocalDataPemegangSaham,
    setIsEmptyLocalDataDireksi,
    setIsEmptyLocalDataKomisaris,
    setSkipUpdateState,
    setLoadFromAPI,
    resetDataFormulir
} = formulirSlice.actions

export default formulirSlice.reducer