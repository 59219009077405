import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { 
    getAPI,
    // getAPIPython,
    postAPIPython,
    postAPI,
    putAPI, 
    // putAPI 
} from '../../services/data'

export const createCreditGiven = createAsyncThunk(
    'creditGiven/createCreditGiven',
    async (payload, thunkAPI) => {
        try {
            await postAPIPython("input_credit_given", payload.invoice);
            await postAPI("summary-credit-given", payload.persentase);
            // console.log("SUKSES API", response)
            return true;
        } catch (error) {
            // console.log("GAGAL API", error)
            throw error
        }
    }
)

export const updateInvoice = createAsyncThunk(
    'creditGiven/updateInvoice',
    async (payload, thunkAPI) => {
        try {
            const res = await putAPI(`credit-given/credit-given-input?id_debitur=${payload.id}`, payload.invoice);
            // console.log("SUKSES API", response)
            return res;
        } catch (error) {
            // console.log("GAGAL API", error)
            throw error
        }
    }
)

export const updateCreditGiven = createAsyncThunk(
    'creditGiven/updateCreditGiven',
    async (payload, thunkAPI) => {
        try {
            const res = await putAPI(`credit-given/credit-given-summary-parameter?id_debitur=${payload.id_debitur}`, payload);
            // console.log("SUKSES API", response)
            return res;
        } catch (error) {
            // console.log("GAGAL API", error)
            throw error
        }
    }
)

export const fetchInvoiceList = createAsyncThunk(
    'creditGiven/fetchInvoiceList',
    async (idDebitur, thunkAPI) => {
        try {
            const response = await getAPI(`credit-given/credit-given-input?id_debitur=${idDebitur}`);
            // console.log("SUKSES API", response)
            return response;
        } catch (error) {
            // console.log("GAGAL API", error)
            throw error
        }
    }
)

export const fetchSummary = createAsyncThunk(
    'creditGiven/fetchSummary',
    async (idDebitur, thunkAPI) => {
        try {
            const response = await getAPI(`credit-given/credit-given-summary-view?id_debitur=${idDebitur}`);
            // console.log("SUKSES API", response)
            return response;
        } catch (error) {
            // console.log("GAGAL API", error)
            throw error
        }
    }
)

const initialState = {
    dataInvoice: [],
    dataPembiayaan: [],
    isLoadingSubmit: false,
    isFirstTime: true,
    isEditing: false,
    isSubmit: false,
    skipUpdateState: false
}

export const creditGivenSlice = createSlice({
    name: 'creditGiven',
    initialState,
    reducers: {
        setDataInvoice: (state, action) => {
            state.dataInvoice = action.payload
        },
        setDataPembiayaan: (state, action) => {
            state.dataPembiayaan = action.payload
        },
        setEditing: (state, action) => {
            state.isEditing = action.payload
        },
        setIsFirstTime: (state, action) => {
            state.isFirstTime = action.payload
        },
        setIsSubmit: (state, action) => {
            state.isSubmit = action.payload
        },
        setSkipUpdateState: (state, action) => {
            state.skipUpdateState = action.payload
        },
        resetCreditGiven: () => initialState
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchInvoiceList.fulfilled, (state, action) => {
                if(!state.skipUpdateState)
                    state.dataInvoice = action.payload
            })
            .addCase(fetchSummary.fulfilled, (state, action) => {
                if(!state.skipUpdateState)
                    state.dataPembiayaan = action.payload
            })
            .addCase(createCreditGiven.pending, (state, action) => {
                state.isLoadingSubmit = true
            })
            .addCase(createCreditGiven.fulfilled, (state, action) => {
                state.isLoadingSubmit = false
            })
            .addCase(updateInvoice.pending, (state, action) => {
                state.isLoadingSubmit = true
            })
            .addCase(updateInvoice.fulfilled, (state, action) => {
                state.isLoadingSubmit = false
            })
            .addCase(updateInvoice.rejected, (state, action) => {
                state.isLoadingSubmit = false
            })
            .addCase(updateCreditGiven.pending, (state, action) => {
                state.isLoadingSubmit = true
            })
            .addCase(updateCreditGiven.fulfilled, (state, action) => {
                state.isLoadingSubmit = false
                state.isFirstTime = false
            })
            .addCase(updateCreditGiven.rejected, (state, action) => {
                state.isLoadingSubmit = false
            })
    }
})

export const { 
    setDataInvoice,
    setDataPembiayaan,
    setLoadingSubmit,
    setEditing,
    setIsFirstTime,
    setIsSubmit,
    setSkipUpdateState,
    resetCreditGiven,
    // setLoadFromAPI
} = creditGivenSlice.actions

export default creditGivenSlice.reducer