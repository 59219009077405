import React from 'react';
import { Steps, Form, Input, Button, message, Select, Table, InputNumber, Modal, Popconfirm, Tooltip } from "antd";
import { useState,useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAPIPython, getAPI } from '../../services/data';
import { DeleteOutlined } from '@ant-design/icons';
import { convertDateFormatNumber, convertDateFormatString } from "../../utils/util";
import { 
  setEditing, 
  setIsFirstTime, 
  setIsSubmit, 
  setPLInput,
  pushPLInput,
  setFinancialYr,
  fetchInputProfitLoss,
} from "../../store/actions/plSlice";

const { Option } = Select;
const { confirm } = Modal;

const errorSubmit = (response) => {
  message.error(response);
};

const success = (response) => {
  message.success(response);
};

const postData = async (object) => {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(object),
  };
  
  const response = await fetch(process.env.REACT_APP_PYTHON_SCORING + "input_profit_loss",requestOptions);
  console.log(response);
  const result = await response.json();
  console.log(result);
  return result;
};

const PLComponent = (props) => {
  const {object} = props;
  const parent = JSON.parse(object);
  const {dropdown, title, segment} = parent;
  const [form] = Form.useForm();

  const dispatch = useDispatch();

  // Load redux state
  const idDebitur = useSelector(state => state.home.id_debitur);
  const neracaTahun = useSelector(state => state.neraca.neracaTahun);
  const PLInput = useSelector(state => state.pl.PLInput);
  const financialYr = useSelector(state => state.pl.financialYr);
  const isEditing = useSelector(state => state.pl.isEditing);
  const isFirstTime = useSelector(state => state.pl.isFirstTime);
  const isLoadingSubmit = useSelector(state => state.pl.isLoadingSubmit);
  const isSubmit = useSelector(state => state.pl.isSubmit);

  // const [content, setContent] = useState();
  // const [tableData, setTableData] = useState([]);
  // const [inputtedKeterangan,setInputtedKeterangan] = useState([]);
  // const [inputtedInfo,setInputtedInfo] = useState([]);
  // const [submitTrigger,setSubmitTrigger] = useState([]);
  // const [spin,setSpinner] = useState(false);
  // const [settings, setSettings] = useState(0);

  // console.log(dropdown)
  // console.log("INPUTTED KETERANGAN PL",inputtedKeterangan);
  // console.log("KETERANGAN PL",dropdown);
  // let filtered = dropdown.filter((value) => !inputtedKeterangan.includes(value['id']))

  // const apiGetKeterangan = async (id,year,kategori) => {
  //   const data = await getAPIPython(`pl_kategori-get?id_debitur=${id}&year=${year}&segment=${kategori}`);
  //   console.log(data);
  //   const result = data['data'];
  //   setInputtedInfo(result)
  // }

  // useEffect(() => {
  //   if(masterData && year && dropdown) apiGetKeterangan(masterData['data']['id'],year,dropdown[0]['category'])
  // },[submitTrigger,year])

  // useEffect(() => {
  //   switch (settingValue['setting']) {
  //     case "m":
  //       setSettings(1000000);
  //       break;
  //     case "b":
  //       setSettings(1000000000);
  //       break;
  //     default:
  //       setSettings(1);
  //       break;
  //   }
  // },[settingValue]);

  // const handleSubmit = async (values) => {
  //   setSpinner(true);
  //   let data = values
  //   let found = false;

  //   data["Year"] = year.toString();
  //   data["Id_Debitur"] = masterData["data"]["id"];
  //   //  data['Value']*=settings

  //   console.log(data);

  //   //Validation
  //   //  inputtedKeterangan.map((item) => {
  //   //    if (item == data["Type_of_Income_Expense"]) found = true;
  //   //  });

  //   //  if (found) {
  //   //    setSpinner(false);
  //   //    return errorSubmit("Type of income expense tidak bisa diinput lagi !");
  //   //  }

  //   // console.log(data);
  //   console.log("INPUT KETERANGAN",inputtedKeterangan);

  //   //  const tes = await postDataNew(data);
  //   //  console.log(tes);

  //   postData(data).then((response) => {
  //     console.log(response);
  //     if(response['statusCode'] == '200'){
  //       setSpinner(false);
  //       // setInputtedKeterangan([...inputtedKeterangan,data["Type_of_Income_Expense"]]);
  //       // setInputtedInfo([...inputtedInfo,getCategoryName(data)])
  //       setSubmitTrigger(!submitTrigger);
  //       success(response["message"]);
  //     }else{
  //       setSpinner(false)
  //       errorSubmit(response["message"])
  //     }
  //   }).catch((error) => {
  //     console.log(error);
  //     errorSubmit("Server Error")
  //     setSpinner(false)
  //   })
  // };

  const apiGetProfitLoss = async (id) => {
    const res = await dispatch(fetchInputProfitLoss(id)).unwrap();
    if(res.length !== 0){
      dispatch(setIsFirstTime(false));  
    }else{
      dispatch(setIsFirstTime(true)); 
      dispatch(setEditing(false));
      dispatch(setIsSubmit(false));
    }
  }

  useEffect(() => {
    if(!isEditing || neracaTahun.some(item => item.id_debitur !== idDebitur)){
      apiGetProfitLoss(idDebitur);    
    }
  },[idDebitur, financialYr]);

  const deleteRow = async (record) => {
    const existingPLInput = JSON.parse(JSON.stringify(PLInput));

    const indexToDelete = existingPLInput.findIndex((element) =>
      dropdown.find((obj) => obj.id === element.type_of_income_expense)?.type_income_expense === record.keterangan  &&
      element.year === convertDateFormatNumber(record.year, "MMM YYYY")
    );

    if(indexToDelete !== -1){
      existingPLInput.splice(indexToDelete, 1);
      dispatch(setPLInput(existingPLInput));
    }
  }

  const filteredData = PLInput.filter((item) => 
    item?.id_debitur === idDebitur && 
    item?.year === financialYr &&
    dropdown.find((d) => d.id == item.type_of_income_expense)?.segment == segment 
  );  

  const content = filteredData.map((item) => ({
    year: convertDateFormatString(item.year, "MMM YYYY"),
    keterangan: dropdown.find((obj) => obj.id === item.type_of_income_expense)?.type_income_expense,
    value: item.value
  }));

  const columns = [
    {
      title: "Year",
      dataIndex:"year",
    },
    {
      title: "Keterangan",
      dataIndex: "keterangan",
    },
    {
      title: "Amount",
      dataIndex: "value",
      render: (_, record) => {
        return <p>{"Rp" + record["value"].toLocaleString()}</p>;
      }
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (_, record) => {
        return (
          <>
            {!isSubmit ? (
              <Popconfirm title="Confirm deletion?" onConfirm={() => deleteRow(record)} okButtonProps={{type: "secondary"}}>
                <Tooltip title="delete">
                  <Button type="secondary" icon={<DeleteOutlined />}/>
                </Tooltip>
              </Popconfirm>
            ) : (
              <Tooltip title="delete">
                <Button type="secondary" disabled={isSubmit} icon={<DeleteOutlined />}/>
              </Tooltip>
            )}           
          </>
        )
      },
    }
  ];

  const handleSubmit = async (values) => {
    const payload = JSON.parse(JSON.stringify(values.values));    
    payload.id_debitur = idDebitur;
    payload.year = financialYr; 

    // console.log(payload);
    
    try {
      const nonNegativeIE = ["IE08", "IE09", "IE10"].includes(payload.type_of_income_expense);
      const existingPLInput = JSON.parse(JSON.stringify(PLInput));
      const findIndex = existingPLInput.findIndex((item) => item.id_debitur == payload.id_debitur && item.year == payload.year && item.type_of_income_expense == payload.type_of_income_expense);
      
      if(nonNegativeIE && payload.value < 0){
        errorSubmit("Nilai tidak boleh negatif");
        form.setFieldsValue({ value: null });
      }else{
        if(findIndex >= 0){
          existingPLInput.splice(findIndex, 1, payload);
          dispatch(setPLInput(existingPLInput));
        }else{
          dispatch(pushPLInput(payload));
        }
        dispatch(setIsFirstTime(false));
        dispatch(setEditing(true));
        success("Data tersimpan"); 
      }
    } catch (error) {
      errorSubmit("Gagal menyimpan data");      
    }
  }

  return (
    <div className="mx-5">
      <p className="text-2xl font-bold my-4">{title}</p>
      <Form
        form={form}
        disabled={isEditing ? false : true}
        onFinish={(values) => handleSubmit({ values })}
        labelCol={{
          span: 6,
        }}
        labelAlign="left"
        wrapperCol={{
          span: 10,
        }}
        initialValues={{
          remember: true,
        }}
      >
        <div>
          <Form.Item
            key="keterangan"
            label="Input Keterangan"
            name="type_of_income_expense"
            rules={[
              {
                required: true,
                message: "Masukkan keterangan",
              },
            ]}
          >
            <Select>
              {dropdown.map((item, i) => {
                return (
                  <Option key={i} value={item.id}>{item.type_income_expense}</Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item
            key="amount"
            label="Input Amount"
            name="value"
            rules={[
              {
                required: true,
                message: "Masukkan amount",
              },
            ]}
          >
            <InputNumber
              addonBefore="Rp"
              formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              style={{ width: "100%" }}
            />
          </Form.Item>
        </div>
        <div className="float-right">
          <Form.Item>
            <Button htmlType="submit" loading={isLoadingSubmit}>
              Save
            </Button>
          </Form.Item>
        </div>
      </Form>
      <Table dataSource={content} columns={columns} pagination={false}/>
    </div>
  );
}

export default PLComponent